// src/components/institutions/InstitutionDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { 
  School, Building2, Building, Landmark, ArrowLeft, 
  MapPin, Phone, Mail, Globe, Users, Book, Briefcase,
  Calendar, Edit, Trash2, FileText, ExternalLink, ChevronDown, ChevronUp,
  AlertTriangle, Shield, Lock
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useInstitutionPermissions } from '../../hooks/useInstitutionPermissions';
import { db } from '../../firebase/config';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';

const InstitutionDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  const { 
    canViewInstitution, 
    canEditInstitution 
  } = useInstitutionPermissions();
  
  const [institution, setInstitution] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [permissionError, setPermissionError] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  const [openSection, setOpenSection] = useState('all'); // For mobile accordion view
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  
  // Fetch institution details
  useEffect(() => {
    const fetchInstitutionDetails = async () => {
      if (!currentUser) {
        navigate('/login');
        return;
      }
      
      setIsLoading(true);
      setError('');
      setPermissionError(false);
      
      try {
        const institutionDoc = await getDoc(doc(db, "institutions", id));
        
        if (!institutionDoc.exists()) {
          setError("Institution not found");
          setIsLoading(false);
          return;
        }
        
        const institutionData = {
          id: institutionDoc.id,
          ...institutionDoc.data()
        };
        
        // Check if user has permission to view this institution
        if (!canViewInstitution(institutionData)) {
          setPermissionError(true);
          setIsLoading(false);
          return;
        }
        
        setInstitution(institutionData);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching institution details:', err);
        setError('Failed to load institution details');
        setIsLoading(false);
      }
    };
    
    fetchInstitutionDetails();
  }, [id, currentUser, canViewInstitution, navigate]);
  
  const handleDeleteInstitution = async () => {
    if (!institution || !canEditInstitution(institution)) {
      setError("You don't have permission to delete this institution");
      return;
    }
    
    try {
      setIsLoading(true);
      await deleteDoc(doc(db, "institutions", id));
      navigate('/institutions');
    } catch (err) {
      console.error('Error deleting institution:', err);
      setError('Failed to delete institution');
      setIsLoading(false);
    }
  };
  
  const getInstitutionIcon = (type) => {
    switch (type) {
      case 'School':
        return <School className="h-12 w-12 text-blue-600" />;
      case 'Circuit Office':
        return <Building2 className="h-12 w-12 text-green-600" />;
      case 'District Office':
        return <Building className="h-12 w-12 text-purple-600" />;
      case 'Provincial Department':
        return <Landmark className="h-12 w-12 text-red-600" />;
      default:
        return <School className="h-12 w-12 text-blue-600" />;
    }
  };
  
  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  
  const isOpen = (section) => {
    return openSection === section || openSection === 'all';
  };
  
  // Format date helper function
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    try {
      const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(date);
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };
  
  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center py-16">
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent"></div>
          <p className="mt-4 text-gray-600">Loading institution details...</p>
        </div>
      </div>
    );
  }
  
  if (permissionError) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 border border-red-200 text-red-800 px-6 py-8 rounded-md flex flex-col items-center">
          <Lock className="h-12 w-12 text-red-500 mb-4" />
          <h2 className="text-xl font-bold mb-2">Access Denied</h2>
          <p className="text-center mb-6">You don't have permission to view this institution.</p>
          <button
            onClick={() => navigate('/institutions')}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back to Institutions
          </button>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded-md">
          {error}
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Go Back
          </button>
        </div>
      </div>
    );
  }
  
  if (!institution) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-yellow-50 border border-yellow-200 text-yellow-800 px-4 py-3 rounded-md">
          Institution not found
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate('/institutions')}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back to Institutions
          </button>
        </div>
      </div>
    );
  }
  
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Back button and actions */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
        <button
          onClick={() => navigate(-1)}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </button>
        
        {canEditInstitution(institution) && (
          <div className="mt-4 sm:mt-0 space-x-2">
            <Link
              to={`/institutions/edit/${id}`}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Edit className="mr-2 h-4 w-4" />
              Edit
            </Link>
            
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="inline-flex items-center px-4 py-2 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <Trash2 className="mr-2 h-4 w-4" />
              Delete
            </button>
          </div>
        )}
      </div>
      
      {/* Institution header */}
      <div className="bg-white shadow rounded-lg overflow-hidden mb-8">
        <div className="p-6">
          <div className="flex flex-col md:flex-row md:items-center">
            <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-6">
              {getInstitutionIcon(institution.type)}
            </div>
            <div className="flex-1">
              <h1 className="text-2xl font-bold text-gray-900">{institution.name}</h1>
              <div className="flex flex-col sm:flex-row sm:items-center text-sm text-gray-600 mt-1 space-y-1 sm:space-y-0 sm:space-x-4">
                <span className="flex items-center">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    {institution.type}
                  </span>
                </span>
                {institution.phase && (
                  <span className="flex items-center">
                    <Book className="mr-1 h-4 w-4" />
                    {institution.phase} School
                  </span>
                )}
                <span className="flex items-center">
                  <MapPin className="mr-1 h-4 w-4" />
                  {institution.location || 'N/A'}
                </span>
              </div>
            </div>
          </div>
          
          {institution.description && (
            <div className="mt-6 border-t pt-4">
              <div className="text-sm">{institution.description}</div>
            </div>
          )}
        </div>
      </div>
      
      {/* Tabs for larger screens */}
      <div className="hidden md:block mb-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <button
              onClick={() => setActiveTab('overview')}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'overview'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('contact')}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'contact'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Contact Information
            </button>
            <button
              onClick={() => setActiveTab('leadership')}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'leadership'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Leadership
            </button>
            <button
              onClick={() => setActiveTab('documents')}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'documents'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Documents
            </button>
          </nav>
        </div>
      </div>
      
      {/* Mobile accordion */}
      <div className="md:hidden space-y-4 mb-6">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <button 
            className="w-full px-6 py-4 flex items-center justify-between focus:outline-none"
            onClick={() => toggleSection('overview')}
          >
            <span className="text-lg font-medium text-gray-900">Overview</span>
            {isOpen('overview') ? <ChevronUp className="h-5 w-5 text-gray-500" /> : <ChevronDown className="h-5 w-5 text-gray-500" />}
          </button>
          
          {isOpen('overview') && (
            <div className="px-6 pb-4">
              <div className="space-y-4">
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Institution Type</h3>
                  <p className="mt-1 text-sm text-gray-900">{institution.type}</p>
                </div>
                
                {institution.phase && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">School Phase</h3>
                    <p className="mt-1 text-sm text-gray-900">{institution.phase}</p>
                  </div>
                )}
                
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Location</h3>
                  <div className="mt-1 text-sm text-gray-900">
                    {institution.province && <p>{institution.province}</p>}
                    {institution.district && <p>{institution.district}</p>}
                    {institution.circuit && <p>{institution.circuit}</p>}
                    {institution.address && <p>{institution.address}</p>}
                  </div>
                </div>
                
                {institution.foundedYear && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Founded</h3>
                    <p className="mt-1 text-sm text-gray-900">{institution.foundedYear}</p>
                  </div>
                )}
                
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Statistics</h3>
                  <div className="mt-2 grid grid-cols-2 gap-4">
                    {institution.studentCount && (
                      <div className="bg-blue-50 rounded-md p-3">
                        <p className="text-xs text-gray-500">Students</p>
                        <p className="text-lg font-semibold text-blue-700">{institution.studentCount}</p>
                      </div>
                    )}
                    {institution.staffCount && (
                      <div className="bg-green-50 rounded-md p-3">
                        <p className="text-xs text-gray-500">Staff</p>
                        <p className="text-lg font-semibold text-green-700">{institution.staffCount}</p>
                      </div>
                    )}
                    {institution.classroomCount && (
                      <div className="bg-purple-50 rounded-md p-3">
                        <p className="text-xs text-gray-500">Classrooms</p>
                        <p className="text-lg font-semibold text-purple-700">{institution.classroomCount}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <button 
            className="w-full px-6 py-4 flex items-center justify-between focus:outline-none"
            onClick={() => toggleSection('contact')}
          >
            <span className="text-lg font-medium text-gray-900">Contact Information</span>
            {isOpen('contact') ? <ChevronUp className="h-5 w-5 text-gray-500" /> : <ChevronDown className="h-5 w-5 text-gray-500" />}
          </button>
          
          {isOpen('contact') && (
            <div className="px-6 pb-4">
              <div className="space-y-4">
                {institution.address && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Address</h3>
                    <p className="mt-1 text-sm text-gray-900">{institution.address}</p>
                  </div>
                )}
                
                {institution.phone && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Phone</h3>
                    <p className="mt-1 text-sm text-gray-900">{institution.phone}</p>
                  </div>
                )}
                
                {institution.email && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Email</h3>
                    <p className="mt-1 text-sm text-gray-900">{institution.email}</p>
                  </div>
                )}
                
                {institution.website && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Website</h3>
                    <p className="mt-1 text-sm text-gray-900">
                      <a 
                        href={`https://${institution.website}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 flex items-center"
                      >
                        {institution.website}
                        <ExternalLink className="ml-1 h-3 w-3" />
                      </a>
                    </p>
                  </div>
                )}
                
                {institution.socialMedia && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Social Media</h3>
                    <div className="mt-1 space-y-1">
                      {institution.socialMedia.facebook && (
                        <p className="text-sm text-gray-900">
                          <a 
                            href={`https://${institution.socialMedia.facebook}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800"
                          >
                            Facebook
                          </a>
                        </p>
                      )}
                      {institution.socialMedia.instagram && (
                        <p className="text-sm text-gray-900">
                          <a 
                            href={`https://${institution.socialMedia.instagram}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800"
                          >
                            Instagram
                          </a>
                        </p>
                      )}
                      {institution.socialMedia.twitter && (
                        <p className="text-sm text-gray-900">
                          <a 
                            href={`https://${institution.socialMedia.twitter}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800"
                          >
                            Twitter
                          </a>
                        </p>
                      )}
                      {institution.socialMedia.youtube && (
                        <p className="text-sm text-gray-900">
                          <a 
                            href={`https://${institution.socialMedia.youtube}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800"
                          >
                            YouTube
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <button 
            className="w-full px-6 py-4 flex items-center justify-between focus:outline-none"
            onClick={() => toggleSection('leadership')}
          >
            <span className="text-lg font-medium text-gray-900">Leadership</span>
            {isOpen('leadership') ? <ChevronUp className="h-5 w-5 text-gray-500" /> : <ChevronDown className="h-5 w-5 text-gray-500" />}
          </button>
          
          {isOpen('leadership') && (
            <div className="px-6 pb-4">
              <div className="space-y-4">
                {institution.type === 'School' && (
                  <>
                    {institution.principal && (
                      <div>
                        <h3 className="text-sm font-medium text-gray-500">Principal</h3>
                        <p className="mt-1 text-sm font-medium text-gray-900">{institution.principal}</p>
                        {institution.principalEmail && <p className="text-sm text-gray-600">{institution.principalEmail}</p>}
                        {institution.principalPhone && <p className="text-sm text-gray-600">{institution.principalPhone}</p>}
                      </div>
                    )}
                    
                    {institution.deputyPrincipal && (
                      <div>
                        <h3 className="text-sm font-medium text-gray-500">Deputy Principal</h3>
                        <p className="mt-1 text-sm font-medium text-gray-900">{institution.deputyPrincipal}</p>
                        {institution.deputyEmail && <p className="text-sm text-gray-600">{institution.deputyEmail}</p>}
                        {institution.deputyPhone && <p className="text-sm text-gray-600">{institution.deputyPhone}</p>}
                      </div>
                    )}
                  </>
                )}
                
                {institution.type === 'Circuit Office' && institution.manager && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Circuit Manager</h3>
                    <p className="mt-1 text-sm font-medium text-gray-900">{institution.manager}</p>
                    {institution.managerEmail && <p className="text-sm text-gray-600">{institution.managerEmail}</p>}
                    {institution.managerPhone && <p className="text-sm text-gray-600">{institution.managerPhone}</p>}
                  </div>
                )}
                
                {institution.type === 'District Office' && institution.director && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">District Director</h3>
                    <p className="mt-1 text-sm font-medium text-gray-900">{institution.director}</p>
                    {institution.directorEmail && <p className="text-sm text-gray-600">{institution.directorEmail}</p>}
                    {institution.directorPhone && <p className="text-sm text-gray-600">{institution.directorPhone}</p>}
                  </div>
                )}
                
                {institution.type === 'Provincial Department' && institution.head && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">Head of Department</h3>
                    <p className="mt-1 text-sm font-medium text-gray-900">{institution.head}</p>
                    {institution.headEmail && <p className="text-sm text-gray-600">{institution.headEmail}</p>}
                    {institution.headPhone && <p className="text-sm text-gray-600">{institution.headPhone}</p>}
                  </div>
                )}
                
                {/* If no leadership info is available */}
                {((institution.type === 'School' && !institution.principal && !institution.deputyPrincipal) ||
                  (institution.type === 'Circuit Office' && !institution.manager) ||
                  (institution.type === 'District Office' && !institution.director) ||
                  (institution.type === 'Provincial Department' && !institution.head)) && (
                  <p className="text-sm text-gray-500">No leadership information available</p>
                )}
              </div>
            </div>
          )}
        </div>
        
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <button 
            className="w-full px-6 py-4 flex items-center justify-between focus:outline-none"
            onClick={() => toggleSection('documents')}
          >
            <span className="text-lg font-medium text-gray-900">Documents</span>
            {isOpen('documents') ? <ChevronUp className="h-5 w-5 text-gray-500" /> : <ChevronDown className="h-5 w-5 text-gray-500" />}
          </button>
          
          {isOpen('documents') && (
            <div className="px-6 pb-4">
              <div className="text-center py-4">
                <FileText className="mx-auto h-12 w-12 text-gray-400" />
                <p className="mt-2 text-sm text-gray-500">No documents available</p>
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Desktop tab content */}
      <div className="hidden md:block">
        {activeTab === 'overview' && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h2 className="text-lg font-medium text-gray-900 mb-4">Institution Details</h2>
                  
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-sm font-medium text-gray-500">Institution Type</h3>
                      <p className="mt-1 text-sm text-gray-900">{institution.type}</p>
                    </div>
                    
                    {institution.phase && (
                      <div>
                        <h3 className="text-sm font-medium text-gray-500">School Phase</h3>
                        <p className="mt-1 text-sm text-gray-900">{institution.phase}</p>
                      </div>
                    )}
                    
                    <div>
                      <h3 className="text-sm font-medium text-gray-500">Location</h3>
                      <div className="mt-1 text-sm text-gray-900">
                        {institution.province && <p>{institution.province}</p>}
                        {institution.district && <p>{institution.district}</p>}
                        {institution.circuit && <p>{institution.circuit}</p>}
                      </div>
                    </div>
                    
                    {institution.address && (
                      <div>
                        <h3 className="text-sm font-medium text-gray-500">Address</h3>
                        <p className="mt-1 text-sm text-gray-900">{institution.address}</p>
                      </div>
                    )}
                    
                    {institution.foundedYear && (
                      <div>
                        <h3 className="text-sm font-medium text-gray-500">Founded</h3>
                        <p className="mt-1 text-sm text-gray-900">{institution.foundedYear}</p>
                      </div>
                    )}
                    
                    {institution.emis && (
                      <div>
                        <h3 className="text-sm font-medium text-gray-500">EMIS Number</h3>
                        <p className="mt-1 text-sm text-gray-900">{institution.emis}</p>
                      </div>
                    )}
                    
                    <div>
                      <h3 className="text-sm font-medium text-gray-500">Last Updated</h3>
                      <p className="mt-1 text-sm text-gray-900">{institution.updatedAt ? formatDate(institution.updatedAt) : 'N/A'}</p>
                    </div>
                  </div>
                </div>
                
                <div>
                  <h2 className="text-lg font-medium text-gray-900 mb-4">Statistics</h2>
                  
                  <div className="grid grid-cols-2 gap-4">
                    {institution.studentCount && (
                      <div className="bg-blue-50 rounded-md p-4">
                        <p className="text-sm text-gray-500">Students</p>
                        <p className="text-2xl font-semibold text-blue-700">{institution.studentCount}</p>
                      </div>
                    )}
                    
                    {institution.staffCount && (
                      <div className="bg-green-50 rounded-md p-4">
                        <p className="text-sm text-gray-500">Total Staff</p>
                        <p className="text-2xl font-semibold text-green-700">{institution.staffCount}</p>
                      </div>
                    )}
                    
                    {institution.teachingStaff && (
                      <div className="bg-indigo-50 rounded-md p-4">
                        <p className="text-sm text-gray-500">Teaching Staff</p>
                        <p className="text-2xl font-semibold text-indigo-700">{institution.teachingStaff}</p>
                      </div>
                    )}
                    
                    {institution.nonTeachingStaff && (
                      <div className="bg-purple-50 rounded-md p-4">
                        <p className="text-sm text-gray-500">Non-Teaching Staff</p>
                        <p className="text-2xl font-semibold text-purple-700">{institution.nonTeachingStaff}</p>
                      </div>
                    )}
                    
                    {institution.classroomCount && (
                      <div className="bg-amber-50 rounded-md p-4">
                        <p className="text-sm text-gray-500">Classrooms</p>
                        <p className="text-2xl font-semibold text-amber-700">{institution.classroomCount}</p>
                      </div>
                    )}
                    
                    {institution.type === 'Provincial Department' && institution.districtsCount && (
                      <div className="bg-purple-50 rounded-md p-4">
                        <p className="text-sm text-gray-500">Districts</p>
                        <p className="text-2xl font-semibold text-purple-700">{institution.districtsCount}</p>
                      </div>
                    )}
                    
                    {(institution.type === 'Provincial Department' || institution.type === 'District Office') && 
                      institution.circuitsCount && (
                      <div className="bg-green-50 rounded-md p-4">
                        <p className="text-sm text-gray-500">Circuits</p>
                        <p className="text-2xl font-semibold text-green-700">{institution.circuitsCount}</p>
                      </div>
                    )}
                    
                    {(institution.type === 'Provincial Department' || 
                      institution.type === 'District Office' || 
                      institution.type === 'Circuit Office') && 
                      institution.schoolsCount && (
                      <div className="bg-blue-50 rounded-md p-4">
                        <p className="text-sm text-gray-500">Schools</p>
                        <p className="text-2xl font-semibold text-blue-700">{institution.schoolsCount}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'contact' && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h2 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h2>
                  
                  <div className="space-y-4">
                    {institution.address && (
                      <div className="flex items-start">
                        <MapPin className="h-5 w-5 text-gray-400 mr-3 mt-0.5" />
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">Address</h3>
                          <p className="mt-1 text-sm text-gray-600">{institution.address}</p>
                        </div>
                      </div>
                    )}
                    
                    {institution.phone && (
                      <div className="flex items-start">
                        <Phone className="h-5 w-5 text-gray-400 mr-3 mt-0.5" />
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">Phone</h3>
                          <p className="mt-1 text-sm text-gray-600">{institution.phone}</p>
                        </div>
                      </div>
                    )}
                    
                    {institution.email && (
                      <div className="flex items-start">
                        <Mail className="h-5 w-5 text-gray-400 mr-3 mt-0.5" />
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">Email</h3>
                          <p className="mt-1 text-sm text-gray-600">{institution.email}</p>
                        </div>
                      </div>
                    )}
                    
                    {institution.website && (
                      <div className="flex items-start">
                        <Globe className="h-5 w-5 text-gray-400 mr-3 mt-0.5" />
                        <div>
                          <h3 className="text-sm font-medium text-gray-900">Website</h3>
                          <p className="mt-1 text-sm text-gray-600">
                            <a 
                              href={`https://${institution.website}`} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:text-blue-800 flex items-center"
                            >
                              {institution.website}
                              <ExternalLink className="ml-1 h-3 w-3" />
                            </a>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                {institution.socialMedia && (
                  <div>
                    <h2 className="text-lg font-medium text-gray-900 mb-4">Social Media</h2>
                    
                    <div className="space-y-4">
                      {institution.socialMedia.facebook && (
                        <div className="flex items-center">
                          <div className="h-10 w-10 bg-blue-100 rounded-lg flex items-center justify-center text-blue-600 mr-4">F</div>
                          <div>
                            <h3 className="text-sm font-medium text-gray-900">Facebook</h3>
                            <p className="mt-1 text-sm text-gray-600">
                              <a 
                                href={`https://${institution.socialMedia.facebook}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:text-blue-800"
                              >
                                {institution.socialMedia.facebook}
                              </a>
                            </p>
                          </div>
                        </div>
                      )}
                      
                      {institution.socialMedia.instagram && (
                        <div className="flex items-center">
                          <div className="h-10 w-10 bg-pink-100 rounded-lg flex items-center justify-center text-pink-600 mr-4">I</div>
                          <div>
                            <h3 className="text-sm font-medium text-gray-900">Instagram</h3>
                            <p className="mt-1 text-sm text-gray-600">
                              <a 
                                href={`https://${institution.socialMedia.instagram}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:text-blue-800"
                              >
                                {institution.socialMedia.instagram}
                              </a>
                            </p>
                          </div>
                        </div>
                      )}
                      
                      {institution.socialMedia.twitter && (
                        <div className="flex items-center">
                          <div className="h-10 w-10 bg-blue-100 rounded-lg flex items-center justify-center text-blue-600 mr-4">T</div>
                          <div>
                            <h3 className="text-sm font-medium text-gray-900">Twitter</h3>
                            <p className="mt-1 text-sm text-gray-600">
                              <a 
                                href={`https://${institution.socialMedia.twitter}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:text-blue-800"
                              >
                                {institution.socialMedia.twitter}
                              </a>
                            </p>
                          </div>
                        </div>
                      )}
                      
                      {institution.socialMedia.youtube && (
                        <div className="flex items-center">
                          <div className="h-10 w-10 bg-red-100 rounded-lg flex items-center justify-center text-red-600 mr-4">Y</div>
                          <div>
                            <h3 className="text-sm font-medium text-gray-900">YouTube</h3>
                            <p className="mt-1 text-sm text-gray-600">
                              <a 
                                href={`https://${institution.socialMedia.youtube}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:text-blue-800"
                              >
                                {institution.socialMedia.youtube}
                              </a>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'leadership' && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-6">Leadership Team</h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {institution.type === 'School' && institution.principal && (
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 mr-4">
                        <div className="h-16 w-16 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-medium text-xl">
                          {institution.principal.substring(0, 2).toUpperCase()}
                        </div>
                      </div>
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">Principal</h3>
                        <p className="text-blue-600">{institution.principal}</p>
                        <div className="mt-2 space-y-1">
                          {institution.principalEmail && (
                            <p className="text-sm text-gray-600 flex items-center">
                              <Mail className="h-4 w-4 text-gray-400 mr-2" />
                              {institution.principalEmail}
                            </p>
                          )}
                          {institution.principalPhone && (
                            <p className="text-sm text-gray-600 flex items-center">
                              <Phone className="h-4 w-4 text-gray-400 mr-2" />
                              {institution.principalPhone}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                
                {institution.type === 'School' && institution.deputyPrincipal && (
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 mr-4">
                        <div className="h-16 w-16 bg-green-100 rounded-full flex items-center justify-center text-green-600 font-medium text-xl">
                          {institution.deputyPrincipal.substring(0, 2).toUpperCase()}
                        </div>
                      </div>
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">Deputy Principal</h3>
                        <p className="text-green-600">{institution.deputyPrincipal}</p>
                        <div className="mt-2 space-y-1">
                          {institution.deputyEmail && (
                            <p className="text-sm text-gray-600 flex items-center">
                              <Mail className="h-4 w-4 text-gray-400 mr-2" />
                              {institution.deputyEmail}
                            </p>
                          )}
                          {institution.deputyPhone && (
                            <p className="text-sm text-gray-600 flex items-center">
                              <Phone className="h-4 w-4 text-gray-400 mr-2" />
                              {institution.deputyPhone}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                
                {institution.type === 'Circuit Office' && institution.manager && (
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 mr-4">
                        <div className="h-16 w-16 bg-green-100 rounded-full flex items-center justify-center text-green-600 font-medium text-xl">
                          {institution.manager.substring(0, 2).toUpperCase()}
                        </div>
                      </div>
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">Circuit Manager</h3>
                        <p className="text-green-600">{institution.manager}</p>
                        <div className="mt-2 space-y-1">
                          {institution.managerEmail && (
                            <p className="text-sm text-gray-600 flex items-center">
                              <Mail className="h-4 w-4 text-gray-400 mr-2" />
                              {institution.managerEmail}
                            </p>
                          )}
                          {institution.managerPhone && (
                            <p className="text-sm text-gray-600 flex items-center">
                              <Phone className="h-4 w-4 text-gray-400 mr-2" />
                              {institution.managerPhone}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                
                {institution.type === 'District Office' && institution.director && (
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 mr-4">
                        <div className="h-16 w-16 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-medium text-xl">
                          {institution.director.substring(0, 2).toUpperCase()}
                        </div>
                      </div>
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">District Director</h3>
                        <p className="text-purple-600">{institution.director}</p>
                        <div className="mt-2 space-y-1">
                          {institution.directorEmail && (
                            <p className="text-sm text-gray-600 flex items-center">
                              <Mail className="h-4 w-4 text-gray-400 mr-2" />
                              {institution.directorEmail}
                            </p>
                          )}
                          {institution.directorPhone && (
                            <p className="text-sm text-gray-600 flex items-center">
                              <Phone className="h-4 w-4 text-gray-400 mr-2" />
                              {institution.directorPhone}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                
                {institution.type === 'Provincial Department' && institution.head && (
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 mr-4">
                        <div className="h-16 w-16 bg-red-100 rounded-full flex items-center justify-center text-red-600 font-medium text-xl">
                          {institution.head.substring(0, 2).toUpperCase()}
                        </div>
                      </div>
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">Head of Department</h3>
                        <p className="text-red-600">{institution.head}</p>
                        <div className="mt-2 space-y-1">
                          {institution.headEmail && (
                            <p className="text-sm text-gray-600 flex items-center">
                              <Mail className="h-4 w-4 text-gray-400 mr-2" />
                              {institution.headEmail}
                            </p>
                          )}
                          {institution.headPhone && (
                            <p className="text-sm text-gray-600 flex items-center">
                              <Phone className="h-4 w-4 text-gray-400 mr-2" />
                              {institution.headPhone}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                
                {/* If no leadership info is available */}
                {((institution.type === 'School' && !institution.principal && !institution.deputyPrincipal) ||
                  (institution.type === 'Circuit Office' && !institution.manager) ||
                  (institution.type === 'District Office' && !institution.director) ||
                  (institution.type === 'Provincial Department' && !institution.head)) && (
                  <div className="md:col-span-2 text-center py-8">
                    <Users className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No Leadership Information</h3>
                    <p className="mt-1 text-sm text-gray-500">Leadership information not available for this institution.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'documents' && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-6">Institution Documents</h2>
              
              <div className="text-center py-12">
                <FileText className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">No documents</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by uploading a document.</p>
                
                {canEditInstitution(institution) && (
                  <div className="mt-6">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <FileText className="mr-2 h-5 w-5" aria-hidden="true" />
                      Upload document
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      
      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div 
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
              aria-hidden="true"
              onClick={() => setShowDeleteConfirm(false)}
            ></div>
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Delete Institution
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this institution? All data associated with 
                        "{institution.name}" will be permanently removed.
                        This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleDeleteInstitution}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowDeleteConfirm(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstitutionDetails;