// src/components/records/RecordForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Save, X, ArrowLeft, Calendar, Clock, AlertTriangle,
  Camera, Video, FileText, User, Users, BookOpen, 
  Briefcase, Check, PlusCircle, Upload, RotateCw, Download
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase/config';
import { 
  collection, doc, getDoc, setDoc, addDoc, serverTimestamp 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const RecordForm = ({ isEditing = false }) => {
  const navigate = useNavigate();
  const { recordId } = useParams();
  const { currentUser, userProfile } = useAuth();
  
  // Form state
  const [formData, setFormData] = useState({
    recordId: '',
    userId: currentUser?.uid || '',
    institutionId: userProfile?.institutionId || '',
    date: new Date().toISOString().split('T')[0],
    category: '',
    target: '',
    targetRef: '',
    type: '',
    recordMessage: '',
    measurement: [],
    mediaType: '',
    mediaUrl: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    quantity: '',
    status: 'Green',
    requireAction: 'No',
    taskId: '',
    createdAt: null,
    updatedAt: null
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  
  // Options for form selections
  const categoryOptions = [
    'Administration', 'Curriculum', 'Operations', 
    'Extra Curricula', 'General', 'HR', 'Nutrition' 
  ];
  
  const targetOptions = [
    'Learner', 'Parent', 'Educator', 'Stakeholder', 'Structure', 'Resource'
  ];
  
  const typeOptions = [
    'Attendance', 'Submission', 'Visit', 'Leave', 'Delivery', 'Activity', 'Incident', 'Event', 'Travel'
  ];
  
  const measurementOptions = [
    'Time', 'Date', 'Duration', 'Quantity'
  ];
  
  const mediaOptions = [
    'Image', 'Video', 'Document'
  ];
  
  const statusOptions = [
    'Red', 'Orange', 'Green'
  ];
  
  // Fetch record data if editing
  useEffect(() => {
    if (isEditing && recordId) {
      fetchRecord();
    }
  }, [isEditing, recordId]);
  
  const fetchRecord = async () => {
    setIsLoading(true);
    try {
      const recordDoc = await getDoc(doc(db, "records", recordId));
      
      if (recordDoc.exists()) {
        const recordData = recordDoc.data();
        setFormData({
          ...recordData,
          recordId
        });
        
        // If there's a media URL, set the preview
        if (recordData.mediaUrl) {
          setFilePreview(recordData.mediaUrl);
        }
      } else {
        setError("Record not found");
        setTimeout(() => navigate('/records'), 2000);
      }
    } catch (err) {
      console.error("Error fetching record:", err);
      setError("Failed to load record data");
    } finally {
      setIsLoading(false);
    }
  };
  
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  // Handle measurement selection (multiple choice)
  const handleMeasurementChange = (measurement) => {
    setFormData(prev => {
      const measurements = [...prev.measurement];
      
      if (measurements.includes(measurement)) {
        return {
          ...prev,
          measurement: measurements.filter(m => m !== measurement)
        };
      } else {
        return {
          ...prev,
          measurement: [...measurements, measurement]
        };
      }
    });
  };
  
  // Handle file selection
// Update the file size check to 25MB
const handleFileChange = (e) => {
  const selectedFile = e.target.files[0];
  
  if (!selectedFile) {
    return;
  }
  
  // Check file size (max 25MB)
  if (selectedFile.size > 25 * 1024 * 1024) {
    setError("File is too large. Maximum size is 25MB");
    return;
  }
  
  // Set the file in state
  setFile(selectedFile);
  
  // Create preview for images
  if (selectedFile.type.startsWith('image/')) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  } else {
    // For non-images, just clear the preview
    setFilePreview('');
  }
};

// Enhanced uploadFile function
const uploadFile = async () => {
  if (!file) return null;
  
  try {
    setUploading(true);
    setUploadProgress(10);
    
    // Create a simple path structure with userId as the folder name
    const userId = currentUser?.uid;
    if (!userId) {
      throw new Error("User authentication required");
    }
    
    // Create a unique filename
    const timestamp = Date.now();
    const filename = `${timestamp}_${file.name.replace(/\s+/g, '_')}`;
    const storagePath = `records/${userId}/${filename}`;
    
    console.log("Uploading to:", storagePath);
    
    // Create storage reference
    const storageRef = ref(storage, storagePath);
    
    // Set up progress simulation
    const progressInterval = setInterval(() => {
      setUploadProgress(prev => Math.min(prev + 10, 80));
    }, 300);
    
    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);
    clearInterval(progressInterval);
    setUploadProgress(90);
    
    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    setUploadProgress(100);
    
    // Wait a moment before finishing to show 100% progress
    setTimeout(() => {
      setUploading(false);
    }, 500);
    
    // Return file info
    return {
      url: downloadURL,
      name: file.name,
      type: file.type,
      path: storagePath
    };
  } catch (error) {
    console.error("Error uploading file:", error);
    
    let errorMessage = "Failed to upload file.";
    if (error.code === 'storage/unauthorized') {
      errorMessage = "You don't have permission to upload files. Please contact an administrator.";
    }
    
    setError(errorMessage);
    setUploading(false);
    return null;
  }
};

// Simplified handleSubmit function
const handleSubmit = async (e) => {
  e.preventDefault();
  
  if (!currentUser) {
    setError("You must be logged in to submit a record");
    return;
  }
  
  // Validate form fields
  if (!formData.category || !formData.type || !formData.recordMessage) {
    setError("Please fill in all required fields");
    return;
  }
  
  try {
    setIsLoading(true);
    setError('');
    
    // Upload file if selected
    let mediaData = {};
    if (file) {
      const uploadedFile = await uploadFile();
      if (uploadedFile) {
        mediaData = {
          mediaUrl: uploadedFile.url,
          mediaName: uploadedFile.name,
          mediaType: formData.mediaType
        };
      } else {
        // If upload failed but was attempted
        setError("Failed to upload file");
        setIsLoading(false);
        return;
      }
    } else if (formData.mediaUrl) {
      // Keep existing media if not being replaced
      mediaData = {
        mediaUrl: formData.mediaUrl,
        mediaName: formData.mediaName,
        mediaType: formData.mediaType
      };
    }
    
    // Prepare record data
    const recordData = {
      ...formData,
      ...mediaData,
      userId: currentUser.uid,
      institutionId: userProfile?.institutionId || '',
      updatedAt: new Date() // Using regular Date instead of serverTimestamp for simplicity
    };
    
    delete recordData.recordId; // Remove the ID from the data object
    
    // Add or update record in Firestore
    if (isEditing && recordId) {
      await setDoc(doc(db, "records", recordId), recordData, { merge: true });
      setSuccess("Record updated successfully");
    } else {
      recordData.createdAt = new Date();
      // If not editing, create a new document
      await setDoc(doc(collection(db, "records"), recordId || doc(collection(db, "records")).id), recordData);
      setSuccess("Record created successfully");
    }
    
    // Navigate back after showing success message
    setTimeout(() => {
      navigate('/records');
    }, 1500);
    
  } catch (err) {
    console.error("Error saving record:", err);
    setError(`Failed to save record: ${err.message}`);
  } finally {
    setIsLoading(false);
  }
};
  
  // Create a new task related to this record
  const handleCreateTask = () => {
    // Navigate to the task creation page with record data
    // We'll store current form data in sessionStorage to retrieve it when we return
    sessionStorage.setItem('pendingRecordData', JSON.stringify(formData));
    navigate('/tasks/create?fromRecord=true');
  };

// File preview component that handles different file types
  const FilePreview = ({ file, filePreview, mediaType }) => {
    if (!file && !filePreview) return null;
    
    // Handle image previews
    if (mediaType === 'Image') {
      return (
        <div className="mt-2">
          <div className="rounded-md overflow-hidden border border-gray-200">
            <img 
              src={filePreview} 
              alt="Preview" 
              className="h-48 object-contain w-full"
            />
          </div>
          <p className="mt-1 text-sm text-gray-500">{file?.name}</p>
        </div>
      );
    }
    
    // Handle PDF previews
    if (mediaType === 'Document' && (file?.type === 'application/pdf' || filePreview?.includes('.pdf'))) {
      return (
        <div className="mt-2">
          <div className="p-4 border border-gray-200 rounded-md bg-gray-50 flex items-center">
            <FileText className="h-10 w-10 text-red-500 mr-3" />
            <div>
              <p className="font-medium text-sm">{file?.name || "PDF Document"}</p>
              <p className="text-xs text-gray-500">
                {file?.size ? `${Math.round(file.size / 1024)} KB` : ''}
              </p>
            </div>
          </div>
          {filePreview && (
            <div className="mt-2">
              <object
                data={filePreview}
                type="application/pdf"
                width="100%"
                height="200"
                className="border rounded-md"
              >
                <p>PDF preview not available. <a href={filePreview} target="_blank" rel="noreferrer" className="text-blue-600 hover:underline">Open PDF</a></p>
              </object>
            </div>
          )}
        </div>
      );
    }
    
    // Handle Video previews
    if (mediaType === 'Video') {
      return (
        <div className="mt-2">
          {filePreview ? (
            <video
              controls
              src={filePreview}
              className="w-full h-48 rounded-md border border-gray-200"
            >
              Your browser does not support the video tag.
            </video>
          ) : (
            <div className="p-4 border border-gray-200 rounded-md bg-gray-50 flex items-center">
              <Video className="h-10 w-10 text-blue-500 mr-3" />
              <div>
                <p className="font-medium text-sm">{file?.name || "Video"}</p>
                <p className="text-xs text-gray-500">
                  {file?.size ? `${Math.round(file.size / 1024)} KB` : ''}
                </p>
              </div>
            </div>
          )}
        </div>
      );
    }
    
    // Default file preview (documents, etc.)
    return (
      <div className="mt-2">
        <div className="p-4 border border-gray-200 rounded-md bg-gray-50 flex items-center">
          <FileText className="h-10 w-10 text-gray-500 mr-3" />
          <div>
            <p className="font-medium text-sm">{file?.name || "Document"}</p>
            <p className="text-xs text-gray-500">
              {file?.size ? `${Math.round(file.size / 1024)} KB` : ''}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
      <div className="flex items-start justify-between mb-6">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/records')}
            className="mr-4 text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <h1 className="text-xl font-bold text-gray-900">
            {isEditing ? 'Edit Record' : 'Create New Record'}
          </h1>
        </div>
      </div>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 mr-2" />
            <span>{error}</span>
          </div>
        </div>
      )}
      
      {success && (
        <div className="mb-4 p-3 bg-green-50 border-l-4 border-green-500 text-green-700">
          <div className="flex">
            <Check className="h-5 w-5 mr-2" />
            <span>{success}</span>
          </div>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Basic Information */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-md font-medium text-gray-800 mb-4">Basic Information</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
                Record Date
              </label>
              <div className="relative">
                <Calendar className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
                <input
                  id="date"
                  name="date"
                  type="date"
                  className="pl-6 py-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            
            <div>
              <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                Category*
              </label>
              <select
                id="category"
                name="category"
                className="block w-full rounded-md border-gray-400 pl-2 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="">Select a category</option>
                {categoryOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        
        {/* Target Information */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-md font-medium text-gray-800 mb-4">Target Information</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="target" className="block text-sm font-medium text-gray-700 mb-1">
                Target Type
              </label>
              <div className="flex flex-wrap gap-2">
                {targetOptions.map(option => (
                  <button
                    key={option}
                    type="button"
                    className={`py-1 px-4 rounded-full shadow-md text-sm ${
                      formData.target === option
                        ? 'bg-blue-100 text-blue-800 border border-blue-300'
                        : 'bg-gray-100 text-gray-700 border border-gray-200 hover:bg-gray-200'
                    }`}
                    onClick={() => setFormData(prev => ({ ...prev, target: option }))}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
            
            <div>
              <label htmlFor="targetRef" className="block text-sm font-medium text-gray-700 mb-1">
                Target Reference
              </label>
              <input
                id="targetRef"
                name="targetRef"
                type="text"
                className="block w-full rounded-md border-gray-400 pl-2 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.targetRef}
                onChange={handleChange}
                placeholder={formData.target ? `Enter ${formData.target} ID or name` : "Select a target type first"}
                disabled={!formData.target}
              />
            </div>
          </div>
        </div>
        
        {/* Record Details */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-md font-medium text-gray-800 mb-4">Record Details</h2>
          
          <div className="space-y-4">
            <div>
              <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
                Record Type*
              </label>
              <select
                id="type"
                name="type"
                className="block w-full rounded-md border-gray-400 pl-2 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value="">Select a record type</option>
                {typeOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label htmlFor="recordMessage" className="block text-sm font-medium text-gray-700 mb-1">
                Record Message*
              </label>
              <textarea
                id="recordMessage"
                name="recordMessage"
                rows={3}
                className="block w-full rounded-md border-gray-400 bg-white pl-2 py-2 shadow-sm focus:border-blue-400 focus:ring-blue-400"
                value={formData.recordMessage}
                onChange={handleChange}
                placeholder="Enter record details or description"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Measurement
              </label>
              <div className="flex flex-wrap gap-2">
                {measurementOptions.map(option => (
                  <button
                    key={option}
                    type="button"
                    className={`py-1 px-4 rounded-full shadow-md text-sm ${
                      formData.measurement.includes(option)
                        ? 'bg-blue-100 text-blue-800 border border-blue-300'
                        : 'bg-gray-100 text-gray-700 border border-gray-200 hover:bg-gray-200'
                    }`}
                    onClick={() => handleMeasurementChange(option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
            
            {/* Conditional fields based on measurement selections */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {formData.measurement.includes('Date') && (
                <div>
                  <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                    Start Date
                  </label>
                  <input
                    id="startDate"
                    name="startDate"
                    type="date"
                    className="block w-full rounded-md border-gray-300 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.startDate}
                    onChange={handleChange}
                  />
                </div>
              )}
              
              {formData.measurement.includes('Time') && (
                <div>
                  <label htmlFor="startTime" className="block text-sm font-medium text-gray-700 mb-1">
                    Start Time
                  </label>
                  <input
                    id="startTime"
                    name="startTime"
                    type="time"
                    className="block w-full rounded-md border-gray-300 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.startTime}
                    onChange={handleChange}
                  />
                </div>
              )}
              
              {formData.measurement.includes('Duration') && formData.measurement.includes('Date') && (
                <div>
                  <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                    End Date
                  </label>
                  <input
                    id="endDate"
                    name="endDate"
                    type="date"
                    className="block w-full rounded-md border-gray-300 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.endDate}
                    onChange={handleChange}
                  />
                </div>
              )}
              
              {formData.measurement.includes('Duration') && formData.measurement.includes('Time') && (
                <div>
                  <label htmlFor="endTime" className="block text-sm font-medium text-gray-700 mb-1">
                    End Time
                  </label>
                  <input
                    id="endTime"
                    name="endTime"
                    type="time"
                    className="block w-full rounded-md border-gray-300 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.endTime}
                    onChange={handleChange}
                  />
                </div>
              )}
              
              {formData.measurement.includes('Quantity') && (
                <div>
                  <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 mb-1">
                    Quantity
                  </label>
                  <input
                    id="quantity"
                    name="quantity"
                    type="number"
                    min="0"
                    className="block w-full rounded-md border-gray-300 py-2 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.quantity}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Media Upload */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-md font-medium text-gray-800 mb-4">Media Attachment</h2>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Media Type
              </label>
              <div className="flex flex-wrap gap-2">
                {mediaOptions.map(option => (
                  <button
                    key={option}
                    type="button"
                    className={`py-1 px-3 rounded-full shadow-md text-sm flex items-center ${
                      formData.mediaType === option
                        ? 'bg-blue-100 text-blue-800 border border-blue-300'
                        : 'bg-gray-100 text-gray-700 border border-gray-200 hover:bg-gray-200'
                    }`}
                    onClick={() => setFormData(prev => ({ ...prev, mediaType: option }))}
                  >
                    {option === 'Image' && <Camera className="h-4 w-4 mr-1" />}
                    {option === 'Video' && <Video className="h-4 w-4 mr-1" />}
                    {option === 'Document' && <FileText className="h-4 w-4 mr-1" />}
                    {option}
                  </button>
                ))}
              </div>
            </div>
            
            {/* Replace the existing file preview section in the RecordForm with this */}
            {formData.mediaType && (
              <div>
                <label htmlFor="mediaUpload" className="block text-sm font-medium text-gray-700 mb-2">
                  Upload {formData.mediaType}
                </label>
                
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <Upload className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={handleFileChange}
                          accept={
                            formData.mediaType === 'Image' 
                              ? 'image/*' 
                              : formData.mediaType === 'Video'
                                ? 'video/*'
                                : '.pdf,.doc,.docx,.xls,.xlsx,.txt'
                          }
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      {formData.mediaType === 'Image' && 'PNG, JPG, GIF up to 10MB'}
                      {formData.mediaType === 'Video' && 'MP4, WebM up to 10MB'}
                      {formData.mediaType === 'Document' && 'PDF, DOC, XLSX up to 10MB'}
                    </p>
                  </div>
                </div>
                
                {uploading && (
                  <div className="mt-2">
                    <div className="relative pt-1">
                      <div className="flex mb-2 items-center justify-between">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                            Uploading
                          </span>
                        </div>
                        <div className="text-right">
                          <span className="text-xs font-semibold inline-block text-blue-600">
                            {uploadProgress}%
                          </span>
                        </div>
                      </div>
                      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                        <div 
                          style={{ width: `${uploadProgress}%` }}
                          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                
                {/* Use our new FilePreview component */}
                <FilePreview file={file} filePreview={filePreview} mediaType={formData.mediaType} />
              </div>
            )}
          </div>
        </div>
        
        {/* Status and Actions */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-md font-medium text-gray-800 mb-4">Status and Actions</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Status
              </label>
              <div className="flex gap-2">
                {statusOptions.map(option => (
                  <button
                    key={option}
                    type="button"
                    className={`py-2 px-4 rounded-md shadow-md text-sm ${
                      formData.status === option
                        ? `bg-${option.toLowerCase()}-100 text-${option.toLowerCase()}-800 border border-${option.toLowerCase()}-300`
                        : 'bg-gray-100 text-gray-700 border border-gray-200 hover:bg-gray-200'
                    }`}
                    style={formData.status === option ? {
                      backgroundColor: option === 'Red' ? '#fee2e2' : option === 'Orange' ? '#ffedd5' : '#dcfce7',
                      color: option === 'Red' ? '#991b1b' : option === 'Orange' ? '#9a3412' : '#166534',
                      borderColor: option === 'Red' ? '#fca5a5' : option === 'Orange' ? '#fdba74' : '#86efac'
                    } : {}}
                    onClick={() => setFormData(prev => ({ ...prev, status: option }))}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Require Action?
              </label>
              <div className="flex gap-2">
                <button
                  type="button"
                  className={`py-2 px-4 rounded-md shadow-md text-sm ${
                    formData.requireAction === 'Yes'
                      ? 'bg-blue-100 text-blue-800 border border-blue-300'
                      : 'bg-gray-100 text-gray-700 border border-gray-200 hover:bg-gray-200'
                  }`}
                  onClick={() => setFormData(prev => ({ ...prev, requireAction: 'Yes' }))}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={`py-2 px-4 rounded-md shadow-md text-sm ${
                    formData.requireAction === 'No'
                      ? 'bg-blue-100 text-blue-800 border border-blue-300'
                      : 'bg-gray-100 text-gray-700 border border-gray-200 hover:bg-gray-200'
                  }`}
                  onClick={() => setFormData(prev => ({ ...prev, requireAction: 'No' }))}
                >
                  No
                </button>
              </div>
            </div>
          </div>
          
          {formData.requireAction === 'Yes' && (
            <div className="mt-4">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleCreateTask}
              >
                <PlusCircle className="h-4 w-4 mr-2" />
                Create Task
              </button>
              
              {formData.taskId && (
                <div className="mt-2 text-sm text-gray-600">
                  Task has been created and linked to this record.
                </div>
              )}
            </div>
          )}
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate('/records')}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className={`inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
          >
            {isLoading ? (
              <>
                <RotateCw className="animate-spin h-4 w-4 mr-2" />
                {isEditing ? 'Updating...' : 'Saving...'}
              </>
            ) : (
              <>
               <Save className="h-4 w-4 mr-2" />
                {isEditing ? 'Update Record' : 'Save Record'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RecordForm;