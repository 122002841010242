// src/pages/InstitutionsPage.js
import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Building, PieChart } from 'lucide-react';

const InstitutionsPage = () => {
  const [viewType, setViewType] = useState('institutions'); // 'institutions' or 'projects'
  const navigate = useNavigate();
  const location = useLocation();

  // Handle toggle between institutions and projects/programmes
  const handleViewToggle = (type) => {
    setViewType(type);
    
    // Navigate to the appropriate list view
    if (type === 'institutions') {
      navigate('/institutions');
    } else {
      navigate('/institutions/projects');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
        <h1 className="text-2xl font-bold text-gray-900">
          {viewType === 'institutions' ? 'Institutions' : 'Projects & Programmes'}
        </h1>
        
        {/* Toggle Switch */}
        <div className="mt-4 md:mt-0 bg-gray-200 rounded-lg p-1 flex">
          <button
            onClick={() => handleViewToggle('institutions')}
            className={`flex items-center px-4 py-2 rounded-md text-sm font-medium ${
              viewType === 'institutions'
                ? 'bg-white text-blue-600 shadow'
                : 'text-gray-700 hover:text-gray-900'
            }`}
          >
            <Building className="h-4 w-4 mr-1" />
            Institutions
          </button>
          <button
            onClick={() => handleViewToggle('projects')}
            className={`flex items-center px-4 py-2 rounded-md text-sm font-medium ${
              viewType === 'projects'
                ? 'bg-white text-blue-600 shadow'
                : 'text-gray-700 hover:text-gray-900'
            }`}
          >
            <PieChart className="h-4 w-4 mr-1" />
            Projects & Programmes
          </button>
        </div>
      </div>
      
      <Outlet context={{ viewType }} />
    </div>
  );
};

export default InstitutionsPage;