// src/pages/PlannerPage.js
import React from 'react';
import { Outlet } from 'react-router-dom';

const PlannerPage = () => {
  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-800">School Planning</h1>
        <p className="text-gray-600 mt-1">
          Manage your institution's plans and track progress towards goals.
        </p>
      </div>

      <Outlet /> {/* This will render nested routes */}
    </div>
  );
};

export default PlannerPage;
