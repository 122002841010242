// src/pages/TasksPage.js
import React from 'react';
import { Outlet } from 'react-router-dom';

const TasksPage = () => {
  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-800">Tasks Management</h1>
        <p className="text-gray-600 mt-1">
          Manage tasks, track assignments, and monitor progress.
        </p>
      </div>

      <Outlet /> {/* This will render nested routes */}
    </div>
  );
};

export default TasksPage;
