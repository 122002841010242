// src/components/planner/PlanList.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Plus, Search, Filter, 
  Calendar, Target, Briefcase, 
  ChevronDown, ChevronUp, User, 
  Clock, CheckCircle 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs
} from 'firebase/firestore';

const PlanList = () => {
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  const [plans, setPlans] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  
  // Filter states
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [timeframeFilter, setTimeframeFilter] = useState('all'); // 'all', 'current', 'upcoming', 'past'
  const [showFilters, setShowFilters] = useState(false);
  
  // Categories for filter
  const categories = ['Administration', 'Curriculum', 'Operations', 'Extra-Curricula'];

  // Fetch plans from Firestore
  useEffect(() => {
    const fetchPlans = async () => {
      if (!userProfile) {
        setIsLoading(false);
        return;
      }
      
      setIsLoading(true);
      try {
        // Create a query against the plans collection
        const plansRef = collection(db, "plans");
        
        // Log to debug
        console.log("Fetching plans for user:", currentUser?.uid);
        console.log("User profile:", userProfile);
        
        // Query plans based on userId or institutionName
        let q;
        if (userProfile.institutionName) {
          console.log("Querying by institutionName:", userProfile.institutionName);
          q = query(
            plansRef,
            where("institutionName", "==", userProfile.institutionName),
            orderBy("createdAt", "desc")
          );
        } else {
          console.log("Querying by userId:", currentUser.uid);
          q = query(
            plansRef,
            where("userId", "==", currentUser.uid),
            orderBy("createdAt", "desc")
          );
        }
        
        const querySnapshot = await getDocs(q);
        console.log("Plans query returned:", querySnapshot.size, "results");
        
        const plansList = [];
        
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          console.log("Plan data:", data);
          
          plansList.push({
            id: doc.id,
            ...data,
            // Convert Firestore Timestamps to JS Date objects (if they exist)
            date: data.date ? data.date.toDate() : null,
            createdAt: data.createdAt ? data.createdAt.toDate() : null,
            updatedAt: data.updatedAt ? data.updatedAt.toDate() : null,
            timeframe: data.timeframe ? {
              start: data.timeframe.start ? data.timeframe.start.toDate() : null,
              end: data.timeframe.end ? data.timeframe.end.toDate() : null
            } : {}
          });
        });
        
        console.log("Processed plans:", plansList);
        
        setPlans(plansList);
        setFilteredPlans(plansList);
      } catch (err) {
        console.error("Error fetching plans:", err);
        setError("Failed to load plans. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchPlans();
  }, [userProfile, currentUser]);
  
  // Apply filters when search term or filters change
  useEffect(() => {
    if (plans.length === 0) {
      setFilteredPlans([]);
      return;
    }
    
    const now = new Date();
    
    const filtered = plans.filter(plan => {
      // Search term filter: check in title or description
      const matchesSearch = !searchTerm || 
        (plan.title && plan.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (plan.description && plan.description.toLowerCase().includes(searchTerm.toLowerCase()));
      
      // Category filter
      const matchesCategory = !categoryFilter || plan.category === categoryFilter;
      
      // Timeframe filter
      let matchesTimeframe = true;
      if (timeframeFilter !== 'all' && plan.timeframe) {
        const { start, end } = plan.timeframe;
        if (timeframeFilter === 'current') {
          matchesTimeframe = start && end && start <= now && end >= now;
        } else if (timeframeFilter === 'upcoming') {
          matchesTimeframe = start && start > now;
        } else if (timeframeFilter === 'past') {
          matchesTimeframe = end && end < now;
        }
      }
      
      return matchesSearch && matchesCategory && matchesTimeframe;
    });
    
    setFilteredPlans(filtered);
  }, [plans, searchTerm, categoryFilter, timeframeFilter]);
  
  // Format date range for display using South African date format
  const formatDateRange = (timeframe) => {
    if (!timeframe || !timeframe.start || !timeframe.end) return 'Not specified';
    
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const startFormatted = timeframe.start.toLocaleDateString('en-ZA', options);
    const endFormatted = timeframe.end.toLocaleDateString('en-ZA', options);
    
    return `${startFormatted} - ${endFormatted}`;
  };
  
  // Calculate plan status based on timeframe
  const getPlanStatus = (plan) => {
    if (!plan.timeframe || !plan.timeframe.start || !plan.timeframe.end) {
      return { label: 'Draft', color: 'bg-gray-100 text-gray-800' };
    }
    
    const now = new Date();
    const { start, end } = plan.timeframe;
    
    if (now < start) {
      return { label: 'Upcoming', color: 'bg-blue-100 text-blue-800' };
    } else if (now > end) {
      return { label: 'Completed', color: 'bg-green-100 text-green-800' };
    } else {
      return { label: 'Active', color: 'bg-yellow-100 text-yellow-800' };
    }
  };
  
  // Reset all filters
  const resetFilters = () => {
    setSearchTerm('');
    setCategoryFilter('');
    setTimeframeFilter('all');
  };
  
  // Get category badge color
  const getCategoryBadgeColor = (category) => {
    switch (category) {
      case 'Administration':
        return 'bg-purple-100 text-purple-800';
      case 'Curriculum':
        return 'bg-green-100 text-green-800';
      case 'Operations':
        return 'bg-blue-100 text-blue-800';
      case 'Extra-Curricula':
        return 'bg-amber-100 text-amber-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">Plans</h2>
        <Link
          to="/planner/create"
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 flex items-center"
        >
          <Plus size={18} className="mr-2" />
          Create Plan
        </Link>
      </div>
      
      {/* Search and Filters */}
      <div className="p-4 border-b border-gray-200 bg-gray-50">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0">
          <div className="relative flex items-center">
            <Search size={18} className="absolute left-3 text-gray-400" />
            <input 
              type="text" 
              placeholder="Search plans..." 
              className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full md:w-64" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="flex flex-wrap items-center space-x-3">
            <div className="relative">
              <select
                className="appearance-none pl-3 pr-10 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                value={timeframeFilter}
                onChange={(e) => setTimeframeFilter(e.target.value)}
              >
                <option value="all">All Timeframes</option>
                <option value="current">Current</option>
                <option value="upcoming">Upcoming</option>
                <option value="past">Past</option>
              </select>
              <ChevronDown size={16} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>
            
            <button 
              className="flex items-center text-gray-700 hover:text-gray-900 border px-3 py-2 rounded-md"
              onClick={() => setShowFilters(!showFilters)}
            >
              <Filter size={16} className="mr-2" />
              Filters
              {showFilters ? <ChevronUp size={16} className="ml-2" /> : <ChevronDown size={16} className="ml-2" />}
            </button>
            
            {(searchTerm || categoryFilter !== '' || timeframeFilter !== 'all') && (
              <button 
                className="text-blue-600 hover:text-blue-800 text-sm font-medium"
                onClick={resetFilters}
              >
                Clear All
              </button>
            )}
          </div>
        </div>
        
        {/* Expanded filters */}
        {showFilters && (
          <div className="mt-4 pt-4 border-t border-gray-200">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div>
                <label htmlFor="categoryFilter" className="block text-sm font-medium text-gray-700 mb-1">
                  Category
                </label>
                <select
                  id="categoryFilter"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                >
                  <option value="">All Categories</option>
                  {categories.map((category) => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
      
      {/* Loading State */}
      {isLoading && (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      )}
      
      {/* Error State */}
      {error && (
        <div className="m-4 p-4 bg-red-50 text-red-800 rounded-md">
          {error}
        </div>
      )}
      
      {/* Empty State */}
      {!isLoading && !error && filteredPlans.length === 0 && (
        <div className="text-center py-12">
          <Calendar className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No plans found</h3>
          <p className="mt-1 text-sm text-gray-500">
            {plans.length === 0 ? 
              'Get started by creating your first plan.' : 
              'Try adjusting your filters or search terms.'}
          </p>
          {plans.length === 0 && (
            <div className="mt-6">
              <Link
                to="/planner/create"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
              >
                <Plus size={16} className="mr-2" />
                Create a plan
              </Link>
            </div>
          )}
        </div>
      )}
      
      {/* Plans List */}
      {!isLoading && !error && filteredPlans.length > 0 && (
        <div className="divide-y divide-gray-200">
          {filteredPlans.map((plan) => {
            const status = getPlanStatus(plan);
            return (
              <div 
                key={plan.id} 
                className="p-6 hover:bg-gray-50 transition-colors cursor-pointer"
                onClick={() => navigate(`/planner/details/${plan.id}`)}
              >
                <div className="flex flex-col md:flex-row md:items-start md:justify-between">
                  <div className="flex-1">
                    <div className="flex items-center mb-2">
                      <h3 className="text-lg font-medium text-gray-900 mr-3">{plan.title}</h3>
                      {plan.category && (
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getCategoryBadgeColor(plan.category)}`}>
                          {plan.category}
                        </span>
                      )}
                      <span className={`ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${status.color}`}>
                        {status.label}
                      </span>
                    </div>
                    
                    <p className="text-gray-600 mb-2 line-clamp-2">
                      {plan.description || 'No description provided'}
                    </p>
                    
                    <div className="text-sm text-gray-500 space-y-1">
                      <div className="flex items-center">
                        <Calendar size={16} className="mr-2" />
                        <span>Timeframe: {formatDateRange(plan.timeframe)}</span>
                      </div>
                      
                      {plan.responsibility && (
                        <div className="flex items-center">
                          <User size={16} className="mr-2" />
                          <span>Responsibility: {plan.responsibility}</span>
                        </div>
                      )}
                      
                      {plan.structure && (
                        <div className="flex items-center">
                          <Briefcase size={16} className="mr-2" />
                          <span>Structure: {plan.structure}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <div className="mt-4 md:mt-0 md:ml-6 flex flex-col items-end">
                    <div className="text-sm text-gray-500 mb-2">
                      <Clock size={14} className="inline mr-1" />
                      Created: {plan.createdAt ? plan.createdAt.toLocaleDateString('en-ZA') : 'N/A'}
                    </div>
                    
                    <div className="flex space-x-2">
                      {plan.goals?.length > 0 && (
                        <div className="flex items-center text-xs font-medium text-blue-600">
                          <Target size={14} className="mr-1" />
                          {plan.goals.length} Goal{plan.goals.length !== 1 ? 's' : ''}
                        </div>
                      )}
                      {/* In a real implementation, the tasks count would be fetched from a query */}
                      <div className="flex items-center text-xs font-medium text-green-600 ml-4">
                        <CheckCircle size={14} className="mr-1" />
                        {Math.floor(Math.random() * 10)} Tasks
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PlanList;