// src/pages/StakeholdersPage.js
import React from 'react';
import { Outlet } from 'react-router-dom';

const StakeholdersPage = () => {
  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-800">School Stakeholders</h1>
        <p className="text-gray-600 mt-1">
          Manage relationships with organisations and individuals that support your institution.
        </p>
      </div>
      <Outlet />
    </div>
  );
};

export default StakeholdersPage;
