// src/pages/MessagingPage.js
import React from 'react';
import { Outlet } from 'react-router-dom';

// Remove header/title from MessagingPage since it doesn't fit correctly
const MessagingPage = () => {
  return (
    <div className="h-[calc(100vh-5rem)] flex">
      {/* The messaging interface takes the full height and width */}
      <div className="w-full h-full overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
};

export default MessagingPage;