// src/components/subjects/CurriculumForm.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, Save, Plus, Trash2, 
  AlertTriangle, Calendar, X
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  doc, getDoc, updateDoc, arrayUnion, arrayRemove, serverTimestamp
} from 'firebase/firestore';

const CurriculumForm = () => {
  const { subjectId } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [subject, setSubject] = useState(null);
  const [curriculumItems, setCurriculumItems] = useState([]);
  
  // Empty curriculum item structure
  const emptyItem = {
    id: Date.now().toString(), // Unique ID for the item
    year: new Date().getFullYear(),
    term: 1,
    topic: '',
    subtopic: '',
    content: '',
    startDate: '',
    finishDate: '',
    days: 1
  };
  
  // Format date string to local format dd/mm/yyyy
  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-ZA', {
      day: '2-digit',
      month: '2-digit',
    
    });
  };
  
  // Fetch subject and curriculum data
  useEffect(() => {
    const fetchSubject = async () => {
      if (!subjectId) return;
      
      setIsLoading(true);
      try {
        const subjectDoc = await getDoc(doc(db, "subjects", subjectId));
        if (subjectDoc.exists()) {
          const subjectData = subjectDoc.data();
          setSubject({
            id: subjectDoc.id,
            ...subjectData
          });
          
          // Initialize curriculum items from subject data or create an empty one
          if (subjectData.curriculumItems && subjectData.curriculumItems.length > 0) {
            setCurriculumItems(subjectData.curriculumItems);
          } else {
            setCurriculumItems([{ ...emptyItem }]);
          }
        } else {
          setError("Subject not found");
        }
      } catch (err) {
        console.error("Error fetching subject:", err);
        setError("Failed to load subject data");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchSubject();
  }, [subjectId]);
  
  // Add a new curriculum item
  const addCurriculumItem = () => {
    setCurriculumItems([...curriculumItems, { ...emptyItem, id: Date.now().toString() }]);
  };
  
  // Remove a curriculum item
  const removeCurriculumItem = (index) => {
    if (curriculumItems.length === 1) {
      // Don't remove the last item, just clear it
      setCurriculumItems([{ ...emptyItem }]);
      return;
    }
    
    const newItems = [...curriculumItems];
    newItems.splice(index, 1);
    setCurriculumItems(newItems);
  };
  
  // Handle field changes
  const handleChange = (index, field, value) => {
    const newItems = [...curriculumItems];
    newItems[index] = {
      ...newItems[index],
      [field]: value
    };
    
    // Calculate days automatically if start and finish dates are set
    if ((field === 'startDate' || field === 'finishDate') && 
        newItems[index].startDate && newItems[index].finishDate) {
      const start = new Date(newItems[index].startDate);
      const finish = new Date(newItems[index].finishDate);
      
      // Calculate the difference in days
      const diffTime = Math.abs(finish - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 to include start day
      
      if (diffDays > 0) {
        newItems[index].days = diffDays;
      }
    }
    
    setCurriculumItems(newItems);
  };
  
  // Save curriculum items
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!subject) {
      setError("Subject not found");
      return;
    }
    
    // Validate items
    for (const item of curriculumItems) {
      if (!item.topic) {
        setError("Topic is required for all curriculum items");
        return;
      }
      
      if (item.startDate && item.finishDate) {
        const start = new Date(item.startDate);
        const finish = new Date(item.finishDate);
        
        if (start > finish) {
          setError("Finish date must be after or equal to start date");
          return;
        }
      }
    }
    
    setIsLoading(true);
    setError('');
    
    try {
      // Update the subject with the curriculum items
      await updateDoc(doc(db, "subjects", subjectId), {
        curriculumItems: curriculumItems,
        updatedAt: serverTimestamp()
      });
      
      setSuccess("Curriculum saved successfully");
      
      // Navigate back after a short delay
      setTimeout(() => {
        navigate(`/subjects/details/${subjectId}`);
      }, 1500);
      
    } catch (err) {
      console.error("Error saving curriculum:", err);
      setError(`Failed to save curriculum: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading && !subject) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-[#1d67cd]"></div>
      </div>
    );
  }
  
  if (error && !subject) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <div className="flex">
            <AlertTriangle className="h-6 w-6 text-red-500" />
            <div className="ml-3">
              <p className="text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate(`/subjects/details/${subjectId}`)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#1d67cd] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Subject
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
        <div className="flex items-center">
          <button
            onClick={() => navigate(`/subjects/details/${subjectId}`)}
            className="mr-4 text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <div>
            <h1 className="text-xl font-bold text-gray-900">Curriculum - {subject?.name}</h1>
            <p className="text-sm text-gray-500">
              {subject?.phase && <span className="mr-2">{subject.phase}</span>}
              {subject?.grade && <span>Grade {subject.grade}</span>}
            </p>
          </div>
        </div>
      </div>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 mr-2" />
            <span>{error}</span>
          </div>
        </div>
      )}
      
      {success && (
        <div className="mb-4 p-3 bg-green-50 border-l-4 border-green-500 text-green-700">
          <div className="flex">
            <Save className="h-5 w-5 mr-2" />
            <span>{success}</span>
          </div>
        </div>
      )}
      
      <form onSubmit={handleSubmit}>
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          {/* Table Headers */}
          <div className="px-4 py-3 border-b border-gray-200 bg-gray-50">
            <div className="text-sm font-medium text-gray-700">Curriculum Items</div>
            <p className="text-xs text-gray-500">Enter topics and their time allocations</p>
          </div>
          
          {/* Curriculum Items */}
          <div className="divide-y divide-gray-200">
            {curriculumItems.map((item, index) => (
              <div key={item.id} className="p-4 hover:bg-gray-50">
                <div className="flex justify-between items-start mb-3">
                  <div className="text-sm font-medium text-[#1d67cd]">Item {index + 1}</div>
                  <button
                    type="button"
                    onClick={() => removeCurriculumItem(index)}
                    className="text-red-600 hover:text-red-900"
                    title="Remove item"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
                
                {/* First row - Topic Information */}
                <div className="grid grid-cols-10 gap-2 mb-3">

                  {/* Topic */}
                  <div className="col-span-3">
                    <label className="block text-xs font-medium text-gray-700 mb-1">Topic*</label>
                    <input
                      type="text"
                      placeholder="Main topic"
                      className="block w-full pl-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={item.topic}
                      onChange={(e) => handleChange(index, 'topic', e.target.value)}
                      required
                    />
                  </div>
                  
                  {/* Subtopic */}
                  <div className="col-span-3">
                    <label className="block text-xs font-medium text-gray-700 mb-1">Subtopic</label>
                    <input
                      type="text"
                      placeholder="Subtopic or theme"
                      className="block w-full pl-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={item.subtopic}
                      onChange={(e) => handleChange(index, 'subtopic', e.target.value)}
                    />
                  </div>
                  
                  <div className="col-span-4">
                    <label className="block text-xs font-medium text-gray-700 mb-1">Content</label>
                    <textarea
                      placeholder="Detailed content description"
                      className="block w-full min-h-[60px] pl-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={item.content}
                      onChange={(e) => handleChange(index, 'content', e.target.value)}
                      rows="2"
                    />
                  </div>
                </div>
                
                {/* Second row - Time Information */}
                <div className="grid grid-cols-10 gap-2">
                    {/* Year & Term */}
                  <div className="col-span-1">
                    <label className="block text-xs font-medium text-gray-700 mb-1">Year</label>
                    <input
                      type="number"
                      min="2000"
                      max="2100"
                      className="block w-full pl-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={item.year}
                      onChange={(e) => handleChange(index, 'year', e.target.value)}
                    />
                  </div>
                  
                  <div className="col-span-1">
                    <label className="block text-xs font-medium text-gray-700 mb-1">Term</label>
                    <select
                      className="block w-full pl-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={item.term}
                      onChange={(e) => handleChange(index, 'term', e.target.value)}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  {/* Start Date */}
                  <div className="col-span-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1">Start Date</label>
                    <input
                      type="date"
                      className="block w-full pl-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={item.startDate}
                      onChange={(e) => handleChange(index, 'startDate', e.target.value)}
                    />
                    {item.startDate && (
                      <div className="mt-1 text-xs text-gray-500">
                        {formatDateForDisplay(item.startDate)}
                      </div>
                    )}
                  </div>
                  
                  {/* Finish Date */}
                  <div className="col-span-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1">Finish Date</label>
                    <input
                      type="date"
                      className="block w-full pl-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={item.finishDate}
                      onChange={(e) => handleChange(index, 'finishDate', e.target.value)}
                    />
                    {item.finishDate && (
                      <div className="mt-1 text-xs text-gray-500">
                        {formatDateForDisplay(item.finishDate)}
                      </div>
                    )}
                  </div>
                  
                  {/* Days */}
                  <div className="col-span-2">
                    <label className="block text-xs font-medium text-gray-700 mb-1">Days</label>
                    <input
                      type="number"
                      min="1"
                      className="block w-full pl-2 py-1 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={item.days}
                      onChange={(e) => handleChange(index, 'days', e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Add Item Button */}
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-between">
            <button
              type="button"
              onClick={addCurriculumItem}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Plus className="h-4 w-4 mr-2" />
              Add Item
            </button>
            
            <div className="space-x-3">
              <button
                type="button"
                onClick={() => navigate(`/subjects/details/${subjectId}`)}
                className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#1d67cd] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {isLoading ? 'Saving...' : 'Save Curriculum'}
              </button>
            </div>
          </div>
        </div>
      </form>
      
      {/* Helpful Info Card */}
      <div className="mt-6 bg-blue-50 rounded-lg p-4 text-sm text-blue-700">
        <h4 className="font-medium flex items-center mb-2">
          <Calendar className="h-4 w-4 mr-2" />
          Tips for Creating ATP Curriculum
        </h4>
        <ul className="list-disc pl-5 space-y-1">
          <li>Add topics in chronological order for each term</li>
          <li>When you set start and finish dates, the days will be calculated automatically</li>
          <li>You can manually adjust the number of days if needed</li>
          <li>Content field can include brief notes about what will be taught</li>
        </ul>
      </div>
    </div>
  );
};

export default CurriculumForm;