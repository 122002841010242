// src/components/meetings/MeetingList.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Calendar, Clock, MapPin, User, Search, Filter, 
  ChevronDown, CheckCircle, AlertCircle, Plus
} from 'lucide-react';
import { format } from 'date-fns';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs, 
  limit 
} from 'firebase/firestore';

const MeetingList = () => {
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  
  const [meetings, setMeetings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all'); // 'all', 'upcoming', 'past'
  const [typeFilter, setTypeFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  // Fetch meetings from Firestore
  useEffect(() => {
    const fetchMeetings = async () => {
      setIsLoading(true);
      setError('');

      try {
        const meetingsRef = collection(db, "meetings");
        
        // Query based on institutionName if available
        let q;
        if (userProfile?.institutionName) {
          q = query(
            meetingsRef,
            where("institutionName", "==", userProfile.institutionName),
            orderBy("date", "desc"),
            limit(50)
          );
        } else {
          // Fallback query if institutionName isn't available
          q = query(
            meetingsRef,
            orderBy("date", "desc"),
            limit(50)
          );
        }

        const querySnapshot = await getDocs(q);
        const meetingsList = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          meetingsList.push({
            id: doc.id,
            ...data,
            date: data.date?.toDate() || null,
            endTime: data.endTime?.toDate() || null,
            createdAt: data.createdAt?.toDate() || null,
            updatedAt: data.updatedAt?.toDate() || null
          });
        });

        setMeetings(meetingsList);
      } catch (err) {
        console.error("Error fetching meetings:", err);
        setError("Failed to load meetings. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchMeetings();
  }, [userProfile]);

  // Filter meetings based on search term and filters
  const filteredMeetings = meetings.filter(meeting => {
    const matchesSearch = meeting.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          meeting.description?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const now = new Date();
    const isPast = meeting.date && meeting.date < now;
    
    const matchesDateFilter = 
      filter === 'all' || 
      (filter === 'upcoming' && (!meeting.date || !isPast)) ||
      (filter === 'past' && meeting.date && isPast);
    
    const matchesTypeFilter = !typeFilter || meeting.type === typeFilter;
    
    return matchesSearch && matchesDateFilter && matchesTypeFilter;
  });

  // Get unique meeting types for filter
  const meetingTypes = [...new Set(meetings.map(meeting => meeting.type).filter(Boolean))];

  // Format date time nicely
  const formatDateTime = (date) => {
    if (!date) return '';
    return format(date, 'MMM d, yyyy • h:mm a');
  };

  // Get status badge details
  const getStatusDetails = (meeting) => {
    if (!meeting.date) {
      return {
        label: 'Not Scheduled',
        color: 'bg-gray-100 text-gray-800',
        icon: <Calendar className="h-4 w-4 mr-1" />
      };
    }
    
    const now = new Date();
    const meetingDate = new Date(meeting.date);
    
    if (meetingDate < now) {
      return {
        label: 'Completed',
        color: 'bg-gray-100 text-gray-800',
        icon: <CheckCircle className="h-4 w-4 mr-1" />
      };
    }
    
    // Check if meeting is happening today
    const today = new Date();
    const isToday = meetingDate.getDate() === today.getDate() &&
                    meetingDate.getMonth() === today.getMonth() &&
                    meetingDate.getFullYear() === today.getFullYear();
                    
    if (isToday) {
      return {
        label: 'Today',
        color: 'bg-green-100 text-green-800',
        icon: <Clock className="h-4 w-4 mr-1" />
      };
    }
    
    // Calculate days until meeting
    const diffTime = Math.abs(meetingDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays <= 3) {
      return {
        label: 'Upcoming',
        color: 'bg-yellow-100 text-yellow-800',
        icon: <AlertCircle className="h-4 w-4 mr-1" />
      };
    }
    
    return {
      label: 'Scheduled',
      color: 'bg-blue-100 text-blue-800',
      icon: <Calendar className="h-4 w-4 mr-1" />
    };
  };

  const handleSelectMeeting = (meetingId) => {
    navigate(`/meetings/details/${meetingId}`);
  };

  return (
    <div className="p-6">
      {/* Search and Filters */}
      <div className="mb-6">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="relative flex-grow max-w-md">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
            <input
              type="text"
              placeholder="Search meetings..."
              className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="flex items-center space-x-2">
            <div className="relative">
              <select
                className="appearance-none pl-3 pr-8 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="all">All Meetings</option>
                <option value="upcoming">Upcoming</option>
                <option value="past">Past</option>
              </select>
              <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" size={16} />
            </div>
            
            <button
              className="inline-flex items-center px-4 py-2 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => setShowFilters(!showFilters)}
            >
              <Filter size={16} className="mr-2" />
              Filters
              <ChevronDown size={16} className={`ml-1 transition-transform ${showFilters ? 'transform rotate-180' : ''}`} />
            </button>

            <button
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => navigate('/meetings/create')}
            >
              <Plus size={16} className="mr-2" />
              Create Meeting
            </button>
          </div>
        </div>
        
        {/* Additional filters */}
        {showFilters && (
          <div className="mt-4 p-4 bg-gray-50 rounded-md">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Meeting Type</label>
                <select
                  className="w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={typeFilter}
                  onChange={(e) => setTypeFilter(e.target.value)}
                >
                  <option value="">All Types</option>
                  {meetingTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => {
                  setSearchTerm('');
                  setFilter('all');
                  setTypeFilter('');
                }}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Reset all filters
              </button>
            </div>
          </div>
        )}
      </div>
      
      {/* Error message */}
      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
          {error}
        </div>
      )}

      {/* Loading indicator */}
      {isLoading && (
        <div className="flex justify-center items-center p-12">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          <p className="ml-2">Loading meetings...</p>
        </div>
      )}
      
      {/* Meeting List */}
      {!isLoading && filteredMeetings.length === 0 ? (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <Calendar className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No meetings found</h3>
          <p className="mt-1 text-sm text-gray-500">
            {searchTerm || typeFilter || filter !== 'all' 
              ? 'Try changing your search or filter criteria' 
              : 'Get started by creating a new meeting'}
          </p>
          {!searchTerm && !typeFilter && filter === 'all' && (
            <button
              onClick={() => navigate('/meetings/create')}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              <Plus size={16} className="mr-2" />
              Create Meeting
            </button>
          )}
        </div>
      ) : (
        <div className="overflow-hidden rounded-md border border-gray-200">
          <ul className="divide-y divide-gray-200">
            {filteredMeetings.map((meeting) => {
              const statusDetails = getStatusDetails(meeting);
              
              return (
                <li 
                  key={meeting.id}
                  className="hover:bg-gray-50 cursor-pointer transition-colors"
                  onClick={() => handleSelectMeeting(meeting.id)}
                >
                  <div className="px-6 py-4">
                    <div className="flex items-center justify-between">
                      <div className="flex-1 min-w-0">
                        <h3 className="text-lg font-medium text-gray-900 truncate">{meeting.title}</h3>
                        <div className="mt-1 flex flex-wrap items-center text-sm text-gray-500 gap-x-4 gap-y-1">
                          <div className="flex items-center">
                            <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                            {formatDateTime(meeting.date)}
                          </div>
                          {meeting.location && (
                            <div className="flex items-center">
                              <MapPin className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                              {meeting.location}
                            </div>
                          )}
                          {meeting.secretary && (
                            <div className="flex items-center">
                              <User className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                              {meeting.secretary}
                            </div>
                          )}
                          {meeting.type && (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                              {meeting.type}
                            </span>
                          )}
                        </div>
                        {meeting.description && (
                          <p className="mt-1 text-sm text-gray-600 line-clamp-1">{meeting.description}</p>
                        )}
                      </div>
                      <div className="ml-4 flex-shrink-0 flex flex-col items-end">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusDetails.color}`}>
                          {statusDetails.icon}
                          {statusDetails.label}
                        </span>
                        <span className="mt-1 text-xs text-gray-500">
                          {Array.isArray(meeting.participants) ? meeting.participants.length : 0} participants
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MeetingList;