// src/pages/About.js
import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, Users, Calendar, FileText, Briefcase, BarChart2, MessageSquare } from 'lucide-react';
import logo from '../assets/images/logo.png'; 
import header from '../assets/images/header.png'; 

const About = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <header className="relative bg-[#1d67cd] pb-24 pt-16">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover opacity-50" 
            src={header}
            alt="School background"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-[#1d67cd] to-[#1d67cd] mix-blend-multiply" aria-hidden="true"></div>
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
         <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-4xl lg:text-4xl mb-6">
                Enhance your school productivity through digital workflows with Schoolmate
            </h1>
            <p className="text-xl text-blue-100 max-w-3xl">
                A comprehensive management solution designed for productive schools
            </p>
         </div>
        </div>
      </header>

      {/* Main Content */}
      <main>
        {/* Value Proposition */}
        <section className="py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                Streamline Your School Workflows
              </h2>
              <p className="mt-4 text-lg text-gray-600 max-w-3xl mx-auto">
                Schoolmate provides a unified platform for managing resources, documents, staff, and communication within your educational institution.
              </p>
            </div>

            <div className="mt-16">
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                <div className="bg-gray-50 rounded-lg p-6 border border-gray-100 shadow-sm">
                  <div className="flex items-center mb-4">
                    <Briefcase className="h-6 w-6 text-[#1d67cd] mr-2" />
                    <h3 className="text-lg font-medium text-gray-900">Resource Management</h3>
                  </div>
                  <p className="text-gray-600">
                    Track and optimize all school resources including equipment, facilities, and teaching materials.
                  </p>
                </div>

                <div className="bg-gray-50 rounded-lg p-6 border border-gray-100 shadow-sm">
                  <div className="flex items-center mb-4">
                    <FileText className="h-6 w-6 text-[#1d67cd] mr-2" />
                    <h3 className="text-lg font-medium text-gray-900">Document Workflows</h3>
                  </div>
                  <p className="text-gray-600">
                    Create, track, and manage document approvals with customizable workflow processes.
                  </p>
                </div>

                <div className="bg-gray-50 rounded-lg p-6 border border-gray-100 shadow-sm">
                  <div className="flex items-center mb-4">
                    <Calendar className="h-6 w-6 text-[#1d67cd] mr-2" />
                    <h3 className="text-lg font-medium text-gray-900">Meeting Scheduler</h3>
                  </div>
                  <p className="text-gray-600">
                    Plan, organize, and track meetings with integrated calendar and notification features.
                  </p>
                </div>

                <div className="bg-gray-50 rounded-lg p-6 border border-gray-100 shadow-sm">
                  <div className="flex items-center mb-4">
                    <MessageSquare className="h-6 w-6 text-[#1d67cd] mr-2" />
                    <h3 className="text-lg font-medium text-gray-900">Internal Communication</h3>
                  </div>
                  <p className="text-gray-600">
                    Facilitate seamless communication between staff members with dedicated channels and direct messaging.
                  </p>
                </div>

                <div className="bg-gray-50 rounded-lg p-6 border border-gray-100 shadow-sm">
                  <div className="flex items-center mb-4">
                    <Users className="h-6 w-6 text-[#1d67cd] mr-2" />
                    <h3 className="text-lg font-medium text-gray-900">Staff Management</h3>
                  </div>
                  <p className="text-gray-600">
                    Manage staff profiles, roles, and responsibilities across all levels of the institution.
                  </p>
                </div>

                <div className="bg-gray-50 rounded-lg p-6 border border-gray-100 shadow-sm">
                  <div className="flex items-center mb-4">
                    <BarChart2 className="h-6 w-6 text-[#1d67cd] mr-2" />
                    <h3 className="text-lg font-medium text-gray-900">AI-Powered Analytics</h3>
                  </div>
                  <p className="text-gray-600">
                    Gain insights and optimize operations with intelligent analytics and reporting.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* For All Educational Levels */}
        <section className="py-16 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                For All Educational Levels
              </h2>
              <p className="mt-4 text-lg text-gray-600 max-w-3xl mx-auto">
                Schoolmate adapts to the specific needs of different educational institutions and administrative levels.
              </p>
            </div>

            <div className="mt-12 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
              <div className="bg-white rounded-lg p-6 shadow-sm text-center">
                <h3 className="text-lg font-medium text-gray-900 mb-3">Schools</h3>
                <p className="text-gray-600">
                  Primary, secondary, and combined schools with customized features for each phase.
                </p>
              </div>
              
              <div className="bg-white rounded-lg p-6 shadow-sm text-center">
                <h3 className="text-lg font-medium text-gray-900 mb-3">Circuit Offices</h3>
                <p className="text-gray-600">
                  Manage and coordinate multiple schools within your circuit efficiently.
                </p>
              </div>
              
              <div className="bg-white rounded-lg p-6 shadow-sm text-center">
                <h3 className="text-lg font-medium text-gray-900 mb-3">District Offices</h3>
                <p className="text-gray-600">
                  Oversee educational administration across entire districts with powerful tools.
                </p>
              </div>
              
              <div className="bg-white rounded-lg p-6 shadow-sm text-center">
                <h3 className="text-lg font-medium text-gray-900 mb-3">Provincial Departments</h3>
                <p className="text-gray-600">
                  Coordinate education management at the provincial level with comprehensive analytics.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Key Benefits */}
        <section className="py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                Why Choose Schoolmate
              </h2>
            </div>

            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <div className="bg-gray-50 rounded-lg p-6 border border-gray-100">
                <div className="space-y-6">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircle className="h-6 w-6 text-green-500" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-gray-900">Designed for South African Education</h3>
                      <p className="mt-2 text-gray-600">
                        Built specifically for the South African education system with all relevant structures, roles, and workflows.
                      </p>
                    </div>
                  </div>
                  
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircle className="h-6 w-6 text-green-500" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-gray-900">Centralized Management</h3>
                      <p className="mt-2 text-gray-600">
                        All your administrative needs in one integrated platform, eliminating the need for multiple disconnected systems.
                      </p>
                    </div>
                  </div>
                  
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircle className="h-6 w-6 text-green-500" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-gray-900">Time-Saving Automation</h3>
                      <p className="mt-2 text-gray-600">
                        Automated workflows and processes reduce administrative burden and free up time for educational priorities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="bg-gray-50 rounded-lg p-6 border border-gray-100">
                <div className="space-y-6">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircle className="h-6 w-6 text-green-500" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-gray-900">Intelligent Insights</h3>
                      <p className="mt-2 text-gray-600">
                        AI-powered analytics provide actionable recommendations to optimize resource utilization and staff productivity.
                      </p>
                    </div>
                  </div>
                  
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircle className="h-6 w-6 text-green-500" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-gray-900">Secure and Compliant</h3>
                      <p className="mt-2 text-gray-600">
                        Built with robust security features to protect sensitive educational data and ensure regulatory compliance.
                      </p>
                    </div>
                  </div>
                  
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircle className="h-6 w-6 text-green-500" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-gray-900">Collaborative Environment</h3>
                      <p className="mt-2 text-gray-600">
                        Foster teamwork and cooperation among staff members with integrated communication and document sharing tools.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <section className="py-16 bg-[#1d67cd]">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              Ready to transform your educational administration?
            </h2>
            <p className="mt-4 text-xl text-blue-100 max-w-2xl mx-auto">
              Join thousands of educational institutions across South Africa that are already benefiting from Schoolmate.
            </p>
            <div className="mt-8 flex justify-center space-x-4">
              <Link
                to="/register"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-[#1d67cd] bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1d67cd]"
              >
                Sign up for free
              </Link>
              <Link
                to="/login"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 bg-opacity-60 hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#1d67cd] focus:ring-blue-500"
              >
                Log in
              </Link>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <div className="flex items-center">
              <img
                  className="h-8 w-auto"
                  src={logo}
                  alt="Schoolmate Logo"
                />
              </div>
              <p className="mt-4 text-gray-300 text-sm">
                A comprehensive school management application for educational institutions across South Africa.
              </p>
            </div>
            
            <div>
              <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider">Useful Links</h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <Link to="/about" className="text-base text-gray-300 hover:text-white">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/register" className="text-base text-gray-300 hover:text-white">
                    Sign Up
                  </Link>
                </li>
                <li>
                  <Link to="/login" className="text-base text-gray-300 hover:text-white">
                    Log In
                  </Link>
                </li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider">Contact Us</h3>
              <p className="mt-4 text-gray-300">
                Have questions about Schoolmate? Reach out to our support team at:
                <br />
                <a href="mailto:support@schoolmate.africa" className="text-blue-400 hover:text-blue-300">
                  support@schoolmate.africa
                </a>
              </p>
            </div>
          </div>
          
          <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400 text-sm">
            <p>&copy; {new Date().getFullYear()} Schoolmate. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default About;