// src/components/documents/DocumentWorkflowForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
    ArrowLeft, Save, Plus, X, Calendar, Users, FileUp, MessageSquare, Trash, FileText 
  } from 'lucide-react';
  
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase/config';
import { 
  collection, doc, getDoc, setDoc, getDocs, query, where, 
  arrayUnion, Timestamp 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const DocumentWorkflowForm = ({ isEditing = false }) => {
  const navigate = useNavigate();
  const { workflowId } = useParams();
  const { userProfile } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Form fields
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('Medium');
  const [dueDate, setDueDate] = useState('');
  const [steps, setSteps] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [newDocumentFile, setNewDocumentFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  
  // Staff options for participants and steps
  const [staffOptions, setStaffOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showParticipantSearch, setShowParticipantSearch] = useState(false);
  
  // New step form
  const [newStep, setNewStep] = useState({
    name: '',
    assignee: '',
    dueDate: ''
  });

  // Priority options
  const priorities = ['High', 'Medium', 'Low'];

  // Fetch staff members for the institution
  useEffect(() => {
    const fetchStaff = async () => {
      if (!userProfile?.institutionName) return;
      
      try {
        const staffRef = collection(db, "users");
        const q = query(
          staffRef, 
          where("institutionName", "==", userProfile.institutionName)
        );
        const querySnapshot = await getDocs(q);
        
        const staffList = [];
        querySnapshot.forEach((doc) => {
          staffList.push({
            id: doc.id,
            ...doc.data(),
            initials: `${doc.data().firstName?.charAt(0) || ''}${doc.data().lastName?.charAt(0) || ''}`
          });
        });
        
        setStaffOptions(staffList);
      } catch (error) {
        console.error("Error fetching staff:", error);
        setError("Failed to load staff members. Please try again.");
      }
    };

    fetchStaff();
  }, [userProfile]);

  // Fetch existing workflow if editing
  useEffect(() => {
    const fetchWorkflow = async () => {
      if (!isEditing || !workflowId) return;
      
      setIsLoading(true);
      try {
        const workflowDoc = await getDoc(doc(db, "documentWorkflows", workflowId));
        
        if (workflowDoc.exists()) {
          const data = workflowDoc.data();
          
          setTitle(data.title || '');
          setDescription(data.description || '');
          setPriority(data.priority || 'Medium');
          
          // Format date for input
          if (data.dueDate) {
            const dueDateObj = data.dueDate instanceof Timestamp 
              ? data.dueDate.toDate() 
              : new Date(data.dueDate);
            
            setDueDate(dueDateObj.toISOString().split('T')[0]);
          }
          
          setSteps(data.steps || []);
          setParticipants(data.participants || []);
          setDocuments(data.documents || []);
        } else {
          setError("Workflow not found");
          navigate('/documents');
        }
      } catch (error) {
        console.error("Error fetching workflow:", error);
        setError("Failed to load workflow. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkflow();
  }, [isEditing, workflowId, navigate]);

  const handleAddStep = () => {
    if (!newStep.name) {
      setError("Step name is required");
      return;
    }
    
    if (!newStep.assignee) {
      setError("Please assign the step to a staff member");
      return;
    }
    
    if (!newStep.dueDate) {
      setError("Step due date is required");
      return;
    }
    
    // Find assignee details
    const assignee = staffOptions.find(staff => staff.id === newStep.assignee);
    if (!assignee) {
      setError("Invalid assignee selected");
      return;
    }
    
    // Create step with status
    const stepWithStatus = {
      id: Date.now().toString(),
      name: newStep.name,
      assignee: assignee.initials,
      assigneeId: assignee.id,
      assigneeName: `${assignee.title || ''} ${assignee.firstName} ${assignee.lastName}`,
      dueDate: newStep.dueDate,
      status: steps.length === 0 ? 'In Progress' : 'Pending'
    };
    
    // Add to steps array
    setSteps([...steps, stepWithStatus]);
    
    // Reset form
    setNewStep({
      name: '',
      assignee: '',
      dueDate: ''
    });
    
    setError('');
  };

  const handleRemoveStep = (stepId) => {
    setSteps(steps.filter(step => step.id !== stepId));
  };

  const handleAddParticipant = (staff) => {
    // Check if already added
    if (participants.some(p => p.id === staff.id)) {
      return;
    }
    
    // Create participant object
    const participant = {
      id: staff.id,
      name: `${staff.title || ''} ${staff.firstName} ${staff.lastName}`.trim(),
      avatar: staff.initials,
      color: getColorForInitial(staff.initials.charAt(0))
    };
    
    setParticipants([...participants, participant]);
    setShowParticipantSearch(false);
    setSearchTerm('');
  };

  const handleRemoveParticipant = (participantId) => {
    setParticipants(participants.filter(p => p.id !== participantId));
  };

  const getColorForInitial = (initial) => {
    const colors = ['blue', 'green', 'purple', 'yellow', 'red'];
    const charCode = initial.charCodeAt(0);
    return colors[charCode % colors.length];
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (10MB limit)
      if (file.size > 10 * 1024 * 1024) {
        setError("File is too large. Maximum size is 10MB.");
        return;
      }
      
      setNewDocumentFile(file);
      setError('');
    }
  };

  const handleRemoveFile = () => {
    setNewDocumentFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!title.trim()) {
      setError("Title is required");
      return;
    }
    
    if (!description.trim()) {
      setError("Description is required");
      return;
    }
    
    if (!dueDate) {
      setError("Due date is required");
      return;
    }
    
    if (steps.length === 0) {
      setError("At least one step is required");
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      // Upload file if present
      let uploadedDocuments = [...documents];
      if (newDocumentFile) {
        const storageRef = ref(storage, `workflows/${workflowId || 'new'}/documents/${Date.now()}_${newDocumentFile.name}`);
        
        const snapshot = await uploadBytes(storageRef, newDocumentFile);
        const downloadURL = await getDownloadURL(snapshot.ref);
        
        uploadedDocuments.push({
          id: Date.now().toString(),
          name: newDocumentFile.name,
          url: downloadURL,
          type: newDocumentFile.type,
          size: newDocumentFile.size,
          uploadedBy: userProfile?.id,
          uploadedAt: new Date()
        });
      }
      
      // Calculate workflow progress
      const completedSteps = steps.filter(step => step.status === 'Completed').length;
      const progress = steps.length > 0 ? Math.round((completedSteps / steps.length) * 100) : 0;
      
      // Prepare workflow data
      const workflowData = {
        title: title.trim(),
        description: description.trim(),
        priority,
        dueDate: new Date(dueDate),
        steps,
        participants,
        documents: uploadedDocuments,
        progress,
        status: completedSteps === steps.length ? 'Completed' : 'In Progress',
        currentStep: completedSteps + 1,
        totalSteps: steps.length,
        institutionName: userProfile?.institutionName,
        institutionId: userProfile?.institutionId,
        lastUpdated: new Date(),
        ...(isEditing ? {} : {
          createdBy: userProfile?.id,
          createdAt: new Date()
        })
      };
      
      // Save to Firestore
      const workflowRef = isEditing 
        ? doc(db, "documentWorkflows", workflowId) 
        : doc(collection(db, "documentWorkflows"));
      
      await setDoc(workflowRef, workflowData, { merge: isEditing });
      
      setSuccessMessage(`Workflow successfully ${isEditing ? 'updated' : 'created'}`);
      
      // Redirect after success
      setTimeout(() => {
        navigate('/documents');
      }, 1500);
    } catch (error) {
      console.error("Error saving workflow:", error);
      setError(`Failed to ${isEditing ? 'update' : 'create'} workflow: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Filter staff for search
  const filteredStaff = staffOptions.filter(staff => 
    !searchTerm || 
    `${staff.firstName} ${staff.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  return (
    <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
      {/* Header */}
      <div className="flex items-start justify-between mb-6">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/documents')}
            className="mr-4 text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft size={18} />
          </button>
          <h1 className="text-xl font-bold text-gray-900">
            {isEditing ? 'Edit Document Workflow' : 'Create Document Workflow'}
          </h1>
        </div>
      </div>
  
      {/* Error and Success Messages */}
      {error && (
        <div className="mb-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
          {error}
        </div>
      )}
  
      {successMessage && (
        <div className="mb-4 p-3 bg-green-50 border-l-4 border-green-500 text-green-700">
          {successMessage}
        </div>
      )}
  
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Primary Details */}
        <div className="bg-[#f7f7f7] p-4 rounded-md">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="md:col-span-2">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                Workflow Title*
              </label>
              <input
                id="title"
                name="title"
                type="text"
                required
                className="pl-2 py-2 block w-full rounded-md border-gray-500 shadow-md focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                disabled={isLoading}
              />
            </div>
  
            <div className="md:col-span-2">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                Description*
              </label>
              <textarea
                id="description"
                name="description"
                rows="3"
                required
                className="pl-2 py-2 block w-full rounded-md border-gray-400 shadow-md focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={isLoading}
              ></textarea>
            </div>
  
            <div>
              <label htmlFor="priority" className="block text-sm font-medium text-gray-700 mb-1">
                Priority*
              </label>
              <select
                id="priority"
                name="priority"
                required
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                disabled={isLoading}
              >
                {priorities.map((p) => (
                  <option key={p} value={p}>{p}</option>
                ))}
              </select>
            </div>
  
            <div>
              <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700 mb-1">
                Due Date*
              </label>
              <input
                id="dueDate"
                name="dueDate"
                type="date"
                required
                className="block w-full rounded-md border-gray-400 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                min={new Date().toISOString().split('T')[0]}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
  
        {/* Workflow Steps Section */}
        <div className="bg-gray-50 p-4 rounded-md border-t">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Workflow Steps</h3>
          <div className="bg-white p-4 rounded-md mb-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div>
                <label htmlFor="stepName" className="block text-sm font-medium text-gray-700 mb-1">
                  Step Name*
                </label>
                <input
                  id="stepName"
                  name="stepName"
                  type="text"
                  className="block w-full rounded-md border-gray-400 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  value={newStep.name}
                  onChange={(e) => setNewStep({ ...newStep, name: e.target.value })}
                  disabled={isLoading}
                />
              </div>
  
              <div>
                <label htmlFor="stepAssignee" className="block text-sm font-medium text-gray-700 mb-1">
                  Assignee*
                </label>
                <select
                  id="stepAssignee"
                  name="stepAssignee"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  value={newStep.assignee}
                  onChange={(e) => setNewStep({ ...newStep, assignee: e.target.value })}
                  disabled={isLoading}
                >
                  <option value="">Select Assignee</option>
                  {staffOptions.map((staff) => (
                    <option key={staff.id} value={staff.id}>
                      {staff.title} {staff.firstName} {staff.lastName}
                    </option>
                  ))}
                </select>
              </div>
  
              <div>
                <label htmlFor="stepDueDate" className="block text-sm font-medium text-gray-700 mb-1">
                  Step Due Date*
                </label>
                <input
                  id="stepDueDate"
                  name="stepDueDate"
                  type="date"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  value={newStep.dueDate}
                  onChange={(e) => setNewStep({ ...newStep, dueDate: e.target.value })}
                  min={new Date().toISOString().split('T')[0]}
                  max={dueDate}
                  disabled={isLoading || !dueDate}
                />
              </div>
            </div>
  
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleAddStep}
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-sm"
                disabled={isLoading}
              >
                <Plus size={16} className="mr-2" />
                Add Step
              </button>
            </div>
          </div>
  
          {steps.length > 0 ? (
            <div className="space-y-4">
              {steps.map((step, index) => (
                <div 
                  key={step.id}
                  className="flex items-center justify-between bg-white p-4 rounded-md border border-gray-200"
                >
                  <div className="flex items-center">
                    <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
                      step.status === 'Completed'
                        ? 'bg-green-100 text-green-600'
                        : step.status === 'In Progress'
                          ? 'bg-blue-100 text-blue-600'
                          : step.status === 'Awaiting Input'
                            ? 'bg-yellow-100 text-yellow-600'
                            : step.status === 'Rejected'
                              ? 'bg-red-100 text-red-600'
                              : 'bg-gray-100 text-gray-400'
                    } mr-3`}>
                      {index + 1}
                    </div>
                    <div>
                      <p className="text-sm font-medium">{step.name}</p>
                      <div className="flex items-center mt-1">
                        <span className="text-xs text-gray-500 mr-3">
                          Assigned to: {step.assigneeName || step.assignee}
                        </span>
                        <span className="text-xs text-gray-500 flex items-center">
                          <Calendar size={12} className="mr-1" />
                          Due: {step.dueDate}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button 
                    type="button"
                    onClick={() => handleRemoveStep(step.id)}
                    className="text-gray-400 hover:text-red-600"
                    disabled={isLoading}
                  >
                    <Trash size={16} />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8 bg-gray-50 rounded-md border border-gray-200">
              <p className="text-gray-500">No steps added yet. Add steps to define your workflow.</p>
            </div>
          )}
        </div>
  
        {/* Participants Section */}
        <div className="bg-gray-50 p-4 rounded-md border-t">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Participants</h3>
            {!showParticipantSearch && (
              <button 
                type="button"
                onClick={() => setShowParticipantSearch(true)}
                className="flex items-center text-sm font-medium text-blue-600 hover:text-blue-800"
                disabled={isLoading}
              >
                <Plus size={16} className="mr-1" />
                Add Participant
              </button>
            )}
          </div>
          
          {showParticipantSearch && (
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <input
                  type="text"
                  placeholder="Search staff members..."
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  disabled={isLoading}
                />
                <button 
                  type="button"
                  onClick={() => setShowParticipantSearch(false)}
                  className="ml-2 text-gray-500 hover:text-gray-700"
                  disabled={isLoading}
                >
                  <X size={18} />
                </button>
              </div>
              
              <div className="bg-white border border-gray-300 rounded-md shadow-sm max-h-60 overflow-y-auto">
                {filteredStaff.length > 0 ? (
                  filteredStaff.map(staff => (
                    <div 
                      key={staff.id}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                      onClick={() => handleAddParticipant(staff)}
                    >
                      <div className={`w-8 h-8 rounded-full bg-${getColorForInitial(staff.initials.charAt(0))}-100 flex items-center justify-center text-${getColorForInitial(staff.initials.charAt(0))}-600 font-medium mr-2`}>
                        {staff.initials}
                      </div>
                      <div>
                        <p className="text-sm font-medium">
                          {staff.title} {staff.firstName} {staff.lastName}
                        </p>
                        <p className="text-xs text-gray-500">{staff.userRole}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="px-4 py-3 text-sm text-gray-500">
                    {searchTerm ? 'No matching staff found' : 'Start typing to search for staff members'}
                  </div>
                )}
              </div>
            </div>
          )}
          
          {participants.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
              {participants.map(participant => (
                <div 
                  key={participant.id}
                  className="flex items-center justify-between bg-gray-50 p-3 rounded-md border border-gray-200"
                >
                  <div className="flex items-center">
                    <div className={`w-8 h-8 rounded-full bg-${participant.color}-100 flex items-center justify-center text-${participant.color}-600 font-medium mr-2`}>
                      {participant.avatar}
                    </div>
                    <div className="text-sm font-medium">{participant.name}</div>
                  </div>
                  <button 
                    type="button"
                    onClick={() => handleRemoveParticipant(participant.id)}
                    className="text-gray-400 hover:text-red-600"
                    disabled={isLoading}
                  >
                    <X size={16} />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-6 bg-gray-50 rounded-md border border-gray-200">
              <p className="text-gray-500">No participants added yet.</p>
            </div>
          )}
        </div>
  
        {/* Documents Section */}
        <div className="bg-gray-50 p-4 rounded-md border-t">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Documents</h3>
          
          {!newDocumentFile && (
            <div className="mb-4 border-2 border-gray-300 border-dashed rounded-md p-6">
              <div className="text-center">
                <FileUp className="mx-auto h-12 w-12 text-gray-400" />
                <div className="mt-2">
                  <label htmlFor="file-upload" className="cursor-pointer text-blue-600 hover:text-blue-500">
                    <span>Upload a document</span>
                    <input 
                      id="file-upload" 
                      name="file-upload" 
                      type="file" 
                      className="sr-only"
                      onChange={handleFileChange}
                      disabled={isLoading} 
                    />
                  </label>
                  <p className="text-sm text-gray-500 mt-1">
                    PDF, DOC, DOCX, XLS, XLSX up to 10MB
                  </p>
                </div>
              </div>
            </div>
          )}
          
          {newDocumentFile && (
            <div className="mb-4 bg-gray-50 p-4 rounded-md border border-gray-200 flex items-center justify-between">
              <div className="flex items-center">
                <FileText size={20} className="text-blue-500 mr-3" />
                <div>
                  <p className="text-sm font-medium">{newDocumentFile.name}</p>
                  <p className="text-xs text-gray-500">
                    {(newDocumentFile.size / 1024).toFixed(1)} KB • {newDocumentFile.type}
                  </p>
                </div>
              </div>
              <button 
                type="button"
                onClick={handleRemoveFile}
                className="text-gray-400 hover:text-red-600"
                disabled={isLoading}
              >
                <X size={16} />
              </button>
            </div>
          )}
          
          {documents.length > 0 && (
            <div className="mt-4">
              <h4 className="text-sm font-medium text-gray-700 mb-2">Existing Documents</h4>
              <div className="space-y-2">
                {documents.map(doc => (
                  <div key={doc.id} className="bg-gray-50 p-3 rounded-md border border-gray-200 flex items-center justify-between">
                    <div className="flex items-center">
                      <FileText size={18} className="text-blue-500 mr-2" />
                      <div>
                        <a 
                          href={doc.url} 
                          target="_blank" 
                          rel="noreferrer" 
                          className="text-sm font-medium text-blue-600 hover:underline"
                        >
                          {doc.name}
                        </a>
                        <p className="text-xs text-gray-500">
                          {(doc.size / 1024).toFixed(1)} KB
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
  
        {/* Form Actions */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate('/documents')}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={isLoading}
          >
            <Save size={16} className="mr-2" />
            {isLoading ? 'Saving...' : 'Save Workflow'}
          </button>
        </div>
      </form>
    </div>
  );
};  

export default DocumentWorkflowForm;