// src/components/tasks/TaskList.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  Plus, Search, Filter, ChevronDown, ChevronUp, 
  Calendar, User, CheckCircle, Clock, AlertCircle,
  Target, Clipboard, MessageSquare
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs
} from 'firebase/firestore';

const TaskList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, userProfile } = useAuth();
  
  // Get planId from query params if any
  const queryParams = new URLSearchParams(location.search);
  const planIdParam = queryParams.get('planId');
  
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  
  // Filter states
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  
  // Options for filters
  const categories = ['Planned', 'Meeting', 'Instruction'];
  const statuses = ['Not Started', 'In Progress', 'Completed', 'Overdue'];
  
  // Fetch tasks from Firestore
  useEffect(() => {
    const fetchTasks = async () => {
      if (!userProfile?.institutionName) {
        setIsLoading(false);
        return;
      }
      
      setIsLoading(true);
      try {
        const tasksRef = collection(db, "tasks");
        let q;
        
        // If planId is provided, filter by that plan
        if (planIdParam) {
          q = query(
            tasksRef,
            where("institutionName", "==", userProfile.institutionName),
            where("taskRef.type", "==", "Plan"),
            where("taskRef.id", "==", planIdParam),
            orderBy("date", "desc")
          );
        } else {
          q = query(
            tasksRef,
            where("institutionName", "==", userProfile.institutionName),
            orderBy("date", "desc")
          );
        }
        
        const querySnapshot = await getDocs(q);
        const tasksList = [];
        
        querySnapshot.forEach((doc) => {
          tasksList.push({
            id: doc.id,
            ...doc.data(),
            // Convert Firestore Timestamps to JS Date objects
            date: doc.data().date?.toDate(),
            startDate: doc.data().startDate?.toDate(),
            endDate: doc.data().endDate?.toDate()
          });
        });
        
        setTasks(tasksList);
        setFilteredTasks(tasksList);
      } catch (err) {
        console.error("Error fetching tasks:", err);
        setError("Failed to load tasks. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTasks();
  }, [userProfile, planIdParam]);
  
  // Apply filters when search term or filters change
  useEffect(() => {
    if (tasks.length === 0) return;
    
    const now = new Date();
    
    let filtered = tasks.filter(task => {
      // Search term filter
      const matchesSearch = !searchTerm || 
        task.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.assignee?.toLowerCase().includes(searchTerm.toLowerCase());
      
      // Category filter
      const matchesCategory = !categoryFilter || task.category === categoryFilter;
      
      // Status filter
      let matchesStatus = true;
      if (statusFilter) {
        const taskStatus = getTaskStatus(task);
        matchesStatus = taskStatus.label === statusFilter;
      }
      
      // Assignee filter
      const matchesAssignee = !assigneeFilter || task.assignee === assigneeFilter;
      
      return matchesSearch && matchesCategory && matchesStatus && matchesAssignee;
    });
    
    setFilteredTasks(filtered);
  }, [tasks, searchTerm, categoryFilter, statusFilter, assigneeFilter]);
  
  // Calculate task status
  const getTaskStatus = (task) => {
    if (task.status === 'Completed') {
      return { label: 'Completed', color: 'bg-green-100 text-green-800' };
    }
    
    if (!task.startDate || !task.endDate) {
      return { label: 'Not Started', color: 'bg-gray-100 text-gray-800' };
    }
    
    const now = new Date();
    const startDate = new Date(task.startDate);
    const endDate = new Date(task.endDate);
    
    if (now < startDate) {
      return { label: 'Not Started', color: 'bg-gray-100 text-gray-800' };
    } else if (now > endDate) {
      return { label: 'Overdue', color: 'bg-red-100 text-red-800' };
    } else {
      return { label: 'In Progress', color: 'bg-yellow-100 text-yellow-800' };
    }
  };
  
  // Format date for display
  const formatDate = (date) => {
    if (!date) return 'Not specified';
    
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };
  
  // Get category badge color
  const getCategoryBadgeColor = (category) => {
    switch (category) {
      case 'Planned':
        return 'bg-blue-100 text-blue-800';
      case 'Meeting':
        return 'bg-purple-100 text-purple-800';
      case 'Instruction':
        return 'bg-amber-100 text-amber-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  // Reset all filters
  const resetFilters = () => {
    setSearchTerm('');
    setCategoryFilter('');
    setStatusFilter('');
    setAssigneeFilter('');
  };
  
  // Get unique assignees for filter
  const getUniqueAssignees = () => {
    const assignees = new Set();
    tasks.forEach(task => {
      if (task.assignee) {
        assignees.add(task.assignee);
      }
    });
    return [...assignees].sort();
  };
  
  // Get task type icon
  const getTaskTypeIcon = (task) => {
    switch (task.category) {
      case 'Planned':
        return <Clipboard className="h-5 w-5 text-blue-500" />;
      case 'Meeting':
        return <MessageSquare className="h-5 w-5 text-purple-500" />;
      case 'Instruction':
        return <Target className="h-5 w-5 text-amber-500" />;
      default:
        return <Clipboard className="h-5 w-5 text-gray-500" />;
    }
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">
          {planIdParam ? 'Plan Tasks' : 'Tasks'}
        </h2>
        <Link
          to="/tasks/create"
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 flex items-center"
        >
          <Plus size={18} className="mr-2" />
          Create Task
        </Link>
      </div>
      
      {/* Search and Filters */}
      <div className="p-4 border-b border-gray-200 bg-gray-50">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0">
          <div className="relative flex items-center">
            <Search size={18} className="absolute left-3 text-gray-400" />
            <input 
              type="text" 
              placeholder="Search tasks..." 
              className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full md:w-64" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="flex flex-wrap items-center space-x-3">
            <div className="relative">
              <select
                className="appearance-none pl-3 pr-10 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="">All Statuses</option>
                {statuses.map((status) => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
              <ChevronDown size={16} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>
            
            <button 
              className="flex items-center text-gray-700 hover:text-gray-900 border px-3 py-2 rounded-md"
              onClick={() => setShowFilters(!showFilters)}
            >
              <Filter size={16} className="mr-2" />
              Filters
              {showFilters ? <ChevronUp size={16} className="ml-2" /> : <ChevronDown size={16} className="ml-2" />}
            </button>
            
            {(searchTerm || categoryFilter || statusFilter || assigneeFilter) && (
              <button 
                className="text-blue-600 hover:text-blue-800 text-sm font-medium"
                onClick={resetFilters}
              >
                Clear All
              </button>
            )}
          </div>
        </div>
        
        {/* Expanded filters */}
        {showFilters && (
          <div className="mt-4 pt-4 border-t border-gray-200">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label htmlFor="categoryFilter" className="block text-sm font-medium text-gray-700 mb-1">
                  Category
                </label>
                <select
                  id="categoryFilter"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={categoryFilter}
                  onChange={(e) => setCategoryFilter(e.target.value)}
                >
                  <option value="">All Categories</option>
                  {categories.map((category) => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>
              
              <div>
                <label htmlFor="assigneeFilter" className="block text-sm font-medium text-gray-700 mb-1">
                  Assignee
                </label>
                <select
                  id="assigneeFilter"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={assigneeFilter}
                  onChange={(e) => setAssigneeFilter(e.target.value)}
                >
                  <option value="">All Assignees</option>
                  {getUniqueAssignees().map((assignee) => (
                    <option key={assignee} value={assignee}>{assignee}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
      
      {/* Loading State */}
      {isLoading && (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      )}
      
      {/* Error State */}
      {error && (
        <div className="m-4 p-4 bg-red-50 text-red-800 rounded-md">
          {error}
        </div>
      )}
      
      {/* Empty State */}
      {!isLoading && !error && filteredTasks.length === 0 && (
        <div className="text-center py-12">
          <CheckCircle className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No tasks found</h3>
          <p className="mt-1 text-sm text-gray-500">
            {tasks.length === 0 ? 
              'Get started by creating your first task.' : 
              'Try adjusting your filters or search terms.'}
          </p>
          {tasks.length === 0 && (
            <div className="mt-6">
              <Link
                to="/tasks/create"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
              >
                <Plus size={16} className="mr-2" />
                Create a task
              </Link>
            </div>
          )}
        </div>
      )}
      
      {/* Tasks List */}
      {!isLoading && !error && filteredTasks.length > 0 && (
        <div className="overflow-hidden">
          <ul className="divide-y divide-gray-200">
            {filteredTasks.map((task) => {
              const taskStatus = getTaskStatus(task);
              
              return (
                <li 
  key={task.id} 
  className="hover:bg-gray-50 transition-colors cursor-pointer"
  onClick={() => navigate(`/tasks/details/${task.id}`)}
>
  <div className="p-6">
    <div className="flex items-start">
      <div className="flex-shrink-0 mr-4">
        {getTaskTypeIcon(task)}
      </div>
      
      <div className="flex-1 min-w-0">
        <div className="flex items-center justify-between mb-1">
          <h3 className="text-base font-medium text-gray-900 truncate">{task.description}</h3>
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${taskStatus.color}`}>
            {taskStatus.label}
          </span>
        </div>
        
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            {task.category && (
              <p className="flex items-center text-sm text-gray-500">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getCategoryBadgeColor(task.category)}`}>
                  {task.category}
                </span>
              </p>
            )}
            
            {task.taskRef && task.taskRef.type && (
              <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                <Target className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                From: {task.taskRef.type}
              </p>
            )}
          </div>
          
          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
            <Calendar className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
            <p>
              {task.startDate && task.endDate 
                ? `${formatDate(task.startDate)} - ${formatDate(task.endDate)}` 
                : 'No dates specified'}
            </p>
          </div>
        </div>
        
        {task.assignee && (
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <User className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
            <p>
              Assigned to: {task.assignee}
            </p>
          </div>
        )}
      </div>
    </div>
  </div>
</li>
);
})}
</ul>
</div>
)}
</div>
);
};

export default TaskList;