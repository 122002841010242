// src/pages/RecordPage.js
import React from 'react';
import { Outlet } from 'react-router-dom';

const RecordPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Outlet /> {/* This will render nested routes */}
    </div>
  );
};

export default RecordPage;
