// src/routes/index.js
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

// Layouts
import MainLayout from '../layouts/MainLayout';
import PublicLayout from '../layouts/PublicLayout';

// Pages
import About from '../pages/About';
import Login from '../pages/Login';
import Register from '../pages/Register';
import CreateProfile from '../pages/CreateProfile';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';
import Dashboard from '../pages/Dashboard';
import RecordPage from '../pages/RecordPage';
import RecordList from '../components/records/RecordList';
import RecordForm from '../components/records/RecordForm';
import RecordDetail from '../components/records/RecordDetail';
import InstitutionsPage from '../pages/InstitutionsPage';
import SchoolStructuresPage from '../pages/SchoolStructuresPage';
import StakeholdersPage from '../pages/StakeholdersPage';
import SubjectsPage from '../pages/SubjectsPage';
import ResourcePage from '../pages/ResourcePage';
import DocumentsPage from '../pages/DocumentsPage';
import MessagingPage from '../pages/MessagingPage';
import MonitoringPage from '../pages/MonitoringPage';

// Meetings Components
import Meetings from '../pages/Meetings'; // Layout for Meetings section
import MeetingList from '../components/meetings/MeetingList';
import MeetingForm from '../components/meetings/MeetingForm';
import MeetingDetails from '../components/meetings/MeetingDetails';
import AgendaForm from '../components/meetings/AgendaForm';
import MinutesCapture from '../components/meetings/MinutesCapture';

// Other component imports remain unchanged
import InstitutionList from '../components/institutions/InstitutionList';
import InstitutionDetails from '../components/institutions/InstitutionDetails';
import InstitutionRegistration from '../components/institutions/InstitutionRegistration';
import ProjectProgrammeList from '../components/institutions/ProjectProgrammeList';
import ProjectProgrammeRegistration from '../components/institutions/ProjectProgrammeRegistration';
import ProjectProgrammeDetails from '../components/institutions/ProjectProgrammeDetails';
import SubjectList from '../components/subjects/SubjectList';
import SubjectForm from '../components/subjects/SubjectForm';
import SubjectDetails from '../components/subjects/SubjectDetails';
import CurriculumForm from '../components/subjects/CurriculumForm';
import StakeholderList from '../components/stakeholders/StakeholderList';
import StakeholderForm from '../components/stakeholders/StakeholderForm';
import StakeholderDetails from '../components/stakeholders/StakeholderDetails';
import ResourceList from '../components/resources/ResourceList';
import ResourceForm from '../components/resources/ResourceForm';
import ResourceDetails from '../components/resources/ResourceDetails';
import SchoolStructureList from '../components/structures/SchoolStructureList';
import SchoolStructureForm from '../components/structures/SchoolStructureForm';
import SchoolStructureDetails from '../components/structures/SchoolStructureDetails';
import DocumentWorkflowList from '../components/documents/DocumentWorkflowList';
import DocumentWorkflowForm from '../components/documents/DocumentWorkflowForm';
import DocumentWorkflowDetails from '../components/documents/DocumentWorkflowDetails';
import InternalMessaging from '../components/messaging/InternalMessaging';
import ChannelList from '../components/messaging/ChannelList';
import DirectMessageList from '../components/messaging/DirectMessageList';
import MessageList from '../components/messaging/MessageList';
import EmojiPicker from '../components/messaging/EmojiPicker';
import UserMessages from '../components/messaging/UserMessages';
import MonitoringToolDashboard from '../components/monitoring/MonitoringToolDashboard';
import MonitoringForm from '../components/monitoring/MonitoringForm';
import MonitoringAnalytics from '../components/monitoring/MonitoringAnalytics';
import PlannerPage from '../pages/PlannerPage';
import PlanList from '../components/planner/PlanList';
import PlanForm from '../components/planner/PlanForm';
import PlanDetails from '../components/planner/PlanDetails';
import TasksPage from '../pages/TasksPage';
import TaskList from '../components/tasks/TaskList';
import TaskForm from '../components/tasks/TaskForm';
import TaskDetails from '../components/tasks/TaskDetails';
import StaffPage from '../pages/StaffPage';
import StaffList from '../components/staff/StaffList';
import AIChatPage from '../pages/AIChatPage';

const AppRoutes = () => {
  const { currentUser, userProfile, loading } = useAuth();
  
  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#1d67cd]"></div>
      </div>
    );
  }
  
  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route element={<PublicLayout />}>
          
          <Route path="/about" element={<About />} />
          <Route path="/login" element={!currentUser ? <Login /> : <Navigate to="/dashboard" />} />
          <Route path="/register" element={!currentUser ? <Register /> : <Navigate to="/dashboard" />} />
        </Route>
        
        {/* Create Profile route */}
        <Route 
          path="/create-profile" 
          element={
            currentUser && !userProfile ? 
            <CreateProfile /> : 
            (currentUser ? <Navigate to="/dashboard" /> : <Navigate to="/login" />)
          } 
        />
        
        <Route path="/" element={
          currentUser ? 
            (userProfile ? <MainLayout /> : <Navigate to="/create-profile" />) : 
            <Navigate to="/login" />
        }>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="settings" element={<Settings />} />
          <Route path="profile/:id" element={<Profile />} />
          
          {/* Resources routes */}
          <Route path="resources" element={<ResourcePage />}>
            <Route index element={<ResourceList />} />
            <Route path="create" element={<ResourceForm />} />
            <Route path="edit/:resourceId" element={<ResourceForm isEditing />} />
            <Route path="details/:resourceId" element={<ResourceDetails />} />
            <Route path="*" element={<Navigate to="/resources" replace />} />
          </Route>

          {/* Documents routes */}
          <Route path="documents" element={<DocumentsPage />}>
            <Route index element={<DocumentWorkflowList />} />
            <Route path="create" element={<DocumentWorkflowForm />} />
            <Route path="edit/:workflowId" element={<DocumentWorkflowForm isEditing />} />
            <Route path="details/:workflowId" element={<DocumentWorkflowDetails />} />
            <Route path="*" element={<Navigate to="/documents" replace />} />
          </Route>

          {/* Meetings routes */}
          <Route path="meetings" element={<Meetings />}>
            {/* Default Meetings route shows the meeting list */}
            <Route index element={<MeetingList />} />
            <Route path="create" element={<MeetingForm />} />
            <Route path="edit/:id" element={<MeetingForm isEditing />} />
            <Route path="details/:id" element={<MeetingDetails />} />
            <Route path="agenda/:id" element={<AgendaForm />} />
            <Route path="minutes/:id" element={<MinutesCapture />} />
            <Route path="*" element={<Navigate to="/meetings" replace />} />
          </Route>

          {/* Communication routes */}
          <Route path="communication" element={<MessagingPage />}>
            <Route index element={<InternalMessaging />} />
            <Route path="channels" element={<ChannelList />} />
            <Route path="direct-messages" element={<DirectMessageList />} />
            <Route path="messages" element={<MessageList />} />
            <Route path="emoji" element={<EmojiPicker />} />
            <Route path="user-messages" element={<UserMessages />} />
            <Route path="*" element={<Navigate to="/communication" replace />} />
          </Route>

          <Route path="staff" element={<StaffPage />}>
            <Route index element={<StaffList />} />
          </Route>
          
          {/* Subjects routes */}
          <Route path="subjects" element={<SubjectsPage />}>
            <Route index element={<SubjectList />} />
            <Route path="create" element={<SubjectForm />} />
            <Route path="edit/:subjectId" element={<SubjectForm isEditing />} />
            <Route path="details/:subjectId" element={<SubjectDetails />} />
            <Route path="curriculum/:subjectId" element={<CurriculumForm />} />
            <Route path="*" element={<SubjectList />} />
          </Route>
          
          {/* Institutions routes */}
          <Route path="institutions" element={<InstitutionsPage />}>
            <Route index element={<InstitutionList />} /> 
            <Route path="province/:id" element={<InstitutionList />} />
            <Route path="district/:id" element={<InstitutionList />} />
            <Route path="circuit/:id" element={<InstitutionList />} />
            <Route path="details/:id" element={<InstitutionDetails />} />
            <Route path="register" element={<InstitutionRegistration />} />
            <Route path="edit/:id" element={<InstitutionRegistration isEditing />} />
            <Route path="*" element={<Navigate to="/institutions" replace />} />

            {/* Routes for Projects and Programmes */}
            <Route path="projects" element={<ProjectProgrammeList />} />
            <Route path="projects/register" element={<ProjectProgrammeRegistration />} />
            <Route path="projects/details/:id" element={<ProjectProgrammeDetails />} />
            <Route path="projects/edit/:id" element={<ProjectProgrammeRegistration isEditing />} />
          </Route>

          {/* Structures routes */}
          <Route path="structures" element={<SchoolStructuresPage />}>
            <Route index element={<SchoolStructureList />} />
            <Route path="create" element={<SchoolStructureForm />} />
            <Route path="edit/:structureId" element={<SchoolStructureForm isEditing />} />
            <Route path="details/:structureId" element={<SchoolStructureDetails />} />
            <Route path="*" element={<Navigate to="/structures" replace />} />
          </Route>

          {/* Stakeholders routes */}
          <Route path="stakeholders" element={<StakeholdersPage />}>
            <Route index element={<StakeholderList />} />
            <Route path="create" element={<StakeholderForm />} />
            <Route path="edit/:stakeholderId" element={<StakeholderForm isEditing />} />
            <Route path="details/:stakeholderId" element={<StakeholderDetails />} />
            <Route path="*" element={<Navigate to="/stakeholders" replace />} />
          </Route>

          {/* Monitoring routes */}
          <Route path="monitoring" element={<MonitoringPage />}>
            <Route index element={<MonitoringToolDashboard />} /> 
            <Route path="form/:id" element={<MonitoringForm />} />
            <Route path="create" element={<MonitoringForm />} />
            <Route path="analytics" element={<MonitoringAnalytics />} />
          </Route>

          <Route path="planner" element={<PlannerPage />}>
            <Route index element={<PlanList />} />
            <Route path="create" element={<PlanForm />} />
            <Route path="edit/:planId" element={<PlanForm isEditing />} />
            <Route path="details/:planId" element={<PlanDetails />} />
          </Route>

          <Route path="tasks" element={<TasksPage />}>
            <Route index element={<TaskList />} />
            <Route path="create" element={<TaskForm />} />
            <Route path="edit/:taskId" element={<TaskForm isEditing />} />
            <Route path="details/:taskId" element={<TaskDetails />} />
          </Route>

          <Route path="records" element={<RecordPage />}>
            <Route index element={<RecordList />} />
            <Route path="create" element={<RecordForm />} />
            <Route path="edit/:recordId" element={<RecordForm isEditing />} />
            <Route path="view/:recordId" element={<RecordDetail />} />
          </Route>

          <Route path="ai-chat" element={<AIChatPage />} />
          
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;