// src/components/subjects/CurriculumList.jsx
import React, { useState } from 'react';
import { 
  ChevronDown, ChevronUp, Calendar, ArrowRight, 
  FileText, Clock, Edit
} from 'lucide-react';
import { Link } from 'react-router-dom';

const CurriculumList = ({ curriculumItems, subjectId }) => {
  const [expandedTerm, setExpandedTerm] = useState(null);
  
  // Toggle expanded state for a term
  const toggleTerm = (term) => {
    setExpandedTerm(expandedTerm === term ? null : term);
  };
  
  // Group items by term
  const groupedItems = curriculumItems.reduce((acc, item) => {
    const term = item.term;
    if (!acc[term]) {
      acc[term] = [];
    }
    acc[term].push(item);
    return acc;
  }, {});
  
  // Format date in a readable way
  const formatDate = (dateString) => {
    if (!dateString) return '—';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-ZA', { 
      day: '2-digit', 
      month: '2-digit',
      year: 'numeric'
    });
  };
  
  if (!curriculumItems || curriculumItems.length === 0) {
    return (
      <div className="text-center py-8 bg-gray-50 rounded-lg">
        <FileText className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No curriculum items</h3>
        <p className="mt-1 text-sm text-gray-500">
          This subject doesn't have any curriculum items yet.
        </p>
        <div className="mt-6">
          <Link
            to={`/subjects/curriculum/${subjectId}`}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#1d67cd] hover:bg-blue-700"
          >
            <Edit className="h-4 w-4 mr-2" />
            Add Curriculum Items
          </Link>
        </div>
      </div>
    );
  }
  
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      {Object.entries(groupedItems).map(([term, items]) => (
        <div key={term} className="border-b last:border-b-0 border-gray-200">
          <div 
            className={`px-4 py-5 cursor-pointer hover:bg-gray-50 ${
              expandedTerm === term ? 'bg-blue-50' : ''
            }`}
            onClick={() => toggleTerm(term)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0 h-10 w-10 rounded-full bg-[#1d67cd] bg-opacity-10 flex items-center justify-center text-[#1d67cd]">
                  {expandedTerm === term ? (
                    <ChevronUp className="h-5 w-5" />
                  ) : (
                    <ChevronDown className="h-5 w-5" />
                  )}
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    Term {term}
                  </p>
                  <p className="text-xs text-gray-500">
                    {items.length} topic{items.length !== 1 ? 's' : ''}
                  </p>
                </div>
              </div>
              <div className="text-xs text-gray-500">
                {items[0]?.year || new Date().getFullYear()}
              </div>
            </div>
          </div>
          
          {expandedTerm === term && (
            <div className="border-t border-gray-200 bg-gray-50">
              <div className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider grid grid-cols-10 gap-2">
                <div className="col-span-6">Topic Details</div>
                <div className="col-span-2">Dates</div>
                <div className="col-span-2">Duration</div>
              </div>
              <div className="divide-y divide-gray-200">
                {items.map((item) => (
                  <div key={item.id} className="px-4 py-3 grid grid-cols-10 gap-4 text-sm">
                    {/* Topic, Subtopic and Content combined */}
                    <div className="col-span-6">
                      <div className="flex items-center mb-1">
                        <span className="font-medium text-gray-900 mr-2">{item.topic}</span>
                        {item.subtopic && (
                          <span className="text-gray-600">• {item.subtopic}</span>
                        )}
                      </div>
                      {item.content && (
                        <div className="text-sm text-gray-700 mt-1">{item.content}</div>
                      )}
                    </div>
                    
                    {/* Dates */}
                    <div className="col-span-2 text-gray-800 text-xs self-center">
                      {item.startDate ? (
                        <div>
                          <div className="flex items-center">
                            <Calendar className="h-3 w-3 text-gray-400 mr-1" />
                            <span>{formatDate(item.startDate)}</span>
                          </div>
                          {item.finishDate && (
                            <div className="flex items-center mt-1">
                              <ArrowRight className="h-3 w-3 text-gray-400 mr-1" />
                              <span>{formatDate(item.finishDate)}</span>
                            </div>
                          )}
                        </div>
                      ) : (
                        '—'
                      )}
                    </div>
                    
                    {/* Duration */}
                    <div className="col-span-2 text-gray-800 text-xs self-center">
                      {item.days ? (
                        <div className="flex items-center">
                          <Clock className="h-3 w-3 text-gray-400 mr-1" />
                          <span>{item.days} day{item.days !== 1 ? 's' : ''}</span>
                        </div>
                      ) : (
                        '—'
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
      
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <Link
          to={`/subjects/curriculum/${subjectId}`}
          className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Edit className="h-4 w-4 mr-2" />
          Edit Curriculum
        </Link>
      </div>
    </div>
  );
};

export default CurriculumList;