// src/components/ai/TimeSelector.jsx
import React, { useRef, useEffect, useState } from 'react';
import { Calendar, Clock } from 'lucide-react';

const TimeSelector = ({ onSelect, onClose }) => {
  const ref = useRef(null);
  const [showAbove, setShowAbove] = useState(false);
  
  // Determine if popup should appear above button
  useEffect(() => {
    if (!ref.current) return;
    
    const updatePosition = () => {
      const rect = ref.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const bottomSpace = viewportHeight - rect.bottom;
      
      // If there's less than 400px of space below (enough for all time options),
      // and enough space above, show the popup above the button
      if (bottomSpace < 400 && rect.top > 300) {
        setShowAbove(true);
      } else {
        setShowAbove(false);
      }
    };
    
    // Run once when component mounts
    updatePosition();
    
    // Also re-check on window resize
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, []);
  
  // Close when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const timeOptions = [
    { value: 'today', label: 'Today', icon: <Calendar className="h-4 w-4" /> },
    { value: 'yesterday', label: 'Yesterday', icon: <Calendar className="h-4 w-4" /> },
    { value: 'this_week', label: 'This Week', icon: <Calendar className="h-4 w-4" /> },
    { value: 'last_week', label: 'Last Week', icon: <Calendar className="h-4 w-4" /> },
    { value: 'this_month', label: 'This Month', icon: <Calendar className="h-4 w-4" /> },
    { value: 'last_month', label: 'Last Month', icon: <Calendar className="h-4 w-4" /> },
    { value: 'this_term', label: 'This Term', icon: <Calendar className="h-4 w-4" /> },
    { value: 'last_term', label: 'Last Term', icon: <Calendar className="h-4 w-4" /> },
    { value: 'this_year', label: 'This Year', icon: <Calendar className="h-4 w-4" /> },
    { value: 'last_year', label: 'Last Year', icon: <Calendar className="h-4 w-4" /> },
    { value: 'all_time', label: 'All Time', icon: <Clock className="h-4 w-4" /> },
  ];

  return (
    <div 
      ref={ref}
      className={`absolute z-10 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${
        showAbove ? 'bottom-full mb-1' : 'top-full mt-1'
      }`}
      style={{ left: 0 }}
    >
      <div className="py-1">
        {timeOptions.map((option) => (
          <button
            key={option.value}
            className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
            onClick={() => onSelect(option.value)}
          >
            <span className="mr-2 text-gray-500">{option.icon}</span>
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TimeSelector;