// src/components/ai/DataSourceSelector.jsx
import React, { useRef, useEffect, useState } from 'react';
import { 
  FileText, 
  Users, 
  Calendar, 
  ClipboardCheck,
  BarChart,
  BookOpen,
  Building
} from 'lucide-react';

const DataSourceSelector = ({ selected, onSelect, onClose }) => {
  const ref = useRef(null);
  const [showAbove, setShowAbove] = useState(false);
  
  // Determine if popup should appear above button
  useEffect(() => {
    if (!ref.current) return;
    
    const updatePosition = () => {
      const rect = ref.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const bottomSpace = viewportHeight - rect.bottom;
      
      // If there's less than 300px of space below, show above
      if (bottomSpace < 300 && rect.top > 300) {
        setShowAbove(true);
      } else {
        setShowAbove(false);
      }
    };
    
    // Run once when component mounts
    updatePosition();
    
    // Also re-check on window resize
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, []);
  
  // Close when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const dataSourceOptions = [
    { value: 'records', label: 'Records', icon: <FileText className="h-4 w-4" /> },
    { value: 'students', label: 'Students', icon: <Users className="h-4 w-4" /> },
    { value: 'attendance', label: 'Attendance', icon: <ClipboardCheck className="h-4 w-4" /> },
    { value: 'meetings', label: 'Meetings', icon: <Calendar className="h-4 w-4" /> },
    { value: 'reports', label: 'Reports', icon: <BarChart className="h-4 w-4" /> },
    { value: 'curriculum', label: 'Curriculum', icon: <BookOpen className="h-4 w-4" /> },
    { value: 'institutions', label: 'Institutions', icon: <Building className="h-4 w-4" /> }
  ];

  const toggleDataSource = (value) => {
    if (selected.includes(value)) {
      onSelect(selected.filter(item => item !== value));
    } else {
      onSelect([...selected, value]);
    }
  };

  return (
    <div 
      ref={ref}
      className={`absolute z-10 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${
        showAbove ? 'bottom-full mb-1' : 'top-full mt-1'
      }`}
      style={{ right: 0 }}
    >
      <div className="py-1">
        {dataSourceOptions.map((option) => (
          <button
            key={option.value}
            className="w-full text-left block px-4 py-2 text-sm hover:bg-gray-100 flex items-center justify-between"
            onClick={() => toggleDataSource(option.value)}
          >
            <div className="flex items-center">
              <span className={`mr-2 ${selected.includes(option.value) ? 'text-blue-500' : 'text-gray-500'}`}>
                {option.icon}
              </span>
              <span className={selected.includes(option.value) ? 'text-blue-700 font-medium' : 'text-gray-700'}>
                {option.label}
              </span>
            </div>
            
            {selected.includes(option.value) && (
              <div className="h-4 w-4 rounded-full bg-blue-500 flex items-center justify-center">
                <svg className="h-3 w-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
            )}
          </button>
        ))}
      </div>
      
      <div className="py-1 px-3 border-t border-gray-100">
        <div className="flex justify-between">
          <button
            className="text-xs text-blue-600 hover:text-blue-800"
            onClick={() => onSelect(dataSourceOptions.map(option => option.value))}
          >
            Select all
          </button>
          <button
            className="text-xs text-gray-600 hover:text-gray-800"
            onClick={() => onSelect([])}
          >
            Clear all
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataSourceSelector;