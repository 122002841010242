import React from 'react';

// Main card container
export function MonitoringCard({ className, ...props }) {
  return (
    <div
      className={`rounded-lg border border-gray-200 bg-white shadow-sm ${className || ''}`}
      {...props}
    />
  );
}

// Card header component
export function MonitoringCardHeader({ className, ...props }) {
  return (
    <div
      className={`flex flex-col space-y-1.5 p-6 ${className || ''}`}
      {...props}
    />
  );
}

// Card title component
export function MonitoringCardTitle({ className, ...props }) {
  return (
    <h3
      className={`text-lg font-semibold leading-none tracking-tight ${className || ''}`}
      {...props}
    />
  );
}

// Card description component
export function MonitoringCardDescription({ className, ...props }) {
  return (
    <p
      className={`text-sm text-gray-500 ${className || ''}`}
      {...props}
    />
  );
}

// Card content component
export function MonitoringCardContent({ className, ...props }) {
  return (
    <div
      className={`p-6 pt-0 ${className || ''}`}
      {...props}
    />
  );
}

// Card footer component
export function MonitoringCardFooter({ className, ...props }) {
  return (
    <div
      className={`flex items-center p-6 pt-0 ${className || ''}`}
      {...props}
    />
  );
}