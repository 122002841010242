// In SubjectsPage.js
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const SubjectsPage = () => {
  const location = useLocation();
  console.log("Current path in SubjectsPage:", location.pathname);

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-800">Subjects</h1>
        <p className="text-gray-600 mt-1">
          Manage and organize curriculum subjects for your institution.
        </p>
      </div>
      <Outlet />
    </div>
  );
};

export default SubjectsPage;
