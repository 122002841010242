import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  AlertCircle, 
  Download, 
  FileText, 
  Plus, 
  Save, 
  Check, 
  Clock, 
  Calendar, 
  BarChart2,
  Share,
  ArrowLeft,
  Send
} from 'lucide-react';

// Import our custom components
import { 
  MonitoringCard as Card,
  MonitoringCardContent as CardContent,
  MonitoringCardDescription as CardDescription, 
  MonitoringCardFooter as CardFooter, 
  MonitoringCardHeader as CardHeader, 
  MonitoringCardTitle as CardTitle 
} from '../common/MonitoringCard';

import { 
  MonitoringTabs as Tabs, 
  MonitoringTabsContent as TabsContent, 
  MonitoringTabsList as TabsList, 
  MonitoringTabsTrigger as TabsTrigger 
} from '../common/MonitoringTabs';

// Import the form component
import MonitoringForm from '../../components/monitoring/MonitoringForm';

const MonitoringToolDashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('overview');
  const [savedDrafts, setSavedDrafts] = useState(3);
  const [pendingForms, setPendingForms] = useState(2);
  const [completedForms, setCompletedForms] = useState(5);
  const [showForm, setShowForm] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);

  const handleNewEvaluation = () => {
    setShowForm(true);
  };

  const handleBackToDashboard = () => {
    setShowForm(false);
  };

  if (showForm) {
    return (
      <div className="container mx-auto">
        <MonitoringForm onBackClick={handleBackToDashboard} />
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-6 p-6 max-w-full">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Circuit Monitoring Dashboard</h1>
          <p className="text-gray-600 mt-1">Evaluate and monitor school functionality and compliance</p>
        </div>
        <div className="flex space-x-2">
          <button className="inline-flex items-center px-4 py-2 border border-blue-600 text-blue-600 rounded-md hover:bg-blue-50">
            <Download className="h-4 w-4 mr-2" />
            Export Data
          </button>
          <button 
            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            onClick={handleNewEvaluation}
          >
            <Plus className="h-4 w-4 mr-2" />
            New Evaluation
          </button>
        </div>
      </div>

      <Tabs defaultValue="overview" value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="grid grid-cols-4 mb-6">
          <TabsTrigger value="overview" activeValue={activeTab}>Overview</TabsTrigger>
          <TabsTrigger value="forms" activeValue={activeTab}>Monitoring Forms</TabsTrigger>
          <TabsTrigger value="reports" activeValue={activeTab}>Reports</TabsTrigger>
          <TabsTrigger value="trends" activeValue={activeTab}>Trends & Analytics</TabsTrigger>
        </TabsList>

        {/* Overview Tab Content */}
        <TabsContent value="overview" activeValue={activeTab} className="space-y-6">
          {/* Stats Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <Card>
              <CardHeader className="pb-2">
                <CardTitle className="text-sm font-medium text-gray-500">Saved Drafts</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex items-center">
                  <Save className="h-8 w-8 text-amber-500 mr-3" />
                  <div>
                    <p className="text-2xl font-bold">{savedDrafts}</p>
                    <p className="text-xs text-gray-500">Last draft saved 2 days ago</p>
                  </div>
                </div>
              </CardContent>
            </Card>
            
            <Card>
              <CardHeader className="pb-2">
                <CardTitle className="text-sm font-medium text-gray-500">Pending Forms</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex items-center">
                  <Clock className="h-8 w-8 text-blue-500 mr-3" />
                  <div>
                    <p className="text-2xl font-bold">{pendingForms}</p>
                    <p className="text-xs text-gray-500">Awaiting review or completion</p>
                  </div>
                </div>
              </CardContent>
            </Card>
            
            <Card>
              <CardHeader className="pb-2">
                <CardTitle className="text-sm font-medium text-gray-500">Completed Forms</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex items-center">
                  <Check className="h-8 w-8 text-green-500 mr-3" />
                  <div>
                    <p className="text-2xl font-bold">{completedForms}</p>
                    <p className="text-xs text-gray-500">For Q1 2025</p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Recent Activity */}
          <Card className="col-span-2">
            <CardHeader>
              <CardTitle>Recent Activity</CardTitle>
              <CardDescription>Your latest monitoring activities</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mr-3">SK</div>
                  <div>
                    <p className="font-medium">Sarah King submitted a monitoring form for Pretoria High School</p>
                    <p className="text-sm text-gray-500">Today, 10:30 AM</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-full bg-green-100 flex items-center justify-center text-green-600 mr-3">RL</div>
                  <div>
                    <p className="font-medium">Robert Lewis saved a draft monitoring form for Johannesburg Secondary School</p>
                    <p className="text-sm text-gray-500">Yesterday, 4:30 PM</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-full bg-purple-100 flex items-center justify-center text-purple-600 mr-3">JD</div>
                  <div>
                    <p className="font-medium">Jessica Davis exported a quarterly report for Circuit 1</p>
                    <p className="text-sm text-gray-500">Yesterday, 2:15 PM</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* School Compliance Rate Chart */}
          <Card>
            <CardHeader>
              <CardTitle>School Compliance Rate</CardTitle>
              <CardDescription>Based on monitoring data from Q1 2025</CardDescription>
            </CardHeader>
            <CardContent className="flex justify-center">
              <div className="h-60 w-full bg-gray-100 flex items-center justify-center">
                <p className="text-gray-500">Compliance rate chart visualization</p>
              </div>
            </CardContent>
          </Card>

          {/* Call to Action */}
          <Card className="border-blue-200 bg-blue-50">
            <CardContent className="pt-6">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div className="mb-4 md:mb-0">
                  <h3 className="text-lg font-semibold text-blue-800">Complete Pending Evaluations</h3>
                  <p className="text-blue-600">You have {pendingForms} pending monitoring forms that need completion.</p>
                </div>
                <button 
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  onClick={() => setActiveTab('forms')}
                >
                  View Pending Forms
                </button>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        {/* Forms Tab Content */}
        <TabsContent value="forms" activeValue={activeTab} className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Monitoring Forms</CardTitle>
              <CardDescription>Complete, view, and manage school monitoring forms</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="flex items-center justify-between mb-6">
                <div className="relative w-64">
                  <input 
                    type="text" 
                    placeholder="Search schools..."
                    className="w-full pl-3 pr-10 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <select className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
                    <option>Current Quarter (Q1 2025)</option>
                    <option>Previous Quarter (Q4 2024)</option>
                    <option>All Forms</option>
                  </select>
                  <button 
                    className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    onClick={handleNewEvaluation}
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    New Form
                  </button>
                </div>
              </div>

              <div className="border rounded-md overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">School</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Compliance</th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="font-medium text-gray-900">Pretoria High School</div>
                        <div className="text-sm text-gray-500">ID: SCH-12345</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        March 15, 2025
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Completed
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-16 bg-gray-200 rounded-full h-2.5 mr-2">
                            <div className="bg-green-600 h-2.5 rounded-full" style={{ width: '92%' }}></div>
                          </div>
                          <span className="text-sm text-gray-900">92%</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button 
                          className="text-blue-600 hover:text-blue-900 mr-3"
                          onClick={() => {
                            setSelectedSchool({
                              id: 'SCH-12345',
                              name: 'Pretoria High School',
                              status: 'completed'
                            });
                            setShowForm(true);
                          }}
                        >
                          View
                        </button>
                        <button className="text-blue-600 hover:text-blue-900 mr-3">Edit</button>
                        <button className="text-gray-600 hover:text-gray-900">
                          <Download className="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="font-medium text-gray-900">Johannesburg Secondary School</div>
                        <div className="text-sm text-gray-500">ID: SCH-23456</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        March 10, 2025
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-amber-100 text-amber-800">
                          Draft
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-16 bg-gray-200 rounded-full h-2.5 mr-2">
                            <div className="bg-amber-500 h-2.5 rounded-full" style={{ width: '45%' }}></div>
                          </div>
                          <span className="text-sm text-gray-900">45%</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button 
                          className="text-blue-600 hover:text-blue-900 mr-3"
                          onClick={() => {
                            setSelectedSchool({
                              id: 'SCH-23456',
                              name: 'Johannesburg Secondary School',
                              status: 'draft'
                            });
                            setShowForm(true);
                          }}
                        >
                          Continue
                        </button>
                        <button className="text-gray-600 hover:text-gray-900">
                          <Download className="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="font-medium text-gray-900">Cape Town Primary</div>
                        <div className="text-sm text-gray-500">ID: SCH-34567</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        March 5, 2025
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                          In Review
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-16 bg-gray-200 rounded-full h-2.5 mr-2">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: '78%' }}></div>
                          </div>
                          <span className="text-sm text-gray-900">78%</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button 
                          className="text-blue-600 hover:text-blue-900 mr-3"
                          onClick={() => {
                            setSelectedSchool({
                              id: 'SCH-34567',
                              name: 'Cape Town Primary',
                              status: 'review'
                            });
                            setShowForm(true);
                          }}
                        >
                          View
                        </button>
                        <button className="text-gray-600 hover:text-gray-900">
                          <Download className="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="flex items-center justify-between mt-4 text-sm">
                <div className="text-gray-500">
                  Showing 3 of 10 forms
                </div>
                <div className="flex space-x-1">
                  <button className="px-3 py-1 border rounded-md">Previous</button>
                  <button className="px-3 py-1 border rounded-md bg-blue-50 border-blue-200">1</button>
                  <button className="px-3 py-1 border rounded-md">2</button>
                  <button className="px-3 py-1 border rounded-md">3</button>
                  <button className="px-3 py-1 border rounded-md">Next</button>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        {/* Reports Tab Content */}
        <TabsContent value="reports" activeValue={activeTab} className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Generate Reports</CardTitle>
              <CardDescription>Create and download reports based on monitoring data</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <Card className="border-2 hover:border-blue-500 cursor-pointer">
                  <CardHeader>
                    <div className="flex justify-center mb-2">
                      <FileText className="h-10 w-10 text-blue-600" />
                    </div>
                    <CardTitle className="text-center">School Compliance Report</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-center text-sm text-gray-500">Detailed compliance report for individual schools</p>
                  </CardContent>
                  <CardFooter className="justify-center">
                    <button className="text-blue-600 hover:underline inline-flex items-center">
                      <FileText className="h-4 w-4 mr-2" />
                      Generate Report
                    </button>
                  </CardFooter>
                </Card>

                <Card className="border-2 hover:border-blue-500 cursor-pointer">
                  <CardHeader>
                    <div className="flex justify-center mb-2">
                      <BarChart2 className="h-10 w-10 text-purple-600" />
                    </div>
                    <CardTitle className="text-center">Circuit Performance</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-center text-sm text-gray-500">Aggregate statistics across all schools in the circuit</p>
                  </CardContent>
                  <CardFooter className="justify-center">
                    <button className="text-blue-600 hover:underline inline-flex items-center">
                      <FileText className="h-4 w-4 mr-2" />
                      Generate Report
                    </button>
                  </CardFooter>
                </Card>

                <Card className="border-2 hover:border-blue-500 cursor-pointer">
                  <CardHeader>
                    <div className="flex justify-center mb-2">
                      <Calendar className="h-10 w-10 text-green-600" />
                    </div>
                    <CardTitle className="text-center">Quarterly Summary</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-center text-sm text-gray-500">Summary of all monitoring activities for the quarter</p>
                  </CardContent>
                  <CardFooter className="justify-center">
                    <button className="text-blue-600 hover:underline inline-flex items-center">
                      <FileText className="h-4 w-4 mr-2" />
                      Generate Report
                    </button>
                  </CardFooter>
                </Card>
              </div>

              <div className="mt-8">
                <h3 className="text-lg font-medium mb-4">Recent Reports</h3>
                <div className="border rounded-md">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Report Name</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Generated</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr className="hover:bg-gray-50">
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <FileText className="h-5 w-5 text-gray-400 mr-2" />
                            <div>
                              <div className="font-medium text-gray-900">Circuit 1 - Q1 2025 Summary</div>
                              <div className="text-sm text-gray-500">Quarterly Report</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          March 20, 2025
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="h-8 w-8 rounded-full bg-purple-100 flex items-center justify-center text-purple-600 mr-2">
                              JD
                            </div>
                            <span>Jessica Davis</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-blue-600 hover:text-blue-900 mr-3">View</button>
                          <button className="text-gray-600 hover:text-gray-900">
                            <Download className="h-4 w-4" />
                          </button>
                        </td>
                      </tr>
                      <tr className="hover:bg-gray-50">
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <FileText className="h-5 w-5 text-gray-400 mr-2" />
                            <div>
                              <div className="font-medium text-gray-900">Pretoria High School - Compliance Report</div>
                              <div className="text-sm text-gray-500">School Report</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          March 18, 2025
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mr-2">
                              SK
                            </div>
                            <span>Sarah King</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-blue-600 hover:text-blue-900 mr-3">View</button>
                          <button className="text-gray-600 hover:text-gray-900">
                            <Download className="h-4 w-4" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        {/* Trends & Analytics Tab Content */}
        <TabsContent value="trends" activeValue={activeTab} className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Trends & Analytics</CardTitle>
              <CardDescription>Visualize monitoring data to identify patterns and trends</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-lg font-medium">Compliance Rate by Category</h3>
                <div className="flex space-x-2">
                  <select className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
                    <option>Current Quarter (Q1 2025)</option>
                    <option>Previous Quarter (Q4 2024)</option>
                    <option>Year to Date</option>
                  </select>
                  <button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50">
                    <Share className="h-4 w-4 mr-2" />
                    Share
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="bg-gray-100 h-64 rounded-md flex items-center justify-center">
                  <p className="text-gray-500">Chart: Compliance by Policy Category</p>
                </div>
                <div className="bg-gray-100 h-64 rounded-md flex items-center justify-center">
                  <p className="text-gray-500">Chart: Compliance by School Type</p>
                </div>
              </div>

              <h3 className="text-lg font-medium mb-4">Key Performance Metrics</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                <Card>
                  <CardHeader className="pb-2">
                    <CardTitle className="text-sm font-medium text-gray-500">Administration</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex items-center justify-between">
                      <p className="text-2xl font-bold">87%</p>
                      <div className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded-full">
                        ↑ 4%
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader className="pb-2">
                    <CardTitle className="text-sm font-medium text-gray-500">Policies</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex items-center justify-between">
                      <p className="text-2xl font-bold">72%</p>
                      <div className="text-xs bg-red-100 text-red-800 px-2 py-1 rounded-full">
                        ↓ 3%
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader className="pb-2">
                    <CardTitle className="text-sm font-medium text-gray-500">Curriculum Management</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex items-center justify-between">
                      <p className="text-2xl font-bold">81%</p>
                      <div className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded-full">
                        ↑ 2%
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="mt-8">
                <h3 className="text-lg font-medium mb-4">Schools Requiring Attention</h3>
                <div className="border rounded-md">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">School</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Compliance</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Key Issues</th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr className="hover:bg-gray-50">
                        <td className="px-6 py-4">
                          <div className="font-medium text-gray-900">Durban North Primary</div>
                          <div className="text-sm text-gray-500">ID: SCH-45678</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="w-16 bg-gray-200 rounded-full h-2.5 mr-2">
                              <div className="bg-red-600 h-2.5 rounded-full" style={{ width: '42%' }}></div>
                            </div>
                            <span className="text-sm text-gray-900">42%</span>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-gray-900">Missing policy documents, incomplete staff records</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-blue-600 hover:text-blue-900 mr-3">View Details</button>
                          <button className="px-3 py-1 bg-orange-100 text-orange-700 rounded-md hover:bg-orange-200">
                            Flag for Follow-up
                          </button>
                        </td>
                      </tr>
                      <tr className="hover:bg-gray-50">
                        <td className="px-6 py-4">
                          <div className="font-medium text-gray-900">East Rand Secondary</div>
                          <div className="text-sm text-gray-500">ID: SCH-56789</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="w-16 bg-gray-200 rounded-full h-2.5 mr-2">
                              <div className="bg-orange-500 h-2.5 rounded-full" style={{ width: '58%' }}></div>
                            </div>
                            <span className="text-sm text-gray-900">58%</span>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-gray-900">Curriculum implementation issues, governance concerns</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-blue-600 hover:text-blue-900 mr-3">View Details</button>
                          <button className="px-3 py-1 bg-orange-100 text-orange-700 rounded-md hover:bg-orange-200">
                            Flag for Follow-up
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Insights Alert */}
              <div className="mt-8 bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-start">
                <AlertCircle className="h-5 w-5 text-blue-500 mt-0.5 mr-2 flex-shrink-0" />
                <div>
                  <h4 className="text-sm font-medium text-blue-800">AI-Generated Insight</h4>
                  <p className="text-sm text-blue-700 mt-1">Schools with lower compliance rates show a common pattern of missing policy documentation and governance structures. Consider organizing a targeted workshop to address these specific issues.</p>
                  <button className="mt-2 text-xs bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700">
                    Generate Action Plan
                  </button>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default MonitoringToolDashboard;