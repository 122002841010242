// src/components/meetings/MeetingForm.js
import React, { useState, useEffect } from 'react';
import { 
  Calendar, Clock, MapPin, User, Plus, X, 
  ChevronDown, ChevronUp, ArrowLeft, Check, RotateCw, Save
} from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { 
  collection, 
  doc, 
  getDoc, 
  addDoc, 
  updateDoc, 
  serverTimestamp 
} from 'firebase/firestore';
import { db } from '../../firebase/config';

const MeetingForm = ({ meeting, onSave, isEditing = false }) => {
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  const [formData, setFormData] = useState({
    title: '',
    type: '',
    date: '',
    startTime: '',
    endTime: '',
    location: '',
    chairperson: '',
    description: '',
    participants: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showParticipantSearch, setShowParticipantSearch] = useState(false);
  const [success, setSuccess] = useState('');
  const { meetingId } = useParams();
  
  // Meeting types
  const meetingTypes = ['SMT', 'SGB', 'Department', 'Staff', 'PTA', 'Disciplinary', 'Other'];

  // Initialize form data from meeting prop if editing
  useEffect(() => {
    if (meeting) {
      const date = meeting.date ? new Date(meeting.date) : new Date();
      const endTime = meeting.endTime ? new Date(meeting.endTime) : new Date(date.getTime() + 60 * 60 * 1000);
      
      setFormData({
        title: meeting.title || '',
        type: meeting.type || '',
        date: date.toISOString().split('T')[0],
        startTime: date.toTimeString().slice(0, 5),
        endTime: endTime.toTimeString().slice(0, 5),
        location: meeting.location || '',
        chairperson: meeting.chairperson || '',
        description: meeting.description || '',
        participants: meeting.participants || []
      });
    }
  }, [meeting]);

  useEffect(() => {
    if (isEditing && meetingId) {
      fetchMeeting();
    }
  }, [isEditing, meetingId]);
    
  const fetchMeeting = async () => {
    try {
      setIsLoading(true);
      const meetingDoc = await getDoc(doc(db, "meetings", meetingId));
      
      if (meetingDoc.exists()) {
        const meetingData = meetingDoc.data();
        
        // Convert Firestore timestamps to Date objects
        const date = meetingData.date?.toDate();
        const endTime = meetingData.endTime?.toDate();
        
        // Format date for the date input (YYYY-MM-DD)
        const formattedDate = date ? date.toISOString().split('T')[0] : '';
        
        // Format times for the time inputs (HH:MM)
        const formattedStartTime = date ? 
          `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}` : 
          '';
        
        const formattedEndTime = endTime ? 
          `${String(endTime.getHours()).padStart(2, '0')}:${String(endTime.getMinutes()).padStart(2, '0')}` : 
          '';
        
        // Update form data with meeting info
        setFormData({
          ...meetingData,
          date: formattedDate,
          startTime: formattedStartTime,
          endTime: formattedEndTime
        });
      } else {
        setError('Meeting not found');
        setTimeout(() => navigate('/meetings'), 2000);
      }
    } catch (err) {
      console.error('Error fetching meeting:', err);
      setError('Failed to load meeting data');
    } finally {
      setIsLoading(false);
    }
  };

  // Mock staff data for participant search
  const mockStaff = [
    { id: 'user1', name: 'Dr. Michael Johnson', role: 'Principal' },
    { id: 'user2', name: 'Sarah King', role: 'Deputy Principal' },
    { id: 'user3', name: 'Robert Lewis', role: 'HOD Sciences' },
    { id: 'user4', name: 'Jessica Davis', role: 'HOD Humanities' },
    { id: 'user5', name: 'Thomas Brown', role: 'Mathematics Teacher' },
    { id: 'user6', name: 'Amanda White', role: 'English Teacher' },
    { id: 'user7', name: 'James Wilson', role: 'Science Teacher' },
    { id: 'user8', name: 'Emily Clark', role: 'History Teacher' },
  ];

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle search for participants
  const handleSearch = (term) => {
    setSearchTerm(term);
    
    if (term.length < 2) {
      setSearchResults([]);
      return;
    }
    
    // Filter staff based on search term
    const results = mockStaff.filter(staff => 
      staff.name.toLowerCase().includes(term.toLowerCase()) ||
      staff.role.toLowerCase().includes(term.toLowerCase())
    );
    
    // Exclude already added participants
    const filteredResults = results.filter(result => 
      !formData.participants.some(p => p.id === result.id)
    );
    
    setSearchResults(filteredResults);
  };

  
  // Add participant to meeting
  const handleAddParticipant = (participant) => {
    setFormData({
      ...formData,
      participants: [
        ...formData.participants,
        { ...participant, confirmed: false }
      ]
    });
    setSearchTerm('');
    setSearchResults([]);
  };

  // Remove participant from meeting
  const handleRemoveParticipant = (participantId) => {
    setFormData({
      ...formData,
      participants: formData.participants.filter(p => p.id !== participantId)
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!formData.title) {
      setError('Meeting title is required');
      return;
    }
    
    if (!formData.date || !formData.startTime) {
      setError('Meeting date and start time are required');
      return;
    }
    
    try {
      setIsLoading(true);
      
      // Combine date and time into Date objects
      const dateStr = formData.date;
      const startTimeStr = formData.startTime;
      const endTimeStr = formData.endTime;
      
      const startDateTime = new Date(`${dateStr}T${startTimeStr}`);
      const endDateTime = endTimeStr 
        ? new Date(`${dateStr}T${endTimeStr}`)
        : new Date(startDateTime.getTime() + 60 * 60 * 1000); // Default to 1 hour later
      
      // Prepare meeting data
      const meetingData = {
        ...formData,
        date: startDateTime,
        endTime: endDateTime,
        participants: formData.participants || [],
        status: 'scheduled',
        createdBy: userProfile?.id || currentUser?.uid || 'unknown',
        institutionId: userProfile?.institutionId || '',
        institutionName: userProfile?.institutionName || '',
        updatedAt: serverTimestamp()
      };
      
      delete meetingData.startTime; // Remove separate startTime field as it's now part of date
      
      // Save to Firestore based on whether we're editing or creating
      if (isEditing && meetingId) {
        // Update existing meeting
        await updateDoc(doc(db, "meetings", meetingId), {
          ...meetingData,
          updatedAt: serverTimestamp()
        });
        setSuccess('Meeting updated successfully');
      } else {
        // Create new meeting
        meetingData.createdAt = serverTimestamp();
        const docRef = await addDoc(collection(db, "meetings"), meetingData);
        console.log("Meeting created with ID: ", docRef.id);
        setSuccess('Meeting created successfully');
      }
      
      // Navigate back after a short delay
      setTimeout(() => {
        navigate('/meetings');
      }, 1500);
      
    } catch (err) {
      console.error('Error submitting form:', err);
      setError(`Failed to ${isEditing ? 'update' : 'create'} meeting: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
      {/* Header */}
      <div className="flex items-start justify-between mb-6">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/meetings')}
            className="mr-4 text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <h1 className="text-xl font-bold text-gray-900">
            {meeting ? 'Edit Meeting' : 'Create New Meeting'}
          </h1>
        </div>
      </div>
  
      {/* Error Message */}
      {error && (
        <div className="mb-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
          <div className="flex">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
            <div className="ml-3">
              <p className="text-sm">{error}</p>
            </div>
          </div>
        </div>
      )}
  
      {/* Success Message */}
      {success && (
        <div className="mb-4 p-3 bg-green-50 border-l-4 border-green-500 text-green-700">
          <div className="flex">
            <Check className="h-5 w-5 mr-2" />
            <span>{success}</span>
          </div>
        </div>
      )}
  
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Basic Meeting Information */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-md font-medium text-gray-800 mb-4">Basic Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Meeting Title */}
            <div className="md:col-span-2">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                Meeting Title <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="title"
                name="title"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 pl-2 focus:border-blue-500 focus:ring-blue-500"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>
  
            {/* Meeting Type */}
            <div>
              <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
                Meeting Type
              </label>
              <select
                id="type"
                name="type"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 pl-2 focus:border-blue-500 focus:ring-blue-500"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="">Select a type</option>
                {meetingTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
  
            {/* Location */}
            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
                Location
              </label>
              <input
                type="text"
                id="location"
                name="location"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 pl-2 focus:border-blue-500 focus:ring-blue-500"
                value={formData.location}
                onChange={handleChange}
              />
            </div>
  
            {/* Date */}
            <div>
              <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
                Date <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <Calendar className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
                <input
                  type="date"
                  id="date"
                  name="date"
                  className="pl-6 py-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
  
            {/* Time */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="startTime" className="block text-sm font-medium text-gray-700 mb-1">
                  Start Time <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <Clock className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
                  <input
                    type="time"
                    id="startTime"
                    name="startTime"
                    className="pl-6 py-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.startTime}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="endTime" className="block text-sm font-medium text-gray-700 mb-1">
                  End Time
                </label>
                <div className="relative">
                  <Clock className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
                  <input
                    type="time"
                    id="endTime"
                    name="endTime"
                    className="pl-6 py-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    value={formData.endTime}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
  
            {/* Chairperson */}
            <div>
              <label htmlFor="chairperson" className="block text-sm font-medium text-gray-700 mb-1">
                Chairperson
              </label>
              <input
                type="text"
                id="chairperson"
                name="chairperson"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 pl-2 focus:border-blue-500 focus:ring-blue-500"
                value={formData.chairperson}
                onChange={handleChange}
              />
            </div>
  
            {/* Secretary */}
            <div>
              <label htmlFor="secretary" className="block text-sm font-medium text-gray-700 mb-1">
                Secretary
              </label>
              <input
                type="text"
                id="secretary"
                name="secretary"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 pl-2 focus:border-blue-500 focus:ring-blue-500"
                value={formData.secretary}
                onChange={handleChange}
              />
            </div>
  
            {/* Description */}
            <div className="md:col-span-2">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 pl-2 focus:border-blue-500 focus:ring-blue-500"
                value={formData.description}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
        </div>
  
        {/* Participants Section */}
        <div className="bg-gray-50 p-4 rounded-md">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-sm font-medium text-gray-700">Participants</h3>
            <button
              type="button"
              className="inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-500"
              onClick={() => setShowParticipantSearch(!showParticipantSearch)}
            >
              <Plus className="h-4 w-4 mr-1" />
              Add Participant
            </button>
          </div>
          {/* Participant search */}
          {showParticipantSearch && (
            <div className="mb-4 p-4 bg-gray-50 rounded-md">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search staff by name or role..."
                  className="block w-full rounded-md border-gray-300 shadow-sm py-2 pl-2 focus:border-blue-500 focus:ring-blue-500"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {searchTerm.length > 0 && searchResults.length > 0 && (
                  <div className="absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md border border-gray-200 max-h-60 overflow-y-auto">
                    <ul>
                      {searchResults.map(staff => (
                        <li
                          key={staff.id}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleAddParticipant(staff)}
                        >
                          <div className="font-medium">{staff.name}</div>
                          <div className="text-sm text-gray-500">{staff.role}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {searchTerm.length > 1 && searchResults.length === 0 && (
                  <div className="absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md border border-gray-200 p-4 text-center text-gray-500">
                    No matching staff found
                  </div>
                )}
              </div>
            </div>
          )}
          {/* Participants list */}
          {formData.participants.length > 0 ? (
            <div className="border rounded-md divide-y">
              {formData.participants.map(participant => (
                <div key={participant.id} className="p-4 flex items-center justify-between">
                  <div>
                    <div className="font-medium">{participant.name}</div>
                    <div className="text-sm text-gray-500">{participant.role}</div>
                  </div>
                  <button
                    type="button"
                    className="text-gray-400 hover:text-red-500"
                    onClick={() => handleRemoveParticipant(participant.id)}
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center p-4 bg-gray-50 rounded-md">
              <User className="mx-auto h-10 w-10 text-gray-400" />
              <p className="mt-2 text-sm text-gray-500">No participants added yet</p>
            </div>
          )}
        </div>
  
        {/* Form Actions */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => window.history.back()}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
          >
            {isLoading ? (
              <>
                <RotateCw className="animate-spin h-4 w-4 mr-2" />
                {meeting ? 'Updating...' : 'Saving...'}
              </>
            ) : (
              <>
                <Save className="h-4 w-4 mr-2" />
                {meeting ? 'Update Meeting' : 'Save Meeting'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
  };
  
  export default MeetingForm;
  
  