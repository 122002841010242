// src/utils/dataFetcher.js
import { db } from '../firebase/config';
import { collection, query, where, getDocs, orderBy, limit, Timestamp } from 'firebase/firestore';
import { format, subDays, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';

/**
 * Converts a time period string to a date range
 * @param {string} timePeriod - The time period (today, yesterday, this_week, etc.)
 * @returns {Object} Object with start and end dates
 */
// Improved date range function in dataFetcher.js
const getDateRangeForTimePeriod = (timePeriod) => {
    const now = new Date();
    let startDate, endDate;
    
    switch(timePeriod) {
      case 'today':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
        endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
        break;
      // ...other cases remain the same
      default:
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
        endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    }
    
    // Convert to Firestore timestamps
    return { 
      startDate: Timestamp.fromDate(startDate), 
      endDate: Timestamp.fromDate(endDate)
    };
  };

  const fetchRecords = async (timePeriod, userProfile) => {
    try {
      const { startDate, endDate } = getDateRangeForTimePeriod(timePeriod);
      
      // Guard against missing institutionId
      if (!userProfile?.institutionId) {
        console.log("Missing institutionId in user profile");
        // For debugging: Output the entire userProfile
        console.log("User profile:", userProfile);
        return { error: "No institution ID found in your profile" };
      }
      
      console.log(`Fetching records from ${startDate.toDate()} to ${endDate.toDate()}`);
      console.log(`For institution: ${userProfile.institutionId}`);
      
      // IMPORTANT FIX: Check if the "records" collection actually exists
      // and review field names - are we using the right field names?
      const recordsRef = collection(db, "records");
      
      // In some implementations, the date field might be called differently
      // like "createdAt", "timestamp", etc. Let's check for that.
      const q = query(
        recordsRef,
        where("institutionId", "==", userProfile.institutionId),
        orderBy("date", "desc"), // Check if "date" is the right field name
        limit(50)
      );
      
      // Log the query for debugging
      console.log("Query:", q);
      
      const querySnapshot = await getDocs(q);
      console.log(`Query returned ${querySnapshot.size} documents`);
      
      // Log the first document to see its structure
      if (querySnapshot.size > 0) {
        const firstDoc = querySnapshot.docs[0];
        console.log("First document structure:", {
          id: firstDoc.id,
          ...firstDoc.data()
        });
      }
      
      const records = [];
      querySnapshot.forEach((doc) => {
        records.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      return records;
    } catch (error) {
      console.error("Error fetching records:", error);
      return { error: error.message };
    }
  };

/**
 * Fetches attendance data from Firestore
 * @param {string} timePeriod - The time period to fetch attendance for
 * @param {Object} userProfile - User profile containing institution info
 * @returns {Promise<Array>} Array of attendance records
 */
const fetchAttendance = async (timePeriod, userProfile) => {
  try {
    const { startDate, endDate } = getDateRangeForTimePeriod(timePeriod);
    
    // Guard against missing institutionId
    if (!userProfile?.institutionId) {
      return { error: "No institution ID found in your profile" };
    }
    
    const attendanceRef = collection(db, "attendance");
    const q = query(
      attendanceRef,
      where("institutionId", "==", userProfile.institutionId),
      where("date", ">=", startDate),
      where("date", "<=", endDate),
      orderBy("date", "desc"),
      limit(50)
    );
    
    const querySnapshot = await getDocs(q);
    const attendance = [];
    
    querySnapshot.forEach((doc) => {
      attendance.push({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate ? doc.data().date.toDate() : doc.data().date
      });
    });
    
    return attendance;
  } catch (error) {
    console.error("Error fetching attendance:", error);
    return { error: error.message };
  }
};

/**
 * Fetches student data from Firestore
 * @param {Object} userProfile - User profile containing institution info
 * @returns {Promise<Array>} Array of students
 */
const fetchStudents = async (userProfile) => {
  try {
    // Guard against missing institutionId
    if (!userProfile?.institutionId) {
      return { error: "No institution ID found in your profile" };
    }
    
    const studentsRef = collection(db, "students");
    const q = query(
      studentsRef,
      where("institutionId", "==", userProfile.institutionId),
      limit(100)
    );
    
    const querySnapshot = await getDocs(q);
    const students = [];
    
    querySnapshot.forEach((doc) => {
      students.push({
        id: doc.id,
        ...doc.data()
      });
    });
    
    return students;
  } catch (error) {
    console.error("Error fetching students:", error);
    return { error: error.message };
  }
};

/**
 * Fetches meetings from Firestore
 * @param {string} timePeriod - The time period to fetch meetings for
 * @param {Object} userProfile - User profile containing institution info
 * @returns {Promise<Array>} Array of meetings
 */
const fetchMeetings = async (timePeriod, userProfile) => {
  try {
    const { startDate, endDate } = getDateRangeForTimePeriod(timePeriod);
    
    // Guard against missing institutionId
    if (!userProfile?.institutionId) {
      return { error: "No institution ID found in your profile" };
    }
    
    const meetingsRef = collection(db, "meetings");
    const q = query(
      meetingsRef,
      where("institutionId", "==", userProfile.institutionId),
      where("date", ">=", startDate),
      where("date", "<=", endDate),
      orderBy("date", "desc"),
      limit(50)
    );
    
    const querySnapshot = await getDocs(q);
    const meetings = [];
    
    querySnapshot.forEach((doc) => {
      meetings.push({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate ? doc.data().date.toDate() : doc.data().date
      });
    });
    
    return meetings;
  } catch (error) {
    console.error("Error fetching meetings:", error);
    return { error: error.message };
  }
};

/**
 * Fetches reports from Firestore
 * @param {string} timePeriod - The time period to fetch reports for
 * @param {Object} userProfile - User profile containing institution info
 * @returns {Promise<Array>} Array of reports
 */
const fetchReports = async (timePeriod, userProfile) => {
  try {
    const { startDate, endDate } = getDateRangeForTimePeriod(timePeriod);
    
    // Guard against missing institutionId
    if (!userProfile?.institutionId) {
      return { error: "No institution ID found in your profile" };
    }
    
    const reportsRef = collection(db, "reports");
    const q = query(
      reportsRef,
      where("institutionId", "==", userProfile.institutionId),
      where("date", ">=", startDate),
      where("date", "<=", endDate),
      orderBy("date", "desc"),
      limit(50)
    );
    
    const querySnapshot = await getDocs(q);
    const reports = [];
    
    querySnapshot.forEach((doc) => {
      reports.push({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate ? doc.data().date.toDate() : doc.data().date
      });
    });
    
    return reports;
  } catch (error) {
    console.error("Error fetching reports:", error);
    return { error: error.message };
  }
};

/**
 * Fetches curriculum data from Firestore
 * @param {Object} userProfile - User profile containing institution info
 * @returns {Promise<Array>} Array of curriculum items
 */
const fetchCurriculum = async (userProfile) => {
  try {
    // Guard against missing institutionId
    if (!userProfile?.institutionId) {
      return { error: "No institution ID found in your profile" };
    }
    
    const curriculumRef = collection(db, "subjects");
    const q = query(
      curriculumRef,
      where("institutionId", "==", userProfile.institutionId),
      limit(50)
    );
    
    const querySnapshot = await getDocs(q);
    const curriculum = [];
    
    querySnapshot.forEach((doc) => {
      curriculum.push({
        id: doc.id,
        ...doc.data()
      });
    });
    
    return curriculum;
  } catch (error) {
    console.error("Error fetching curriculum:", error);
    return { error: error.message };
  }
};

/**
 * Fetches institution data from Firestore
 * @param {Object} userProfile - User profile containing institution info
 * @returns {Promise<Object>} Institution data
 */
const fetchInstitution = async (userProfile) => {
  try {
    // Guard against missing institutionId
    if (!userProfile?.institutionId) {
      return { error: "No institution ID found in your profile" };
    }
    
    const institutionsRef = collection(db, "institutions");
    const q = query(
      institutionsRef,
      where("id", "==", userProfile.institutionId),
      limit(1)
    );
    
    const querySnapshot = await getDocs(q);
    let institution = null;
    
    querySnapshot.forEach((doc) => {
      institution = {
        id: doc.id,
        ...doc.data()
      };
    });
    
    return institution || { error: "Institution not found" };
  } catch (error) {
    console.error("Error fetching institution:", error);
    return { error: error.message };
  }
};

/**
 * Fetches data based on selected sources and time period
 * @param {string} timePeriod - The time period to fetch data for
 * @param {Array} dataSources - Array of data source types to fetch
 * @param {Object} userProfile - User profile containing institution info
 * @returns {Promise<Object>} Object containing data from all requested sources
 */
export const fetchContextData = async (timePeriod, dataSources, userProfile) => {
  try {
    // Guard against missing userProfile
    if (!userProfile) {
      console.error("Error fetching context data: Missing user profile");
      return { error: "Missing user profile" };
    }
    
    const result = {};
    
    // Process each requested data source
    for (const source of dataSources) {
      switch (source) {
        case 'records':
          result.records = await fetchRecords(timePeriod, userProfile);
          break;
        case 'students':
          result.students = await fetchStudents(userProfile);
          break;
        case 'attendance':
          result.attendance = await fetchAttendance(timePeriod, userProfile);
          break;
        case 'meetings':
          result.meetings = await fetchMeetings(timePeriod, userProfile);
          break;
        case 'reports':
          result.reports = await fetchReports(timePeriod, userProfile);
          break;
        case 'curriculum':
          result.curriculum = await fetchCurriculum(userProfile);
          break;
        case 'institutions':
          result.institution = await fetchInstitution(userProfile);
          break;
        default:
          console.warn(`Unknown data source: ${source}`);
      }
    }
    
    return result;
  } catch (error) {
    console.error("Error fetching context data:", error);
    return { error: "Failed to fetch context data: " + error.message };
  }
};

/**
 * Formats the fetched data into a human-readable format for the AI prompt
 * @param {Object} data - The data fetched from Firestore
 * @returns {string} Formatted data as a string
 */
export const formatContextData = (data) => {
  // Handle error case
  if (data.error) {
    return `ERROR: ${data.error}`;
  }
  
  // Handle empty data
  if (Object.keys(data).length === 0) {
    return "No data available for the requested time period and sources.";
  }
  
  let formattedOutput = [];
  
  // Format records data if present
  if (data.records) {
    if (Array.isArray(data.records)) {
      const recordTypes = {};
      const recordStatuses = {};
      
      // Extract trends and summaries
      data.records.forEach(record => {
        // Count record types
        if (record.type) {
          recordTypes[record.type] = (recordTypes[record.type] || 0) + 1;
        }
        
        // Count statuses
        if (record.status) {
          recordStatuses[record.status] = (recordStatuses[record.status] || 0) + 1;
        }
      });
      
      let recordsSection = `RECORDS (${data.records.length} total):\n`;
      
      // Add type summary
      recordsSection += "Record types:\n";
      Object.entries(recordTypes).forEach(([type, count]) => {
        recordsSection += `- ${type}: ${count}\n`;
      });
      
      // Add status summary
      recordsSection += "\nRecord statuses:\n";
      Object.entries(recordStatuses).forEach(([status, count]) => {
        recordsSection += `- ${status}: ${count}\n`;
      });
      
      // Add recent records (limited to 5)
      recordsSection += "\nRecent records:\n";
      data.records.slice(0, 5).forEach(record => {
        const date = record.date instanceof Date 
          ? format(record.date, 'yyyy-MM-dd')
          : 'Unknown date';
        
        recordsSection += `- ${date}: ${record.recordMessage || 'No message'} (${record.status || 'No status'})\n`;
      });
      
      formattedOutput.push(recordsSection);
    } else if (data.records.error) {
      formattedOutput.push(`RECORDS ERROR: ${data.records.error}`);
    }
  }
  
  // Format attendance data if present
  if (data.attendance) {
    if (Array.isArray(data.attendance)) {
      let attendanceSection = `ATTENDANCE (${data.attendance.length} records):\n`;
      
      // Calculate overall attendance rate if available
      const attendanceRates = data.attendance
        .filter(att => att.presentCount !== undefined && att.totalCount !== undefined)
        .map(att => ({
          date: att.date,
          rate: (att.presentCount / att.totalCount) * 100
        }));
      
      if (attendanceRates.length > 0) {
        const averageRate = attendanceRates.reduce((sum, item) => sum + item.rate, 0) / attendanceRates.length;
        attendanceSection += `Average attendance rate: ${averageRate.toFixed(1)}%\n`;
        
        // Add attendance trend (most recent records)
        attendanceSection += "\nRecent attendance:\n";
        attendanceRates.slice(0, 5).forEach(item => {
          const date = item.date instanceof Date 
            ? format(item.date, 'yyyy-MM-dd')
            : 'Unknown date';
          
          attendanceSection += `- ${date}: ${item.rate.toFixed(1)}%\n`;
        });
      } else {
        attendanceSection += "No detailed attendance metrics available.\n";
      }
      
      formattedOutput.push(attendanceSection);
    } else if (data.attendance.error) {
      formattedOutput.push(`ATTENDANCE ERROR: ${data.attendance.error}`);
    }
  }
  
  // Format student data if present
  if (data.students) {
    if (Array.isArray(data.students)) {
      let studentsSection = `STUDENTS (${data.students.length} total):\n`;
      
      // Count students by grade/class if available
      const studentsByGrade = {};
      data.students.forEach(student => {
        if (student.grade) {
          studentsByGrade[student.grade] = (studentsByGrade[student.grade] || 0) + 1;
        }
      });
      
      if (Object.keys(studentsByGrade).length > 0) {
        studentsSection += "Students by grade:\n";
        Object.entries(studentsByGrade).forEach(([grade, count]) => {
          studentsSection += `- Grade ${grade}: ${count} students\n`;
        });
      }
      
      formattedOutput.push(studentsSection);
    } else if (data.students.error) {
      formattedOutput.push(`STUDENTS ERROR: ${data.students.error}`);
    }
  }
  
  // Format meetings data if present
  if (data.meetings) {
    if (Array.isArray(data.meetings)) {
      let meetingsSection = `MEETINGS (${data.meetings.length} total):\n`;
      
      // Count meeting types if available
      const meetingTypes = {};
      data.meetings.forEach(meeting => {
        if (meeting.type) {
          meetingTypes[meeting.type] = (meetingTypes[meeting.type] || 0) + 1;
        }
      });
      
      if (Object.keys(meetingTypes).length > 0) {
        meetingsSection += "Meeting types:\n";
        Object.entries(meetingTypes).forEach(([type, count]) => {
          meetingsSection += `- ${type}: ${count}\n`;
        });
      }
      
      // List recent meetings
      meetingsSection += "\nRecent meetings:\n";
      data.meetings.slice(0, 5).forEach(meeting => {
        const date = meeting.date instanceof Date 
          ? format(meeting.date, 'yyyy-MM-dd')
          : 'Unknown date';
        
        meetingsSection += `- ${date}: ${meeting.title || 'Untitled'} (${meeting.type || 'No type'})\n`;
      });
      
      formattedOutput.push(meetingsSection);
    } else if (data.meetings.error) {
      formattedOutput.push(`MEETINGS ERROR: ${data.meetings.error}`);
    }
  }
  
  // Format reports data if present
  if (data.reports) {
    if (Array.isArray(data.reports)) {
      let reportsSection = `REPORTS (${data.reports.length} total):\n`;
      
      // List recent reports
      reportsSection += "Recent reports:\n";
      data.reports.slice(0, 5).forEach(report => {
        const date = report.date instanceof Date 
          ? format(report.date, 'yyyy-MM-dd')
          : 'Unknown date';
        
        reportsSection += `- ${date}: ${report.title || 'Untitled'} (${report.category || 'No category'})\n`;
      });
      
      formattedOutput.push(reportsSection);
    } else if (data.reports.error) {
      formattedOutput.push(`REPORTS ERROR: ${data.reports.error}`);
    }
  }
  
  // Format curriculum data if present
  if (data.curriculum) {
    if (Array.isArray(data.curriculum)) {
      let curriculumSection = `CURRICULUM (${data.curriculum.length} subjects):\n`;
      
      // Group subjects by category if available
      const subjectsByCategory = {};
      data.curriculum.forEach(subject => {
        const category = subject.category || 'Uncategorized';
        if (!subjectsByCategory[category]) {
          subjectsByCategory[category] = [];
        }
        subjectsByCategory[category].push(subject.name || 'Unnamed subject');
      });
      
      // List subjects by category
      Object.entries(subjectsByCategory).forEach(([category, subjects]) => {
        curriculumSection += `${category}:\n`;
        subjects.forEach(subject => {
          curriculumSection += `- ${subject}\n`;
        });
        curriculumSection += '\n';
      });
      
      formattedOutput.push(curriculumSection);
    } else if (data.curriculum.error) {
      formattedOutput.push(`CURRICULUM ERROR: ${data.curriculum.error}`);
    }
  }
  
  // Format institution data if present
  if (data.institution) {
    if (data.institution.id) {
      let institutionSection = "INSTITUTION:\n";
      
      institutionSection += `Name: ${data.institution.name || 'Unknown'}\n`;
      institutionSection += `Type: ${data.institution.type || 'Unknown'}\n`;
      
      if (data.institution.location) {
        institutionSection += `Location: ${data.institution.location}\n`;
      }
      
      if (data.institution.studentCount) {
        institutionSection += `Student count: ${data.institution.studentCount}\n`;
      }
      
      if (data.institution.staffCount) {
        institutionSection += `Staff count: ${data.institution.staffCount}\n`;
      }
      
      formattedOutput.push(institutionSection);
    } else if (data.institution.error) {
      formattedOutput.push(`INSTITUTION ERROR: ${data.institution.error}`);
    }
  }
  
  return formattedOutput.join('\n\n');
};