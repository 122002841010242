// src/components/institutions/ProjectProgrammeDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  PieChart, ArrowLeft, Calendar, Edit, Trash2, User, Phone, 
  Mail, MapPin, Clock, ChevronDown, ChevronUp, AlertTriangle,
  Building, School, Landmark, Building2, ExternalLink
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';

const ProjectProgrammeDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  
  // UI state for expandable sections
  const [expandedSections, setExpandedSections] = useState({
    provinces: true,
    districts: true,
    circuits: true,
    schools: true
  });
  
  // Fetch project details
  useEffect(() => {
    const fetchProjectDetails = async () => {
      setIsLoading(true);
      try {
        const projectDoc = await getDoc(doc(db, "institutions", id));
        
        if (!projectDoc.exists()) {
          setError("Project/Programme not found");
          setIsLoading(false);
          return;
        }
        
        const projectData = {
          id: projectDoc.id,
          ...projectDoc.data()
        };
        
        // Convert timestamps to dates
        if (projectData.createdAt) {
          projectData.createdAt = projectData.createdAt.toDate();
        }
        if (projectData.updatedAt) {
          projectData.updatedAt = projectData.updatedAt.toDate();
        }
        if (projectData.startDate) {
          projectData.startDate = new Date(projectData.startDate);
        }
        if (projectData.endDate) {
          projectData.endDate = new Date(projectData.endDate);
        }
        
        setProject(projectData);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching project details:", err);
        setError("Failed to load project details");
        setIsLoading(false);
      }
    };
    
    fetchProjectDetails();
  }, [id]);
  
  // Handle section toggle
  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section]
    });
  };
  
  // Format date helper
  const formatDate = (date) => {
    if (!date) return 'N/A';
    
    try {
      return new Intl.DateTimeFormat('en-ZA', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(date);
    } catch (e) {
      return 'Invalid date';
    }
  };
  
  // Handle delete
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await deleteDoc(doc(db, "institutions", id));
      navigate('/institutions/projects');
    } catch (err) {
      console.error("Error deleting project:", err);
      setError("Failed to delete project/programme");
      setIsLoading(false);
    }
  };
  
  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center py-16">
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent"></div>
          <p className="mt-4 text-gray-600">Loading details...</p>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded-md">
          {error}
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate('/institutions/projects')}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back to Projects & Programmes
          </button>
        </div>
      </div>
    );
  }
  
  if (!project) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-yellow-50 border border-yellow-200 text-yellow-800 px-4 py-3 rounded-md">
          Project/Programme not found
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate('/institutions/projects')}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back to Projects & Programmes
          </button>
        </div>
      </div>
    );
  }
  
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Back button and actions */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
        <button
          onClick={() => navigate('/institutions/projects')}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Projects & Programmes
        </button>
        
        <div className="mt-4 sm:mt-0 space-x-2">
          <Link
            to={`/institutions/projects/edit/${id}`}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Edit className="mr-2 h-4 w-4" />
            Edit
          </Link>
          
          <button
            onClick={() => setShowDeleteConfirm(true)}
            className="inline-flex items-center px-4 py-2 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <Trash2 className="mr-2 h-4 w-4" />
            Delete
          </button>
        </div>
      </div>
      
      {/* Project header */}
      <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
        <div className="p-6">
          <div className="flex flex-col md:flex-row md:items-center">
            <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-6">
              <div className={`h-16 w-16 rounded-full flex items-center justify-center ${
                project.type === 'Project' 
                  ? 'bg-blue-100 text-blue-600' 
                  : 'bg-purple-100 text-purple-600'
              }`}>
                <PieChart className="h-8 w-8" />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">{project.name}</h1>
                  <div className="flex items-center mt-1">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      project.type === 'Project' 
                        ? 'bg-blue-100 text-blue-800' 
                        : 'bg-purple-100 text-purple-800'
                    }`}>
                      {project.type}
                    </span>
                    
                    <span className={`ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      project.status === 'Active' ? 'bg-green-100 text-green-800' :
                      project.status === 'Planning' ? 'bg-yellow-100 text-yellow-800' :
                      project.status === 'Completed' ? 'bg-gray-100 text-gray-800' :
                      project.status === 'On Hold' ? 'bg-orange-100 text-orange-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {project.status}
                    </span>
                  </div>
                </div>
                {project.projectId && (
                  <div className="mt-2 sm:mt-0 sm:ml-4 text-sm text-gray-600">
                    ID: {project.projectId}
                  </div>
                )}
              </div>
              
              {project.description && (
                <p className="mt-4 text-gray-600">{project.description}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {/* Project details */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        {/* Timeline */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">Timeline</h2>
          </div>
          <div className="p-6">
            <div className="space-y-4">
              <div className="flex items-start">
                <Calendar className="h-5 w-5 text-blue-500 mr-3 mt-0.5" />
                <div>
                  <h3 className="text-sm font-medium text-gray-900">Start Date</h3>
                  <p className="mt-1 text-sm text-gray-600">{formatDate(project.startDate)}</p>
                </div>
              </div>
              
              <div className="flex items-start">
                <Calendar className="h-5 w-5 text-blue-500 mr-3 mt-0.5" />
                <div>
                  <h3 className="text-sm font-medium text-gray-900">End Date</h3>
                  <p className="mt-1 text-sm text-gray-600">{formatDate(project.endDate)}</p>
                </div>
              </div>
              
              {project.budget && (
                <div className="flex items-start">
                  <Clock className="h-5 w-5 text-blue-500 mr-3 mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">Budget</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      {project.budget.startsWith('R') ? project.budget : `R ${project.budget}`}
                    </p>
                  </div>
                </div>
              )}
              
              <div className="flex items-start">
                <Clock className="h-5 w-5 text-blue-500 mr-3 mt-0.5" />
                <div>
                  <h3 className="text-sm font-medium text-gray-900">Created On</h3>
                  <p className="mt-1 text-sm text-gray-600">{formatDate(project.createdAt)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Coordinator Information */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">Coordinator</h2>
          </div>
          <div className="p-6">
            {project.coordinator ? (
              <div className="space-y-4">
                <div className="flex items-start">
                  <User className="h-5 w-5 text-blue-500 mr-3 mt-0.5" />
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">Name</h3>
                    <p className="mt-1 text-sm text-gray-600">{project.coordinator}</p>
                  </div>
                </div>
                
                {project.coordinatorEmail && (
                  <div className="flex items-start">
                    <Mail className="h-5 w-5 text-blue-500 mr-3 mt-0.5" />
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">Email</h3>
                      <p className="mt-1 text-sm text-gray-600">{project.coordinatorEmail}</p>
                    </div>
                  </div>
                )}
                
                {project.coordinatorPhone && (
                  <div className="flex items-start">
                    <Phone className="h-5 w-5 text-blue-500 mr-3 mt-0.5" />
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">Phone</h3>
                      <p className="mt-1 text-sm text-gray-600">{project.coordinatorPhone}</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <p className="text-sm text-gray-500">No coordinator information available</p>
            )}
          </div>
        </div>
        
        {/* Operational Levels */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">Operational Levels</h2>
          </div>
          <div className="p-6">
            {project.operationalLevels && project.operationalLevels.length > 0 ? (
              <div className="space-y-2">
                {project.operationalLevels.map(level => (
                  <div 
                    key={level}
                    className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 mr-2 mb-2"
                  >
                    {level === 'Provincial Level' && <Landmark className="h-4 w-4 mr-1" />}
                    {level === 'District Level' && <Building className="h-4 w-4 mr-1" />}
                    {level === 'Circuit Level' && <Building2 className="h-4 w-4 mr-1" />}
                    {level === 'School Level' && <School className="h-4 w-4 mr-1" />}
                    {level}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-sm text-gray-500">No operational levels defined</p>
            )}
            
            <div className="mt-4">
              <div className="grid grid-cols-2 gap-4">
                {project.provinces && project.provinces.length > 0 && (
                  <div className="bg-red-50 rounded-md p-3">
                    <p className="text-xs text-gray-500">Provinces</p>
                    <p className="text-lg font-semibold text-red-700">{project.provinces.length}</p>
                  </div>
                )}
                
                {project.districts && project.districts.length > 0 && (
                  <div className="bg-purple-50 rounded-md p-3">
                    <p className="text-xs text-gray-500">Districts</p>
                    <p className="text-lg font-semibold text-purple-700">{project.districts.length}</p>
                  </div>
                )}
                
                {project.circuits && project.circuits.length > 0 && (
                  <div className="bg-green-50 rounded-md p-3">
                    <p className="text-xs text-gray-500">Circuits</p>
                    <p className="text-lg font-semibold text-green-700">{project.circuits.length}</p>
                  </div>
                )}
                
                {project.schools && project.schools.length > 0 && (
                  <div className="bg-blue-50 rounded-md p-3">
                    <p className="text-xs text-gray-500">Schools</p>
                    <p className="text-lg font-semibold text-blue-700">{project.schools.length}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Institutions List */}
      <div className="space-y-6">
        {/* Provinces */}
        {project.provinces && project.provinces.length > 0 && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div 
              className="px-6 py-4 border-b border-gray-200 flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('provinces')}
            >
              <h2 className="text-lg font-medium text-gray-900 flex items-center">
                <Landmark className="h-5 w-5 mr-2 text-red-600" />
                Provinces ({project.provinces.length})
              </h2>
              {expandedSections.provinces ? (
                <ChevronUp className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDown className="h-5 w-5 text-gray-500" />
              )}
            </div>
            
            {expandedSections.provinces && (
              <div className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {project.provinces.map(province => (
                    <div key={province.id} className="border rounded-md p-4 hover:bg-gray-50">
                      <div className="flex items-center">
                        <Landmark className="h-5 w-5 text-red-600 mr-2" />
                        <h3 className="text-sm font-medium text-gray-900">{province.name}</h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        
        {/* Districts */}
        {project.districts && project.districts.length > 0 && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div 
              className="px-6 py-4 border-b border-gray-200 flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('districts')}
            >
              <h2 className="text-lg font-medium text-gray-900 flex items-center">
                <Building className="h-5 w-5 mr-2 text-purple-600" />
                Districts ({project.districts.length})
              </h2>
              {expandedSections.districts ? (
                <ChevronUp className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDown className="h-5 w-5 text-gray-500" />
              )}
            </div>
            
            {expandedSections.districts && (
              <div className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {project.districts.map(district => (
                    <div key={district.id} className="border rounded-md p-4 hover:bg-gray-50">
                      <div className="flex items-center">
                        <Building className="h-5 w-5 text-purple-600 mr-2" />
                        <h3 className="text-sm font-medium text-gray-900">{district.name}</h3>
                      </div>
                      {district.province && (
                        <p className="mt-1 text-xs text-gray-500 ml-7">
                          {district.province}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        
        {/* Circuits */}
        {project.circuits && project.circuits.length > 0 && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div 
              className="px-6 py-4 border-b border-gray-200 flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('circuits')}
            >
              <h2 className="text-lg font-medium text-gray-900 flex items-center">
                <Building2 className="h-5 w-5 mr-2 text-green-600" />
                Circuits ({project.circuits.length})
              </h2>
              {expandedSections.circuits ? (
                <ChevronUp className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDown className="h-5 w-5 text-gray-500" />
              )}
            </div>
            
            {expandedSections.circuits && (
              <div className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {project.circuits.map(circuit => (
                    <div key={circuit.id} className="border rounded-md p-4 hover:bg-gray-50">
                      <div className="flex items-center">
                        <Building2 className="h-5 w-5 text-green-600 mr-2" />
                        <h3 className="text-sm font-medium text-gray-900">{circuit.name}</h3>
                      </div>
                      {(circuit.district || circuit.province) && (
                        <p className="mt-1 text-xs text-gray-500 ml-7">
                          {circuit.district}{circuit.province ? `, ${circuit.province}` : ''}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        
        {/* Schools */}
        {project.schools && project.schools.length > 0 && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div 
              className="px-6 py-4 border-b border-gray-200 flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('schools')}
            >
              <h2 className="text-lg font-medium text-gray-900 flex items-center">
                <School className="h-5 w-5 mr-2 text-blue-600" />
                Schools ({project.schools.length})
              </h2>
              {expandedSections.schools ? (
                <ChevronUp className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDown className="h-5 w-5 text-gray-500" />
              )}
            </div>
            
            {expandedSections.schools && (
              <div className="p-6">
                {project.schools.length > 20 ? (
                  <div className="mb-4 bg-yellow-50 p-3 rounded-md flex items-center text-sm text-yellow-800">
                    <AlertTriangle className="h-5 w-5 mr-2 text-yellow-500" />
                    Showing first 20 of {project.schools.length} schools
                  </div>
                ) : null}
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {project.schools.slice(0, 20).map(school => (
                    <div key={school.id} className="border rounded-md p-4 hover:bg-gray-50">
                      <div className="flex items-center">
                        <School className="h-5 w-5 text-blue-600 mr-2" />
                        <h3 className="text-sm font-medium text-gray-900">{school.name}</h3>
                      </div>
                      {(school.circuit || school.district || school.province) && (
                        <p className="mt-1 text-xs text-gray-500 ml-7">
                        {school.circuit ? `${school.circuit}, ` : ''}
                        {school.district ? `${school.district}, ` : ''}
                        {school.province || ''}
                      </p>
                    )}
                  </div>
                ))}
              </div>
              
              {project.schools.length > 20 && (
                <div className="mt-4 text-center">
                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    View All Schools
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
    
    {/* Delete Confirmation Modal */}
    {showDeleteConfirm && (
      <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div 
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
            aria-hidden="true"
            onClick={() => setShowDeleteConfirm(false)}
          ></div>
          
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Delete {project.type}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete this {project.type.toLowerCase()}? All data associated with 
                      "{project.name}" will be permanently removed.
                      This action cannot be undone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setShowDeleteConfirm(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);
};

export default ProjectProgrammeDetails;