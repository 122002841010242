// src/components/institutions/ProjectProgrammeList.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Plus, Search, Filter, ChevronDown, MapPin, 
  PieChart, Calendar, Users, ExternalLink
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';

const ProjectProgrammeList = () => {
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [typeFilter, setTypeFilter] = useState(''); // 'Project' or 'Programme'
  const [levelFilter, setLevelFilter] = useState(''); // Operational level filter
  const [showFilters, setShowFilters] = useState(false);
  
  // Fetch projects and programmes
  useEffect(() => {
    const fetchProjects = async () => {
      if (!currentUser) return;
      
      setIsLoading(true);
      try {
        const projectsRef = collection(db, "institutions");
        const q = query(
          projectsRef,
          where("type", "in", ["Project", "Programme"]),
          orderBy("name")
        );
        
        const querySnapshot = await getDocs(q);
        const projectList = [];
        
        querySnapshot.forEach((doc) => {
          projectList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setProjects(projectList);
      } catch (err) {
        console.error("Error fetching projects:", err);
        setError("Failed to load projects and programmes");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchProjects();
  }, [currentUser]);
  
  // Filter projects based on search term and filters
  const filteredProjects = projects.filter(project => {
    const matchesSearch = project.name?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = typeFilter ? project.type === typeFilter : true;
    const matchesLevel = levelFilter ? 
      (project.operationalLevels && project.operationalLevels.includes(levelFilter)) : true;
    
    return matchesSearch && matchesType && matchesLevel;
  });
  
  // Format date helper
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    try {
      const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
      return new Intl.DateTimeFormat('en-ZA', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(date);
    } catch (e) {
      return 'N/A';
    }
  };
  
  return (
    <div>
      {/* Header with Add button */}
      <div className="flex justify-end mb-6">
        <Link
          to="/institutions/projects/register"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Plus className="h-4 w-4 mr-2" />
          Register Project/Programme
        </Link>
      </div>
      
      {/* Search and Filters */}
      <div className="bg-white shadow rounded-lg mb-6 p-4">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="relative flex-grow">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
            <input
              type="text"
              placeholder="Search projects and programmes..."
              className="pl-10 pr-4 py-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Filter className="h-4 w-4 mr-2" />
            Filters
            <ChevronDown className={`ml-1 h-4 w-4 transition-transform ${showFilters ? 'rotate-180' : ''}`} />
          </button>
        </div>
        
        {/* Filter options */}
        {showFilters && (
          <div className="mt-4 pt-4 border-t grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type
              </label>
              <select
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
              >
                <option value="">All Types</option>
                <option value="Project">Projects</option>
                <option value="Programme">Programmes</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Operational Level
              </label>
              <select
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={levelFilter}
                onChange={(e) => setLevelFilter(e.target.value)}
              >
                <option value="">All Levels</option>
                <option value="School Level">School Level</option>
                <option value="Circuit Level">Circuit Level</option>
                <option value="District Level">District Level</option>
                <option value="Provincial Level">Provincial Level</option>
              </select>
            </div>
            
            <div className="md:col-span-2 flex justify-end">
              <button
                onClick={() => {
                  setTypeFilter('');
                  setLevelFilter('');
                  setSearchTerm('');
                }}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Clear All Filters
              </button>
            </div>
          </div>
        )}
      </div>
      
      {/* Loading state */}
      {isLoading && (
        <div className="text-center py-12">
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent"></div>
          <p className="mt-4 text-gray-600">Loading projects and programmes...</p>
        </div>
      )}
      
      {/* Error state */}
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded-md">
          {error}
        </div>
      )}
      
      {/* Empty state */}
      {!isLoading && !error && filteredProjects.length === 0 && (
        <div className="bg-white shadow rounded-lg p-6 text-center">
          <PieChart className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-lg font-medium text-gray-900">No projects or programmes found</h3>
          <p className="mt-1 text-gray-500">
            {searchTerm || typeFilter || levelFilter 
              ? 'Try adjusting your search or filters'
              : 'Get started by registering your first project or programme'}
          </p>
          
          <div className="mt-6">
            <Link
              to="/institutions/projects/register"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Plus className="h-4 w-4 mr-2" />
              Register Project/Programme
            </Link>
          </div>
        </div>
      )}
      
      {/* Project/Programme List */}
      {!isLoading && !error && filteredProjects.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredProjects.map((project) => (
            <div key={project.id} className="bg-white shadow rounded-lg overflow-hidden">
              <div className="p-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0 mr-3">
                    <div className={`h-10 w-10 rounded-full flex items-center justify-center ${
                      project.type === 'Project' 
                        ? 'bg-blue-100 text-blue-600' 
                        : 'bg-purple-100 text-purple-600'
                    }`}>
                      <PieChart className="h-5 w-5" />
                    </div>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">{project.name}</h3>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      project.type === 'Project' 
                        ? 'bg-blue-100 text-blue-800' 
                        : 'bg-purple-100 text-purple-800'
                    }`}>
                      {project.type}
                    </span>
                  </div>
                </div>
                
                {project.description && (
                  <p className="mt-3 text-sm text-gray-600 line-clamp-2">{project.description}</p>
                )}
                
                <div className="mt-4 text-xs text-gray-500">
                  <div className="flex items-center mb-1">
                    <Calendar className="h-3.5 w-3.5 mr-1" />
                    {project.startDate ? `Started: ${formatDate(project.startDate)}` : 'No start date'}
                  </div>
                  
                  {project.location && (
                    <div className="flex items-center mb-1">
                      <MapPin className="h-3.5 w-3.5 mr-1" />
                      {project.location}
                    </div>
                  )}
                  
                  {project.coordinator && (
                    <div className="flex items-center">
                      <Users className="h-3.5 w-3.5 mr-1" />
                      Coordinator: {project.coordinator}
                    </div>
                  )}
                </div>
              </div>
              
              <div className="bg-gray-50 px-6 py-3 flex justify-between items-center border-t">
                <div className="text-xs text-gray-500">
                  {project.operationalLevels?.length || 0} operational levels
                </div>
                
                <Link
                  to={`/institutions/projects/details/${project.id}`}
                  className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
                >
                  View Details
                  <ExternalLink size={14} className="ml-1" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectProgrammeList;