// First, make sure the imports are at the very top and saved properly
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { 
  Save, ArrowLeft, Plus, X, Calendar, 
  Target, Clock, User, Briefcase, FileText 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, 
  doc, 
  getDoc, 
  setDoc, 
  addDoc,
  query,
  where, 
  getDocs,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';

const PlanForm = ({ isEditing = false }) => {
  const { planId } = useParams();
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  
  // Form state
  const [institutionId, setInstitutionId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [goals, setGoals] = useState([{ text: '' }]);
  const [policyRef, setPolicyRef] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [responsibility, setResponsibility] = useState('');
  const [structure, setStructure] = useState('');
  const [measurableOutcomes, setMeasurableOutcomes] = useState([{ text: '' }]);
const [planType, setPlanType] = useState('');
const [subjects, setSubjects] = useState([]);
const [selectedSubject, setSelectedSubject] = useState('');
const [selectedGrade, setSelectedGrade] = useState('');
const [gradeOptions, setGradeOptions] = useState([]);
const [isCustomCategory, setIsCustomCategory] = useState(false);
const [isCustomType, setIsCustomType] = useState(false);
const [showCustomCategory, setShowCustomCategory] = useState(false);
const [showCustomType, setShowCustomType] = useState(false);
  
  // UI state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Categories for dropdown
  const categories = ['Administration', 'Curriculum', 'Operations', 'Extra-Curricula'];
  
  const planTypesByCategory = {
    'Operations': ['School Improvement Plan', 'Operational Plan', 'CPTD Plan', 'Extra-Curricula Plan'],
    'Curriculum': ['Subject Improvement Plan', 'Annual Teaching Plan', 'Programme of Assessment'],
    'Administration': ['Budget Plan', 'Resource Plan', 'Policy Implementation Plan'],
    'Extra-Curricula': ['Sports Plan', 'Cultural Activities Plan', 'Community Outreach Plan']
  };
  // Mock data for structures and policies
  const mockStructures = [
    'School Management Team', 
    'Academic Department', 
    'Administrative Office', 
    'Sports Department',
    'Cultural Department'
  ];
  
  const mockPolicies = [
    'School Policy',
    'Admission Policy',
    'Assessment Policy',
    'Code of Conduct',
    'Financial Policy',
    'Health and Safety Policy'
  ];

  // Add this useEffect to fetch subjects
  useEffect(() => {
    const fetchSubjects = async () => {
      if (!userProfile?.institutionId) return;
      
      try {
        const subjectsRef = collection(db, "subjects");
        const q = query(
          subjectsRef,
          where("institutionId", "==", userProfile.institutionId)
        );
        
        const querySnapshot = await getDocs(q);
        const subjectsList = [];
        
        querySnapshot.forEach((doc) => {
          subjectsList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setSubjects(subjectsList);
      } catch (err) {
        console.error("Error fetching subjects:", err);
      }
    };
    
    fetchSubjects();
  }, [userProfile]);
  
  // Fetch plan data if editing
  useEffect(() => {
    if (isEditing && planId) {
      setIsLoading(true);
      const fetchPlan = async () => {
        try {
          const planDoc = await getDoc(doc(db, 'plans', planId));
          
          if (planDoc.exists()) {
            const planData = planDoc.data();
            setTitle(planData.title || '');
            setDescription(planData.description || '');
            setCategory(planData.category || '');
            setPlanType(planData.planType || '');
            setSelectedSubject(planData.subject || '');
            setSelectedGrade(planData.grade || '');
            setGoals(planData.goals?.length ? planData.goals : [{ text: '' }]);
            setPolicyRef(planData.policyRef || '');
            
            // Set dates if available
            if (planData.timeframe?.start) {
              const startTimestamp = planData.timeframe.start;
              const startDate = startTimestamp.toDate ? startTimestamp.toDate() : new Date(startTimestamp);
              setStartDate(startDate.toISOString().split('T')[0]);
            }
            
            if (planData.timeframe?.end) {
              const endTimestamp = planData.timeframe.end;
              const endDate = endTimestamp.toDate ? endTimestamp.toDate() : new Date(endTimestamp);
              setEndDate(endDate.toISOString().split('T')[0]);
            }
            
            setResponsibility(planData.responsibility || '');
            setStructure(planData.structure || '');
            setMeasurableOutcomes(planData.measurableOutcomes?.length ? 
              planData.measurableOutcomes : [{ text: '' }]);
          } else {
            setError('Plan not found');
          }
        } catch (err) {
          console.error('Error fetching plan:', err);
          setError('Failed to load plan data');
        } finally {
          setIsLoading(false);
        }
      };
      
      fetchPlan();
    }
  }, [isEditing, planId]);

  // Add this useEffect to update grade options
  useEffect(() => {
    if (selectedSubject) {
      const subject = subjects.find(s => s.id === selectedSubject);
      if (subject && subject.grades) {
        setGradeOptions(subject.grades);
      } else {
        setGradeOptions([]);
      }
    } else {
      setGradeOptions([]);
    }
  }, [selectedSubject, subjects]);

  // Add this useEffect to fetch institutionId when component mounts
  useEffect(() => {
    const fetchInstitutionId = async () => {
      if (!userProfile?.institutionName) return;
      
      try {
        // Query the institutions collection to find the matching institution
        const institutionsRef = collection(db, "institutions");
        const q = query(
          institutionsRef,
          where("name", "==", userProfile.institutionName)
        );
        
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          // Get the first matching institution's ID
          const institutionDoc = querySnapshot.docs[0];
          setInstitutionId(institutionDoc.id);
        } else {
          console.warn(`No institution found with name: ${userProfile.institutionName}`);
        }
      } catch (err) {
        console.error('Error fetching institution ID:', err);
      }
    };
    
    fetchInstitutionId();
  }, [userProfile]);
  
  // Handle goal actions
  const addGoal = () => {
    setGoals([...goals, { text: '' }]);
  };
  
  const updateGoal = (index, value) => {
    const updatedGoals = [...goals];
    updatedGoals[index] = { text: value };
    setGoals(updatedGoals);
  };
  
  const removeGoal = (index) => {
    if (goals.length > 1) {
      const updatedGoals = [...goals];
      updatedGoals.splice(index, 1);
      setGoals(updatedGoals);
    }
  };
  
  // Handle outcome actions
  const addOutcome = () => {
    setMeasurableOutcomes([...measurableOutcomes, { text: '' }]);
  };
  
  const updateOutcome = (index, value) => {
    const updatedOutcomes = [...measurableOutcomes];
    updatedOutcomes[index] = { text: value };
    setMeasurableOutcomes(updatedOutcomes);
  };
  
  const removeOutcome = (index) => {
    if (measurableOutcomes.length > 1) {
      const updatedOutcomes = [...measurableOutcomes];
      updatedOutcomes.splice(index, 1);
      setMeasurableOutcomes(updatedOutcomes);
    }
  };
  
  // Add this function to the PlanForm component
  const validateForm = () => {
    if (!title.trim()) {
      setError('Plan title is required');
      return false;
    }
    
    if (!category) {
      setError('Please select a category');
      return false;
    }
    
    // Validate goals
    const validGoals = goals.filter(goal => goal.text.trim());
    if (validGoals.length === 0) {
      setError('At least one goal is required');
      return false;
    }
    
    // Validate dates if both are provided
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      
      if (start > end) {
        setError('End date must be after start date');
        return false;
      }
    }
    
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      // Make sure all fields are defined or have fallback values
      const planData = {
        title: title.trim(),
        description: description.trim() || '',
        category: category || '',
        planType: planType,
        subject: selectedSubject,
        grade: selectedGrade,
        goals: goals.filter(goal => goal.text.trim()),
        policyRef: policyRef || '',
        timeframe: {
          start: startDate ? Timestamp.fromDate(new Date(startDate)) : null,
          end: endDate ? Timestamp.fromDate(new Date(endDate)) : null
        },
        responsibility: responsibility.trim() || '',
        structure: structure || '',
        measurableOutcomes: measurableOutcomes.filter(outcome => outcome.text.trim()),
        userId: currentUser?.uid || '',
        
        // Use institutionName instead of trying to fetch institutionId
        // This should avoid the permission error
        institutionName: userProfile?.institutionName || '',
        
        // Since we're not querying for institutionId, don't include it
        // unless we have it directly from userProfile
        ...(userProfile?.institutionId ? { institutionId: userProfile.institutionId } : {}),
        
        updatedAt: serverTimestamp()
      };
      
      if (isEditing) {
        await setDoc(doc(db, 'plans', planId), planData, { merge: true });
        setSuccess('Plan updated successfully');
      } else {
        planData.date = serverTimestamp();
        planData.createdAt = serverTimestamp();
        
        // This should no longer fail with undefined error
        const docRef = await addDoc(collection(db, 'plans'), planData);
        setSuccess('Plan created successfully');
      }
      
      // Navigate back after a short delay
      setTimeout(() => {
        navigate('/planner');
      }, 1500);
      
    } catch (err) {
      console.error('Error saving plan:', err);
      setError(`Failed to ${isEditing ? 'update' : 'create'} plan: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">
          {isEditing ? 'Edit Plan' : 'Create New Plan'}
        </h2>
        <button
          onClick={() => navigate('/planner')}
          className="text-gray-600 hover:text-gray-900 flex items-center"
        >
          <ArrowLeft size={16} className="mr-2" />
          Back to Plans
        </button>
      </div>
      
      {/* Error and Success Messages */}
      {error && (
        <div className="mx-6 my-4 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
          {error}
        </div>
      )}
      
      {success && (
        <div className="mx-6 my-4 bg-green-50 border-l-4 border-green-500 p-4 text-green-700">
          {success}
        </div>
      )}
      
      {/* Loading indicator */}
      {isLoading && (
        <div className="flex justify-center items-center p-6">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="p-6 space-y-6">
        {/* Basic Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              Plan Title <span className="text-red-500">*</span>
            </label>
            <input
              id="title"
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              disabled={isLoading}
            />
          </div>
          
          <div>
          <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
            Category <span className="text-red-500">*</span>
          </label>
          <div className="flex">
            {!isCustomCategory ? (
              <div className="relative flex-grow">
                <select
                  id="category"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    // Reset plan type when category changes
                    setPlanType('');
                    setIsCustomType(false);
                  }}
                  required
                  disabled={isLoading}
                >
                  <option value="">Select a category</option>
                  {categories.map((cat) => (
                    <option key={cat} value={cat}>{cat}</option>
                  ))}
                </select>
              </div>
            ) : (
              <input
                type="text"
                id="category"
                placeholder="Enter custom category"
                className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
                disabled={isLoading}
              />
            )}
            <button
              type="button"
              className="ml-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => {
                setIsCustomCategory(!isCustomCategory);
                if (!isCustomCategory) {
                  setCategory('');
                  setPlanType('');
                  setIsCustomType(false);
                }
              }}
              disabled={isLoading}
            >
              {isCustomCategory ? 'Use List' : 'Add Option'}
            </button>
          </div>
        </div>
      </div>

      {/* Add Plan Type dropdown */}
      <div>
        <label htmlFor="planType" className="block text-sm font-medium text-gray-700 mb-1">
          Plan Type <span className="text-red-500">*</span>
        </label>
        <div className="flex">
          {!isCustomType ? (
            <div className="relative flex-grow">
              <select
                id="planType"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                value={planType}
                onChange={(e) => setPlanType(e.target.value)}
                required
                disabled={isLoading || !category || isCustomCategory}
              >
                <option value="">Select a plan type</option>
                {!isCustomCategory && category && planTypesByCategory[category]?.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
          ) : (
            <input
              type="text"
              id="planType"
              placeholder="Enter custom plan type"
              className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={planType}
              onChange={(e) => setPlanType(e.target.value)}
              required
              disabled={isLoading}
            />
          )}
          <button
            type="button"
            className="ml-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => {
              setIsCustomType(!isCustomType);
              if (!isCustomType) {
                setPlanType('');
              }
            }}
            disabled={isLoading || (isCustomCategory && !category)}
          >
            {isCustomType ? 'Use List' : 'Add Option'}
          </button>
        </div>
      </div>

      {/* Add Subject and Grade fields */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            Subject
          </label>
          <select
            id="subject"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            value={selectedSubject}
            onChange={(e) => setSelectedSubject(e.target.value)}
            disabled={isLoading || !subjects.length}
          >
            <option value="">Select a subject</option>
            {subjects.map((subject) => (
              <option key={subject.id} value={subject.id}>{subject.name}</option>
            ))}
          </select>
        </div>
        
        <div>
          <label htmlFor="grade" className="block text-sm font-medium text-gray-700 mb-1">
            Grade
          </label>
          <select
            id="grade"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            value={selectedGrade}
            onChange={(e) => setSelectedGrade(e.target.value)}
            disabled={isLoading || !gradeOptions.length}
          >
            <option value="">Select a grade</option>
            {gradeOptions.map((grade) => (
              <option key={grade} value={grade}>Grade {grade}</option>
            ))}
          </select>
        </div>
      </div>
        
        {/* Description */}
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <textarea
            id="description"
            rows={3}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={isLoading}
          />
        </div>
        
        {/* Goals */}
        <div>
          <div className="flex justify-between items-center mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Goals <span className="text-red-500">*</span>
            </label>
            <button
              type="button"
              className="text-blue-600 hover:text-blue-800 text-sm flex items-center"
              onClick={addGoal}
              disabled={isLoading}
            >
              <Plus size={14} className="mr-1" />
              Add Goal
            </button>
          </div>
          
          <div className="space-y-2">
            {goals.map((goal, index) => (
              <div key={index} className="flex items-center">
                <div className="flex-shrink-0 mr-2">
                  <div className="w-6 h-6 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 text-xs font-medium">
                    {index + 1}
                  </div>
                </div>
                <input
                  type="text"
                  className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  value={goal.text}
                  onChange={(e) => updateGoal(index, e.target.value)}
                  placeholder="Enter goal..."
                  disabled={isLoading}
                />
                <button
                  type="button"
                  className="ml-2 text-gray-400 hover:text-red-500"
                  onClick={() => removeGoal(index)}
                  disabled={isLoading || goals.length <= 1}
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
        
        {/* Timeframe */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <input
              id="startDate"
              type="date"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled={isLoading}
            />
          </div>
          
          <div>
  <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
    End Date
  </label>
  <input
    id="endDate"
    type="date"
    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
    value={endDate}
    onChange={(e) => setEndDate(e.target.value)}
    disabled={isLoading}
  />
</div>
</div>

{/* Responsibility and Structure */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
  <div>
    <label htmlFor="responsibility" className="block text-sm font-medium text-gray-700 mb-1">
      Responsibility
    </label>
    <input
      id="responsibility"
      type="text"
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      value={responsibility}
      onChange={(e) => setResponsibility(e.target.value)}
      disabled={isLoading}
      placeholder="Person or role responsible"
    />
  </div>
  
  <div>
    <label htmlFor="structure" className="block text-sm font-medium text-gray-700 mb-1">
      Structure
    </label>
    <select
      id="structure"
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      value={structure}
      onChange={(e) => setStructure(e.target.value)}
      disabled={isLoading}
    >
      <option value="">Select a structure</option>
      {mockStructures.map((struct) => (
        <option key={struct} value={struct}>{struct}</option>
      ))}
    </select>
  </div>
</div>

{/* Policy Reference */}
<div>
  <label htmlFor="policyRef" className="block text-sm font-medium text-gray-700 mb-1">
    Policy Reference
  </label>
  <select
    id="policyRef"
    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
    value={policyRef}
    onChange={(e) => setPolicyRef(e.target.value)}
    disabled={isLoading}
  >
    <option value="">Select a policy</option>
    {mockPolicies.map((policy) => (
      <option key={policy} value={policy}>{policy}</option>
    ))}
  </select>
</div>

{/* Measurable Outcomes */}
<div>
  <div className="flex justify-between items-center mb-2">
    <label className="block text-sm font-medium text-gray-700">
      Measurable Outcomes
    </label>
    <button
      type="button"
      className="text-blue-600 hover:text-blue-800 text-sm flex items-center"
      onClick={addOutcome}
      disabled={isLoading}
    >
      <Plus size={14} className="mr-1" />
      Add Outcome
    </button>
  </div>
  
  <div className="space-y-2">
    {measurableOutcomes.map((outcome, index) => (
      <div key={index} className="flex items-center">
        <div className="flex-shrink-0 mr-2">
          <div className="w-6 h-6 bg-green-100 rounded-full flex items-center justify-center text-green-600 text-xs font-medium">
            {index + 1}
          </div>
        </div>
        <input
          type="text"
          className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={outcome.text}
          onChange={(e) => updateOutcome(index, e.target.value)}
          placeholder="Enter measurable outcome..."
          disabled={isLoading}
        />
        <button
          type="button"
          className="ml-2 text-gray-400 hover:text-red-500"
          onClick={() => removeOutcome(index)}
          disabled={isLoading || measurableOutcomes.length <= 1}
        >
          <X size={16} />
        </button>
      </div>
    ))}
  </div>
</div>

{/* Submit Button */}
<div className="flex justify-end pt-4 border-t border-gray-200">
  <button
    type="button"
    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-3"
    onClick={() => navigate('/planner')}
    disabled={isLoading}
  >
    Cancel
  </button>
  <button
    type="submit"
    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center"
    disabled={isLoading}
  >
    <Save size={16} className="mr-2" />
    {isLoading ? 'Saving...' : isEditing ? 'Update Plan' : 'Create Plan'}
  </button>
</div>
</form>
</div>
);
};

export default PlanForm;