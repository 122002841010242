import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { 
  collection, 
  addDoc, 
  doc, 
  updateDoc, 
  serverTimestamp, 
  getDoc 
} from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { 
  MonitoringCard as Card,
  MonitoringCardContent as CardContent,
  MonitoringCardDescription as CardDescription, 
  MonitoringCardFooter as CardFooter, 
  MonitoringCardHeader as CardHeader, 
  MonitoringCardTitle as CardTitle 
} from '../common/MonitoringCard';
import { 
  MonitoringTabs as Tabs, 
  MonitoringTabsContent as TabsContent, 
  MonitoringTabsList as TabsList, 
  MonitoringTabsTrigger as TabsTrigger 
} from '../common/MonitoringTabs';
import { 
  ArrowLeft, 
  Save, 
  Plus, 
  Calendar, 
  Download, 
  Upload, 
  CheckCircle,
  AlertTriangle,
  Info,
  FileText,
  Send
} from 'lucide-react';

const MonitoringForm = () => {
  const [activeTab, setActiveTab] = useState('administration');
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({
    // Basic Info
    year: '2025',
    quarter: 'Q1',
    district: '',
    circuit: '',
    school: '',
    visitDate: '',
    
    // School Staff Data
    ppn: '',
    principal: '',
    dp: '',
    hod: '',
    level1: '',
    temporalEducator: '',
    ctt: '',
    substitute: '',
    displacedEducator: '',
    sgbPaid: '',
    clerk: '',
    security: '',
    cleaner: '',
    gradeR: '',
    ecdPost: '',
    teacherAssistant: '',
    snapSurvey: '',
    
    // Learner Attendance
    attendance: {
      grR: '',
      gr1: '',
      gr2: '',
      gr3: '',
      gr4: '',
      gr5: '',
      gr6: '',
      gr7: '',
      gr8: '',
      gr9: '',
      gr10: '',
      gr11: '',
      gr12: '',
    },
    
    administration: {
      timeBook: {
        available: '',
        controlled: ''
      },
      leaveRegister: {
        available: '',
        controlled: ''
      },
      learnerAttendanceRegisters: {
        available: '',
        controlled: ''
      },
      learnerLeaveRegister: {
        available: ''
      },
      logBook: {
        available: '',
        controlled: ''
      },
      admissionBook: {
        available: '',
        controlled: ''
      },
      telephoneRegister: {
        available: '',
        controlled: ''
      },
      mailRegister: {
        available: '',
        controlled: ''
      },
      saSams: {
        implemented: '',
        upToDate: ''
      },
      comments: '',
      actions: ''
    },
    
    // Policies Section
    policies: {
      schoolPolicy: '',
      admissionPolicy: '',
      languagePolicy: '',
      religiousPolicy: '',
      assessmentPolicy: '',
      financePolicy: '',
      supplyChainPolicy: '',
      hivAidsPolicy: '',
      safetySecurityPolicy: '',
      nutritionPolicy: '',
      leavePolicy: '',
      feesExemptionPolicy: '',
      learnerConductCode: '',
      sgbConductCode: '',
      educatorConductCode: '',
      classManagementPolicy: '',
      ltsmRetrievalPolicy: '',
      pastoralCarePolicy: '',
      comments: '',
      actions: ''
    },
    
    // Curriculum Section
    curriculum: {
      properlyStaffed: '',
      staffingReasons: '',
      staffingActions: '',
      packages: '',
      packageDeviations: '',
      circular41Implementation: '',
      managementBooks: {
        communication: '',
        control: '',
        departmentalFile: '',
        minuteBooks: ''
      },
      timeTables: {
        composite: '',
        personal: '',
        class: '',
        relief: '',
        groundDuty: ''
      },
      periodRegisters: '',
      ltsm: {
        distributed: '',
        notDistributedReason: ''
      },
      controlOfWork: {
        byTeachers: '',
        bySmt: ''
      },
      examinationManagement: '',
      syllabiCoverage: '',
      syllabiActions: '',
      performanceTargets: {
        set: '',
        matricPass: '',
        matricTarget: '',
        bachelorTarget: '',
        grades: {
          gr1: { prev: '', cur: '' },
          gr2: { prev: '', cur: '' },
          gr3: { prev: '', cur: '' },
          gr6: { prev: '', cur: '' },
          gr9: { prev: '', cur: '' }
        }
      }
    },
    
    // Infrastructure Section
    infrastructure: {
      classrooms: {
        number: '',
        condition: ''
      },
      ablution: {
        number: '',
        condition: ''
      },
      water: {
        available: '',
        running: ''
      },
      overEnrolment: {
        status: '',
        permission: ''
      },
      underEnrolled: '',
      laboratories: {
        available: '',
        resourced: '',
        utilized: '',
        plans: '',
        timeframe: ''
      },
      library: {
        available: '',
        resourced: '',
        utilized: '',
        plans: '',
        timeframe: ''
      }
    },
    
    // Finances Section
    finances: {
      sgbConstituted: '',
      fincomFunctional: '',
      budget: {
        available: '',
        approved: ''
      },
      booksAudited: {
        status: '',
        expectedDate: ''
      }
    },
    
    // Nutrition Section
    nutrition: {
      participates: '',
      nonParticipationReason: '',
      kitchen: {
        available: '',
        clean: '',
        corrective: ''
      },
      lunchTime: {
        onTime: '',
        challenges: ''
      },
      schoolGarden: {
        available: '',
        reasons: ''
      }
    },
    
    // Campaigns Section
    campaigns: {
      myLifeMyFuture: '',
      qltp: '',
      healthPromoting: '',
      bathoPele: '',
      nationalSymbols: '',
      sukumaSakhe: '',
      epmds: '',
      iqms: ''
    },
    
    // General Comments
    generalComments: [
      { comment: '', completionDate: '' }
    ]
  });

  const [availableDrafts, setAvailableDrafts] = useState([]);
  const [showDraftsModal, setShowDraftsModal] = useState(false);
  
  // Generate a consistent draft key for local storage
  const getDraftKey = () => {
    return `schoolmate_monitoring_draft_${currentUser?.uid || 'anonymous'}`;
  };

  useEffect(() => {
    const loadLocalDrafts = () => {
      try {
        const draftKey = getDraftKey();
        const savedDrafts = localStorage.getItem(draftKey);
        
        if (savedDrafts) {
          const parsedDrafts = JSON.parse(savedDrafts);
          setAvailableDrafts(parsedDrafts);
          
          // If there's a draft and we have no form data, ask if the user wants to load it
          if (parsedDrafts.length > 0 && !formData.docId && 
              !formData.year && !formData.school) {
            setShowDraftsModal(true);
          }
        }
      } catch (error) {
        console.error('Error loading draft:', error);
      }
    };
    
    loadLocalDrafts();
  }, [currentUser, formData.docId, formData.year, formData.school]);
  
  // Save form to local storage as a draft
  const saveLocalDraft = () => {
    try {
      const draftKey = getDraftKey();
      
      // Create a draft object with metadata
      const draft = {
        id: `draft_${Date.now()}`,
        data: formData,
        createdAt: new Date().toISOString(),
        label: `${formData.school || 'Unknown School'} - ${formData.year} ${formData.quarter}`,
      };
      
      // Get existing drafts or initialize new array
      let existingDrafts = [];
      const savedDrafts = localStorage.getItem(draftKey);
      
      if (savedDrafts) {
        existingDrafts = JSON.parse(savedDrafts);
        
        // Find if there's already a draft for this school/year/quarter
        const existingIndex = existingDrafts.findIndex(d => 
          d.data.school === formData.school && 
          d.data.year === formData.year && 
          d.data.quarter === formData.quarter
        );
        
        // Replace existing draft if found
        if (existingIndex >= 0) {
          existingDrafts[existingIndex] = draft;
        } else {
          // Add new draft
          existingDrafts.push(draft);
        }
      } else {
        existingDrafts = [draft];
      }
      
      // Save drafts back to local storage
      localStorage.setItem(draftKey, JSON.stringify(existingDrafts));
      setAvailableDrafts(existingDrafts);
      
      return true;
    } catch (error) {
      console.error('Error saving local draft:', error);
      return false;
    }
  };
  
  // Load a specific draft
  const loadDraft = (draftId) => {
    try {
      const draft = availableDrafts.find(d => d.id === draftId);
      
      if (draft) {
        setFormData(draft.data);
        setSuccess('Draft loaded successfully');
        setShowDraftsModal(false);
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Error loading draft:', error);
      setError('Failed to load draft');
      return false;
    }
  };
  
  // Delete a specific draft
  const deleteDraft = (draftId) => {
    try {
      const draftKey = getDraftKey();
      const updatedDrafts = availableDrafts.filter(d => d.id !== draftId);
      
      localStorage.setItem(draftKey, JSON.stringify(updatedDrafts));
      setAvailableDrafts(updatedDrafts);
      
      return true;
    } catch (error) {
      console.error('Error deleting draft:', error);
      return false;
    }
  };
  
  const saveForm = async (isDraft = true) => {
    try {
      setIsLoading(true);
      setError('');
      
      // Always save to local storage for safety
      const localSaveSuccess = saveLocalDraft();
      
      if (isDraft) {
        setSuccess('Form saved as draft successfully');
        setIsLoading(false);
        alert('Form saved as draft');
        return;
      }
      
      // Continue with Firestore submission if not just saving as draft
      const formId = formData.formId || `MON-${formData.year}-${formData.quarter}-${formData.school ? formData.school.substring(0, 3).toUpperCase() : 'XXX'}-${Date.now()}`;
      
      const monitoringFormData = {
        ...formData,
        formId,
        status: 'Submitted',
        updatedAt: serverTimestamp(),
        userId: currentUser?.uid || '',
        userEmail: currentUser?.email || '',
        userName: userProfile ? `${userProfile.firstName || ''} ${userProfile.lastName || ''}`.trim() : '',
        userRole: userProfile?.userRole || '',
        userInstitution: userProfile?.institutionName || '',
        submittedAt: serverTimestamp()
      };
      
      // Check if we're updating an existing form or creating a new one
      if (monitoringFormData.docId) {
        // Update existing document
        const formRef = doc(db, "monitoringForms", monitoringFormData.docId);
        
        // Remove docId from the data before updating
        const { docId, ...dataToUpdate } = monitoringFormData;
        
        await updateDoc(formRef, dataToUpdate);
      } else {
        // Create new document
        const formsRef = collection(db, "monitoringForms");
        const docRef = await addDoc(formsRef, monitoringFormData);
        
        // Update the form data with the new document ID
        setFormData(prev => ({
          ...prev,
          docId: docRef.id
        }));
      }
      
      // Delete the local draft after successful submission
      if (formData.draftId) {
        deleteDraft(formData.draftId);
      }
      
      setSuccess('Form submitted successfully');
      alert('Form submitted successfully');
      
      // Navigate away after successful submission
      setTimeout(() => {
        navigate('/monitoring');  // Adjust this path if needed
      }, 1500);
    } catch (error) {
      console.error('Error saving form:', error);
      setError(`Failed to ${isDraft ? 'save' : 'submit'} form: ${error.message || 'Unknown error'}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Load a saved form from Firestore
  const loadSavedForm = async (formId) => {
    try {
      setIsLoading(true);
      setError('');
      
      const formRef = doc(db, "monitoringForms", formId);
      const formDoc = await getDoc(formRef);
      
      if (formDoc.exists()) {
        const formData = formDoc.data();
        setFormData({
          ...formData,
          docId: formId
        });
        setSuccess('Form loaded successfully');
      } else {
        setError('Form not found');
      }
    } catch (error) {
      console.error('Error loading form:', error);
      setError('Failed to load form: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  const handleChange = (section, field, value) => {
    if (section) {
      setFormData({
        ...formData,
        [section]: {
          ...formData[section],
          [field]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [field]: value
      });
    }
  };
  
  const handleNestedChange = (section, subsection, field, value) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [subsection]: {
          ...formData[section][subsection],
          [field]: value
        }
      }
    });
  };
  
  // Helper function to calculate form completion percentage
  const calculateCompletion = () => {
    // This would be a more complex calculation in real implementation
    return 65;
  };
  
  // Extended helper functions for the administration section
// Place these in your component

// Calculate the administration section score (percentage of maximum possible points)
const calculateAdminScore = () => {
  const adminSection = formData.administration;
  let totalPoints = 0;
  let possiblePoints = 0;
  
  // Helper to process each field
  const processField = (value) => {
    if (value === '0' || value === '1' || value === '2') {
      totalPoints += parseInt(value);
      possiblePoints += 2; // Maximum possible points per field is 2
    }
  };
  
  // Process all fields
  processField(adminSection.timeBook.available);
  processField(adminSection.timeBook.controlled);
  processField(adminSection.leaveRegister.available);
  processField(adminSection.leaveRegister.controlled);
  processField(adminSection.learnerAttendanceRegisters.available);
  processField(adminSection.learnerAttendanceRegisters.controlled);
  processField(adminSection.learnerLeaveRegister.available);
  processField(adminSection.logBook.available);
  processField(adminSection.logBook.controlled);
  processField(adminSection.admissionBook.available);
  processField(adminSection.admissionBook.controlled);
  processField(adminSection.telephoneRegister.available);
  processField(adminSection.telephoneRegister.controlled);
  processField(adminSection.mailRegister.available);
  processField(adminSection.mailRegister.controlled);
  processField(adminSection.saSams.implemented);
  processField(adminSection.saSams.upToDate);
  
  // If no fields have been filled, return 0
  if (possiblePoints === 0) return 0;
  
  // Return percentage score
  return Math.round((totalPoints / possiblePoints) * 100);
};

// Calculate the percentage of fields completed in the administration section
const calculateAdminProgress = () => {
  const adminSection = formData.administration;
  let filledFields = 0;
  const totalFields = 17; // Total number of fields in administration section
  
  // Helper to check if a field has been filled
  const checkField = (value) => {
    if (value === '0' || value === '1' || value === '2') {
      filledFields += 1;
    }
  };
  
  // Check all fields
  checkField(adminSection.timeBook.available);
  checkField(adminSection.timeBook.controlled);
  checkField(adminSection.leaveRegister.available);
  checkField(adminSection.leaveRegister.controlled);
  checkField(adminSection.learnerAttendanceRegisters.available);
  checkField(adminSection.learnerAttendanceRegisters.controlled);
  checkField(adminSection.learnerLeaveRegister.available);
  checkField(adminSection.logBook.available);
  checkField(adminSection.logBook.controlled);
  checkField(adminSection.admissionBook.available);
  checkField(adminSection.admissionBook.controlled);
  checkField(adminSection.telephoneRegister.available);
  checkField(adminSection.telephoneRegister.controlled);
  checkField(adminSection.mailRegister.available);
  checkField(adminSection.mailRegister.controlled);
  checkField(adminSection.saSams.implemented);
  checkField(adminSection.saSams.upToDate);
  
  // Return percentage of completed fields
  return Math.round((filledFields / totalFields) * 100);
};

// Get the number of completed fields
const calculateAdminFieldsCompleted = () => {
  const adminSection = formData.administration;
  let filledFields = 0;
  
  // Helper to check if a field has been filled
  const checkField = (value) => {
    if (value === '0' || value === '1' || value === '2') {
      filledFields += 1;
    }
  };
  
  // Check all fields
  checkField(adminSection.timeBook.available);
  checkField(adminSection.timeBook.controlled);
  checkField(adminSection.leaveRegister.available);
  checkField(adminSection.leaveRegister.controlled);
  checkField(adminSection.learnerAttendanceRegisters.available);
  checkField(adminSection.learnerAttendanceRegisters.controlled);
  checkField(adminSection.learnerLeaveRegister.available);
  checkField(adminSection.logBook.available);
  checkField(adminSection.logBook.controlled);
  checkField(adminSection.admissionBook.available);
  checkField(adminSection.admissionBook.controlled);
  checkField(adminSection.telephoneRegister.available);
  checkField(adminSection.telephoneRegister.controlled);
  checkField(adminSection.mailRegister.available);
  checkField(adminSection.mailRegister.controlled);
  checkField(adminSection.saSams.implemented);
  checkField(adminSection.saSams.upToDate);
  
  return filledFields;
};

// Count fields with a specific score
const calculateAdminFieldsByScore = (scoreValue) => {
  const adminSection = formData.administration;
  let fieldCount = 0;
  
  // Helper to check field value
  const checkField = (value) => {
    if (value === scoreValue.toString()) {
      fieldCount += 1;
    }
  };
  
  // Check all fields
  checkField(adminSection.timeBook.available);
  checkField(adminSection.timeBook.controlled);
  checkField(adminSection.leaveRegister.available);
  checkField(adminSection.leaveRegister.controlled);
  checkField(adminSection.learnerAttendanceRegisters.available);
  checkField(adminSection.learnerAttendanceRegisters.controlled);
  checkField(adminSection.learnerLeaveRegister.available);
  checkField(adminSection.logBook.available);
  checkField(adminSection.logBook.controlled);
  checkField(adminSection.admissionBook.available);
  checkField(adminSection.admissionBook.controlled);
  checkField(adminSection.telephoneRegister.available);
  checkField(adminSection.telephoneRegister.controlled);
  checkField(adminSection.mailRegister.available);
  checkField(adminSection.mailRegister.controlled);
  checkField(adminSection.saSams.implemented);
  checkField(adminSection.saSams.upToDate);
  
  return fieldCount;
};

// Determine if action is required based on scores
const calculateAdminActionRequired = () => {
  // If more than 20% of completed fields have a score of 0, or
  // if the overall score is less than 60%, action is required
  const fieldsWithZero = calculateAdminFieldsByScore(0);
  const completedFields = calculateAdminFieldsCompleted();
  const overallScore = calculateAdminScore();
  
  if (completedFields === 0) return false;
  
  return (fieldsWithZero / completedFields) > 0.2 || overallScore < 60;
};

const handleBackNavigation = () => {
  // Check if there are unsaved changes
  const hasUnsavedChanges = true; // You can implement your own logic to determine this
  
  if (hasUnsavedChanges) {
    // Prompt the user before leaving if there are unsaved changes
    const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave this page?');
    if (!confirmLeave) return;
  }
  
  // Navigate back to the monitoring dashboard
  navigate('/monitoring'); // Update this path to match your actual route
};

const renderDraftsModal = () => {
  if (!showDraftsModal) return null;
  
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-md mx-auto">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Available Drafts</h3>
        
        {availableDrafts.length > 0 ? (
          <div className="max-h-60 overflow-y-auto mb-4">
            {availableDrafts.map(draft => (
              <div key={draft.id} className="border-b last:border-b-0 py-2">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-medium">{draft.label}</p>
                    <p className="text-xs text-gray-500">
                      Last edited: {new Date(draft.createdAt).toLocaleString()}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => loadDraft(draft.id)}
                      className="text-blue-600 hover:text-blue-800 text-sm"
                    >
                      Load
                    </button>
                    <button
                      onClick={() => deleteDraft(draft.id)}
                      className="text-red-600 hover:text-red-800 text-sm"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 mb-4">No saved drafts found.</p>
        )}
        
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => setShowDraftsModal(false)}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <button 
            className="mr-4 inline-flex items-center text-blue-600"
            onClick={handleBackNavigation}
          >
            <ArrowLeft className="h-4 w-4 mr-1" />
            Back to Monitoring Tools
          </button>
          <h3 className="text-2xl font-bold text-gray-350">School Evaluation Form</h3>
        </div>
        <div className="flex space-x-2">
          <button 
            onClick={() => saveForm(true)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <Save className="h-4 w-4 mr-2" />
            Save Draft
          </button>
          <button 
            onClick={() => saveForm(false)}
            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md shadow-sm text-sm font-medium hover:bg-blue-700"
          >
            <Send className="h-4 w-4 mr-2" />
            Submit Form
          </button>
        </div>
      </div>
      
      {/* Form Progress Card */}
      <Card className="mb-6">
        <CardContent className="p-4">
          <div className="flex flex-col md:flex-row md:items-center justify-between">
            <div className="mb-4 md:mb-0">
              <h2 className="text-lg font-semibold">Form Completion</h2>
              <div className="flex items-center mt-1">
                <div className="w-full md:w-64 bg-gray-200 rounded-full h-2.5 mr-2">
                  <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${calculateCompletion()}%` }}></div>
                </div>
                <span className="text-sm font-medium">{calculateCompletion()}%</span>
              </div>
            </div>
            <div className="flex flex-col text-sm text-gray-600">
              <div className="flex items-center mb-1">
                <Calendar className="h-4 w-4 mr-2 text-blue-500" />
                <span>School Visit Date: {formData.visitDate || 'Not set'}</span>
              </div>
              <div className="flex items-center">
                <Info className="h-4 w-4 mr-2 text-blue-500" />
                <span>Form ID: CM-25-Q1-{formData.school ? formData.school.substring(0, 3).toUpperCase() : 'XXX'}</span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      
      {/* School Information Card */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>School Information</CardTitle>
          <CardDescription>Enter basic school and visit information</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Quarter</label>
              <select 
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.quarter}
                onChange={(e) => handleChange(null, 'quarter', e.target.value)}
              >
                <option value="Q1">First Quarter</option>
                <option value="Q2">Second Quarter</option>
                <option value="Q3">Third Quarter</option>
                <option value="Q4">Fourth Quarter</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Year</label>
              <input 
                type="text" 
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.year}
                onChange={(e) => handleChange(null, 'year', e.target.value)}
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Visit Date</label>
              <input 
                type="date" 
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.visitDate}
                onChange={(e) => handleChange(null, 'visitDate', e.target.value)}
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">District</label>
              <select 
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.district}
                onChange={(e) => handleChange(null, 'district', e.target.value)}
              >
                <option value="">Select District</option>
                <option value="Amajuba District">Amajuba District</option>
                <option value="Umlazi District">Umlazi District</option>
                <option value="Pinetown District">Pinetown District</option>
                <option value="Ilembe District">Ilembe District</option>
                <option value="King Cetshwayo District">King Cetshwayo District</option>
                <option value="Zululand District">Zululand District</option>
                <option value="uThukela District">uThukela District</option>
                <option value="Mzinyathi District">Mzinyathi District</option>
                <option value="Ugu District">ugu District</option>
                <option value="Harry Gwala District">Harry Gwala District</option>

                
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Circuit</label>
              <select 
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.circuit}
                onChange={(e) => handleChange(null, 'circuit', e.target.value)}
              >
                <option value="">Select Circuit</option>
                <option value="Mxenge Circuit">Mxenge Circuit</option>
                <option value="Phumelela Circuit">Phumelela Circuit</option>
                <option value="Ndwedwe Circuit">Ndwedwe Circuit</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">School</label>
              <select 
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.school}
                onChange={(e) => handleChange(null, 'school', e.target.value)}
              >
                <option value="">Select School</option>
                <option value="Lamontville High School">Lamontville High School</option>
                <option value="Swelihle Secondary School">Swelihle Secondary School</option>
                <option value="Isikhumbuzo Primary School">Isikhumbuzo Primary School</option>
              </select>
            </div>
          </div>
          
          <div className="mt-6">
            <h3 className="text-md font-medium mb-2">Staff Composition</h3>
            <div className="grid grid-cols-2 md:grid-cols-6 gap-3">
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">PPN</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.ppn}
                  onChange={(e) => handleChange(null, 'ppn', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">Principal</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.principal}
                  onChange={(e) => handleChange(null, 'principal', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">DP</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.dp}
                  onChange={(e) => handleChange(null, 'dp', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">HOD</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.hod}
                  onChange={(e) => handleChange(null, 'hod', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">Level 1</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.level1}
                  onChange={(e) => handleChange(null, 'level1', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">Temp Educator</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.temporalEducator}
                  onChange={(e) => handleChange(null, 'temporalEducator', e.target.value)}
                />
              </div>
            </div>
            
            <div className="grid grid-cols-2 md:grid-cols-6 gap-3 mt-3">
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">CTT</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.ctt}
                  onChange={(e) => handleChange(null, 'ctt', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">Substitute</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.substitute}
                  onChange={(e) => handleChange(null, 'substitute', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">Displaced</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.displacedEducator}
                  onChange={(e) => handleChange(null, 'displacedEducator', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">SGB Paid</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.sgbPaid}
                  onChange={(e) => handleChange(null, 'sgbPaid', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">Clerk</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.clerk}
                  onChange={(e) => handleChange(null, 'clerk', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">Security</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.security}
                  onChange={(e) => handleChange(null, 'security', e.target.value)}
                />
              </div>
            </div>
            
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mt-3">
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">Cleaner</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.cleaner}
                  onChange={(e) => handleChange(null, 'cleaner', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">Grade R</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.gradeR}
                  onChange={(e) => handleChange(null, 'gradeR', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">ECD Post</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.ecdPost}
                  onChange={(e) => handleChange(null, 'ecdPost', e.target.value)}
                />
              </div>
              <div>
                <label className="block text-xs font-medium text-gray-700 mb-1">Teacher Assistant</label>
                <input 
                  type="text" 
                  className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.teacherAssistant}
                  onChange={(e) => handleChange(null, 'teacherAssistant', e.target.value)}
                />
              </div>
            </div>
          </div>
          
          {/* Learner Attendance Section */}
          <div className="mt-6">
            <h3 className="text-md font-medium mb-2">Learner Attendance</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    {Object.keys(formData.attendance).map((grade) => (
                      <th key={grade} className="px-2 py-1 text-xs font-medium text-gray-500 uppercase">
                        {grade.toUpperCase()}
                      </th>
                    ))}
                    <th className="px-2 py-1 text-xs font-medium text-gray-500 uppercase">TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(formData.attendance).map((grade) => (
                      <td key={grade} className="px-1 py-1">
                        <input 
                          type="number" 
                          className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                          value={formData.attendance[grade]}
                          onChange={(e) => {
                            // Ensure we handle numeric input properly
                            const value = e.target.value;
                            // Create a new attendance object with the updated value
                            const updatedAttendance = {
                              ...formData.attendance,
                              [grade]: value
                            };
                            // Update the overall form data
                            setFormData({
                              ...formData,
                              attendance: updatedAttendance
                            });
                          }}
                          min="0" // Prevent negative numbers
                          onKeyPress={(e) => {
                            // Allow only numbers and control keys
                            if (!/[\d\s\b]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </td>
                    ))}
                    <td className="px-1 py-1">
                      <div className="w-full px-2 py-1 bg-gray-100 rounded-md text-sm font-medium">
                        {Object.values(formData.attendance).reduce((sum, val) => {
                          const num = parseInt(val) || 0;
                          return sum + num;
                        }, 0)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </CardContent>
      </Card>
      
      {/* Form Sections Tabs */}
      <Tabs value={activeTab} defaultValue="administration" className="w-full">
        <TabsList className="grid grid-cols-2 md:grid-cols-7 mb-6">
          <TabsTrigger 
            value="administration" 
            activeValue={activeTab} 
            onClick={handleTabChange}
          >
            Administration
          </TabsTrigger>
          <TabsTrigger 
            value="policies" 
            activeValue={activeTab} 
            onClick={handleTabChange}
          >
            Policies
          </TabsTrigger>
          <TabsTrigger 
            value="curriculum" 
            activeValue={activeTab} 
            onClick={handleTabChange}
          >
            Curriculum
          </TabsTrigger>
          <TabsTrigger 
            value="infrastructure" 
            activeValue={activeTab} 
            onClick={handleTabChange}
          >
            Infrastructure
          </TabsTrigger>
          <TabsTrigger 
            value="finances" 
            activeValue={activeTab} 
            onClick={handleTabChange}
          >
            Finances
          </TabsTrigger>
          <TabsTrigger 
            value="nutrition" 
            activeValue={activeTab} 
            onClick={handleTabChange}
          >
            Nutrition
          </TabsTrigger>
          <TabsTrigger 
            value="campaigns" 
            activeValue={activeTab} 
            onClick={handleTabChange}
          >
            Campaigns
          </TabsTrigger>
        </TabsList>
        
        <TabsContent value="administration" activeValue={activeTab}>
          <Card>
            <CardHeader>
              <div className="flex justify-between items-center">
                <div>
                  <CardTitle>Administration</CardTitle>
                  <CardDescription>Check administrative documents and processes</CardDescription>
                </div>
                
                {/* Section Scorecard */}
                <div className="bg-white border border-gray-200 rounded-lg shadow-sm px-4 py-2">
                  <div className="text-xs text-gray-500 font-medium">Section Score</div>
                  <div className="flex items-center">
                    <span className="text-2xl font-bold text-blue-600">{calculateAdminScore()}</span>
                    <span className="text-lg font-medium text-gray-600">%</span>
                  </div>
                </div>
              </div>
              
              {/* Add legend for the numeric values */}
              <div className="mt-3 p-2 bg-blue-50 rounded-md">
                <p className="text-sm font-medium text-blue-800">Scoring Legend:</p>
                <div className="grid grid-cols-3 gap-2 mt-1">
                  <div className="text-sm flex items-center">
                    <span className="inline-block w-5 h-5 bg-red-100 text-red-600 rounded flex items-center justify-center font-semibold text-xs mr-1.5">0</span>
                    <span>= No</span>
                  </div>
                  <div className="text-sm flex items-center">
                    <span className="inline-block w-5 h-5 bg-yellow-100 text-yellow-600 rounded flex items-center justify-center font-semibold text-xs mr-1.5">1</span>
                    <span>= Yes</span>
                  </div>
                  <div className="text-sm flex items-center">
                    <span className="inline-block w-5 h-5 bg-green-100 text-green-600 rounded flex items-center justify-center font-semibold text-xs mr-1.5">2</span>
                    <span>= Yes and Good</span>
                  </div>
                </div>
              </div>
            </CardHeader>
            
            <CardContent className="space-y-4">
              {/* Row 1: Time Book, Leave Register, Learner Attendance Registers */}
              <div className="grid grid-cols-1 md:grid-cols-3 bg-gray-100 gap-4">
                <div>
                  <label className="pl-2 py-2 block text-sm font-medium text-blue-700 mb-1">Time Book</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center">
                      <label className="inline-flex items-center mr-4">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Available?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm" 
                          value={formData.administration.timeBook.available}
                          onChange={(e) => handleNestedChange('administration', 'timeBook', 'available', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator based on selected value */}
                      {formData.administration.timeBook.available && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.timeBook.available === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.timeBook.available === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.timeBook.available}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <label className="inline-flex items-center">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Controlled?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm"
                          value={formData.administration.timeBook.controlled}
                          onChange={(e) => handleNestedChange('administration', 'timeBook', 'controlled', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator based on selected value */}
                      {formData.administration.timeBook.controlled && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.timeBook.controlled === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.timeBook.controlled === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.timeBook.controlled}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                
                <div>
                  <label className="pl-2 py-2 block text-sm font-medium text-blue-700 mb-1">Leave Register</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center">
                      <label className="inline-flex items-center mr-4">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Available?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm" 
                          value={formData.administration.leaveRegister.available}
                          onChange={(e) => handleNestedChange('administration', 'leaveRegister', 'available', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.leaveRegister.available && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.leaveRegister.available === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.leaveRegister.available === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.leaveRegister.available}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <label className="inline-flex items-center">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Controlled?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm"
                          value={formData.administration.leaveRegister.controlled}
                          onChange={(e) => handleNestedChange('administration', 'leaveRegister', 'controlled', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.leaveRegister.controlled && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.leaveRegister.controlled === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.leaveRegister.controlled === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.leaveRegister.controlled}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                
                <div>
                  <label className="bl-white pl-2 py-2 block text-sm font-medium text-blue-700 mb-1">Learner Attendance Registers</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center">
                      <label className="inline-flex items-center mr-4">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Available?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm" 
                          value={formData.administration.learnerAttendanceRegisters.available}
                          onChange={(e) => handleNestedChange('administration', 'learnerAttendanceRegisters', 'available', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.learnerAttendanceRegisters.available && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.learnerAttendanceRegisters.available === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.learnerAttendanceRegisters.available === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.learnerAttendanceRegisters.available}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <label className="inline-flex items-center">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Controlled?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm"
                          value={formData.administration.learnerAttendanceRegisters.controlled}
                          onChange={(e) => handleNestedChange('administration', 'learnerAttendanceRegisters', 'controlled', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.learnerAttendanceRegisters.controlled && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.learnerAttendanceRegisters.controlled === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.learnerAttendanceRegisters.controlled === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.learnerAttendanceRegisters.controlled}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Row 2: Learner Leave Register, Log Book, Admission Book */}
              <div className="grid grid-cols-1 md:grid-cols-3 bg-gray-100 gap-4">
                <div>
                  <label className="pl-2 py-2 block text-sm font-medium text-gray-700 mb-1">Learner Leave Register</label>
                  <div className="flex items-center">
                    <label className="inline-flex items-center mr-4">
                      <span className="pl-2 text-xs text-gray-700 mr-1">Available?</span>
                      <select 
                        className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm" 
                        value={formData.administration.learnerLeaveRegister.available}
                        onChange={(e) => handleNestedChange('administration', 'learnerLeaveRegister', 'available', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="0" className="bg-red-50 text-red-700">0</option>
                        <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                        <option value="2" className="bg-green-50 text-green-700">2</option>
                      </select>
                    </label>
                    {/* Color indicator for this field */}
                    {formData.administration.learnerLeaveRegister.available && (
                      <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                        ${formData.administration.learnerLeaveRegister.available === '0' ? 'bg-red-100 text-red-600' : 
                          formData.administration.learnerLeaveRegister.available === '1' ? 'bg-yellow-100 text-yellow-600' : 
                          'bg-green-100 text-green-600'}`}>
                        {formData.administration.learnerLeaveRegister.available}
                      </span>
                    )}
                  </div>
                </div>
                
                <div>
                  <label className="pl-2 py-2 block text-sm font-medium text-gray-700 mb-1">Log Book</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center">
                      <label className="inline-flex items-center mr-4">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Available?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm" 
                          value={formData.administration.logBook.available}
                          onChange={(e) => handleNestedChange('administration', 'logBook', 'available', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.logBook.available && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.logBook.available === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.logBook.available === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.logBook.available}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <label className="inline-flex items-center">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Controlled?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm"
                          value={formData.administration.logBook.controlled}
                          onChange={(e) => handleNestedChange('administration', 'logBook', 'controlled', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.logBook.controlled && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.logBook.controlled === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.logBook.controlled === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.logBook.controlled}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                
                <div>
                  <label className="pl-2 py-2 block text-sm font-medium text-gray-700 mb-1">Admission Book</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center">
                      <label className="inline-flex items-center mr-4">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Available?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm" 
                          value={formData.administration.admissionBook.available}
                          onChange={(e) => handleNestedChange('administration', 'admissionBook', 'available', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.admissionBook.available && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.admissionBook.available === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.admissionBook.available === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.admissionBook.available}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <label className="inline-flex items-center">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Controlled?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm"
                          value={formData.administration.admissionBook.controlled}
                          onChange={(e) => handleNestedChange('administration', 'admissionBook', 'controlled', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.admissionBook.controlled && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.admissionBook.controlled === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.admissionBook.controlled === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.admissionBook.controlled}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Row 3: Telephone Register, Mail Register, SA-SAMS */}
              <div className="grid grid-cols-1 md:grid-cols-3 bg-gray-100 gap-4">
                <div>
                  <label className="pl-2 py-2 block text-sm font-medium text-gray-700 mb-1">Telephone Register</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center">
                      <label className="inline-flex items-center mr-4">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Available?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm" 
                          value={formData.administration.telephoneRegister.available}
                          onChange={(e) => handleNestedChange('administration', 'telephoneRegister', 'available', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.telephoneRegister.available && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.telephoneRegister.available === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.telephoneRegister.available === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.telephoneRegister.available}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <label className="inline-flex items-center">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Controlled?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm"
                          value={formData.administration.telephoneRegister.controlled}
                          onChange={(e) => handleNestedChange('administration', 'telephoneRegister', 'controlled', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.telephoneRegister.controlled && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.telephoneRegister.controlled === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.telephoneRegister.controlled === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.telephoneRegister.controlled}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                
                <div>
                  <label className="pl-2 py-2 block text-sm font-medium text-gray-700 mb-1">Mail Register</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center">
                      <label className="inline-flex items-center mr-4">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Available?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm" 
                          value={formData.administration.mailRegister.available}
                          onChange={(e) => handleNestedChange('administration', 'mailRegister', 'available', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.mailRegister.available && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.mailRegister.available === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.mailRegister.available === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.mailRegister.available}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <label className="inline-flex items-center">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Controlled?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm"
                          value={formData.administration.mailRegister.controlled}
                          onChange={(e) => handleNestedChange('administration', 'mailRegister', 'controlled', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.mailRegister.controlled && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.mailRegister.controlled === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.mailRegister.controlled === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.mailRegister.controlled}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                
                <div>
                  <label className="pl-2 py-2 block text-sm font-medium text-gray-700 mb-1">SA-SAMS</label>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center">
                      <label className="inline-flex items-center mr-4">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Implemented?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm" 
                          value={formData.administration.saSams.implemented}
                          onChange={(e) => handleNestedChange('administration', 'saSams', 'implemented', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.saSams.implemented && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.saSams.implemented === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.saSams.implemented === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.saSams.implemented}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <label className="inline-flex items-center">
                        <span className="pl-2 text-xs text-gray-700 mr-1">Up to date?</span>
                        <select 
                          className="ml-1 border border-gray-300 rounded-md shadow-sm text-sm"
                          value={formData.administration.saSams.upToDate}
                          onChange={(e) => handleNestedChange('administration', 'saSams', 'upToDate', e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="0" className="bg-red-50 text-red-700">0</option>
                          <option value="1" className="bg-yellow-50 text-yellow-700">1</option>
                          <option value="2" className="bg-green-50 text-green-700">2</option>
                        </select>
                      </label>
                      {/* Color indicator for this field */}
                      {formData.administration.saSams.upToDate && (
                        <span className={`inline-block w-5 h-5 rounded-full flex items-center justify-center text-xs font-medium
                          ${formData.administration.saSams.upToDate === '0' ? 'bg-red-100 text-red-600' : 
                            formData.administration.saSams.upToDate === '1' ? 'bg-yellow-100 text-yellow-600' : 
                            'bg-green-100 text-green-600'}`}>
                          {formData.administration.saSams.upToDate}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Section Summary */}
              <div className="mt-6 bg-gray-50 p-4 rounded-lg border border-gray-200">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="pl-2 text-sm font-medium text-gray-700">Section Summary</h3>
                  <div className="text-sm text-gray-500">{calculateAdminProgress()}% Complete</div>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div 
                    className="bg-blue-600 h-2 rounded-full" 
                    style={{ width: `${calculateAdminProgress()}%` }}
                  ></div>
                </div>
              </div>
              
              {/* Section Analytics */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                <div className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm">
                  <div className="text-xs font-medium text-gray-500 mb-1">Average Score</div>
                  <div className="text-xl font-bold">{calculateAdminScore() / 2}/10</div>
                  <div className="mt-2 text-xs text-gray-500">Based on {calculateAdminFieldsCompleted()} of 17 fields</div>
                </div>
                
                <div className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm">
                  <div className="text-xs font-medium text-gray-500 mb-1">Fields by Score</div>
                  <div className="flex items-center space-x-2 mt-2">
                    <div className="flex items-center">
                      <span className="inline-block w-3 h-3 bg-red-100 rounded-full mr-1"></span>
                      <span className="text-xs">{calculateAdminFieldsByScore(0)}</span>
                    </div>
                    <div className="flex items-center">
                      <span className="inline-block w-3 h-3 bg-yellow-100 rounded-full mr-1"></span>
                      <span className="text-xs">{calculateAdminFieldsByScore(1)}</span>
                    </div>
                    <div className="flex items-center">
                      <span className="inline-block w-3 h-3 bg-green-100 rounded-full mr-1"></span>
                      <span className="text-xs">{calculateAdminFieldsByScore(2)}</span>
                    </div>
                  </div>
                </div>
                
                <div className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm">
                  <div className="text-xs font-medium text-gray-500 mb-1">Action Required</div>
                  <div className="text-sm mt-1">
                    {calculateAdminActionRequired() ? (
                      <span className="flex items-center text-amber-600">
                        <AlertTriangle size={14} className="mr-1" />
                        Needs improvement
                      </span>
                    ) : (
                      <span className="flex items-center text-green-600">
                        <CheckCircle size={14} className="mr-1" />
                        Satisfactory
                      </span>
                    )}
                  </div>
                </div>
              </div>
              
              {/* Comments and Actions */}
              <div className="mt-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Comments
                </label>
                <textarea 
                  rows={3}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.administration.comments}
                  onChange={(e) => handleChange('administration', 'comments', e.target.value)}
                  placeholder="Add any comments about administrative issues..."
                ></textarea>
              </div>
              
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Recommended Actions
                </label>
                <textarea 
                  rows={3}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.administration.actions}
                  onChange={(e) => handleChange('administration', 'actions', e.target.value)}
                  placeholder="List any recommended actions to address administrative issues..."
                ></textarea>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        {/* Policies Tab */}
        <TabsContent value="policies" activeValue={activeTab}>
          <Card>
            <CardHeader>
              <CardTitle>Policies</CardTitle>
              <CardDescription>Review school policies and documentation</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    School Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.schoolPolicy}
                    onChange={(e) => handleChange('policies', 'schoolPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Admission Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.admissionPolicy}
                    onChange={(e) => handleChange('policies', 'admissionPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Language Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.languagePolicy}
                    onChange={(e) => handleChange('policies', 'languagePolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Religious Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.religiousPolicy}
                    onChange={(e) => handleChange('policies', 'religiousPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Assessment Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.assessmentPolicy}
                    onChange={(e) => handleChange('policies', 'assessmentPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Finance Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.financePolicy}
                    onChange={(e) => handleChange('policies', 'financePolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Supply Chain Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.supplyChainPolicy}
                    onChange={(e) => handleChange('policies', 'supplyChainPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    HIV/AIDS Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.hivAidsPolicy}
                    onChange={(e) => handleChange('policies', 'hivAidsPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Safety & Security Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.safetySecurityPolicy}
                    onChange={(e) => handleChange('policies', 'safetySecurityPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Nutrition Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.nutritionPolicy}
                    onChange={(e) => handleChange('policies', 'nutritionPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Leave Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.leavePolicy}
                    onChange={(e) => handleChange('policies', 'leavePolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Fees Exemption Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.feesExemptionPolicy}
                    onChange={(e) => handleChange('policies', 'feesExemptionPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                    <option value="Not Applicable">Not Applicable</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Learner Code of Conduct
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.learnerConductCode}
                    onChange={(e) => handleChange('policies', 'learnerConductCode', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    SGB Code of Conduct
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.sgbConductCode}
                    onChange={(e) => handleChange('policies', 'sgbConductCode', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Educator Code of Conduct
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.educatorConductCode}
                    onChange={(e) => handleChange('policies', 'educatorConductCode', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Class Management Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.classManagementPolicy}
                    onChange={(e) => handleChange('policies', 'classManagementPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    LTSM Retrieval Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.ltsmRetrievalPolicy}
                    onChange={(e) => handleChange('policies', 'ltsmRetrievalPolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Pastoral Care Policy
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.policies.pastoralCarePolicy}
                    onChange={(e) => handleChange('policies', 'pastoralCarePolicy', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="In Development">In Development</option>
                  </select>
                </div>
              </div>
              
              <div className="mt-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Comments
                </label>
                <textarea 
                  rows={3}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.policies.comments}
                  onChange={(e) => handleChange('policies', 'comments', e.target.value)}
                  placeholder="Add any comments about policy issues..."
                ></textarea>
              </div>
              
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Recommended Actions
                </label>
                <textarea 
                  rows={3}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.policies.actions}
                  onChange={(e) => handleChange('policies', 'actions', e.target.value)}
                  placeholder="List any recommended actions regarding policies..."
                ></textarea>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        {/* Curriculum Tab */}
        <TabsContent value="curriculum" activeValue={activeTab}>
          <Card>
            <CardHeader>
              <CardTitle>Curriculum Management</CardTitle>
              <CardDescription>Evaluate curriculum implementation and management</CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Is the school properly staffed?
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.properlyStaffed}
                    onChange={(e) => handleChange('curriculum', 'properlyStaffed', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Partially">Partially</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    If not properly staffed, reasons
                  </label>
                  <input 
                    type="text" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.staffingReasons}
                    onChange={(e) => handleChange('curriculum', 'staffingReasons', e.target.value)}
                    placeholder="Explain the staffing challenges"
                  />
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Staffing actions to be taken
                </label>
                <textarea 
                  rows={2}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.curriculum.staffingActions}
                  onChange={(e) => handleChange('curriculum', 'staffingActions', e.target.value)}
                  placeholder="List actions needed to address staffing issues..."
                ></textarea>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Subject Packages
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.packages}
                    onChange={(e) => handleChange('curriculum', 'packages', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Complete">Complete</option>
                    <option value="Incomplete">Incomplete</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Deviations from approved packages
                  </label>
                  <input 
                    type="text" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.packageDeviations}
                    onChange={(e) => handleChange('curriculum', 'packageDeviations', e.target.value)}
                  />
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Circular 41 Implementation
                </label>
                <select 
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.curriculum.circular41Implementation}
                  onChange={(e) => handleChange('curriculum', 'circular41Implementation', e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="Fully Implemented">Fully Implemented</option>
                  <option value="Partially Implemented">Partially Implemented</option>
                  <option value="Not Implemented">Not Implemented</option>
                </select>
              </div>
              
              <div>
                <h3 className="text-md font-medium text-gray-700 mb-2">Management Books</h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Communication
                    </label>
                    <select 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.managementBooks.communication}
                      onChange={(e) => handleNestedChange('curriculum', 'managementBooks', 'communication', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Control
                    </label>
                    <select 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.managementBooks.control}
                      onChange={(e) => handleNestedChange('curriculum', 'managementBooks', 'control', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Departmental File
                    </label>
                    <select 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.managementBooks.departmentalFile}
                      onChange={(e) => handleNestedChange('curriculum', 'managementBooks', 'departmentalFile', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Minute Books
                    </label>
                    <select 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.managementBooks.minuteBooks}
                      onChange={(e) => handleNestedChange('curriculum', 'managementBooks', 'minuteBooks', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                    </select>
                  </div>
                </div>
              </div>
              
              <div>
                <h3 className="text-md font-medium text-gray-700 mb-2">Time Tables</h3>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Composite
                    </label>
                    <select 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.timeTables.composite}
                      onChange={(e) => handleNestedChange('curriculum', 'timeTables', 'composite', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Personal
                    </label>
                    <select 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.timeTables.personal}
                      onChange={(e) => handleNestedChange('curriculum', 'timeTables', 'personal', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Class
                    </label>
                    <select 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.timeTables.class}
                      onChange={(e) => handleNestedChange('curriculum', 'timeTables', 'class', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Relief
                    </label>
                    <select 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.timeTables.relief}
                      onChange={(e) => handleNestedChange('curriculum', 'timeTables', 'relief', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Ground Duty
                    </label>
                    <select 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.timeTables.groundDuty}
                      onChange={(e) => handleNestedChange('curriculum', 'timeTables', 'groundDuty', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Available">Available</option>
                      <option value="Not Available">Not Available</option>
                    </select>
                  </div>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Period Registers
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.periodRegisters}
                    onChange={(e) => handleChange('curriculum', 'periodRegisters', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Available">Available</option>
                    <option value="Not Available">Not Available</option>
                    <option value="Partially Available">Partially Available</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    LTSM Distribution
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.ltsm.distributed}
                    onChange={(e) => handleNestedChange('curriculum', 'ltsm', 'distributed', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Complete">Complete</option>
                    <option value="Incomplete">Incomplete</option>
                    <option value="Not Distributed">Not Distributed</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    If incomplete, reasons
                  </label>
                  <input 
                    type="text" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.ltsm.notDistributedReason}
                    onChange={(e) => handleNestedChange('curriculum', 'ltsm', 'notDistributedReason', e.target.value)}
                  />
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Control of work by teachers
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.controlOfWork.byTeachers}
                    onChange={(e) => handleNestedChange('curriculum', 'controlOfWork', 'byTeachers', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Good">Good</option>
                    <option value="Average">Average</option>
                    <option value="Poor">Poor</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Control of work by SMT
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.controlOfWork.bySmt}
                    onChange={(e) => handleNestedChange('curriculum', 'controlOfWork', 'bySmt', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Good">Good</option>
                    <option value="Average">Average</option>
                    <option value="Poor">Poor</option>
                  </select>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Examination Management
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.examinationManagement}
                    onChange={(e) => handleChange('curriculum', 'examinationManagement', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Good">Good</option>
                    <option value="Average">Average</option>
                    <option value="Poor">Poor</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Syllabi Coverage
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.curriculum.syllabiCoverage}
                    onChange={(e) => handleChange('curriculum', 'syllabiCoverage', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Complete">Complete</option>
                    <option value="Behind Schedule">Behind Schedule</option>
                    <option value="Far Behind">Far Behind</option>
                  </select>
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Actions for Syllabi Coverage
                </label>
                <textarea 
                  rows={2}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                  value={formData.curriculum.syllabiActions}
                  onChange={(e) => handleChange('curriculum', 'syllabiActions', e.target.value)}
                  placeholder="Actions to address syllabi coverage issues..."
                ></textarea>
              </div>
              
              <div>
                <h3 className="text-md font-medium text-gray-700 mb-2">Performance Targets</h3>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Performance Targets Set
                    </label>
                    <select 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.performanceTargets.set}
                      onChange={(e) => handleNestedChange('curriculum', 'performanceTargets', 'set', e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Matric Pass % Target
                    </label>
                    <input 
                      type="number" 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.performanceTargets.matricPass}
                      onChange={(e) => handleNestedChange('curriculum', 'performanceTargets', 'matricPass', e.target.value)}
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Bachelor Passes % Target
                    </label>
                    <input 
                      type="number" 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formData.curriculum.performanceTargets.bachelorTarget}
                      onChange={(e) => handleNestedChange('curriculum', 'performanceTargets', 'bachelorTarget', e.target.value)}
                    />
                  </div>
                </div>
                
                <h4 className="text-sm font-medium text-gray-700 mb-2">Grade Performance Targets (%)</h4>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-2 py-1 text-xs font-medium text-gray-500 uppercase">Grade</th>
                        <th className="px-2 py-1 text-xs font-medium text-gray-500 uppercase">Previous Year</th>
                        <th className="px-2 py-1 text-xs font-medium text-gray-500 uppercase">Current Target</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(formData.curriculum.performanceTargets.grades).map((grade) => (
                        <tr key={grade}>
                          <td className="px-2 py-1 text-sm font-medium">{grade.toUpperCase()}</td>
                          <td className="px-2 py-1">
                            <input 
                              type="number" 
                              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                              value={formData.curriculum.performanceTargets.grades[grade].prev}
                              onChange={(e) => {
                                const updatedGrades = {
                                  ...formData.curriculum.performanceTargets.grades,
                                  [grade]: {
                                    ...formData.curriculum.performanceTargets.grades[grade],
                                    prev: e.target.value
                                  }
                                };
                                setFormData({
                                  ...formData,
                                  curriculum: {
                                    ...formData.curriculum,
                                    performanceTargets: {
                                      ...formData.curriculum.performanceTargets,
                                      grades: updatedGrades
                                    }
                                  }
                                });
                              }}
                            />
                          </td>
                          <td className="px-2 py-1">
                            <input 
                              type="number" 
                              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                              value={formData.curriculum.performanceTargets.grades[grade].cur}
                              onChange={(e) => {
                                const updatedGrades = {
                                  ...formData.curriculum.performanceTargets.grades,
                                  [grade]: {
                                    ...formData.curriculum.performanceTargets.grades[grade],
                                    cur: e.target.value
                                  }
                                };
                                setFormData({
                                  ...formData,
                                  curriculum: {
                                    ...formData.curriculum,
                                    performanceTargets: {
                                      ...formData.curriculum.performanceTargets,
                                      grades: updatedGrades
                                    }
                                  }
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        {/* Infrastructure Tab */}
        <TabsContent value="infrastructure" activeValue={activeTab}>
          <Card>
            <CardHeader>
              <CardTitle>Infrastructure</CardTitle>
              <CardDescription>Evaluate school facilities and infrastructure</CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <h3 className="text-md font-medium text-gray-700 mb-2">Classrooms</h3>
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Number</label>
                      <input 
                        type="number" 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.classrooms.number}
                        onChange={(e) => handleNestedChange('infrastructure', 'classrooms', 'number', e.target.value)}
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Condition</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.classrooms.condition}
                        onChange={(e) => handleNestedChange('infrastructure', 'classrooms', 'condition', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Good">Good</option>
                        <option value="Average">Average</option>
                        <option value="Poor">Poor</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                <div>
                  <h3 className="text-md font-medium text-gray-700 mb-2">Ablution Facilities</h3>
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Number</label>
                      <input 
                        type="number" 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.ablution.number}
                        onChange={(e) => handleNestedChange('infrastructure', 'ablution', 'number', e.target.value)}
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Condition</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.ablution.condition}
                        onChange={(e) => handleNestedChange('infrastructure', 'ablution', 'condition', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Good">Good</option>
                        <option value="Average">Average</option>
                        <option value="Poor">Poor</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <h3 className="text-md font-medium text-gray-700 mb-2">Water</h3>
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Available</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.water.available}
                        onChange={(e) => handleNestedChange('infrastructure', 'water', 'available', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Running Water</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.water.running}
                        onChange={(e) => handleNestedChange('infrastructure', 'water', 'running', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                <div>
                  <h3 className="text-md font-medium text-gray-700 mb-2">Over-enrolment</h3>
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.overEnrolment.status}
                        onChange={(e) => handleNestedChange('infrastructure', 'overEnrolment', 'status', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Permission</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.overEnrolment.permission}
                        onChange={(e) => handleNestedChange('infrastructure', 'overEnrolment', 'permission', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              
              <div>
                <h3 className="text-md font-medium text-gray-700 mb-2">Under-enrolled</h3>
                <select 
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.infrastructure.underEnrolled}
                  onChange={(e) => handleChange('infrastructure', 'underEnrolled', e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-md font-medium text-gray-700 mb-2">Laboratories</h3>
                  <div className="grid grid-cols-1 gap-2">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Available</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.laboratories.available}
                        onChange={(e) => handleNestedChange('infrastructure', 'laboratories', 'available', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Resourced</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.laboratories.resourced}
                        onChange={(e) => handleNestedChange('infrastructure', 'laboratories', 'resourced', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Well Resourced">Well Resourced</option>
                        <option value="Partially Resourced">Partially Resourced</option>
                        <option value="Poorly Resourced">Poorly Resourced</option>
                      </select>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Utilized</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.laboratories.utilized}
                        onChange={(e) => handleNestedChange('infrastructure', 'laboratories', 'utilized', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Effectively">Effectively</option>
                        <option value="Partially">Partially</option>
                        <option value="Not Used">Not Used</option>
                      </select>
                    </div>
                  </div>
                </div>
                
                <div>
                  <h3 className="text-md font-medium text-gray-700 mb-2">Library</h3>
                  <div className="grid grid-cols-1 gap-2">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Available</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.library.available}
                        onChange={(e) => handleNestedChange('infrastructure', 'library', 'available', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Resourced</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.library.resourced}
                        onChange={(e) => handleNestedChange('infrastructure', 'library', 'resourced', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Well Resourced">Well Resourced</option>
                        <option value="Partially Resourced">Partially Resourced</option>
                        <option value="Poorly Resourced">Poorly Resourced</option>
                      </select>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Utilized</label>
                      <select 
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData.infrastructure.library.utilized}
                        onChange={(e) => handleNestedChange('infrastructure', 'library', 'utilized', e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="Effectively">Effectively</option>
                        <option value="Partially">Partially</option>
                        <option value="Not Used">Not Used</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        {/* Finances Tab */}
        <TabsContent value="finances" activeValue={activeTab}>
          <Card>
            <CardHeader>
              <CardTitle>Finances</CardTitle>
              <CardDescription>Review school financial management</CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    SGB properly constituted
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.finances.sgbConstituted}
                    onChange={(e) => handleChange('finances', 'sgbConstituted', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Finance Committee functional
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.finances.fincomFunctional}
                    onChange={(e) => handleChange('finances', 'fincomFunctional', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Budget available
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.finances.budget.available}
                    onChange={(e) => handleNestedChange('finances', 'budget', 'available', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Budget approved
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.finances.budget.approved}
                    onChange={(e) => handleNestedChange('finances', 'budget', 'approved', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Books audited
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.finances.booksAudited.status}
                    onChange={(e) => handleNestedChange('finances', 'booksAudited', 'status', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="In Progress">In Progress</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Expected audit completion date
                  </label>
                  <input 
                    type="date" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.finances.booksAudited.expectedDate}
                    onChange={(e) => handleNestedChange('finances', 'booksAudited', 'expectedDate', e.target.value)}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        {/* Nutrition Tab */}
        <TabsContent value="nutrition" activeValue={activeTab}>
          <Card>
            <CardHeader>
              <CardTitle>Nutrition Program</CardTitle>
              <CardDescription>Evaluate implementation of nutrition program</CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Participates in nutrition program
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.nutrition.participates}
                    onChange={(e) => handleChange('nutrition', 'participates', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    If no, reason for non-participation
                  </label>
                  <input 
                    type="text" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.nutrition.nonParticipationReason}
                    onChange={(e) => handleChange('nutrition', 'nonParticipationReason', e.target.value)}
                  />
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Kitchen available
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.nutrition.kitchen.available}
                    onChange={(e) => handleNestedChange('nutrition', 'kitchen', 'available', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Kitchen clean
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.nutrition.kitchen.clean}
                    onChange={(e) => handleNestedChange('nutrition', 'kitchen', 'clean', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Partially">Partially</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Corrective measures
                  </label>
                  <input 
                    type="text" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.nutrition.kitchen.corrective}
                    onChange={(e) => handleNestedChange('nutrition', 'kitchen', 'corrective', e.target.value)}
                  />
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Lunch served on time
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.nutrition.lunchTime.onTime}
                    onChange={(e) => handleNestedChange('nutrition', 'lunchTime', 'onTime', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Challenges with lunch time
                  </label>
                  <input 
                    type="text" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.nutrition.lunchTime.challenges}
                    onChange={(e) => handleNestedChange('nutrition', 'lunchTime', 'challenges', e.target.value)}
                  />
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    School garden available
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.nutrition.schoolGarden.available}
                    onChange={(e) => handleNestedChange('nutrition', 'schoolGarden', 'available', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    If no garden, reasons
                  </label>
                  <input 
                    type="text" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.nutrition.schoolGarden.reasons}
                    onChange={(e) => handleNestedChange('nutrition', 'schoolGarden', 'reasons', e.target.value)}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        {/* Campaigns Tab */}
        <TabsContent value="campaigns" activeValue={activeTab}>
          <Card>
            <CardHeader>
              <CardTitle>Campaigns and Programs</CardTitle>
              <CardDescription>Check implementation of education campaigns</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    My Life My Future Campaign
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.campaigns.myLifeMyFuture}
                    onChange={(e) => handleChange('campaigns', 'myLifeMyFuture', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Implemented">Implemented</option>
                    <option value="Not Implemented">Not Implemented</option>
                    <option value="Partially Implemented">Partially Implemented</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    QLTP Campaign
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.campaigns.qltp}
                    onChange={(e) => handleChange('campaigns', 'qltp', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Implemented">Implemented</option>
                    <option value="Not Implemented">Not Implemented</option>
                    <option value="Partially Implemented">Partially Implemented</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Health Promoting Schools
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.campaigns.healthPromoting}
                    onChange={(e) => handleChange('campaigns', 'healthPromoting', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Implemented">Implemented</option>
                    <option value="Not Implemented">Not Implemented</option>
                    <option value="Partially Implemented">Partially Implemented</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Batho Pele Principles
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.campaigns.bathoPele}
                    onChange={(e) => handleChange('campaigns', 'bathoPele', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Implemented">Implemented</option>
                    <option value="Not Implemented">Not Implemented</option>
                    <option value="Partially Implemented">Partially Implemented</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    National Symbols
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.campaigns.nationalSymbols}
                    onChange={(e) => handleChange('campaigns', 'nationalSymbols', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Implemented">Implemented</option>
                    <option value="Not Implemented">Not Implemented</option>
                    <option value="Partially Implemented">Partially Implemented</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Sukuma Sakhe
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.campaigns.sukumaSakhe}
                    onChange={(e) => handleChange('campaigns', 'sukumaSakhe', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Implemented">Implemented</option>
                    <option value="Not Implemented">Not Implemented</option>
                    <option value="Partially Implemented">Partially Implemented</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    EPMDS
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.campaigns.epmds}
                    onChange={(e) => handleChange('campaigns', 'epmds', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Implemented">Implemented</option>
                    <option value="Not Implemented">Not Implemented</option>
                    <option value="Partially Implemented">Partially Implemented</option>
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    IQMS
                  </label>
                  <select 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.campaigns.iqms}
                    onChange={(e) => handleChange('campaigns', 'iqms', e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Implemented">Implemented</option>
                    <option value="Not Implemented">Not Implemented</option>
                    <option value="Partially Implemented">Partially Implemented</option>
                  </select>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
      
      {/* General Comments Section */}
      <Card className="mt-6">
        <CardHeader>
          <CardTitle>General Comments and Actions</CardTitle>
          <CardDescription>Add overall comments and action items</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {formData.generalComments.map((comment, index) => (
              <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-4 items-start border-b pb-4">
                <div className="md:col-span-3">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Comment/Action Item {index + 1}
                  </label>
                  <textarea 
                    rows={2}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                    value={comment.comment}
                    onChange={(e) => {
                      const newComments = [...formData.generalComments];
                      newComments[index].comment = e.target.value;
                      setFormData({
                        ...formData,
                        generalComments: newComments
                      });
                    }}
                    placeholder="Add comment or action item..."
                  ></textarea>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Completion Date
                  </label>
                  <input 
                    type="date" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={comment.completionDate}
                    onChange={(e) => {
                      const newComments = [...formData.generalComments];
                      newComments[index].completionDate = e.target.value;
                      setFormData({
                        ...formData,
                        generalComments: newComments
                      });
                    }}
                  />
                </div>
              </div>
            ))}
            
            <div className="flex justify-end">
              <button 
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => {
                  setFormData({
                    ...formData,
                    generalComments: [
                      ...formData.generalComments,
                      { comment: '', completionDate: '' }
                    ]
                  });
                }}
              >
                <Plus size={16} className="mr-2" />
                Add Another Comment
              </button>
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex justify-between items-center">
          <div className="flex items-center text-sm text-gray-600">
            <FileText size={16} className="mr-1" />
            Complete all sections before final submission
          </div>
          
          <div className="flex space-x-2">
            <button 
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => saveForm(true)}
            >
              <Download size={16} className="mr-2" />
              Download PDF
            </button>

            <button
              onClick={() => setShowDraftsModal(true)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Manage Drafts
            </button>
            
            <button 
              type="button"
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => saveForm(false)}
            >
              <Send size={16} className="mr-2" />
              Submit Form
            </button>
          </div>
        </CardFooter>
      </Card>
      {renderDraftsModal()}
    </div>
  );
};

export default MonitoringForm;