// src/components/records/RecordDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  ArrowLeft, Edit, Trash2, Calendar, Clock, User, 
  FileText, Camera, Video, Download, MapPin, PlusCircle,
  CheckCircle, XCircle, AlertTriangle, BarChart, FileSpreadsheet,
  Clock as ClockIcon, CalendarCheck, CheckSquare
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { format } from 'date-fns';

const RecordDetail = () => {
  const { recordId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  
  // Fetch record data
  useEffect(() => {
    const fetchRecord = async () => {
      if (!recordId) return;
      
      setIsLoading(true);
      try {
        const recordDoc = await getDoc(doc(db, "records", recordId));
        if (recordDoc.exists()) {
          const data = recordDoc.data();
          const recordData = {
            id: recordDoc.id,
            ...data,
            createdAt: data.createdAt ? data.createdAt.toDate() : null,
            updatedAt: data.updatedAt ? data.updatedAt.toDate() : null
          };
          setRecord(recordData);
        } else {
          setError("Record not found");
        }
      } catch (err) {
        console.error("Error fetching record:", err);
        setError("Failed to load record data");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchRecord();
  }, [recordId]);
  
  // Handle record deletion
  const handleDeleteRecord = async () => {
    try {
      await deleteDoc(doc(db, "records", recordId));
      navigate('/records');
    } catch (err) {
      console.error("Error deleting record:", err);
      setError("Failed to delete record");
    }
  };
  
  // Format date using date-fns with South African-friendly format
  const formatDate = (date) => {
    if (!date) return 'N/A';
    return format(new Date(date), 'MMMM d, yyyy');
  };

  // Format time (placeholder – adjust as needed)
  const formatTime = (time) => {
    if (!time) return 'N/A';
    return time;
  };
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <div className="flex">
            <AlertTriangle className="h-6 w-6 text-red-500" />
            <div className="ml-3">
              <p className="text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate('/records')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Records
          </button>
        </div>
      </div>
    );
  }
  
  if (!record) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4">
          <div className="flex">
            <AlertTriangle className="h-6 w-6 text-yellow-500" />
            <div className="ml-3">
              <p className="text-yellow-700">Record not found</p>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate('/records')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Records
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      {/* Header with navigation and actions */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
        <div className="flex items-center mb-4 md:mb-0">
          <button
            onClick={() => navigate('/records')}
            className="mr-4 text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <h1 className="text-xl font-bold text-gray-900">Record Details</h1>
        </div>
        
        <div className="flex space-x-2">
          <Link
            to={`/records/edit/${recordId}`}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Edit className="h-4 w-4 mr-2" />
            Edit
          </Link>
          <button
            onClick={() => setDeleteConfirm(true)}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <Trash2 className="h-4 w-4 mr-2" />
            Delete
          </button>
        </div>
      </div>
      
      {/* Record Content */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {record.category || 'Uncategorized'} Record
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Created on {record.createdAt ? formatDate(record.createdAt) : 'Unknown date'}
            </p>
          </div>
          
          <span className={`px-3 py-1 inline-flex text-sm leading-5 font-semibold rounded-full ${
            record.status === 'Green' 
              ? 'bg-green-100 text-green-800'
              : record.status === 'Orange'
                ? 'bg-yellow-100 text-yellow-800'
                : 'bg-red-100 text-red-800'
          }`}>
            {record.status}
          </span>
        </div>
        
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                <Calendar className="inline-block h-4 w-4 mr-2" />
                Record Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {formatDate(record.date)}
              </dd>
            </div>
            
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                <FileText className="inline-block h-4 w-4 mr-2" />
                Record Type
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {record.type || 'N/A'}
              </dd>
            </div>
            
            {record.target && (
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  <User className="inline-block h-4 w-4 mr-2" />
                  Target
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {record.target}
                  {record.targetRef && (
                    <span className="ml-2 text-gray-500">({record.targetRef})</span>
                  )}
                </dd>
              </div>
            )}
            
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                <FileText className="inline-block h-4 w-4 mr-2" />
                Record Message
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {record.recordMessage || 'No message provided'}
              </dd>
            </div>
            
            {record.measurement && record.measurement.length > 0 && (
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  <BarChart className="inline-block h-4 w-4 mr-2" />
                  Measurements
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div className="space-y-2">
                    {record.measurement.includes('Date') && (
                      <div>
                        <span className="font-medium">Date: </span>
                        {formatDate(record.startDate)}
                        {record.measurement.includes('Duration') && record.endDate && (
                          <span> to {formatDate(record.endDate)}</span>
                        )}
                      </div>
                    )}
                    
                    {record.measurement.includes('Time') && (
                      <div>
                        <span className="font-medium">Time: </span>
                        {formatTime(record.startTime)}
                        {record.measurement.includes('Duration') && record.endTime && (
                          <span> to {formatTime(record.endTime)}</span>
                        )}
                      </div>
                    )}
                    
                    {record.measurement.includes('Quantity') && record.quantity && (
                      <div>
                        <span className="font-medium">Quantity: </span>
                        {record.quantity}
                      </div>
                    )}
                  </div>
                </dd>
              </div>
            )}
            
            {record.mediaUrl && (
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {record.mediaType === 'Image' ? (
                    <Camera className="inline-block h-4 w-4 mr-2" />
                  ) : record.mediaType === 'Video' ? (
                    <Video className="inline-block h-4 w-4 mr-2" />
                  ) : record.mediaType === 'Document' && record.mediaUrl?.type?.includes('pdf') ? (
                    <FileText className="inline-block h-4 w-4 mr-2 text-red-500" />
                  ) : (
                    <FileText className="inline-block h-4 w-4 mr-2" />
                  )}
                  {record.mediaType || 'Attachment'}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {record.mediaType === 'Image' ? (
                    <div>
                      <img 
                        src={record.mediaUrl.url || record.mediaUrl} 
                        alt="Record attachment" 
                        className="h-48 object-contain rounded-md border border-gray-200"
                      />
                      <a 
                        href={record.mediaUrl.url || record.mediaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800 mt-2"
                      >
                        <Download className="h-4 w-4 mr-1" />
                        Download Image
                      </a>
                    </div>
                  ) : record.mediaType === 'Video' ? (
                    <div>
                      <video 
                        controls
                        className="h-48 object-contain rounded-md border border-gray-200"
                      >
                        <source src={record.mediaUrl.url || record.mediaUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <a 
                        href={record.mediaUrl.url || record.mediaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800 mt-2"
                      >
                        <Download className="h-4 w-4 mr-1" />
                        Download Video
                      </a>
                    </div>
                  ) : record.mediaType === 'Document' && (record.mediaUrl.type === 'application/pdf' || 
                      (typeof record.mediaUrl === 'string' && record.mediaUrl.includes('.pdf'))) ? (
                    <div>
                      <div className="h-[600px] border rounded-md overflow-hidden">
                        <object
                          data={record.mediaUrl.url || record.mediaUrl}
                          type="application/pdf"
                          width="100%"
                          height="100%"
                        >
                          <p>
                            PDF cannot be displayed. 
                            <a 
                              href={record.mediaUrl.url || record.mediaUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:underline ml-1"
                            >
                              Open PDF
                            </a>
                          </p>
                        </object>
                      </div>
                      <a 
                        href={record.mediaUrl.url || record.mediaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800 mt-2"
                      >
                        <Download className="h-4 w-4 mr-1" />
                        Download Document
                      </a>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <FileText className="h-8 w-8 text-gray-400 mr-3" />
                      <div>
                        <p className="text-gray-900">{record.mediaUrl.name || "Document"}</p>
                        <a 
                          href={record.mediaUrl.url || record.mediaUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800 mt-1"
                        >
                          <Download className="h-4 w-4 mr-1" />
                          Download Document
                        </a>
                      </div>
                    </div>
                  )}
                </dd>
              </div>
            )}
            
            {record.requireAction === 'Yes' && (
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  <CheckSquare className="inline-block h-4 w-4 mr-2" />
                  Action Required
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div className="flex items-center">
                    <span className="mr-3">Yes</span>
                    {record.taskId ? (
                      <Link
                        to={`/tasks/view/${record.taskId}`}
                        className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <CheckSquare className="h-4 w-4 mr-1" />
                        View Task
                      </Link>
                    ) : (
                      <Link
                        to={`/tasks/create?recordId=${record.id}`}
                        className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <PlusCircle className="h-4 w-4 mr-1" />
                        Create Task
                      </Link>
                    )}
                  </div>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
      
      {/* Metadata */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Record Metadata
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created At</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {record.createdAt ? formatDate(record.createdAt) + format(record.createdAt, ' h:mm a') : 'Unknown'}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Last Updated</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {record.updatedAt ? formatDate(record.updatedAt) + format(record.updatedAt, ' h:mm a') : 'Unknown'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Record ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {record.id}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      
      {/* Delete Confirmation Dialog */}
      {deleteConfirm && (
        <div className="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div 
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
              aria-hidden="true"
              onClick={() => setDeleteConfirm(false)}
            ></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Delete Record
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this record? This action cannot be undone and all associated data will be permanently removed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button 
                  type="button" 
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleDeleteRecord}
                >
                  Delete
                </button>
                <button 
                  type="button" 
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setDeleteConfirm(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordDetail;
