// src/components/documents/DocumentWorkflowDetails.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { 
  ArrowLeft, Edit, Calendar, Clock, Check, X, Download, 
  MessageSquare, FileText, Paperclip, Send, Smile, 
  ChevronDown, User, MoreHorizontal, AlertTriangle 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase/config';
import { 
  doc, getDoc, updateDoc, arrayUnion, 
  collection, addDoc, query, where, orderBy, getDocs, Timestamp 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const DocumentWorkflowDetails = () => {
  const navigate = useNavigate();
  const { workflowId } = useParams();
  const { userProfile } = useAuth();
  const [workflow, setWorkflow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('overview');
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [commentAttachment, setCommentAttachment] = useState(null);
  const [confirmAction, setConfirmAction] = useState(null); // 'approve', 'reject', null
  const [currentStep, setCurrentStep] = useState(null);

  // Fetch workflow details
  useEffect(() => {
    const fetchWorkflow = async () => {
      if (!workflowId) return;
      
      setIsLoading(true);
      try {
        const workflowDoc = await getDoc(doc(db, "documentWorkflows", workflowId));
        
        if (workflowDoc.exists()) {
          const data = workflowDoc.data();
          setWorkflow({
            id: workflowId,
            ...data
          });
          
          // Find current step
          const inProgressStep = data.steps.find(step => step.status === 'In Progress' || step.status === 'Awaiting Input');
          setCurrentStep(inProgressStep);
          
        } else {
          setError("Workflow not found");
          navigate('/documents');
        }
      } catch (error) {
        console.error("Error fetching workflow:", error);
        setError("Failed to load workflow. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkflow();
  }, [workflowId, navigate]);

  // Fetch comments
  useEffect(() => {
    const fetchComments = async () => {
      if (!workflowId) return;
      
      try {
        const commentsRef = collection(db, "workflowComments");
        const q = query(
          commentsRef,
          where("workflowId", "==", workflowId),
          orderBy("timestamp", "asc")
        );
        
        const querySnapshot = await getDocs(q);
        
        const commentsList = [];
        querySnapshot.forEach((doc) => {
          commentsList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setComments(commentsList);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [workflowId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        setError("File is too large. Maximum size is 5MB.");
        return;
      }
      
      setCommentAttachment(file);
      setError('');
    }
  };

  const handleRemoveAttachment = () => {
    setCommentAttachment(null);
  };

  const handleAddComment = async () => {
    if (!newComment.trim() && !commentAttachment) {
      return;
    }
    
    try {
      // Upload attachment if present
      let attachmentUrl = null;
      let attachmentName = null;
      
      if (commentAttachment) {
        const storageRef = ref(storage, `workflows/${workflowId}/comments/${Date.now()}_${commentAttachment.name}`);
        
        const snapshot = await uploadBytes(storageRef, commentAttachment);
        attachmentUrl = await getDownloadURL(snapshot.ref);
        attachmentName = commentAttachment.name;
      }
      
      // Create comment
      const commentData = {
        workflowId,
        content: newComment.trim(),
        userId: userProfile?.id,
        userName: `${userProfile?.title || ''} ${userProfile?.firstName} ${userProfile?.lastName}`.trim(),
        userInitials: `${userProfile?.firstName?.charAt(0) || ''}${userProfile?.lastName?.charAt(0) || ''}`,
        timestamp: new Date(),
        attachment: attachmentUrl ? {
          name: attachmentName,
          url: attachmentUrl
        } : null
      };
      
      // Add to Firestore
      const docRef = await addDoc(collection(db, "workflowComments"), commentData);
      
      // Update local state
      setComments([...comments, { id: docRef.id, ...commentData }]);
      
      // Clear form
      setNewComment('');
      setCommentAttachment(null);
      
    } catch (error) {
      console.error("Error adding comment:", error);
      setError("Failed to add comment. Please try again.");
    }
  };

  const handleApproveStep = async () => {
    if (!currentStep || !workflow) return;
    
    try {
      // Update step status
      const updatedSteps = workflow.steps.map(step => {
        if (step.id === currentStep.id) {
          return { ...step, status: 'Completed' };
        }
        
        // Set next step to In Progress
        const currentIndex = workflow.steps.findIndex(s => s.id === currentStep.id);
        if (currentIndex < workflow.steps.length - 1 && step.id === workflow.steps[currentIndex + 1].id) {
          return { ...step, status: 'In Progress' };
        }
        
        return step;
      });
      
      // Calculate new progress
      const completedSteps = updatedSteps.filter(step => step.status === 'Completed').length;
      const progress = Math.round((completedSteps / updatedSteps.length) * 100);
      
      // Check if workflow is now complete
      const isComplete = completedSteps === updatedSteps.length;
      
      // Update workflow
      const workflowRef = doc(db, "documentWorkflows", workflowId);
      await updateDoc(workflowRef, {
        steps: updatedSteps,
        progress,
        status: isComplete ? 'Completed' : 'In Progress',
        currentStep: completedSteps + 1,
        ...(isComplete ? { completedDate: new Date() } : {})
      });
      
      // Add comment about approval
      const commentData = {
        workflowId,
        content: `Approved step: ${currentStep.name}`,
        userId: userProfile?.id,
        userName: `${userProfile?.title || ''} ${userProfile?.firstName} ${userProfile?.lastName}`.trim(),
        userInitials: `${userProfile?.firstName?.charAt(0) || ''}${userProfile?.lastName?.charAt(0) || ''}`,
        timestamp: new Date(),
        isSystemComment: true
      };
      
      await addDoc(collection(db, "workflowComments"), commentData);
      
      // Update local state
      setWorkflow({
        ...workflow,
        steps: updatedSteps,
        progress,
        status: isComplete ? 'Completed' : 'In Progress',
        currentStep: completedSteps + 1,
        ...(isComplete ? { completedDate: new Date() } : {})
      });
      
      setCurrentStep(isComplete ? null : updatedSteps.find(s => s.status === 'In Progress'));
      setComments([...comments, commentData]);
      
      // Close confirmation dialog
      setConfirmAction(null);
      
    } catch (error) {
      console.error("Error approving step:", error);
      setError("Failed to approve step. Please try again.");
    }
  };

  const handleRejectStep = async () => {
    if (!currentStep || !workflow) return;
    
    try {
      // Update step status
      const updatedSteps = workflow.steps.map(step => {
        if (step.id === currentStep.id) {
          return { ...step, status: 'Rejected' };
        }
        return step;
      });
      
      // Update workflow
      const workflowRef = doc(db, "documentWorkflows", workflowId);
      await updateDoc(workflowRef, {
        steps: updatedSteps,
        status: 'Rejected'
      });
      
      // Add comment about rejection
      const commentData = {
        workflowId,
        content: `Rejected step: ${currentStep.name}`,
        userId: userProfile?.id,
        userName: `${userProfile?.title || ''} ${userProfile?.firstName} ${userProfile?.lastName}`.trim(),
        userInitials: `${userProfile?.firstName?.charAt(0) || ''}${userProfile?.lastName?.charAt(0) || ''}`,
        timestamp: new Date(),
        isSystemComment: true
      };
      
      await addDoc(collection(db, "workflowComments"), commentData);
      
      // Update local state
      setWorkflow({
        ...workflow,
        steps: updatedSteps,
        status: 'Rejected'
      });
      
      setCurrentStep({ ...currentStep, status: 'Rejected' });
      setComments([...comments, commentData]);
      
      // Close confirmation dialog
      setConfirmAction(null);
      
    } catch (error) {
      console.error("Error rejecting step:", error);
      setError("Failed to reject step. Please try again.");
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    
    try {
      const dateObj = date instanceof Timestamp 
        ? date.toDate() 
        : date instanceof Date 
          ? date 
          : typeof date === 'object' && date.seconds 
            ? new Date(date.seconds * 1000) 
            : new Date(date);
      
      return dateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (err) {
      console.error('Date formatting error:', err);
      return 'Invalid date';
    }
  };

  const formatDateTime = (date) => {
    if (!date) return '';
    
    try {
      const dateObj = date instanceof Timestamp 
        ? date.toDate() 
        : date instanceof Date 
          ? date 
          : typeof date === 'object' && date.seconds 
            ? new Date(date.seconds * 1000) 
            : new Date(date);
      
      return dateObj.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    } catch (err) {
      console.error('Date formatting error:', err);
      return 'Invalid date';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'bg-green-100 text-green-800';
      case 'In Progress':
        return 'bg-blue-100 text-blue-800';
      case 'Awaiting Input':
        return 'bg-yellow-100 text-yellow-800';
      case 'Rejected':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Completed':
        return <Check size={16} />;
      case 'In Progress':
        return <Clock size={16} />;
      case 'Awaiting Input':
        return <AlertTriangle size={16} />;
      case 'Rejected':
        return <X size={16} />;
      default:
        return null;
    }
  };

  const getInitialsColor = (initial) => {
    const colors = ['blue', 'green', 'purple', 'yellow', 'red'];
    const charCode = initial.charCodeAt(0);
    return colors[charCode % colors.length];
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-center mb-6">
          <button 
            onClick={() => navigate('/documents')}
            className="text-gray-500 hover:text-gray-700 flex items-center"
          >
            <ArrowLeft size={18} className="mr-1" />
            Back to Workflows
          </button>
        </div>
        <div className="bg-red-50 text-red-800 p-4 rounded-md">
          {error}
        </div>
      </div>
    );
  }

  if (!workflow) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <button 
              onClick={() => navigate('/documents')}
              className="text-gray-500 hover:text-gray-700 flex items-center mr-4"
            >
              <ArrowLeft size={18} className="mr-1" />
              Back
            </button>
            <h2 className="text-xl font-semibold text-gray-800">{workflow.title}</h2>
            {workflow.status && (
              <span className={`ml-3 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(workflow.status)}`}>
                {getStatusIcon(workflow.status)}
                <span className="ml-1">{workflow.status}</span>
              </span>
            )}
          </div>
          <div className="flex space-x-2">
            {workflow.status !== 'Completed' && (
              <Link
                to={`/documents/edit/${workflowId}`}
                className="bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
              >
                <Edit size={16} className="mr-2" />
                Edit
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200">
        <nav className="flex px-6">
          <button
            onClick={() => setActiveTab('overview')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'overview'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Overview
          </button>
          <button
            onClick={() => setActiveTab('documents')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8 ${
              activeTab === 'documents'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Documents ({workflow.documents?.length || 0})
          </button>
          <button
            onClick={() => setActiveTab('comments')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8 ${
              activeTab === 'comments'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Comments ({comments.length})
          </button>
        </nav>
      </div>

      {/* Content */}
      <div className="p-6">
        {activeTab === 'overview' && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Main Content */}
            <div className="md:col-span-2 space-y-6">
              {/* Workflow Information */}
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Workflow Information</h3>
                <div className="bg-gray-50 rounded-lg p-4">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">Description</dt>
                      <dd className="mt-1 text-sm text-gray-900">{workflow.description}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Status</dt>
                      <dd className="mt-1">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(workflow.status)}`}>
                          {workflow.status}
                        </span>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Priority</dt>
                      <dd className="mt-1">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          workflow.priority === 'High' ? 'bg-red-100 text-red-800' :
                          workflow.priority === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                          'bg-green-100 text-green-800'
                        }`}>
                          {workflow.priority}
                        </span>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Due Date</dt>
                      <dd className="mt-1 text-sm text-gray-900 flex items-center">
                        <Calendar size={14} className="mr-1 text-gray-400" />
                        {formatDate(workflow.dueDate)}
                      </dd>
                    </div>
                    {workflow.status === 'Completed' && workflow.completedDate && (
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Completed On</dt>
                        <dd className="mt-1 text-sm text-gray-900 flex items-center">
                          <Check size={14} className="mr-1 text-green-500" />
                          {formatDate(workflow.completedDate)}
                        </dd>
                      </div>
                    )}
                  </dl>
                </div>
              </div>
              
              {/* Progress */}
              <div>
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-medium text-gray-900">Progress</h3>
                  <span className="text-sm font-medium text-gray-500">{workflow.progress}% Complete</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div 
                    className={`h-2.5 rounded-full ${
                      workflow.status === 'Completed' ? 'bg-green-500' :
                      workflow.status === 'Awaiting Input' ? 'bg-yellow-500' :
                      workflow.status === 'Rejected' ? 'bg-red-500' :
                      'bg-blue-500'
                    }`} 
                    style={{ width: `${workflow.progress}%` }}
                  ></div>
                </div>
              </div>
              
              {/* Workflow Steps */}
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Workflow Steps</h3>
                <div className="space-y-4">
                  {workflow.steps.map((step, index) => (
                    <div key={step.id} className={`bg-gray-50 p-4 rounded-lg border ${
                      step.status === 'In Progress' || step.status === 'Awaiting Input' ? 'border-blue-300 ring-1 ring-blue-300' : 'border-gray-200'
                    }`}>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
                            step.status === 'Completed' ? 'bg-green-100 text-green-600' :
                            step.status === 'In Progress' ? 'bg-blue-100 text-blue-600' :
                            step.status === 'Awaiting Input' ? 'bg-yellow-100 text-yellow-600' :
                            step.status === 'Rejected' ? 'bg-red-100 text-red-600' :
                            'bg-gray-100 text-gray-400'
                          } mr-3`}>
                            {step.status === 'Completed' ? <Check size={16} /> :
                             step.status === 'In Progress' ? <Clock size={16} /> :
                             step.status === 'Awaiting Input' ? <AlertTriangle size={16} /> :
                             step.status === 'Rejected' ? <X size={16} /> :
                             index + 1}
                          </div>
                          <div>
                            <p className="text-sm font-medium">{step.name}</p>
                            <div className="flex items-center mt-1">
                              <span className="text-xs text-gray-500 mr-3">Assigned to: {step.assigneeName || step.assignee}</span>
                              <span className="text-xs text-gray-500 flex items-center">
                                <Calendar size={12} className="mr-1" />
                                Due: {typeof step.dueDate === 'string' ? step.dueDate : formatDate(step.dueDate)}
                              </span>
                            </div>
                          </div>
                        </div>
                        
                        {/* Action buttons for current step */}
                        {(step.status === 'In Progress' || step.status === 'Awaiting Input') && (
                          <div className="flex space-x-2">
                            {workflow.status !== 'Rejected' && (
                              <>
                                <button 
                                  onClick={() => setConfirmAction('reject')}
                                  className="px-3 py-1 border border-red-300 text-red-600 rounded text-xs font-medium hover:bg-red-50"
                                >
                                  Reject
                                </button>
                                <button 
                                  onClick={() => setConfirmAction('approve')}
                                  className="px-3 py-1 bg-green-600 text-white rounded text-xs font-medium hover:bg-green-700"
                                >
                                  Approve
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
            {/* Sidebar */}
            <div>
              {/* Participants */}
              <div className="bg-gray-50 rounded-lg p-4 mb-6">
                <h3 className="text-sm font-medium text-gray-700 mb-3">Participants</h3>
                {workflow.participants && workflow.participants.length > 0 ? (
                  <div className="space-y-3">
                    {workflow.participants.map((participant) => (
                      <div key={participant.id} className="flex items-center">
                        <div className={`w-8 h-8 rounded-full bg-${participant.color}-100 flex items-center justify-center text-${participant.color}-600 font-medium mr-2`}>
                          {participant.avatar}
                        </div>
                        <div className="text-sm font-medium text-gray-900">{participant.name}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">No participants assigned</p>
                )}
              </div>
              
              {/* Recent Activity */}
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="text-sm font-medium text-gray-700 mb-3">Recent Activity</h3>
                {comments.length > 0 ? (
                  <div className="space-y-3">
                    {comments.slice(0, 5).map((comment) => (
                      <div key={comment.id} className="flex">
                        <div className={`flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center text-xs ${
                          comment.isSystemComment 
                          ? 'bg-gray-100 text-gray-600'
                          : `bg-${getInitialsColor(comment.userInitials?.[0] || 'A')}-100 text-${getInitialsColor(comment.userInitials?.[0] || 'A')}-600`
                        } mr-2`}>
                          {comment.isSystemComment ? <User size={12} /> : comment.userInitials}
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-xs text-gray-900 truncate">
                            {comment.isSystemComment ? 'System' : comment.userName}
                            <span className="text-gray-500 ml-1">
                              {comment.content.length > 30 
                                ? comment.content.substring(0, 30) + '...' 
                                : comment.content}
                            </span>
                          </p>
                          <p className="text-xs text-gray-500">
                            {formatDateTime(comment.timestamp)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">No activity yet</p>
                )}
                {comments.length > 5 && (
                  <button 
                    onClick={() => setActiveTab('comments')}
                    className="mt-3 text-xs font-medium text-blue-600 hover:text-blue-800"
                  >
                    View all activity
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === 'documents' && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">Attached Documents</h3>
            {workflow.documents && workflow.documents.length > 0 ? (
              <div className="space-y-3">
                {workflow.documents.map((document) => (
                  <div 
                    key={document.id} 
                    className="bg-gray-50 p-4 rounded-lg border border-gray-200 flex justify-between items-center"
                  >
                    <div className="flex items-center">
                      <FileText size={20} className="text-blue-500 mr-3" />
                      <div>
                        <p className="text-sm font-medium">{document.name}</p>
                        <p className="text-xs text-gray-500">
                          {document.size && `${(document.size / 1024).toFixed(1)} KB • `}
                          Uploaded {document.uploadedAt ? formatDate(document.uploadedAt) : 'unknown'}
                        </p>
                      </div>
                    </div>
                    <a 
                      href={document.url} 
                      target="_blank" 
                      rel="noreferrer" 
                      className="text-blue-600 hover:text-blue-800 text-sm font-medium flex items-center"
                    >
                      <Download size={16} className="mr-1" />
                      Download
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-12 bg-gray-50 rounded-lg border border-gray-200">
                <FileText size={48} className="mx-auto text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">No Documents</h3>
                <p className="text-gray-500 max-w-md mx-auto">
                  There are no documents attached to this workflow.
                </p>
              </div>
            )}
          </div>
        )}

        {activeTab === 'comments' && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">Discussion</h3>
            
            {/* Comment Form */}
            <div className="mb-6 bg-gray-50 rounded-lg p-4">
              <div className="flex">
                <div className="flex-shrink-0 mr-3">
                  <div className={`w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium`}>
                    {`${userProfile?.firstName?.charAt(0) || ''}${userProfile?.lastName?.charAt(0) || ''}`}
                  </div>
                </div>
                <div className="flex-1">
                  <textarea
                    placeholder="Add a comment..."
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    rows="3"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                  ></textarea>
                  
                  {commentAttachment && (
                    <div className="mt-2 bg-white p-2 rounded-md border border-gray-300 flex items-center justify-between">
                      <div className="flex items-center">
                        <FileText size={16} className="text-blue-500 mr-2" />
                        <span className="text-sm">{commentAttachment.name}</span>
                      </div>
                      <button 
                        type="button"
                        onClick={handleRemoveAttachment}
                        className="text-gray-400 hover:text-red-600"
                      >
                        <X size={16} />
                      </button>
                    </div>
                  )}
                  
                  <div className="mt-3 flex justify-between items-center">
                    <div>
                      <label htmlFor="comment-attachment" className="cursor-pointer text-gray-500 hover:text-gray-700">
                        <Paperclip size={16} />
                        <input 
                          id="comment-attachment" 
                          type="file" 
                          className="sr-only" 
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                    <button 
                      type="button"
                      onClick={handleAddComment}
                      disabled={!newComment.trim() && !commentAttachment}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <Send size={16} className="mr-2" />
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Comments List */}
            {comments.length > 0 ? (
              <div className="space-y-6">
                {comments.map((comment) => (
                  <div key={comment.id} className="flex">
                    <div className="flex-shrink-0 mr-3">
                      <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        comment.isSystemComment 
                        ? 'bg-gray-100 text-gray-600'
                        : `bg-${getInitialsColor(comment.userInitials?.[0] || 'A')}-100 text-${getInitialsColor(comment.userInitials?.[0] || 'A')}-600`
                      } font-medium`}>
                        {comment.isSystemComment ? <User size={16} /> : comment.userInitials}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="flex justify-between items-start">
                        <div>
                          <p className="text-sm font-medium text-gray-900">
                            {comment.isSystemComment ? 'System' : comment.userName}
                          </p>
                          <p className="text-xs text-gray-500">{formatDateTime(comment.timestamp)}</p>
                        </div>
                      </div>
                      <div className="mt-2">
                        <p className="text-sm text-gray-800">{comment.content}</p>
                        {comment.attachment && (
                          <div className="mt-2 bg-gray-50 p-2 rounded-md border border-gray-200 inline-flex items-center">
                            <FileText size={16} className="text-blue-500 mr-2" />
                            <a 
                              href={comment.attachment.url} 
                              target="_blank" 
                              rel="noreferrer" 
                              className="text-sm text-blue-600 hover:text-blue-800"
                            >
                              {comment.attachment.name}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-12 bg-gray-50 rounded-lg border border-gray-200">
                <MessageSquare size={48} className="mx-auto text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">No Comments Yet</h3>
                <p className="text-gray-500 max-w-md mx-auto">
                  Be the first to start the discussion about this workflow.
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Confirmation Modals */}
      {confirmAction === 'approve' && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md mx-auto">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Approval</h3>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to approve the current step ({currentStep?.name})? This action will move the workflow to the next step or complete it if this is the final step.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setConfirmAction(null)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={handleApproveStep}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      )}

      {confirmAction === 'reject' && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md mx-auto">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Rejection</h3>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to reject the current step ({currentStep?.name})? This will mark the workflow as rejected and stop the approval process.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setConfirmAction(null)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={handleRejectStep}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentWorkflowDetails;