import { useState, useEffect, useRef, useCallback } from 'react';
import { 
  MessageSquare, Users, Search, Plus, Paperclip, Send, MoreHorizontal, 
  Image, File, FileText, Video, Music, Upload, AlertCircle,
  Smile, Clock, Phone, ChevronDown, X, User, Bell, Menu, ChevronRight, ChevronLeft
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase/config';
import { 
  collection, query, where, orderBy, onSnapshot, addDoc, 
  doc, updateDoc, arrayUnion, Timestamp, getDoc, getDocs, limit, startAfter 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import MessageList from './MessageList';
import ChannelMembers from './ChannelMembers';
import ChannelList from './ChannelList';
import DirectMessageList from './DirectMessageList';
import EmojiPicker from './EmojiPicker';
import MessageInput from './MessageInput';
import MediaModal from './MediaModal';

const InternalMessaging = () => {
  const { currentUser, userProfile } = useAuth();
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [activeChannel, setActiveChannel] = useState('announcements');
  const [activeChannelData, setActiveChannelData] = useState(null);
  const [isDirectMessage, setIsDirectMessage] = useState(false);
  const [channels, setChannels] = useState([]);
  const [directMessages, setDirectMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showChannelMembers, setShowChannelMembers] = useState(false);
  const [selectedChannelForMembers, setSelectedChannelForMembers] = useState(null);
  const [nonMemberError, setNonMemberError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [mediaItems, setMediaItems] = useState([]);
  const messageInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const dropAreaRef = useRef(null);
  const messageContainerRef = useRef(null);
  
  // Detect mobile devices
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Check on initial load
    checkIfMobile();
    
    // Listen for resize events
    window.addEventListener('resize', checkIfMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);
  
  // Get color for user initial - this function is used by multiple components
  const getColorForInitial = (initial) => {
    const colors = ['blue', 'green', 'purple', 'yellow', 'red'];
    const charCode = initial ? initial.charCodeAt(0) : 0;
    return colors[charCode % colors.length];
  };

  const isUserChannelMember = (channelData) => {
    if (!channelData || !currentUser) return false;
    const members = channelData.members || [];
    return members.includes(currentUser.uid);
  };

  const handleManageChannelMembers = (channelId) => {
    setSelectedChannelForMembers(channelId);
    setShowChannelMembers(true);
  };
  
  // Fetch Channels
  useEffect(() => {
    if (!userProfile?.id || !userProfile?.institutionName) {
      setIsLoading(false);
      return;
    }
    
    setIsLoading(true);
    let unsubscribe = () => {};
    
    try {
      // Get channels the user has access to
      const channelsRef = collection(db, "channels");
      const q = query(
        channelsRef,
        where("institutionName", "==", userProfile.institutionName),
        orderBy("name")
      );
      
      unsubscribe = onSnapshot(q, (snapshot) => {
        const channelList = [];
        snapshot.forEach((doc) => {
          // Calculate unread count - in a real app this would be more sophisticated
          const unreadCount = Math.floor(Math.random() * 15); // Just for demo
          
          channelList.push({
            id: doc.id,
            ...doc.data(),
            unread: unreadCount
          });
        });
        
        setChannels(channelList);
        
        // If we don't have channel data yet, set the active channel
        if (channelList.length > 0 && !activeChannelData) {
          const defaultChannel = channelList.find(c => c.id === 'announcements') || channelList[0];
          setActiveChannel(defaultChannel.id);
          setActiveChannelData(defaultChannel);
          setIsDirectMessage(false);
        }
        
        setIsLoading(false);
      }, (error) => {
        console.error("Error fetching channels:", error);
        setError("Failed to load channels. Please try again.");
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error setting up channel listener:", error);
      setError("Failed to initialize channel listener.");
      setIsLoading(false);
    }
    
    return () => unsubscribe();
  }, [userProfile]);
  
  useEffect(() => {
    if (!dropAreaRef.current) return;
    
    const dropArea = dropAreaRef.current;
    
    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };
    
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isDragging) {
        setIsDragging(true);
      }
    };
    
    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      
      // Only set dragging to false if we're leaving the drop area
      if (e.currentTarget === dropArea && !dropArea.contains(e.relatedTarget)) {
        setIsDragging(false);
      }
    };
    
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        // Handle the dropped files
        Array.from(e.dataTransfer.files).forEach(file => {
          handleFileUpload(file);
        });
      }
    };
    
    // Add event listeners
    dropArea.addEventListener('dragenter', handleDragEnter);
    dropArea.addEventListener('dragover', handleDragOver);
    dropArea.addEventListener('dragleave', handleDragLeave);
    dropArea.addEventListener('drop', handleDrop);
    
    // Clean up event listeners
    return () => {
      dropArea.removeEventListener('dragenter', handleDragEnter);
      dropArea.removeEventListener('dragover', handleDragOver);
      dropArea.removeEventListener('dragleave', handleDragLeave);
      dropArea.removeEventListener('drop', handleDrop);
    };
  }, [isDragging]);

  // Fetch Direct Messages
  useEffect(() => {
    if (!userProfile?.id) return;
    
    let unsubscribe = () => {};
    
    try {
      // Get user's direct message conversations
      const conversationsRef = collection(db, "conversations");
      const q = query(
        conversationsRef,
        where("participants", "array-contains", userProfile.id)
      );
      
      unsubscribe = onSnapshot(q, async (snapshot) => {
        const dmList = [];
        
        // For each conversation, get the other participant's details
        for (const docSnapshot of snapshot.docs) {
          const conversation = docSnapshot.data();
          const otherParticipantId = conversation.participants.find(id => id !== userProfile.id);
          
          if (otherParticipantId) {
            try {
              // Fetch from userProfiles instead of users collection
              const userProfileDoc = await getDoc(doc(db, "userProfiles", otherParticipantId));
              
              if (userProfileDoc.exists()) {
                const userData = userProfileDoc.data();
                // Calculate unread messages
                const unreadCount = conversation.unreadBy && conversation.unreadBy[userProfile.id] 
                  ? conversation.unreadBy[userProfile.id] 
                  : 0;
                
                dmList.push({
                  id: docSnapshot.id,
                  userId: otherParticipantId,
                  name: `${userData.title || ''} ${userData.firstName || ''} ${userData.lastName || ''}`.trim() || 'Unknown User',
                  avatar: `${userData.firstName?.charAt(0) || ''}${userData.lastName?.charAt(0) || '?'}`,
                  color: getColorForInitial(userData.firstName?.charAt(0) || 'A'),
                  status: userData.status || 'offline',
                  unread: unreadCount
                });
              } else {
                // If user profile doesn't exist, fall back to basic user data
                const userDoc = await getDoc(doc(db, "users", otherParticipantId));
                
                dmList.push({
                  id: docSnapshot.id,
                  userId: otherParticipantId,
                  name: userDoc.exists() ? userDoc.data().email : 'Unknown User',
                  avatar: '?',
                  color: 'gray',
                  status: 'offline',
                  unread: 0
                });
              }
            } catch (error) {
              console.error("Error fetching user details:", error);
              dmList.push({
                id: docSnapshot.id,
                userId: otherParticipantId,
                name: 'User Data Unavailable',
                avatar: '!',
                color: 'red',
                status: 'offline',
                unread: 0
              });
            }
          }
        }
        
        setDirectMessages(dmList);
      }, (error) => {
        console.error("Error fetching direct messages:", error);
      });
    } catch (error) {
      console.error("Error setting up direct message listener:", error);
    }
    
    return () => unsubscribe();
  }, [userProfile]);

  const preloadInstitutionUsers = async () => {
    if (!userProfile?.institutionName) return [];
    
    try {
      const usersRef = collection(db, "users");
      const q = query(
        usersRef,
        where("institutionName", "==", userProfile.institutionName),
        limit(50)
      );
      
      const querySnapshot = await getDocs(q);
      const usersList = [];
      
      querySnapshot.forEach((doc) => {
        if (doc.id !== userProfile.id) {
          const userData = doc.data();
          usersList.push({
            id: doc.id,
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            title: userData.title || '',
            userRole: userData.userRole || '',
            institutionName: userData.institutionName || ''
          });
        }
      });
      
      return usersList;
    } catch (error) {
      console.error("Error preloading institution users:", error);
      return [];
    }
  };

  

  // Add this useEffect for clipboard paste handling
  useEffect(() => {
    const handlePaste = async (e) => {
      
      
      const items = e.clipboardData?.items;
      if (!items) return;
      
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        
        // Handle image paste
        if (item.type.indexOf('image') !== -1) {
          e.preventDefault(); // Prevent default paste behavior
          
          const file = item.getAsFile();
          if (file) {
            await handleFileUpload(file);
          }
        }
      }
    };
    
    // Add event listener for paste
    window.addEventListener('paste', handlePaste);
    
    // Cleanup
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);
  
  // Add this effect to scroll to bottom when messages change
  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  // Function to scroll to the bottom of the message container
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (messageContainerRef.current) {
        // Dynamically adjust the height of the message container
        const windowHeight = window.innerHeight;
        const headerHeight = 120; // Estimate for header heights
        const inputHeight = isMobile ? 100 : 80; // Taller on mobile
        
        // Calculate the maximum height for the message container
        const maxHeight = windowHeight - (headerHeight + inputHeight);
        messageContainerRef.current.style.maxHeight = `${maxHeight}px`;
      }
    };
  
    // Initialize
    handleResize();
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [isMobile]);

  // Fetch Messages for Active Channel/DM
  useEffect(() => {
    if (!activeChannel) return;
    
    // Clear current messages
    setMessages([]);
    
    let messagesRef;
    let q;
    
    if (isDirectMessage) {
      // Direct messages - keep existing code for this
      messagesRef = collection(db, "messages");
      q = query(
        messagesRef,
        where("conversationId", "==", activeChannel),
        orderBy("timestamp", "asc"),
        limit(50)
      );
    } else {
      
      
      messagesRef = collection(db, "messages");
      q = query(
        messagesRef,
        where("channelId", "==", activeChannel),
        orderBy("timestamp", "asc"),
        limit(50)
      );
    }
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messageList = [];
      snapshot.forEach((doc) => {
        messageList.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      setMessages(messageList);
      
      // Mark as read if direct message
      if (isDirectMessage && activeChannelData) {
        updateUnreadMessages(activeChannel);
      }
      
      // Scroll to bottom
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }, (error) => {
      console.error("Error fetching messages:", error);
      setError("Failed to load messages. Please try again.");
    });
    
    return unsubscribe;
  }, [activeChannel, isDirectMessage, activeChannelData, currentUser]);
  
  // Update unread messages count
  const updateUnreadMessages = async (conversationId) => {
    if (!userProfile?.id) return;
    
    try {
      const conversationRef = doc(db, "conversations", conversationId);
      await updateDoc(conversationRef, {
        [`unreadBy.${userProfile.id}`]: 0
      });
    } catch (error) {
      console.error("Error updating unread messages:", error);
    }
  };
  
  // Handle channel change
  const handleChannelChange = (channelId) => {
    const selectedChannel = channels.find(c => c.id === channelId);
    if (selectedChannel) {
      setActiveChannel(channelId);
      setActiveChannelData(selectedChannel);
      setIsDirectMessage(false);
    }
  };
  
 // Update handleDirectMessageSelect function in InternalMessaging.js

const handleDirectMessageSelect = async (userId) => {
  setIsLoading(true);
  setError('');
  
  try {
    console.log("Selecting user or conversation:", userId);
    
    // First check if this is an existing conversation
    const existingDM = directMessages.find(dm => dm.id === userId);
    
    if (existingDM) {
      console.log("Found existing conversation:", existingDM);
      // This is an existing conversation ID
      setActiveChannel(userId);
      setActiveChannelData(existingDM);
      setIsDirectMessage(true);
      
      // Load messages for this conversation
      try {
        const messagesRef = collection(db, "messages");
        const q = query(
          messagesRef,
          where("conversationId", "==", userId),
          orderBy("timestamp", "asc")
        );
        
        const querySnapshot = await getDocs(q);
        const messagesList = [];
        
        querySnapshot.forEach((doc) => {
          messagesList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setMessages(messagesList);
        
        // Mark as read
        updateUnreadMessages(userId);
      } catch (msgError) {
        console.error("Error loading messages:", msgError);
        setError("Failed to load messages");
      }
    } else {
      console.log("Creating new conversation with user:", userId);
      // This is a user ID - create a new conversation
      try {
        // Get user details
        const userDocRef = doc(db, "users", userId);
        const userSnapshot = await getDoc(userDocRef);
        
        if (!userSnapshot.exists()) {
          console.error("User does not exist:", userId);
          setError("User not found");
          setIsLoading(false);
          return;
        }
        
        const userData = userSnapshot.data();
        console.log("Found user data:", userData);
        
        // Create a new conversation
        const conversationData = {
          participants: [currentUser.uid, userId],
          createdAt: new Date(),
          lastMessageTimestamp: new Date(),
          lastMessage: '',
          unreadBy: {
            [currentUser.uid]: 0,
            [userId]: 0
          }
        };
        
        console.log("Creating conversation with data:", conversationData);
        const docRef = await addDoc(collection(db, "conversations"), conversationData);
        console.log("Created conversation with ID:", docRef.id);
        
        // Create active channel data for UI
        const newDM = {
          id: docRef.id,
          userId: userId,
          name: `${userData.title || ''} ${userData.firstName} ${userData.lastName}`.trim() || "Unknown User",
          avatar: `${userData.firstName?.charAt(0) || ''}${userData.lastName?.charAt(0) || '?'}`,
          status: userData.status || 'offline',
          unread: 0
        };
        
        setActiveChannel(docRef.id);
        setActiveChannelData(newDM);
        setIsDirectMessage(true);
        setMessages([]); // No messages yet
        
        // Also add this to our directMessages list
        setDirectMessages(prev => [newDM, ...prev]);
      } catch (userError) {
        console.error("Error creating conversation:", userError);
        setError("Failed to create conversation");
      }
    }
    
    // Focus the message input
    if (messageInputRef.current) {
      setTimeout(() => {
        messageInputRef.current.focus();
      }, 300);
    }
    
  } catch (error) {
    console.error("Error selecting direct message:", error);
    setError("Failed to load conversation");
  } finally {
    setIsLoading(false);
    
    // Close mobile sidebar if on mobile
    if (isMobile) {
      setMobileSidebarOpen(false);
    }
  }
};
  
  // Send message
  const handleSendMessage = async () => {
    if ((!newMessage.trim() && attachments.length === 0) || !activeChannel || !userProfile) return;
    
    try {
      // Create message object
      const messageData = {
        content: newMessage.trim(),
        senderId: userProfile.id,
        senderName: `${userProfile.title || ''} ${userProfile.firstName} ${userProfile.lastName}`.trim(),
        senderInitials: `${userProfile.firstName?.charAt(0) || ''}${userProfile.lastName?.charAt(0) || ''}`,
        senderRole: userProfile.userRole || 'Staff',
        timestamp: new Date(),
        ...(isDirectMessage 
          ? { conversationId: activeChannel } 
          : { channelId: activeChannel }),
        pinned: false
      };
      
      // Add attachments if any
      if (attachments.length > 0) {
        messageData.attachments = attachments.map(attachment => ({
          name: attachment.name,
          url: attachment.url,
          type: attachment.type,
          size: attachment.size
        }));
      }
      
      // Add message to Firestore
      await addDoc(collection(db, "messages"), messageData);
      
      // If it's a DM, update the conversation with unread message
      if (isDirectMessage) {
        const conversationRef = doc(db, "conversations", activeChannel);
        const conversationDoc = await getDoc(conversationRef);
        
        if (conversationDoc.exists()) {
          const otherParticipantId = conversationDoc.data().participants.find(id => id !== userProfile.id);
          
          if (otherParticipantId) {
            await updateDoc(conversationRef, {
              lastMessage: newMessage.trim() || 'Attachment',
              lastMessageTimestamp: new Date(),
              [`unreadBy.${otherParticipantId}`]: (conversationDoc.data().unreadBy?.[otherParticipantId] || 0) + 1
            });
          }
        }
      }
      
      // Clear message and attachments
      setNewMessage('');
      setAttachments([]);
      setTimeout(scrollToBottom, 100);

    } catch (error) {
      console.error("Error sending message:", error);
      setError("Failed to send message. Please try again.");
    }
  };
  
  // Enhanced handleFileUpload function
  const handleFileUpload = async (file) => {
    if (!file || !activeChannel) return;
    
    try {
      // Show loading indicator for this attachment
      setError('');
      setIsLoading(true);
      
      // Check file size (10MB limit)
      if (file.size > 10 * 1024 * 1024) {
        setError("File is too large. Maximum size is 10MB.");
        setIsLoading(false);
        return;
      }
      
      // Create a unique filename to prevent collisions
      const fileExtension = file.name.split('.').pop();
      const uniqueFileName = `${Date.now()}_${Math.random().toString(36).substring(2, 15)}.${fileExtension}`;
      
      // Determine the correct path based on channel type
      const storagePath = isDirectMessage 
        ? `messages/direct/${activeChannel}/${uniqueFileName}`
        : `messages/channels/${activeChannel}/${uniqueFileName}`;
      
      // Upload to Firebase Storage
      const storageRef = ref(storage, storagePath);
      
      // Upload the file
      const uploadTask = uploadBytes(storageRef, file);
      const snapshot = await uploadTask;
      
      // Get the download URL
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      // Add to attachments with appropriate metadata
      const newAttachment = {
        name: file.name,
        url: downloadURL,
        type: file.type,
        size: file.size,
        path: storagePath // Store the path for potential deletion later
      };
      
      setAttachments(prevAttachments => [...prevAttachments, newAttachment]);
      setIsLoading(false);
      return newAttachment; // Return the attachment in case needed by caller
      
    } catch (error) {
      console.error("Error uploading file:", error);
      setError(`Failed to upload file: ${error.message}`);
      setIsLoading(false);
      return null;
    }
  };
  
  // Handle emoji selection
  const handleEmojiSelect = (emoji) => {
    setNewMessage(newMessage + emoji);
    setShowEmojiPicker(false);
  };
  
  const handleOpenMedia = (media, allMediaItems = [], index = 0) => {
    setSelectedMedia(media);
    setMediaItems(allMediaItems);
    setMediaIndex(index);
    setMediaModalOpen(true);
  };
  
  // Close the media modal
  const handleCloseMedia = () => {
    setMediaModalOpen(false);
    setSelectedMedia(null);
  };
  
  // Navigate to next media
  const handleNextMedia = () => {
    if (mediaIndex < mediaItems.length - 1) {
      setMediaIndex(mediaIndex + 1);
      setSelectedMedia(mediaItems[mediaIndex + 1]);
    }
  };
  
  // Navigate to previous media
  const handlePreviousMedia = () => {
    if (mediaIndex > 0) {
      setMediaIndex(mediaIndex - 1);
      setSelectedMedia(mediaItems[mediaIndex - 1]);
    }
  };
  // Add reaction to message
  const handleAddReaction = async (messageId, emoji) => {
    if (!messageId || !emoji || !userProfile?.id) return;
    
    try {
      const messageRef = doc(db, "messages", messageId);
      
      // Add reaction to the message
      await updateDoc(messageRef, {
        [`reactions.${emoji}`]: arrayUnion(userProfile.id)
      });
      
    } catch (error) {
      console.error("Error adding reaction:", error);
    }
  };
  
  // Remove attachment
  const handleRemoveAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };
  
  return (
    <div className="flex h-full relative">
      {/* Mobile Sidebar Toggle - only visible when sidebar is closed on mobile */}
      {isMobile && !mobileSidebarOpen && (
        <button 
          onClick={() => setMobileSidebarOpen(true)}
          className="fixed left-0 top-[150px] transform -translate-y-1/2 z-20 bg-[#1d67cd] text-white p-2 rounded-r-md shadow-md"
          aria-label="Open sidebar"
        >
          <ChevronRight size={20} />
        </button>
      )}
      
      {/* Mobile Sidebar Overlay - shown when sidebar is open on mobile */}
      {mobileSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={() => setMobileSidebarOpen(false)}
        ></div>
      )}

      {/* Sidebar - Left panel with blue background */}
      {(!isMobile || mobileSidebarOpen) && (
        <div className={`fixed md:relative w-80 flex-shrink-0 flex flex-col bg-[#1d67cd] text-[#f7f7f7] overflow-hidden h-full z-30 transition-transform duration-300 ease-in-out ${
          isMobile && !mobileSidebarOpen ? '-translate-x-full' : 'translate-x-0'
        }`}>
          {/* Search */}
          <div className="p-4 border-b border-[#ffffff33] flex justify-between items-center">
            <div className="relative flex-1">
              <Search size={16} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#1d67cd]" />
              <input 
                type="text" 
                placeholder="Search messages..." 
                className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300 text-sm bg-white text-gray-800" 
              />
            </div>
            
            {/* Close button for mobile */}
            <button 
              onClick={() => setMobileSidebarOpen(false)}
              className="md:hidden ml-2 text-white hover:text-gray-200"
            >
              <ChevronLeft size={20} />
            </button>
          </div>
          
          {/* Scrollable sidebar content */}
          <div className="flex-1 overflow-y-auto">
            {/* Channels */}
            <ChannelList 
              channels={channels}
              activeChannel={activeChannel}
              onChannelSelect={(channelId) => {
                handleChannelChange(channelId);
                if (isMobile) {
                  setMobileSidebarOpen(false);
                }
              }}
              isDirectMessage={isDirectMessage}
            />
            
            {/* Direct Messages */}
            <DirectMessageList 
              directMessages={directMessages}
              activeChannel={activeChannel}
              onDirectMessageSelect={(dmId) => {
                handleDirectMessageSelect(dmId);
                if (isMobile) {
                  setMobileSidebarOpen(false);
                }
              }}
              isDirectMessage={isDirectMessage}
              getColorForInitial={getColorForInitial} // Add this prop
            />
          </div>
        </div>
      )}

      {/* Main Content - Message area and input */}
      <div className="flex-1 flex flex-col overflow-hidden h-full">
        {/* Channel Header */}
        <div className="ml-2 flex-shrink-0 h-10 border-b flex items-center justify-between px-2 bg-white rounded-md z-10">
          <div className="flex items-center">
            <h2 className="text-lg font-semibold text-[#1d67cd] mr-2">
              {isDirectMessage 
                ? activeChannelData?.name 
                : `#${activeChannelData?.name || 'Loading...'}`}
            </h2>
            <span className="text-sm text-gray-500">
              {(!isDirectMessage && activeChannelData?.membersCount) 
                ? `${activeChannelData.membersCount} members` 
                : ''}
            </span>
          </div>
    
          <div className="flex items-center space-x-3">
            {/* Only show for channels (not direct messages) */}
            {!isDirectMessage && activeChannelData && (
              <button
                onClick={() => handleManageChannelMembers(activeChannel)}
                className="text-gray-500 hover:text-gray-700"
                title="Manage channel members"
              >
                <Users size={20} />
              </button>
            )}
            
            <button className="text-gray-500 hover:text-gray-700">
              <Phone size={20} />
            </button>
            <button className="text-gray-500 hover:text-gray-700">
              <Video size={20} />
            </button>
            <div className="relative">
              <button className="text-gray-500 hover:text-gray-700">
                <MoreHorizontal size={20} />
              </button>
            </div>
          </div>
        </div>
        
        {/* Messages Container with adjusted padding to account for fixed input area */}
        <div 
          ref={messageContainerRef}
          className="flex-1 overflow-y-auto bg-white"
          style={{ 
            paddingBottom: isMobile ? '100px' : '100px'  // Increased padding on mobile to account for the keyboard
          }}
        >
          {!isDirectMessage && activeChannelData && !isUserChannelMember(activeChannelData) ? (
            <div className="flex items-center justify-center h-full bg-gray-50">
              <div className="text-center p-6 max-w-md mx-auto bg-white shadow-md rounded-lg">
                <Users className="h-12 w-12 text-blue-500 mx-auto mb-4" />
                <h3 className="text-xl font-medium text-gray-900 mb-2">You're not a member of this channel</h3>
                <p className="text-gray-600 mb-6">You need to join this channel to view messages and participate in discussions.</p>
                <button
                  onClick={() => handleManageChannelMembers(activeChannel)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Join Channel
                </button>
              </div>
            </div>
          ) : (
            <MessageList 
              messages={messages}
              userProfile={userProfile}
              onAddReaction={handleAddReaction}
              getColorForInitial={getColorForInitial}
              messagesEndRef={messagesEndRef}
              isLoading={isLoading}
              onOpenMedia={handleOpenMedia}
            />
          )}
        </div>
        
        {/* Message Input - Fixed at bottom for mobile devices */}
        <div 
            ref={dropAreaRef}
            className={`${isMobile ? 'fixed bottom-0 left-0 right-0 z-20' : 'relative'} border-t bg-white shadow-lg`}
            style={{
              width: isMobile ? '100%' : 'auto',
              left: isMobile && mobileSidebarOpen ? '320px' : '0',
              transition: 'left 0.3s ease-in-out'
            }}
          >
            {isDragging && (
              <div className="absolute inset-0 bg-blue-50 border-2 border-dashed border-blue-300 flex items-center justify-center z-20">
                <div className="text-blue-500 text-center">
                  <Upload size={40} className="mx-auto mb-2" />
                  <p className="font-medium">Drop files to upload</p>
                </div>
              </div>
            )}

            <MessageInput 
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              handleSendMessage={handleSendMessage}
              handleFileUpload={handleFileUpload}
              attachments={attachments}
              handleRemoveAttachment={handleRemoveAttachment}
              error={error}
              setError={setError}
              isLoading={isLoading}
              isDragging={isDragging}
              showEmojiPicker={showEmojiPicker}
              setShowEmojiPicker={setShowEmojiPicker}
              handleEmojiSelect={handleEmojiSelect}
            />
          </div>

          {/* Attachments Preview */}
          {attachments.length > 0 && (
            <div className="px-3 py-2 bg-gray-100 border-b border-gray-200">
              <div className="flex flex-wrap gap-2">
                {attachments.map((attachment, index) => (
                  <div 
                    key={index}
                    className="bg-white rounded p-2 border flex items-center"
                  >
                    {attachment.type.startsWith('image/') ? (
                      <Image size={18} className="text-blue-500 mr-2" />
                    ) : (
                      <File size={18} className="text-blue-500 mr-2" />
                    )}
                    <span className="text-sm truncate max-w-[150px]">{attachment.name}</span>
                    <button 
                      onClick={() => handleRemoveAttachment(index)}
                      className="ml-2 text-gray-400 hover:text-red-500"
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>
         
      {/* Channel Members Modal */}
      {showChannelMembers && selectedChannelForMembers && (
        <ChannelMembers
          channelId={selectedChannelForMembers}
          isOpen={showChannelMembers}
          onClose={() => {
            setShowChannelMembers(false);
            setSelectedChannelForMembers(null);
          }}
        />
      )}

      {mediaModalOpen && selectedMedia && (
        <MediaModal
          media={selectedMedia}
          onClose={handleCloseMedia}
          onNext={handleNextMedia}
          onPrevious={handlePreviousMedia}
          hasNext={mediaIndex < mediaItems.length - 1}
          hasPrevious={mediaIndex > 0}
        />
      )}

      {showChannelMembers && selectedChannelForMembers && (
        <ChannelMembers
          channelId={selectedChannelForMembers}
          isOpen={showChannelMembers}
          onClose={() => {
            setShowChannelMembers(false);
            setSelectedChannelForMembers(null);
          }}
        />
      )}
    </div>
  );
};

export default InternalMessaging;