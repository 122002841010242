import React from 'react';

// Tabs container
export function MonitoringTabs({ className, defaultValue, value, onValueChange, children, ...props }) {
  return (
    <div
      className={`w-full ${className || ''}`}
      {...props}
    >
      {children}
    </div>
  );
}

// Tab list container
export function MonitoringTabsList({ className, children, ...props }) {
  return (
    <div
      className={`inline-flex items-center justify-center rounded-md bg-gray-100 p-1 ${className || ''}`}
      role="tablist"
      {...props}
    >
      {children}
    </div>
  );
}

// Individual tab trigger
export function MonitoringTabsTrigger({ className, value, activeValue, children, onClick, ...props }) {
  const isActive = value === activeValue;
  
  const handleClick = (e) => {
    if (onClick) {
      onClick(value);
    }
  };

  return (
    <button
      className={`inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 ${
        isActive 
          ? 'bg-white text-blue-600 shadow-sm' 
          : 'hover:bg-gray-200 text-gray-700'
      } ${className || ''}`}
      role="tab"
      aria-selected={isActive}
      data-state={isActive ? 'active' : 'inactive'}
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  );
}

// Tab content container
export function MonitoringTabsContent({ className, value, activeValue, children, ...props }) {
  const isActive = value === activeValue;
  
  if (!isActive) return null;
  
  return (
    <div
      className={`mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ${className || ''}`}
      role="tabpanel"
      tabIndex={0}
      {...props}
    >
      {children}
    </div>
  );
}