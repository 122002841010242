// src/components/institutions/ProjectProgrammeRegistration.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  PieChart, ArrowLeft, Calendar, MapPin, User, Landmark, Building2, Phone, 
  Mail, Clock, Plus, X, Save, Search, Building, School
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, query, where, getDocs, addDoc, 
  serverTimestamp, orderBy, limit 
} from 'firebase/firestore';

const ProjectProgrammeRegistration = () => {
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  
  // Basic form state
  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectType, setProjectType] = useState(''); // 'Project' or 'Programme'
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [budget, setBudget] = useState('');
  const [status, setStatus] = useState('Active');
  const [coordinator, setCoordinator] = useState('');
  const [coordinatorEmail, setCoordinatorEmail] = useState('');
  const [coordinatorPhone, setCoordinatorPhone] = useState('');
  
  // Operational levels
  const [operationalLevels, setOperationalLevels] = useState([]);
  
  // Selected institutions
  const [selectedProvinces, setSelectedProvinces] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedCircuits, setSelectedCircuits] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);
  
  // UI state
  const [isLoading, setIsLoading] = useState(false);
  const [provincesLoading, setProvincesLoading] = useState(false);
  const [districtsLoading, setDistrictsLoading] = useState(false);
  const [circuitsLoading, setCircuitsLoading] = useState(false);
  const [schoolsLoading, setSchoolsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Dropdown options
  const projectTypes = ['Project', 'Programme'];
  const operationalLevelOptions = [
    'Provincial Level', 
    'District Level', 
    'Circuit Level', 
    'School Level'
  ];
  const statusOptions = ['Active', 'Planning', 'Completed', 'On Hold', 'Cancelled'];
  
  // Available institutions for selection
  const [availableProvinces, setAvailableProvinces] = useState([]);
  const [availableDistricts, setAvailableDistricts] = useState([]);
  const [availableCircuits, setAvailableCircuits] = useState([]);
  const [availableSchools, setAvailableSchools] = useState([]);
  
  // Auto-generate project ID when type changes
  useEffect(() => {
    if (projectType) {
      const prefix = projectType === 'Project' ? 'PRJ' : 'PRG';
      const randomNum = Math.floor(10000 + Math.random() * 90000);
      setProjectId(`${prefix}-${randomNum}`);
    }
  }, [projectType]);
  
  // Fetch provinces
  useEffect(() => {
    const fetchProvinces = async () => {
      if (!operationalLevels.includes('Provincial Level')) return;
      
      setProvincesLoading(true);
      try {
        const institutionsRef = collection(db, "institutions");
        const q = query(
          institutionsRef,
          where("type", "==", "Provincial Department"),
          orderBy("name")
        );
        
        const querySnapshot = await getDocs(q);
        const provincesList = [];
        
        querySnapshot.forEach((doc) => {
          provincesList.push({
            id: doc.id,
            name: doc.data().name || 'Unnamed Province',
            type: doc.data().type
          });
        });
        
        setAvailableProvinces(provincesList);
      } catch (error) {
        console.error('Error fetching provinces:', error);
        setError('Failed to load provinces');
      } finally {
        setProvincesLoading(false);
      }
    };
    
    fetchProvinces();
  }, [operationalLevels]);
  
  // Fetch districts
  useEffect(() => {
    const fetchDistricts = async () => {
      if (!operationalLevels.includes('District Level')) return;
      
      setDistrictsLoading(true);
      try {
        const institutionsRef = collection(db, "institutions");
        let q;
        
        // If specific provinces are selected, only fetch districts from those provinces
        if (selectedProvinces.length > 0) {
          const provinceNames = selectedProvinces.map(p => p.name);
          q = query(
            institutionsRef,
            where("type", "==", "District Office"),
            where("province", "in", provinceNames),
            orderBy("name")
          );
        } else {
          q = query(
            institutionsRef,
            where("type", "==", "District Office"),
            orderBy("name")
          );
        }
        
        const querySnapshot = await getDocs(q);
        const districtsList = [];
        
        querySnapshot.forEach((doc) => {
          districtsList.push({
            id: doc.id,
            name: doc.data().name || 'Unnamed District',
            province: doc.data().province,
            type: doc.data().type
          });
        });
        
        setAvailableDistricts(districtsList);
      } catch (error) {
        console.error('Error fetching districts:', error);
        setError('Failed to load districts');
      } finally {
        setDistrictsLoading(false);
      }
    };
    
    fetchDistricts();
  }, [operationalLevels, selectedProvinces]);
  
  // Fetch circuits
  useEffect(() => {
    const fetchCircuits = async () => {
      if (!operationalLevels.includes('Circuit Level')) return;
      
      setCircuitsLoading(true);
      try {
        const institutionsRef = collection(db, "institutions");
        let q;
        
        // If specific districts are selected, only fetch circuits from those districts
        if (selectedDistricts.length > 0) {
          const districtNames = selectedDistricts.map(d => d.name);
          q = query(
            institutionsRef,
            where("type", "==", "Circuit Office"),
            where("district", "in", districtNames),
            orderBy("name")
          );
        } else if (selectedProvinces.length > 0) {
          // If no districts but provinces are selected
          const provinceNames = selectedProvinces.map(p => p.name);
          q = query(
            institutionsRef,
            where("type", "==", "Circuit Office"),
            where("province", "in", provinceNames),
            orderBy("name")
          );
        } else {
          q = query(
            institutionsRef,
            where("type", "==", "Circuit Office"),
            orderBy("name"),
            limit(50) // Limit if no filters applied
          );
        }
        
        const querySnapshot = await getDocs(q);
        const circuitsList = [];
        
        querySnapshot.forEach((doc) => {
          circuitsList.push({
            id: doc.id,
            name: doc.data().name || 'Unnamed Circuit',
            district: doc.data().district,
            province: doc.data().province,
            type: doc.data().type
          });
        });
        
        setAvailableCircuits(circuitsList);
      } catch (error) {
        console.error('Error fetching circuits:', error);
        setError('Failed to load circuits');
      } finally {
        setCircuitsLoading(false);
      }
    };
    
    fetchCircuits();
  }, [operationalLevels, selectedProvinces, selectedDistricts]);
  
  // Fetch schools
  useEffect(() => {
    const fetchSchools = async () => {
      if (!operationalLevels.includes('School Level')) return;
      
      setSchoolsLoading(true);
      try {
        const institutionsRef = collection(db, "institutions");
        let q;
        
        // Build query based on selections at higher levels
        if (selectedCircuits.length > 0) {
          const circuitNames = selectedCircuits.map(c => c.name);
          q = query(
            institutionsRef,
            where("type", "==", "School"),
            where("circuit", "in", circuitNames),
            orderBy("name")
          );
        } else if (selectedDistricts.length > 0) {
          const districtNames = selectedDistricts.map(d => d.name);
          q = query(
            institutionsRef,
            where("type", "==", "School"),
            where("district", "in", districtNames),
            orderBy("name")
          );
        } else if (selectedProvinces.length > 0) {
          const provinceNames = selectedProvinces.map(p => p.name);
          q = query(
            institutionsRef,
            where("type", "==", "School"),
            where("province", "in", provinceNames),
            orderBy("name"),
            limit(100) // Limit for province-level queries
          );
        } else {
          q = query(
            institutionsRef,
            where("type", "==", "School"),
            orderBy("name"),
            limit(50) // Stricter limit if no filters applied
          );
        }
        
        const querySnapshot = await getDocs(q);
        const schoolsList = [];
        
        querySnapshot.forEach((doc) => {
          schoolsList.push({
            id: doc.id,
            name: doc.data().name || 'Unnamed School',
            circuit: doc.data().circuit,
            district: doc.data().district,
            province: doc.data().province,
            type: doc.data().type
          });
        });
        
        setAvailableSchools(schoolsList);
      } catch (error) {
        console.error('Error fetching schools:', error);
        setError('Failed to load schools');
      } finally {
        setSchoolsLoading(false);
      }
    };
    
    fetchSchools();
  }, [operationalLevels, selectedProvinces, selectedDistricts, selectedCircuits]);
  
  // Handle operational level change
  const handleOperationalLevelChange = (level) => {
    if (operationalLevels.includes(level)) {
      setOperationalLevels(operationalLevels.filter(l => l !== level));
      
      // Clear selections when removing a level
      if (level === 'Provincial Level') {
        setSelectedProvinces([]);
      } else if (level === 'District Level') {
        setSelectedDistricts([]);
      } else if (level === 'Circuit Level') {
        setSelectedCircuits([]);
      } else if (level === 'School Level') {
        setSelectedSchools([]);
      }
    } else {
      setOperationalLevels([...operationalLevels, level]);
    }
  };
  
  // Handle selections
  const handleProvinceSelect = (province) => {
    if (!selectedProvinces.some(p => p.id === province.id)) {
      setSelectedProvinces([...selectedProvinces, province]);
    }
  };
  
  const handleProvinceRemove = (provinceId) => {
    setSelectedProvinces(selectedProvinces.filter(p => p.id !== provinceId));
  };
  
  const handleDistrictSelect = (district) => {
    if (!selectedDistricts.some(d => d.id === district.id)) {
      setSelectedDistricts([...selectedDistricts, district]);
    }
  };
  
  const handleDistrictRemove = (districtId) => {
    setSelectedDistricts(selectedDistricts.filter(d => d.id !== districtId));
  };
  
  const handleCircuitSelect = (circuit) => {
    if (!selectedCircuits.some(c => c.id === circuit.id)) {
      setSelectedCircuits([...selectedCircuits, circuit]);
    }
  };
  
  const handleCircuitRemove = (circuitId) => {
    setSelectedCircuits(selectedCircuits.filter(c => c.id !== circuitId));
  };
  
  const handleSchoolSelect = (school) => {
    if (!selectedSchools.some(s => s.id === school.id)) {
      setSelectedSchools([...selectedSchools, school]);
    }
  };
  
  const handleSchoolRemove = (schoolId) => {
    setSelectedSchools(selectedSchools.filter(s => s.id !== schoolId));
  };
  
  // Validate form
  const validateForm = () => {
    if (!projectName.trim()) {
      setError('Project/Programme name is required');
      return false;
    }
    
    if (!projectType) {
      setError('Please select Project or Programme');
      return false;
    }
    
    if (operationalLevels.length === 0) {
      setError('Please select at least one operational level');
      return false;
    }
    
    // Validate that each selected operational level has at least one institution
    if (operationalLevels.includes('Provincial Level') && selectedProvinces.length === 0) {
      setError('Please select at least one province');
      return false;
    }
    
    if (operationalLevels.includes('District Level') && selectedDistricts.length === 0) {
      setError('Please select at least one district');
      return false;
    }
    
    if (operationalLevels.includes('Circuit Level') && selectedCircuits.length === 0) {
      setError('Please select at least one circuit');
      return false;
    }
    
    if (operationalLevels.includes('School Level') && selectedSchools.length === 0) {
      setError('Please select at least one school');
      return false;
    }
    
    // Validate date range if both dates are provided
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      
      if (start > end) {
        setError('End date must be after start date');
        return false;
      }
    }
    
    return true;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setIsLoading(true);
    setError('');
    
    try {
      // Prepare data for submission
      const projectData = {
        projectId, // For reference, not the document ID
        name: projectName.trim(),
        type: projectType,
        description: description.trim(),
        startDate: startDate ? new Date(startDate) : null,
        endDate: endDate ? new Date(endDate) : null,
        budget: budget.trim(),
        status,
        
        // Coordinator information
        coordinator: coordinator.trim(),
        coordinatorEmail: coordinatorEmail.trim(),
        coordinatorPhone: coordinatorPhone.trim(),
        
        // Operational levels
        operationalLevels,
        
        // Selected institutions
        provinces: selectedProvinces.map(p => ({
          id: p.id,
          name: p.name
        })),
        districts: selectedDistricts.map(d => ({
          id: d.id,
          name: d.name,
          province: d.province
        })),
        circuits: selectedCircuits.map(c => ({
          id: c.id,
          name: c.name,
          district: c.district,
          province: c.province
        })),
        schools: selectedSchools.map(s => ({
          id: s.id,
          name: s.name,
          circuit: s.circuit,
          district: s.district,
          province: s.province
        })),
        
        // Metadata
        createdBy: currentUser?.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };
      
      // Add to Firestore
      const docRef = await addDoc(collection(db, "institutions"), projectData);
      
      setSuccess(`${projectType} registered successfully!`);
      
      // Redirect after a short delay
      setTimeout(() => {
        navigate('/institutions/projects');
      }, 1500);
      
    } catch (err) {
      console.error("Error registering project/programme:", err);
      setError(`Failed to register: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Institution Selection component
  const InstitutionSelection = ({ 
    title, 
    institutions, 
    selectedInstitutions, 
    onSelect, 
    onRemove, 
    isLoading, 
    icon: Icon
  }) => {
    const [searchTerm, setSearchTerm] = useState('');
    
    const filteredInstitutions = searchTerm.trim() === '' 
      ? institutions 
      : institutions.filter(inst => 
          inst.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    
    return (
      <div className="mt-4 border border-gray-200 rounded-md p-4">
        <h3 className="text-sm font-medium text-gray-700 mb-2 flex items-center">
          <Icon className="h-4 w-4 mr-1" />
          {title}
        </h3>
        
        <div className="mb-3">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
            <input
              type="text"
              placeholder="Search..."
              className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        
        {isLoading ? (
          <div className="text-center py-4">
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-2 text-sm text-gray-500">Loading...</p>
          </div>
        ) : filteredInstitutions.length === 0 ? (
          <p className="text-sm text-gray-500 py-2">No institutions found</p>
        ) : (
          <div className="max-h-40 overflow-y-auto mb-3 border rounded-md">
            <div className="divide-y divide-gray-200">
              {filteredInstitutions.map(institution => (
                <div 
                  key={institution.id} 
                  className="flex items-center p-2 hover:bg-gray-50"
                >
                  <input
                    id={`institution-${institution.id}`}
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={selectedInstitutions.some(i => i.id === institution.id)}
                    onChange={() => {
                      if (selectedInstitutions.some(i => i.id === institution.id)) {
                        onRemove(institution.id);
                      } else {
                        onSelect(institution);
                      }
                    }}
                  />
                  <label 
                    htmlFor={`institution-${institution.id}`}
                    className="ml-3 block text-sm text-gray-700 cursor-pointer w-full"
                  >
                    <div>{institution.name}</div>
                    {institution.district && (
                      <div className="text-xs text-gray-500">
                        {institution.district}{institution.province ? `, ${institution.province}` : ''}
                      </div>
                    )}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {selectedInstitutions.length > 0 && (
          <div>
            <div className="text-xs font-medium text-gray-500 mb-2">
              Selected ({selectedInstitutions.length}):
            </div>
            <div className="flex flex-wrap gap-2">
              {selectedInstitutions.map(institution => (
                <div 
                  key={institution.id}
                  className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded-full flex items-center"
                >
                  <span className="truncate max-w-[150px]">{institution.name}</span>
                  <button
                    type="button"
                    onClick={() => onRemove(institution.id)}
                    className="ml-1 text-blue-600 hover:text-blue-800"
                  >
                    <X className="h-3 w-3" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };
  
  return (
    <div className="max-w-7xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/institutions/projects')}
            className="mr-4 text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <h1 className="text-xl font-bold text-gray-900">Register New Project/Programme</h1>
        </div>
      </div>
      
      {error && (
        <div className="mx-6 my-4 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
          {error}
        </div>
      )}
      
      {success && (
        <div className="mx-6 my-4 bg-green-50 border-l-4 border-green-500 p-4 text-green-700">
          {success}
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="p-6 space-y-6">
        {/* Basic Information */}
        <div className="bg-gray-50 p-6 rounded-md">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="projectType" className="block text-sm font-medium text-gray-700 mb-1">
                Type <span className="text-red-500">*</span>
              </label>
              <select
                id="projectType"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                value={projectType}
                onChange={(e) => setProjectType(e.target.value)}
                required
              >
                <option value="">Select type</option>
                {projectTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label htmlFor="projectId" className="block text-sm font-medium text-gray-700 mb-1">
                Project/Programme ID
              </label>
              <input
                id="projectId"
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-gray-100"
                value={projectId}
                readOnly
              />
              <p className="mt-1 text-xs text-gray-500">Auto-generated based on type</p>
            </div>
            
            <div className="md:col-span-2">
              <label htmlFor="projectName" className="block text-sm font-medium text-gray-700 mb-1">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                id="projectName"
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                required
              />
            </div>
            
            <div className="md:col-span-2">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                id="description"
                rows={3}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            
            <div>
              <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <select
                id="status"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                {statusOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label htmlFor="budget" className="block text-sm font-medium text-gray-700 mb-1">
                Budget
              </label>
              <input
                id="budget"
                type="text"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
                placeholder="ZAR"
              />
            </div>
            
            <div>
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <div className="relative">
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
                <input
                  id="startDate"
                  type="date"
                  className="mt-1 block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            
            <div>
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                End Date
              </label>
              <div className="relative">
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
                <input
                  id="endDate"
                  type="date"
                  className="mt-1 block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        
        {/* Coordinator Information */}
        <div className="bg-gray-50 p-6 rounded-md">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Coordinator Information</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="coordinator" className="block text-sm font-medium text-gray-700 mb-1">
                Coordinator Name
              </label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
                <input
                  id="coordinator"
                  type="text"
                  className="mt-1 block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={coordinator}
                  onChange={(e) => setCoordinator(e.target.value)}
                />
              </div>
            </div>
            
            <div>
              <label htmlFor="coordinatorPhone" className="block text-sm font-medium text-gray-700 mb-1">
                Phone Number
              </label>
              <div className="relative">
                <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
                <input
                  id="coordinatorPhone"
                  type="tel"
                  className="mt-1 block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={coordinatorPhone}
                  onChange={(e) => setCoordinatorPhone(e.target.value)}
                />
              </div>
            </div>
            
            <div className="md:col-span-2">
              <label htmlFor="coordinatorEmail" className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
                <input
                  id="coordinatorEmail"
                  type="email"
                  className="mt-1 block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={coordinatorEmail}
                  onChange={(e) => setCoordinatorEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        
        {/* Operational Levels */}
        <div className="bg-gray-50 p-6 rounded-md">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Operational Levels</h2>
          <p className="text-sm text-gray-600 mb-4">
            Select the levels at which this {projectType || 'project/programme'} operates
          </p>
          
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
            {operationalLevelOptions.map(level => (
              <div key={level} className="flex items-center">
                <input
                  id={`level-${level}`}
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={operationalLevels.includes(level)}
                  onChange={() => handleOperationalLevelChange(level)}
                />
                <label
                  htmlFor={`level-${level}`}
                  className="ml-3 block text-sm text-gray-700"
                >
                  {level}
                </label>
              </div>
            ))}
          </div>
          
          {/* Institution selections based on operational levels */}
          {operationalLevels.includes('Provincial Level') && (
            <InstitutionSelection
              title="Select Provinces"
              institutions={availableProvinces}
              selectedInstitutions={selectedProvinces}
              onSelect={handleProvinceSelect}
              onRemove={handleProvinceRemove}
              isLoading={provincesLoading}
              icon={Landmark}
            />
          )}
          
          {operationalLevels.includes('District Level') && (
            <InstitutionSelection
              title="Select Districts"
              institutions={availableDistricts}
              selectedInstitutions={selectedDistricts}
              onSelect={handleDistrictSelect}
              onRemove={handleDistrictRemove}
              isLoading={districtsLoading}
              icon={Building}
            />
          )}
          
          {operationalLevels.includes('Circuit Level') && (
            <InstitutionSelection
              title="Select Circuits"
              institutions={availableCircuits}
              selectedInstitutions={selectedCircuits}
              onSelect={handleCircuitSelect}
              onRemove={handleCircuitRemove}
              isLoading={circuitsLoading}
              icon={Building2}
            />
          )}
          
          {operationalLevels.includes('School Level') && (
            <InstitutionSelection
              title="Select Schools"
              institutions={availableSchools}
              selectedInstitutions={selectedSchools}
              onSelect={handleSchoolSelect}
              onRemove={handleSchoolRemove}
              isLoading={schoolsLoading}
              icon={School}
            />
          )}
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-3 pt-6">
          <button
            type="button"
            onClick={() => navigate('/institutions/projects')}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white mr-2"></div>
                Registering...
              </>
            ) : (
              <>
                <Save className="h-4 w-4 mr-2" />
                Register {projectType || 'Project/Programme'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProjectProgrammeRegistration;