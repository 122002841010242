// src/components/stakeholders/StakeholderForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Save } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';

const StakeholderForm = ({ isEditing = false }) => {
  const navigate = useNavigate();
  const { stakeholderId } = useParams();
  const { userProfile } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Form fields
  const [stakeholderName, setStakeholderName] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  // Dropdown options
  const categories = ['NGO', 'Government', 'Supplier', 'Supporter', 'Community Organization', 'Corporate', 'Educational Institution'];

  useEffect(() => {
    const fetchStakeholder = async () => {
      if (!isEditing || !stakeholderId) return;
      
      setIsLoading(true);
      try {
        const stakeholderDoc = await getDoc(doc(db, "stakeholders", stakeholderId));
        
        if (stakeholderDoc.exists()) {
          const data = stakeholderDoc.data();
          setStakeholderName(data.name || '');
          setCategory(data.category || '');
          setDescription(data.description || '');
          setContactPerson(data.contactPerson || '');
          setContactNumber(data.contactNumber || '');
          setContactEmail(data.contactEmail || '');
        } else {
          setError("Stakeholder not found");
          navigate('/stakeholders');
        }
      } catch (error) {
        console.error("Error fetching stakeholder:", error);
        setError("Failed to load stakeholder. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStakeholder();
  }, [isEditing, stakeholderId, navigate]);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email === '' || re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!stakeholderName.trim()) {
      setError('Stakeholder name is required');
      return;
    }
    
    if (!category) {
      setError('Category is required');
      return;
    }
    
    if (contactEmail && !validateEmail(contactEmail)) {
      setError('Please enter a valid email address');
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      const stakeholderData = {
        name: stakeholderName.trim(),
        category,
        description,
        contactPerson,
        contactNumber,
        contactEmail,
        institutionName: userProfile?.institutionName,
        institutionName: userProfile?.institutionName,
        updatedBy: userProfile?.id,
        updatedAt: new Date(),
        ...(isEditing ? {} : { createdBy: userProfile?.id, createdAt: new Date() })
      };
      
      const stakeholderRef = isEditing 
        ? doc(db, "stakeholders", stakeholderId) 
        : doc(collection(db, "stakeholders"));
        
      await setDoc(stakeholderRef, stakeholderData, { merge: isEditing });
      
      setSuccessMessage(`Stakeholder successfully ${isEditing ? 'updated' : 'created'}`);
      setTimeout(() => {
        navigate('/stakeholders');
      }, 1500);
    } catch (err) {
      console.error(err);
      setError(`Failed to ${isEditing ? 'update' : 'create'} stakeholder: ${err.message || 'Unknown error'}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
      {/* Header */}
      <div className="flex items-start justify-between mb-6">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/stakeholders')}
            className="mr-4 text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft size={18} className="h-5 w-5" />
          </button>
          <h1 className="text-xl font-bold text-gray-900">
            {isEditing ? 'Edit Stakeholder' : 'Add New Stakeholder'}
          </h1>
        </div>
      </div>
  
      {/* Error Message */}
      {error && (
        <div className="mb-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
          {error}
        </div>
      )}
  
      {/* Success Message */}
      {successMessage && (
        <div className="mb-4 p-3 bg-green-50 border-l-4 border-green-500 text-green-700">
          {successMessage}
        </div>
      )}
  
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Stakeholder Name */}
          <div>
            <label htmlFor="stakeholderName" className="block text-sm font-medium text-gray-700 mb-1">
              Stakeholder Name<span className="text-red-500">*</span>
            </label>
            <input
              id="stakeholderName"
              name="stakeholderName"
              type="text"
              required
              className="w-full pl-2 py-2 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={stakeholderName}
              onChange={(e) => setStakeholderName(e.target.value)}
              disabled={isLoading}
            />
          </div>
  
          {/* Category */}
          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
              Category<span className="text-red-500">*</span>
            </label>
            <select
              id="category"
              name="category"
              required
              className="w-full pl-2 py-2 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              disabled={isLoading}
            >
              <option value="">Select a category</option>
              {categories.map((cat) => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>
          </div>
  
          {/* Description */}
          <div className="md:col-span-2">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              rows="3"
              className="w-full pl-2 py-2 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={isLoading}
            ></textarea>
          </div>
  
          {/* Contact Person */}
          <div>
            <label htmlFor="contactPerson" className="block text-sm font-medium text-gray-700 mb-1">
              Contact Person
            </label>
            <input
              id="contactPerson"
              name="contactPerson"
              type="text"
              className="w-full pl-2 py-2 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={contactPerson}
              onChange={(e) => setContactPerson(e.target.value)}
              disabled={isLoading}
            />
          </div>
  
          {/* Contact Number */}
          <div>
            <label htmlFor="contactNumber" className="block text-sm font-medium text-gray-700 mb-1">
              Contact Number
            </label>
            <input
              id="contactNumber"
              name="contactNumber"
              type="tel"
              className="w-full pl-2 py-2 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              disabled={isLoading}
            />
          </div>
  
          {/* Contact Email */}
          <div>
            <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700 mb-1">
              Contact Email
            </label>
            <input
              id="contactEmail"
              name="contactEmail"
              type="email"
              className="w-full pl-2 py-2 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              disabled={isLoading}
            />
          </div>
        </div>
  
        {/* Form Actions */}
        <div className="mt-8 flex items-center justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate('/stakeholders')}
            className="px-4 py-2 border border-gray-400 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center"
            disabled={isLoading}
          >
            <Save size={16} className="mr-2" />
            {isLoading ? 'Saving...' : 'Save Stakeholder'}
          </button>
        </div>
      </form>
    </div>
  );
  };
  
  export default StakeholderForm;
  