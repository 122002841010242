// src/components/tasks/TaskForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { 
  Save, ArrowLeft, Plus, X, Calendar, 
  CheckCircle, ChevronDown, Target, Clock, User 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, 
  doc, 
  getDoc, 
  setDoc, 
  addDoc, 
  query,
  where,
  limit,
  getDocs,
  orderBy,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';

const TaskForm = ({ isEditing = false }) => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, userProfile } = useAuth();
  
  // Parse query params for optional plan reference
  const queryParams = new URLSearchParams(location.search);
  const planIdParam = queryParams.get('planId');
  const planTitleParam = queryParams.get('planTitle');
  
  // Form state
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('Planned');
  const [objectives, setObjectives] = useState([{ text: '' }]);
  const [taskRefType, setTaskRefType] = useState(planIdParam ? 'Plan' : '');
  const [taskRefId, setTaskRefId] = useState(planIdParam || '');
  const [taskRefTitle, setTaskRefTitle] = useState(planTitleParam || '');
  const [assignee, setAssignee] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState('Not Started');
  const [priority, setPriority] = useState('Medium');
  
  // UI state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [plans, setPlans] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [records, setRecords] = useState([]);
  const [showRefOptions, setShowRefOptions] = useState(false);
  
  // Categories for dropdown
  const categories = ['Planned', 'Meeting', 'Instruction'];
  const statuses = ['Not Started', 'In Progress', 'Completed'];
  const priorities = ['Low', 'Medium', 'High', 'Urgent'];
  
  // Mock data for assignees - in a real app this would come from the users collection
  const mockAssignees = [
    'John Smith',
    'Maria Garcia',
    'Ahmed Khan',
    'Sarah Johnson',
    'David Chen',
    'Olivia Williams'
  ];
  
  // Fetch task data if editing
  useEffect(() => {
    if (isEditing && taskId) {
      setIsLoading(true);
      const fetchTask = async () => {
        try {
          const taskDoc = await getDoc(doc(db, 'tasks', taskId));
          
          if (taskDoc.exists()) {
            const taskData = taskDoc.data();
            setDescription(taskData.description || '');
            setCategory(taskData.category || 'Planned');
            setObjectives(taskData.objectives?.length ? taskData.objectives : [{ text: '' }]);
            
            if (taskData.taskRef) {
              setTaskRefType(taskData.taskRef.type || '');
              setTaskRefId(taskData.taskRef.id || '');
              setTaskRefTitle(taskData.taskRef.title || '');
            }
            
            setAssignee(taskData.assignee || '');
            
            // Set dates if available
            if (taskData.startDate) {
              const startTimestamp = taskData.startDate;
              const startDate = startTimestamp.toDate ? startTimestamp.toDate() : new Date(startTimestamp);
              setStartDate(startDate.toISOString().split('T')[0]);
            }
            
            if (taskData.endDate) {
              const endTimestamp = taskData.endDate;
              const endDate = endTimestamp.toDate ? endTimestamp.toDate() : new Date(endTimestamp);
              setEndDate(endDate.toISOString().split('T')[0]);
            }
            
            setStatus(taskData.status || 'Not Started');
            setPriority(taskData.priority || 'Medium');
          } else {
            setError('Task not found');
          }
        } catch (err) {
          console.error('Error fetching task:', err);
          setError('Failed to load task data');
        } finally {
          setIsLoading(false);
        }
      };
      
      fetchTask();
    }
  }, [isEditing, taskId]);
  
  useEffect(() => {
    if (!userProfile?.institutionName) return;
    
    // Parameters from URL
    const planIdParam = new URLSearchParams(location.search).get('planId');
    const planTitleParam = new URLSearchParams(location.search).get('planTitle');
    
    const meetingIdParam = new URLSearchParams(location.search).get('meetingId');
    const meetingTitleParam = new URLSearchParams(location.search).get('meetingTitle');
    
    const recordIdParam = new URLSearchParams(location.search).get('referenceId');
    const recordTitleParam = new URLSearchParams(location.search).get('referenceTitle');
    const referenceTypeParam = new URLSearchParams(location.search).get('referenceType');
    
    // Fetch plans
    const fetchPlans = async () => {
      try {
        const plansRef = collection(db, 'plans');
        const q = query(
          plansRef,
          where('institutionName', '==', userProfile.institutionName)
        );
        
        const querySnapshot = await getDocs(q);
        const plansList = [];
        
        querySnapshot.forEach((doc) => {
          plansList.push({
            id: doc.id,
            title: doc.data().title,
            type: 'Plan'
          });
        });
        
        setPlans(plansList);
        
        // If planIdParam exists, find and set the plan title
        if (planIdParam && !planTitleParam) {
          const plan = plansList.find(p => p.id === planIdParam);
          if (plan) {
            setTaskRefTitle(plan.title);
            setTaskRefType('Plan');
            setTaskRefId(planIdParam);
          }
        }
      } catch (err) {
        console.error('Error fetching plans:', err);
      }
    };
    
    // Fetch meetings
    const fetchMeetings = async () => {
      try {
        const meetingsRef = collection(db, 'meetings');
        const q = query(
          meetingsRef,
          where('institutionName', '==', userProfile.institutionName),
          orderBy('date', 'desc'),
          limit(50)
        );
        
        const querySnapshot = await getDocs(q);
        const meetingsList = [];
        
        querySnapshot.forEach((doc) => {
          meetingsList.push({
            id: doc.id,
            title: doc.data().title || `Meeting ${doc.id}`,
            type: 'Meeting'
          });
        });
        
        setMeetings(meetingsList);
        
        // If meetingIdParam exists, find and set the meeting title
        if (meetingIdParam && !meetingTitleParam) {
          const meeting = meetingsList.find(m => m.id === meetingIdParam);
          if (meeting) {
            setTaskRefTitle(meeting.title);
            setTaskRefType('Meeting');
            setTaskRefId(meetingIdParam);
          }
        }
      } catch (err) {
        console.error('Error fetching meetings:', err);
      }
    };
    
    // Fetch records
    const fetchRecords = async () => {
      try {
        const recordsRef = collection(db, 'records');
        const q = query(
          recordsRef,
          where('institutionId', '==', userProfile.institutionId || ''),
          orderBy('date', 'desc'),
          limit(50)
        );
        
        const querySnapshot = await getDocs(q);
        const recordsList = [];
        
        querySnapshot.forEach((doc) => {
          const recordData = doc.data();
          const recordTitle = recordData.recordMessage || `Record ${doc.id}`;
          
          recordsList.push({
            id: doc.id,
            title: recordTitle,
            type: 'Record'
          });
        });
        
        setRecords(recordsList);
        
        // If recordIdParam exists and reference type is Record
        if (recordIdParam && referenceTypeParam === 'Record' && !recordTitleParam) {
          const record = recordsList.find(r => r.id === recordIdParam);
          if (record) {
            setTaskRefTitle(record.title);
            setTaskRefType('Record');
            setTaskRefId(recordIdParam);
          } else {
            // Fetch the specific record if not found in our list
            try {
              const recordDoc = await getDoc(doc(db, "records", recordIdParam));
              if (recordDoc.exists()) {
                const recordData = recordDoc.data();
                const recordTitle = recordData.recordMessage || `Record ${recordIdParam}`;
                setTaskRefTitle(recordTitle);
                setTaskRefType('Record');
                setTaskRefId(recordIdParam);
              }
            } catch (err) {
              console.error('Error fetching specific record:', err);
            }
          }
        }
      } catch (err) {
        console.error('Error fetching records:', err);
      }
    };
    
    // Execute fetch operations
    fetchPlans();
    fetchMeetings();
    fetchRecords();
    
    // Handle direct URL parameters when titles are provided
    if (planIdParam && planTitleParam) {
      setTaskRefType('Plan');
      setTaskRefId(planIdParam);
      setTaskRefTitle(planTitleParam);
    }
    
    if (meetingIdParam && meetingTitleParam) {
      setTaskRefType('Meeting');
      setTaskRefId(meetingIdParam);
      setTaskRefTitle(meetingTitleParam);
    }
    
    if (recordIdParam && recordTitleParam && referenceTypeParam === 'Record') {
      setTaskRefType('Record');
      setTaskRefId(recordIdParam);
      setTaskRefTitle(recordTitleParam);
    }
    
  }, [userProfile, location.search]);

  // Handle objective actions
  const addObjective = () => {
    setObjectives([...objectives, { text: '' }]);
  };
  
  const updateObjective = (index, value) => {
    const updatedObjectives = [...objectives];
    updatedObjectives[index] = { text: value };
    setObjectives(updatedObjectives);
  };
  
  const removeObjective = (index) => {
    if (objectives.length > 1) {
      const updatedObjectives = [...objectives];
      updatedObjectives.splice(index, 1);
      setObjectives(updatedObjectives);
    }
  };
  
  // Calculate duration between start and end dates
  const calculateDuration = () => {
    if (!startDate || !endDate) return null;
    
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    // Calculate difference in milliseconds
    const diffTime = Math.abs(end - start);
    // Convert to days
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return diffDays;
  };
  
  // Handle reference item selection
  const handleSelectRefItem = (item) => {
    setTaskRefType(item.type);
    setTaskRefId(item.id);
    setTaskRefTitle(item.title);
    setShowRefOptions(false);
  };
  
  // Get all reference items based on type
  const getRefItems = () => {
    switch (taskRefType) {
      case 'Plan':
        return plans;
      case 'Meeting':
        return meetings;
      case 'Record':
        return records;
      default:
        return [];
    }
  };
  
  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!description.trim()) {
      setError('Task description is required');
      return;
    }
    
    // Validate dates if both are provided
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      
      if (start > end) {
        setError('End date must be after start date');
        return;
      }
    }
    
    setIsLoading(true);
    setError('');
    
    try {
      // Calculate duration
      const duration = calculateDuration();
      
      // Prepare task data
      const taskData = {
        description: description.trim(),
        category,
        objectives: objectives.filter(objective => objective.text.trim()),
        taskRef: taskRefId ? {
          type: taskRefType,
          id: taskRefId,
          title: taskRefTitle
        } : null,
        assignee: assignee || null,
        startDate: startDate ? Timestamp.fromDate(new Date(startDate)) : null,
        endDate: endDate ? Timestamp.fromDate(new Date(endDate)) : null,
        duration,
        status,
        priority,
        userId: currentUser.uid,
        institutionName: userProfile.institutionName,
        updatedAt: serverTimestamp()
      };
      
      if (isEditing) {
        // Update existing task
        await setDoc(doc(db, 'tasks', taskId), {
          ...taskData,
        }, { merge: true });
        
        setSuccess('Task updated successfully');
      } else {
        // Create new task
        taskData.date = serverTimestamp();
        taskData.createdAt = serverTimestamp();
        
        const docRef = await addDoc(collection(db, 'tasks'), taskData);
        setSuccess('Task created successfully');
      }
      
      // Navigate back after a short delay
      setTimeout(() => {
        if (planIdParam) {
          navigate(`/planner/details/${planIdParam}`);
        } else {
          navigate('/tasks');
        }
      }, 1500);
      
    } catch (err) {
      console.error('Error saving task:', err);
      setError(`Failed to ${isEditing ? 'update' : 'create'} task: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">
          {isEditing ? 'Edit Task' : 'Create New Task'}
        </h2>
        <button
          onClick={() => planIdParam ? navigate(`/planner/details/${planIdParam}`) : navigate('/tasks')}
          className="text-gray-600 hover:text-gray-900 flex items-center"
        >
          <ArrowLeft size={16} className="mr-2" />
          {planIdParam ? 'Back to Plan' : 'Back to Tasks'}
        </button>
      </div>
      
      {/* Error and Success Messages */}
      {error && (
        <div className="mx-6 my-4 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
          {error}
        </div>
      )}
      
      {success && (
        <div className="mx-6 my-4 bg-green-50 border-l-4 border-green-500 p-4 text-green-700">
          {success}
        </div>
      )}
      
      {/* Loading indicator */}
      {isLoading && (
        <div className="flex justify-center items-center p-6">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="p-6 space-y-6">
        {/* Task Description */}
        <div>
  <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
    Task Description <span className="text-red-500">*</span>
  </label>
  <textarea
    id="description"
    rows={3}
    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
    value={description}
    onChange={(e) => setDescription(e.target.value)}
    required
    disabled={isLoading}
  />
</div>

{/* Basic Information */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
  <div>
    <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
      Category <span className="text-red-500">*</span>
    </label>
    <select
      id="category"
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      value={category}
      onChange={(e) => setCategory(e.target.value)}
      required
      disabled={isLoading}
    >
      {categories.map((cat) => (
        <option key={cat} value={cat}>{cat}</option>
      ))}
    </select>
  </div>
  
  <div>
    <label htmlFor="assignee" className="block text-sm font-medium text-gray-700 mb-1">
      Assignee
    </label>
    <select
      id="assignee"
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      value={assignee}
      onChange={(e) => setAssignee(e.target.value)}
      disabled={isLoading}
    >
      <option value="">Select assignee</option>
      {mockAssignees.map((person) => (
        <option key={person} value={person}>{person}</option>
      ))}
    </select>
  </div>
</div>

{/* Objectives */}
<div>
  <div className="flex justify-between items-center mb-2">
    <label className="block text-sm font-medium text-gray-700">
      Objectives
    </label>
    <button
      type="button"
      className="text-blue-600 hover:text-blue-800 text-sm flex items-center"
      onClick={addObjective}
      disabled={isLoading}
    >
      <Plus size={14} className="mr-1" />
      Add Objective
    </button>
  </div>
  
  <div className="space-y-2">
    {objectives.map((objective, index) => (
      <div key={index} className="flex items-center">
        <div className="flex-shrink-0 mr-2">
          <div className="w-6 h-6 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 text-xs font-medium">
            {index + 1}
          </div>
        </div>
        <input
          type="text"
          className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={objective.text}
          onChange={(e) => updateObjective(index, e.target.value)}
          placeholder="Enter objective..."
          disabled={isLoading}
        />
        <button
          type="button"
          className="ml-2 text-gray-400 hover:text-red-500"
          onClick={() => removeObjective(index)}
          disabled={isLoading || objectives.length <= 1}
        >
          <X size={16} />
        </button>
      </div>
    ))}
  </div>
</div>

{/* Task Reference */}
<div>
  <label className="block text-sm font-medium text-gray-700 mb-2">
    Task Reference
  </label>
  
  <div className="border border-gray-300 rounded-md p-4">
    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
      <div className="flex-1 mb-3 md:mb-0 md:mr-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Reference Type
        </label>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={taskRefType}
          onChange={(e) => {
            setTaskRefType(e.target.value);
            setTaskRefId('');
            setTaskRefTitle('');
          }}
          disabled={isLoading}
        >
          <option value="">Select reference type</option>
          <option value="Plan">Plan</option>
          <option value="Meeting">Meeting</option>
          <option value="Record">Record</option>
        </select>
      </div>
      
      <div className="flex-1">
        <div className="relative">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Referenced Item
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              className="flex-1 px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
              placeholder={taskRefType ? `Select a ${taskRefType.toLowerCase()}...` : "Select a reference type first"}
              value={taskRefTitle}
              readOnly
              disabled={!taskRefType}
            />
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-l-0 border-gray-300 rounded-r-md bg-gray-50 text-gray-500 hover:text-gray-600"
              onClick={() => taskRefType && setShowRefOptions(!showRefOptions)}
              disabled={!taskRefType || isLoading}
            >
              {showRefOptions ? <X size={16} /> : <ChevronDown size={16} />}
            </button>
          </div>
          
          {/* Reference options dropdown */}
          {showRefOptions && taskRefType && (
            <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
              {getRefItems().length > 0 ? (
                getRefItems().map((item) => (
                  <div
                    key={item.id}
                    className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                    onClick={() => handleSelectRefItem(item)}
                  >
                    <span className="block truncate">{item.title}</span>
                  </div>
                ))
              ) : (
                <div className="cursor-default select-none relative py-2 pl-3 pr-9 text-gray-500">
                  No {taskRefType.toLowerCase()}s available
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
</div>

{/* Time Frame */}
<div className="grid grid-cols-1 md:grid-cols-3 gap-6">
  <div>
    <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
      Start Date
    </label>
    <input
      id="startDate"
      type="date"
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      value={startDate}
      onChange={(e) => setStartDate(e.target.value)}
      disabled={isLoading}
    />
  </div>
  
  <div>
    <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
      End Date
    </label>
    <input
      id="endDate"
      type="date"
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      value={endDate}
      onChange={(e) => setEndDate(e.target.value)}
      disabled={isLoading}
    />
  </div>
  
  <div>
    <label htmlFor="duration" className="block text-sm font-medium text-gray-700 mb-1">
      Duration (days)
    </label>
    <div className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50 text-gray-700">
      {calculateDuration() !== null ? calculateDuration() : 'N/A'}
    </div>
  </div>
</div>

{/* Status and Priority */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
  <div>
    <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-1">
      Status
    </label>
    <select
      id="status"
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      value={status}
      onChange={(e) => setStatus(e.target.value)}
      disabled={isLoading}
    >
      {statuses.map((stat) => (
        <option key={stat} value={stat}>{stat}</option>
      ))}
    </select>
  </div>
  
  <div>
    <label htmlFor="priority" className="block text-sm font-medium text-gray-700 mb-1">
      Priority
    </label>
    <select
      id="priority"
      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      value={priority}
      onChange={(e) => setPriority(e.target.value)}
      disabled={isLoading}
    >
      {priorities.map((pri) => (
        <option key={pri} value={pri}>{pri}</option>
      ))}
    </select>
  </div>
</div>

{/* Submit Button */}
<div className="flex justify-end pt-4 border-t border-gray-200">
  <button
    type="button"
    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-3"
    onClick={() => planIdParam ? navigate(`/planner/details/${planIdParam}`) : navigate('/tasks')}
    disabled={isLoading}
  >
    Cancel
  </button>
  <button
    type="submit"
    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center"
    disabled={isLoading}
  >
    <Save size={16} className="mr-2" />
    {isLoading ? 'Saving...' : isEditing ? 'Update Task' : 'Create Task'}
  </button>
</div>
</form>
</div>
);
};

export default TaskForm;