// src/pages/CreateProfile.js
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Plus, X } from 'lucide-react';
import { db } from '../firebase/config';
import { collection, query, where, getDocs, doc, setDoc, orderBy } from 'firebase/firestore';

const CreateProfile = () => {
  const navigate = useNavigate();
  const { currentUser, userProfile, createUserProfile, profileLoading } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const [schools, setSchools] = useState([]);
  const [isSearchingSchool, setIsSearchingSchool] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Form fields
  const [title, setTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [institutionCategory, setInstitutionCategory] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [circuit, setCircuit] = useState('');
  const [phase, setPhase] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userLevel, setUserLevel] = useState('');
  const [department, setDepartment] = useState('');
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [institutionId, setInstitutionId] = useState('');
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [showProjectSearch, setShowProjectSearch] = useState(false);
  const [projectSearchTerm, setProjectSearchTerm] = useState('');
  
  // Dropdown options
  const titles = ['Ms', 'Mr', 'Dr', 'Prof', 'Rev', 'Adv'];
  const institutionCategories = ['School', 'Circuit', 'District', 'Province'];
  const provinces = [
    'Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 
    'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape'
  ];
  const phases = ['Primary', 'Secondary', 'Combined'];
  const schoolRoles = ['Admin', 'Educator', 'Departmental Head', 'Deputy Principal', 'Principal'];
  const otherRoles = ['Admin', 'Education Specialist', 'Manager'];
  const departments = ['Commerce', 'Humanities', 'Sciences', 'Technical', 'Skills'];
  const gradeOptions = ['00', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const subjectOptions = [
    'Mathematics', 'Physical Sciences', 'Life Sciences', 'Geography', 'History',
    'English', 'Afrikaans', 'isiZulu', 'isiXhosa', 'Accounting', 'Economics',
    'Business Studies', 'Life Orientation', 'Computer Applications Technology',
    'Information Technology', 'Engineering Graphics & Design', 'Technical Sciences'
  ];
  
  // Updated sample data with requested circuit names
  const districtsData = {
    'Gauteng': ['Johannesburg North', 'Johannesburg Central', 'Johannesburg South', 'Tshwane North', 'Tshwane South'],
    'Western Cape': ['Metro Central', 'Metro North', 'Metro South', 'Cape Winelands', 'Eden and Central Karoo'],
    'KwaZulu-Natal': ['Durban Central', 'Pinetown', 'Pietermaritzburg'],
    'Eastern Cape': ['Port Elizabeth', 'East London', 'Mthatha'],
    'Free State': ['Bloemfontein', 'Welkom', 'Bethlehem'],
    'Limpopo': ['Polokwane', 'Tzaneen', 'Thohoyandou'],
    'Mpumalanga': ['Nelspruit', 'Witbank', 'Secunda'],
    'North West': ['Mafikeng', 'Rustenburg', 'Potchefstroom'],
    'Northern Cape': ['Kimberley', 'Upington', 'Springbok']
  };
  
  // Updated circuits data with the requested circuit names
  const circuitsData = {
    'Johannesburg North': ['Naledi Circuit', 'Nelson Mandela Circuit', 'Albert Luthuli Circuit'],
    'Johannesburg Central': ['Soweto Circuit', 'Alexandra Circuit', 'Diepkloof Circuit'],
    'Johannesburg South': ['Lenasia Circuit', 'Eldorado Park Circuit', 'Orange Farm Circuit'],
    'Tshwane North': ['Pretoria Central Circuit', 'Mamelodi Circuit', 'Atteridgeville Circuit'],
    'Tshwane South': ['Centurion Circuit', 'Olievenhoutbosch Circuit', 'Hammanskraal Circuit'],
    'Metro Central': ['Cape Town Circuit', 'Sea Point Circuit', 'Woodstock Circuit'],
    'Metro North': ['Bellville Circuit', 'Durbanville Circuit', 'Parow Circuit'],
    'Metro South': ['Mitchell\'s Plain Circuit', 'Khayelitsha Circuit', 'Grassy Park Circuit'],
    'Cape Winelands': ['Stellenbosch Circuit', 'Paarl Circuit', 'Worcester Circuit'],
    'Durban Central': ['Naledi Circuit', 'Nelson Mandela Circuit', 'Albert Luthuli Circuit'],
    'Polokwane': ['Naledi Circuit', 'Nelson Mandela Circuit', 'Albert Luthuli Circuit'],
    'Bloemfontein': ['Naledi Circuit', 'Nelson Mandela Circuit', 'Albert Luthuli Circuit']
  };
  
  const institutionsData = {
    'School': ['Pretoria High School', 'Johannesburg Secondary School', 'Cape Town Primary', 'Naledi High School', 'Nelson Mandela Academy', 'Albert Luthuli Primary School'],
    'Circuit': ['Johannesburg North Circuit', 'Tshwane Central Circuit', 'Naledi Circuit Office', 'Nelson Mandela Circuit Office', 'Albert Luthuli Circuit Office'],
    'District': ['Johannesburg District', 'Tshwane District', 'Cape Town District'],
    'Province': ['Gauteng Department of Education', 'Western Cape Education Department', 'KwaZulu-Natal Department of Education']
  };
  
  // Filtered options state
  const [districts, setDistricts] = useState([]);
  const [circuits, setCircuits] = useState([]);
  const [institutionList, setInstitutionList] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  
  useEffect(() => {
    const fetchSchools = async () => {
      if (searchTerm.length < 3) return;
      
      setIsSearchingSchool(true);
      try {
        const schoolsRef = collection(db, "schools");
        const q = query(
          schoolsRef, 
          where("schoolName", ">=", searchTerm),
          where("schoolName", "<=", searchTerm + '\uf8ff')
        );
        const querySnapshot = await getDocs(q);
        
        const schoolsList = [];
        querySnapshot.forEach((doc) => {
          schoolsList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setSchools(schoolsList);
      } catch (error) {
        console.error("Error fetching schools:", error);
        setError("Failed to search for schools. Please try again.");
      } finally {
        setIsSearchingSchool(false);
      }
    };
    
    if (searchTerm) {
      fetchSchools();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else if (userProfile) {
      navigate('/');
    }
  }, [currentUser, userProfile, navigate]);
  
  // Handle province change
  useEffect(() => {
    if (province) {
      setDistricts(districtsData[province] || []);
      setDistrict('');
      setCircuit('');
    }
  }, [province]);
  
  // Handle district change
  useEffect(() => {
    if (district) {
      setCircuits(circuitsData[district] || []);
      setCircuit('');
    }
  }, [district]);
  
  // Fetch available projects/programmes
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsRef = collection(db, "institutions");
        const q = query(
          projectsRef,
          where("type", "in", ["Project", "Programme"]),
          orderBy("name")
        );
        
        const querySnapshot = await getDocs(q);
        const projectsList = [];
        
        querySnapshot.forEach((doc) => {
          projectsList.push({
            id: doc.id,
            name: doc.data().name,
            type: doc.data().type
          });
        });
        
        setAvailableProjects(projectsList);
      } catch (err) {
        console.error("Error fetching projects:", err);
      }
    };
    
    fetchProjects();
  }, []);
  // Handle institution category change
  useEffect(() => {
    if (institutionCategory) {
      setInstitutionList(institutionsData[institutionCategory] || []);
      setInstitutionName('');
      
      // Reset role based on institution category
      setUserRole('');
      if (institutionCategory === 'School') {
        setRoleOptions(schoolRoles);
      } else {
        setRoleOptions(otherRoles);
      }
    }
  }, [institutionCategory]);
  
  // Handle grade selection
  const handleGradeChange = (grade) => {
    if (grades.includes(grade)) {
      setGrades(grades.filter(g => g !== grade));
    } else {
      setGrades([...grades, grade]);
    }
  };
  
  // Handle subject selection
  const handleSubjectChange = (subject) => {
    if (subjects.includes(subject)) {
      setSubjects(subjects.filter(s => s !== subject));
    } else {
      setSubjects([...subjects, subject]);
    }
  };

  const handleInstitutionSelect = (institution) => {
    setInstitutionName(institution.name);
    setInstitutionId(institution.id);
  }
  
  // Validate form
  const validateForm = () => {
    if (!title) {
      setError('Title is required');
      return false;
    }
    if (!firstName) {
      setError('First name is required');
      return false;
    }
    if (!lastName) {
      setError('Last name is required');
      return false;
    }
    if (!institutionCategory) {
      setError('Institution category is required');
      return false;
    }
    if (!institutionName) {
      setError('Institution name is required');
      return false;
    }
    if (!province) {
      setError('Province is required');
      return false;
    }
    if (!district) {
      setError('District is required');
      return false;
    }
    if (!circuit) {
      setError('Circuit is required');
      return false;
    }
    if (institutionCategory === 'School' && !phase) {
      setError('Phase is required for schools');
      return false;
    }
    if (!userRole) {
      setError('User role is required');
      return false;
    }
    if (!userLevel) {
      setError('User level is required');
      return false;
    }
    if (['Educator', 'Departmental Head'].includes(userRole) && !department) {
      setError('Department is required for your role');
      return false;
    }
    if (['Educator', 'Departmental Head', 'Deputy Principal', 'Principal'].includes(userRole) && grades.length === 0) {
      setError('Please select at least one grade');
      return false;
    }
    if (['Educator', 'Departmental Head'].includes(userRole) && subjects.length === 0) {
      setError('Please select at least one subject');
      return false;
    }
    return true;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      const profileData = {
        email: currentUser.email,
        uid: currentUser.uid,
        title,
        firstName,
        lastName,
        institutionCategory,
        institutionId, // Add institutionId
        institutionName,
        province,
        district: institutionCategory === 'School' || institutionCategory === 'Circuit' ? district : null,
        circuit: institutionCategory === 'School' ? circuit : null,
        phase: institutionCategory === 'School' ? phase : null,
        userRole,
        userLevel,
        department: ['Educator', 'Departmental Head'].includes(userRole) ? department : null,
        grades: ['Educator', 'Departmental Head', 'Deputy Principal', 'Principal'].includes(userRole) ? grades : [],
        subjects: ['Educator', 'Departmental Head'].includes(userRole) ? subjects : [],
        associatedProjects: selectedProjects, // Add associated projects
        profileCompleted: true,
        createdAt: new Date().toISOString(),
        lastUpdated: new Date().toISOString()
      };
      
      // Save profile to Firestore
      await createUserProfile(currentUser.uid, profileData);
      
      // Also create a dedicated document in the 'profiles' collection
      const profileRef = doc(db, "profiles", currentUser.uid);
      await setDoc(profileRef, profileData);
      
      setFormSubmitted(true);
      
      // Redirect to dashboard
      setTimeout(() => {
        navigate('/dashboard');
      }, 1500);
      
    } catch (err) {
      console.error("Error creating profile:", err);
      setError('Failed to create profile: ' + (err.message || 'Unknown error'));
    } finally {
      setIsLoading(false);
    }
  };
  
  if (formSubmitted) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full text-center">
          <div className="mx-auto h-12 w-12 bg-green-500 rounded-full flex items-center justify-center text-white">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Profile Created!</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Your profile has been successfully created. Redirecting to dashboard...
          </p>
          <div className="mt-6 flex justify-center">
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-600"></div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <div className="mx-auto h-12 w-12 bg-blue-600 rounded-md flex items-center justify-center text-white text-xl font-bold">
            S
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Complete Your Profile
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please provide the following information to complete your account setup
          </p>
        </div>
        
        {error && (
          <div className="bg-red-50 text-red-800 p-3 rounded-md text-sm">
            {error}
          </div>
        )}
        
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-6">
            {/* Personal Information Section */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Personal Information</h3>
              <div>
                <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <select
                  id="title"
                  name="title"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={isLoading}
                >
                  <option value="">Select a title</option>
                  {titles.map((t) => (
                    <option key={t} value={t}>{t}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  autoComplete="given-name"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  disabled={isLoading}
                />
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  autoComplete="family-name"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  disabled={isLoading}
                />
              </div>
            </div>
            
            {/* Institution Information Section */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Institution Information</h3>
              <div>
                <label htmlFor="institutionCategory" className="block text-sm font-medium text-gray-700">
                  Institution Category
                </label>
                <select
                  id="institutionCategory"
                  name="institutionCategory"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={institutionCategory}
                  onChange={(e) => setInstitutionCategory(e.target.value)}
                  disabled={isLoading}
                >
                  <option value="">Select a category</option>
                  {institutionCategories.map((category) => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>
              
              {institutionCategory && (
                <div>
                  <label htmlFor="institutionName" className="block text-sm font-medium text-gray-700">
                    Institution Name
                  </label>
                  <select
                    id="institutionName"
                    name="institutionName"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    value={institutionName}
                    onChange={(e) => setInstitutionName(e.target.value)}
                    disabled={isLoading}
                  >
                    <option value="">Select an institution</option>
                    {institutionList.map((inst) => (
                      <option key={inst} value={inst}>{inst}</option>
                    ))}
                  </select>
                  
                  <div className="mt-2">
                    <p className="text-xs text-gray-500">
                      Can't find your institution? 
                      <button 
                        type="button" 
                        className="ml-1 text-blue-600 hover:text-blue-800 focus:outline-none"
                        onClick={() => {
                          // Implementation for institution search could be added here
                          alert("This feature will be available soon!");
                        }}
                      >
                        Search for it
                      </button>
                    </p>
                  </div>
                </div>
              )}
              
              <div>
                <label htmlFor="province" className="block text-sm font-medium text-gray-700">
                  Province
                </label>
                <select
                  id="province"
                  name="province"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                  disabled={isLoading}
                >
                  <option value="">Select a province</option>
                  {provinces.map((prov) => (
                    <option key={prov} value={prov}>{prov}</option>
                  ))}
                </select>
              </div>

            {(institutionCategory === 'School' || institutionCategory === 'Circuit' || institutionCategory === 'District') && province && (
              <div>
                <label htmlFor="district" className="block text-sm font-medium text-gray-700">
                  District
                </label>
                <select
                  id="district"
                  name="district"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  disabled={isLoading}
                >
                  <option value="">Select a district</option>
                  {districts.map((dist) => (
                    <option key={dist} value={dist}>{dist}</option>
                  ))}
                </select>
              </div>
            )}

            {(institutionCategory === 'School' || institutionCategory === 'Circuit') && district && (
              <div>
                <label htmlFor="circuit" className="block text-sm font-medium text-gray-700">
                  Circuit
                </label>
                <select
                  id="circuit"
                  name="circuit"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={circuit}
                  onChange={(e) => setCircuit(e.target.value)}
                  disabled={isLoading}
                >
                  <option value="">Select a circuit</option>
                  {circuits.map((circ) => (
                    <option key={circ} value={circ}>{circ}</option>
                  ))}
                </select>
              </div>
            )}
            </div>

            {/* Projects/Programmes Section */}
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-gray-900">Projects & Programmes</h3>
                <p className="text-sm text-gray-500">
                  Select projects and programmes you are associated with (optional)
                </p>
                
                <div className="flex items-center">
                  <input
                    type="text"
                    placeholder="Search projects..."
                    className="flex-1 pl-3 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    value={projectSearchTerm}
                    onChange={(e) => setProjectSearchTerm(e.target.value)}
                    disabled={isLoading}
                  />
                  <button
                    type="button"
                    className="ml-2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => setShowProjectSearch(true)}
                    disabled={isLoading}
                  >
                    Search
                  </button>
                </div>
                
                {/* Selected Projects */}
                {selectedProjects.length > 0 && (
                  <div className="mt-2">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Selected Projects/Programmes:</h4>
                    <div className="space-y-2">
                      {selectedProjects.map(project => (
                        <div key={project.id} className="flex items-center justify-between bg-gray-50 p-2 rounded-md">
                          <div className="flex items-center">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              project.type === 'Project' ? 'bg-blue-100 text-blue-800' : 'bg-purple-100 text-purple-800'
                            }`}>
                              {project.type}
                            </span>
                            <span className="ml-2 text-sm text-gray-900">{project.name}</span>
                          </div>
                          <button
                            type="button"
                            className="text-red-500 hover:text-red-700"
                            onClick={() => setSelectedProjects(selectedProjects.filter(p => p.id !== project.id))}
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                
                {/* Project Search Results Modal */}
                {showProjectSearch && (
                  <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => setShowProjectSearch(false)}></div>
                      
                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                      
                      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">
                            <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
                              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                Select Projects/Programmes
                              </h3>
                              
                              <div className="mt-4">
                                <input
                                  type="text"
                                  placeholder="Search by name..."
                                  className="w-full pl-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                  value={projectSearchTerm}
                                  onChange={(e) => setProjectSearchTerm(e.target.value)}
                                />
                              </div>
                              
                              <div className="mt-4 max-h-60 overflow-y-auto">
                                {availableProjects
                                  .filter(project => 
                                    project.name.toLowerCase().includes(projectSearchTerm.toLowerCase()) &&
                                    !selectedProjects.some(p => p.id === project.id)
                                  )
                                  .map(project => (
                                    <div 
                                      key={project.id}
                                      className="py-2 px-3 hover:bg-gray-100 flex items-center justify-between cursor-pointer rounded-md"
                                      onClick={() => {
                                        setSelectedProjects([...selectedProjects, project]);
                                        setProjectSearchTerm('');
                                      }}
                                    >
                                      <div className="flex items-center">
                                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                          project.type === 'Project' ? 'bg-blue-100 text-blue-800' : 'bg-purple-100 text-purple-800'
                                        }`}>
                                          {project.type}
                                        </span>
                                        <span className="ml-2">{project.name}</span>
                                      </div>
                                      <Plus className="h-4 w-4 text-gray-500" />
                                    </div>
                                  ))
                                }
                                
                                {projectSearchTerm && 
                                availableProjects.filter(project => 
                                  project.name.toLowerCase().includes(projectSearchTerm.toLowerCase()) &&
                                  !selectedProjects.some(p => p.id === project.id)
                                ).length === 0 && (
                                  <p className="text-center text-gray-500 py-4">No matching projects found</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => setShowProjectSearch(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            
            {/* Role Information Section */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Role Information</h3>
              
              <div>
                <label htmlFor="userRole" className="block text-sm font-medium text-gray-700">
                  User Role
                </label>
                <select
                  id="userRole"
                  name="userRole"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={userRole}
                  onChange={(e) => setUserRole(e.target.value)}
                  disabled={isLoading}
                >
                  <option value="">Select a role</option>
                  {roleOptions.map((role) => (
                    <option key={role} value={role}>{role}</option>
                  ))}
                </select>
              </div>
              
              <div>
                <label htmlFor="userLevel" className="block text-sm font-medium text-gray-700">
                  User Level
                </label>
                <select
                  id="userLevel"
                  name="userLevel"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={userLevel}
                  onChange={(e) => setUserLevel(e.target.value)}
                  disabled={isLoading}
                >
                  <option value="">Select a level</option>
                  {[1, 2, 3, 4].map((level) => (
                    <option key={level} value={level.toString()}>Level {level}</option>
                  ))}
                </select>
                <p className="mt-1 text-xs text-gray-500">
                  Level 1: View only, Level 2: Contributor, Level 3: Editor, Level 4: Administrator
                </p>
              </div>
              
              {['Educator', 'Departmental Head'].includes(userRole) && (
                <div>
                  <label htmlFor="department" className="block text-sm font-medium text-gray-700">
                    Department
                  </label>
                  <select
                    id="department"
                    name="department"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                    disabled={isLoading}
                  >
                    <option value="">Select a department</option>
                    {departments.map((dept) => (
                      <option key={dept} value={dept}>{dept}</option>
                    ))}
                  </select>
                </div>
              )}
              
              {['Educator', 'Departmental Head', 'Deputy Principal', 'Principal'].includes(userRole) && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Grades
                  </label>
                  <div className="grid grid-cols-4 gap-2">
                    {gradeOptions.map((grade) => (
                      <div key={grade} className="flex items-center">
                        <input
                          id={`grade-${grade}`}
                          name={`grade-${grade}`}
                          type="checkbox"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          checked={grades.includes(grade)}
                          onChange={() => handleGradeChange(grade)}
                          disabled={isLoading}
                        />
                        <label htmlFor={`grade-${grade}`} className="ml-2 block text-sm text-gray-700">
                          {grade === '00' ? 'R' : grade}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              {['Educator', 'Departmental Head'].includes(userRole) && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Subjects
                  </label>
                  <div className="grid grid-cols-2 gap-2">
                    {subjectOptions.map((subject) => (
                      <div key={subject} className="flex items-center">
                        <input
                          id={`subject-${subject}`}
                          name={`subject-${subject}`}
                          type="checkbox"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          checked={subjects.includes(subject)}
                          onChange={() => handleSubjectChange(subject)}
                          disabled={isLoading}
                        />
                        <label htmlFor={`subject-${subject}`} className="ml-2 block text-sm text-gray-700">
                          {subject}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          
          <div>
            <button
              type="submit"
              disabled={isLoading || profileLoading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400 disabled:cursor-not-allowed"
            >
              {isLoading || profileLoading ? (
                <>
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                  Creating Profile...
                </>
              ) : (
                'Complete Profile'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateProfile;