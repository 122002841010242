// src/components/stakeholders/StakeholderList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Plus, Search, Edit, Trash, MoreHorizontal, Mail, Phone, ChevronDown, Eye } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, doc, deleteDoc, orderBy } from 'firebase/firestore';

const StakeholderList = () => {
  const { userProfile } = useAuth();
  const [stakeholders, setStakeholders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);

  // Dropdown options
  const categories = ['NGO', 'Government', 'Supplier', 'Supporter', 'Community Organization', 'Corporate', 'Educational Institution'];

  useEffect(() => {
    const fetchStakeholders = async () => {
      if (!userProfile?.institutionName) return;
      
      setIsLoading(true);
      try {
        const stakeholdersRef = collection(db, "stakeholders");
        let q = query(
          stakeholdersRef, 
          where("institutionName", "==", userProfile.institutionName),
          orderBy("name")
        );
        
        const querySnapshot = await getDocs(q);
        
        const stakeholdersList = [];
        querySnapshot.forEach((doc) => {
          stakeholdersList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setStakeholders(stakeholdersList);
      } catch (error) {
        console.error("Error fetching stakeholders:", error);
        setError("Failed to load stakeholders. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStakeholders();
  }, [userProfile]);

  const handleDeleteStakeholder = async (stakeholderId) => {
    try {
      await deleteDoc(doc(db, "stakeholders", stakeholderId));
      setStakeholders(stakeholders.filter(stakeholder => stakeholder.id !== stakeholderId));
      setConfirmDelete(null);
    } catch (error) {
      console.error("Error deleting stakeholder:", error);
      setError("Failed to delete stakeholder. Please try again.");
    }
  };

  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  // Filter stakeholders based on search term and category filter
  const filteredStakeholders = stakeholders.filter(stakeholder => {
    const matchesSearch = stakeholder.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                         (stakeholder.contactPerson && stakeholder.contactPerson.toLowerCase().includes(searchTerm.toLowerCase()));
    const matchesCategory = categoryFilter ? stakeholder.category === categoryFilter : true;
    
    return matchesSearch && matchesCategory;
  });

  const resetFilters = () => {
    setSearchTerm('');
    setCategoryFilter('');
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">School Stakeholders</h2>
          <Link 
            to="/stakeholders/create"
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
          >
            <Plus size={18} className="mr-2" />
            Add Stakeholder
          </Link>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 text-red-800 p-4 border-l-4 border-red-500">
          {error}
        </div>
      )}

      {/* Filters Section */}
      <div className="p-4 border-b border-gray-200 bg-gray-50">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-3 md:space-y-0">
          <div className="relative flex items-center">
            <Search size={18} className="absolute left-3 text-gray-400" />
            <input 
              type="text" 
              placeholder="Search stakeholders..." 
              className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full md:w-64" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="flex flex-wrap items-center space-x-3">
            <div className="relative">
              <select
                className="appearance-none pl-3 pr-10 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
              >
                <option value="">All Categories</option>
                {categories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
              <ChevronDown size={16} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>

            {(searchTerm || categoryFilter) && (
              <button 
                onClick={resetFilters}
                className="text-blue-600 hover:text-blue-800 text-sm font-medium"
              >
                Clear Filters
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Stakeholders List */}
      <div className="overflow-x-auto">
        {isLoading ? (
          <div className="flex justify-center items-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          </div>
        ) : filteredStakeholders.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Stakeholder Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Category
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact Person
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact Details
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredStakeholders.map((stakeholder) => (
                <tr key={stakeholder.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      <Link 
                        to={`/stakeholders/details/${stakeholder.id}`}
                        className="text-blue-600 hover:text-blue-800 hover:underline"
                      >
                        {stakeholder.name}
                      </Link>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      stakeholder.category === 'NGO' ? 'bg-green-100 text-green-800' :
                      stakeholder.category === 'Government' ? 'bg-blue-100 text-blue-800' :
                      stakeholder.category === 'Supplier' ? 'bg-yellow-100 text-yellow-800' :
                      stakeholder.category === 'Supporter' ? 'bg-purple-100 text-purple-800' :
                      stakeholder.category === 'Community Organization' ? 'bg-pink-100 text-pink-800' :
                      stakeholder.category === 'Corporate' ? 'bg-indigo-100 text-indigo-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                      {stakeholder.category}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{stakeholder.contactPerson || '-'}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="space-y-1">
                      {stakeholder.contactNumber && (
                        <div className="flex items-center text-sm text-gray-500">
                          <Phone size={14} className="mr-1" />
                          {stakeholder.contactNumber}
                        </div>
                      )}
                      {stakeholder.contactEmail && (
                        <div className="flex items-center text-sm text-gray-500">
                          <Mail size={14} className="mr-1" />
                          <a href={`mailto:${stakeholder.contactEmail}`} className="text-blue-600 hover:text-blue-800">
                            {stakeholder.contactEmail}
                          </a>
                        </div>
                      )}
                      {!stakeholder.contactNumber && !stakeholder.contactEmail && (
                        <div className="text-sm text-gray-500">-</div>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-500 max-w-xs truncate">
                      {stakeholder.description || '-'}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="relative inline-block text-left">
                      <button
                        onClick={() => toggleDropdown(stakeholder.id)}
                        className="text-gray-500 hover:text-gray-700"
                        aria-expanded={activeDropdown === stakeholder.id}
                        aria-haspopup="true"
                      >
                        <MoreHorizontal size={20} />
                      </button>
                      
                      {activeDropdown === stakeholder.id && (
                        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" 
                          onClick={() => setActiveDropdown(null)}>
                          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            <Link
                              to={`/stakeholders/details/${stakeholder.id}`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                            >
                              <Eye size={16} className="inline-block mr-2" />
                              View Details
                            </Link>
                            <Link
                              to={`/stakeholders/edit/${stakeholder.id}`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                            >
                              <Edit size={16} className="inline-block mr-2" />
                              Edit
                            </Link>
                            {stakeholder.contactEmail && (
                              <a
                                href={`mailto:${stakeholder.contactEmail}`}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                role="menuitem"
                              >
                                <Mail size={16} className="inline-block mr-2" />
                                Send Email
                              </a>
                            )}
                            <button
                              onClick={() => setConfirmDelete(stakeholder.id)}
                              className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-900"
                              role="menuitem"
                            >
                              <Trash size={16} className="inline-block mr-2" />
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="p-8 text-center">
            <div className="text-gray-500">
              {searchTerm || categoryFilter ? 
                'No stakeholders match your filters.' : 
                'No stakeholders found. Click "Add Stakeholder" to add one.'}
            </div>
          </div>
        )}
      </div>
      
      {/* Delete Confirmation Modal */}
      {confirmDelete && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md mx-auto">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Delete</h3>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to delete this stakeholder? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setConfirmDelete(null)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDeleteStakeholder(confirmDelete)}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StakeholderList;