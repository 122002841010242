// src/context/AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut as firebaseSignOut,
  onAuthStateChanged, 
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail
} from 'firebase/auth';
import { 
  doc, 
  setDoc, 
  getDoc, 
  collection,
  addDoc,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../firebase/config';



const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profileLoading, setProfileLoading] = useState(false);
  const auth = getAuth();

  // Debug log function
  const logDebug = (message, data) => {
    console.log(`[AuthContext] ${message}`, data);
  };

  // Sign up a new user
  async function signUp(email, password) {
    try {
      logDebug('Signing up new user', email);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create a user record in the users collection
      await setDoc(doc(db, "users", userCredential.user.uid), {
        email: userCredential.user.email,
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp(),
        profileCompleted: false
      });
      
      // Create an activity log entry
      await addDoc(collection(db, "activityLogs"), {
        userId: userCredential.user.uid,
        action: "USER_SIGNUP",
        timestamp: serverTimestamp(),
        details: {
          email: userCredential.user.email
        }
      });
      
      logDebug('User signed up successfully', userCredential.user.uid);
      return userCredential.user;
    } catch (error) {
      console.error("Error during sign up:", error);
      logDebug('Sign up error', error.message);
      throw error;
    }
  }

  // Sign in an existing user
  async function signIn(email, password) {
    try {
      logDebug('Signing in user', email);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Update last login time
      await setDoc(doc(db, "users", userCredential.user.uid), {
        lastLogin: serverTimestamp()
      }, { merge: true });
      
      // Create an activity log entry
      await addDoc(collection(db, "activityLogs"), {
        userId: userCredential.user.uid,
        action: "USER_LOGIN",
        timestamp: serverTimestamp(),
        details: {
          email: userCredential.user.email
        }
      });
      
      logDebug('User signed in successfully', userCredential.user.uid);
      return userCredential.user;
    } catch (error) {
      console.error("Error during sign in:", error);
      logDebug('Sign in error', error.message);
      throw error;
    }
  }

  // Sign out the current user
  async function signOut() {
    try {
      logDebug('Signing out user', currentUser?.uid);
      if (currentUser) {
        // Create an activity log entry
        await addDoc(collection(db, "activityLogs"), {
          userId: currentUser.uid,
          action: "USER_LOGOUT",
          timestamp: serverTimestamp(),
          details: {
            email: currentUser.email
          }
        });
      }
      
      await firebaseSignOut(auth);
      setUserProfile(null);
      logDebug('User signed out successfully');
    } catch (error) {
      console.error("Error during sign out:", error);
      logDebug('Sign out error', error.message);
      throw error;
    }
  }

  // Create or update the user's profile
  async function createUserProfile(userId, profileData) {
    try {
      logDebug('Creating/updating user profile', { userId, ...profileData });
      setProfileLoading(true);
      
      // Update the user document to mark profile as completed
      await setDoc(doc(db, "users", userId), {
        profileCompleted: true,
        lastUpdated: serverTimestamp()
      }, { merge: true });
      
      // Create/update the detailed profile document
      await setDoc(doc(db, "userProfiles", userId), {
        ...profileData,
        lastUpdated: serverTimestamp()
      });
      
      // Create an activity log entry
      await addDoc(collection(db, "activityLogs"), {
        userId: userId,
        action: "PROFILE_CREATED",
        timestamp: serverTimestamp(),
        details: {
          institutionName: profileData.institutionName,
          userRole: profileData.userRole,
          province: profileData.province,
          district: profileData.district,
          circuit: profileData.circuit
        }
      });
      
      // Update the local user profile state WITH the userId included
      const completeProfile = {
        id: userId, // Include the userId
        ...profileData
      };
      
      setUserProfile(completeProfile);
      logDebug('User profile created/updated successfully', completeProfile);
      
      return completeProfile;
    } catch (error) {
      console.error("Error creating user profile:", error);
      logDebug('Profile creation error', error.message);
      throw error;
    } finally {
      setProfileLoading(false);
    }
  }

  // Fetch the user's profile data
  async function fetchUserProfile(userId) {
    try {
      logDebug('Fetching user profile', userId);
      setProfileLoading(true);
      
      const userDoc = await getDoc(doc(db, "users", userId));
      
      if (userDoc.exists() && userDoc.data().profileCompleted) {
        const profileDoc = await getDoc(doc(db, "userProfiles", userId));
        
        if (profileDoc.exists()) {
          // Create a complete profile object WITH the userId included
          const completeProfile = {
            id: userId, // Include the userId
            ...profileDoc.data()
          };
          
          setUserProfile(completeProfile);
          logDebug('User profile fetched successfully', completeProfile);
          return completeProfile;
        }
      }
      
      // No profile found or profile not completed
      logDebug('No profile found for user', userId);
      setUserProfile(null);
      return null;
    } catch (error) {
      console.error("Error fetching user profile:", error);
      logDebug('Profile fetch error', error.message);
      setUserProfile(null);
      return null;
    } finally {
      setProfileLoading(false);
    }
  }

  // Update only specific fields of the user profile
  async function updateUserProfile(userId, updatedFields) {
    try {
      logDebug('Updating user profile fields', { userId, fields: Object.keys(updatedFields) });
      setProfileLoading(true);
      
      // Update the profile document with the new fields
      await setDoc(doc(db, "userProfiles", userId), {
        ...updatedFields,
        lastUpdated: serverTimestamp()
      }, { merge: true });
      
      // Create an activity log entry
      await addDoc(collection(db, "activityLogs"), {
        userId: userId,
        action: "PROFILE_UPDATED",
        timestamp: serverTimestamp(),
        details: {
          updatedFields: Object.keys(updatedFields)
        }
      });
      
      // Update the local state, preserving the ID
      setUserProfile(prev => ({
        id: userId, // Ensure ID is preserved
        ...(prev || {}),
        ...updatedFields
      }));
      
      logDebug('User profile updated successfully', { fields: Object.keys(updatedFields) });
      return true;
    } catch (error) {
      console.error("Error updating user profile:", error);
      logDebug('Profile update error', error.message);
      throw error;
    } finally {
      setProfileLoading(false);
    }
  }

  // Set up the auth state listener
  useEffect(() => {
    logDebug('Setting up auth state listener');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        logDebug('Auth state changed - user logged in', { uid: user.uid, email: user.email });
        setCurrentUser(user);
        
        // Fetch the user's profile if they're logged in
        await fetchUserProfile(user.uid);
      } else {
        logDebug('Auth state changed - user logged out');
        setCurrentUser(null);
        setUserProfile(null);
      }
      
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Log changes to user state for debugging
  useEffect(() => {
    if (!loading) {
      logDebug('Auth state loaded', { 
        authenticated: !!currentUser, 
        profileLoaded: !!userProfile,
        userId: currentUser?.uid,
        profileId: userProfile?.id
      });
    }
  }, [loading, currentUser, userProfile]);
  
  async function signInWithGoogle() {
    try {
      logDebug("Signing in with Google", null);
      const provider = new GoogleAuthProvider();
      // Add these lines to force re-consent after logout
      provider.setCustomParameters({
        prompt: 'select_account' // This forces the account selection screen every time
      });
      
      const userCredential = await signInWithPopup(auth, provider);
  
      // The rest of your existing code
      // Check if user already exists in Firestore
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
  
      if (!userDoc.exists()) {
        // If new user, create a record in the users collection
        await setDoc(doc(db, "users", userCredential.user.uid), {
          email: userCredential.user.email,
          createdAt: serverTimestamp(),
          lastLogin: serverTimestamp(),
          profileCompleted: false,
        });
  
        // Log new user sign-in activity
        await addDoc(collection(db, "activityLogs"), {
          userId: userCredential.user.uid,
          action: "USER_SIGNUP_GOOGLE",
          timestamp: serverTimestamp(),
          details: {
            email: userCredential.user.email,
          },
        });
  
        logDebug("New Google user signed up", userCredential.user.uid);
      } else {
        // Update last login for existing users
        await setDoc(
          doc(db, "users", userCredential.user.uid),
          { lastLogin: serverTimestamp() },
          { merge: true }
        );
  
        // Log login activity
        await addDoc(collection(db, "activityLogs"), {
          userId: userCredential.user.uid,
          action: "USER_LOGIN_GOOGLE",
          timestamp: serverTimestamp(),
          details: {
            email: userCredential.user.email,
          },
        });
  
        logDebug("Existing Google user logged in", userCredential.user.uid);
      }
  
      return userCredential.user;
    } catch (error) {
      console.error("Google Sign-in Error:", error);
      logDebug("Google Sign-in Error", error.message);
      throw error;
    }
  }

  // Update the resetPassword function in AuthContext.js

  async function resetPassword(email) {
    try {
      logDebug('Sending password reset email', email);
      
      // First check if the email exists in Firebase Auth
      // This is a safer approach than directly sending the reset email
      await sendPasswordResetEmail(auth, email, {
        url: window.location.origin + '/login',
        handleCodeInApp: false
      });
      
      // Only log success if the above doesn't throw an error
      logDebug('Password reset email sent successfully');
      
      // Create an activity log entry - we'll do this without requiring auth
      try {
        await addDoc(collection(db, "activityLogs"), {
          action: "PASSWORD_RESET_REQUESTED",
          timestamp: serverTimestamp(),
          details: {
            email: email
          }
        });
      } catch (logError) {
        // Just log this error but don't fail the reset
        console.error("Couldn't log password reset action:", logError);
      }
      
      return true;
    } catch (error) {
      console.error("Error sending password reset email:", error);
      logDebug('Password reset error', error.message);
      
      // This will translate Firebase errors to user-friendly messages
      if (error.code === 'auth/user-not-found') {
       
        logDebug('User not found, but returning success for security');
        return true;
      }
      
      throw error;
    }
  }

  const value = {
    currentUser,
    userProfile,
    loading,
    profileLoading,
    signUp,
    signIn,
    signOut,
    signInWithGoogle,
    resetPassword,
    createUserProfile,
    updateUserProfile,
    fetchUserProfile
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}