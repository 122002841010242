import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { doc, getDoc, deleteDoc, collection, query, where, getDocs, orderBy, addDoc, limit } from 'firebase/firestore';
import { School, Building2, Building, Landmark } from 'lucide-react';

const InstitutionRegistration = () => {
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Form fields
  const [institutionId, setInstitutionId] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [institutionType, setInstitutionType] = useState('');
  const [phase, setPhase] = useState('');
  const [address, setAddress] = useState('');
  const [telNumber, setTelNumber] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [youtube, setYoutube] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [circuit, setCircuit] = useState('');
  const [principal, setPrincipal] = useState('');
  const [deputyPrincipal, setDeputyPrincipal] = useState('');
  const [circuitManager, setCircuitManager] = useState('');
  const [districtDirector, setDistrictDirector] = useState('');
  const [headOfDepartment, setHeadOfDepartment] = useState('');
  
  // Dynamic dropdown options
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [circuits, setCircuits] = useState([]);
  const [isLoadingProvinces, setIsLoadingProvinces] = useState(false);
  const [isLoadingDistricts, setIsLoadingDistricts] = useState(false);
  const [isLoadingCircuits, setIsLoadingCircuits] = useState(false);
  
  // Search and select users
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [showUserSearch, setShowUserSearch] = useState(false);
  
  // Dropdown options
  const institutionTypes = ['School', 'Circuit Office', 'District Office', 'Provincial Department'];
  const phases = ['Primary', 'Secondary', 'Combined'];
  
  // Fetch provinces on component mount
  useEffect(() => {
    const fetchProvinces = async () => {
      setIsLoadingProvinces(true);
      try {
        const institutionsRef = collection(db, "institutions");
        const q = query(
          institutionsRef,
          where("type", "==", "Provincial Department"),
          orderBy("name")
        );
        
        const querySnapshot = await getDocs(q);
        const provinceList = [];
        
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.name) {
            provinceList.push(data.name);
          }
        });
        
        // If no provinces found in the DB, use default list
        if (provinceList.length === 0) {
          setProvinces([
            'Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 
            'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape'
          ]);
        } else {
          setProvinces(provinceList);
        }
      } catch (error) {
        console.error('Error fetching provinces:', error);
        // Fallback to default list
        setProvinces([
          'Eastern Cape', 'Free State', 'Gauteng', 'KwaZulu-Natal', 
          'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape', 'Western Cape'
        ]);
      } finally {
        setIsLoadingProvinces(false);
      }
    };
    
    fetchProvinces();
  }, []);
  
  // Fetch districts when province changes
    useEffect(() => {
      const fetchDistricts = async () => {
        if (!province) {
          setDistricts([]);
          return;
        }
        
        setIsLoadingDistricts(true);
        try {
          console.log('Fetching districts for province:', province);
          
          // First, let's check what district offices are in the database
          const allDistrictsRef = collection(db, "institutions");
          const debugQuery = query(
            allDistrictsRef,
            where("type", "==", "District Office")
          );
          
          const debugSnapshot = await getDocs(debugQuery);
          console.log(`Found ${debugSnapshot.size} total district offices in database`);
          
          if (debugSnapshot.size > 0) {
            console.log("Sample district data:");
            debugSnapshot.forEach(doc => {
              const data = doc.data();
              console.log(`- ID: ${doc.id}, Name: ${data.name || 'unnamed'}, Province: ${data.province || 'no province'}, Province field exists: ${data.hasOwnProperty('province')}`);
            });
          }
          
          // Now let's try different field names that might contain the province reference
          const possibleMatches = [];
          for (const doc of debugSnapshot.docs) {
            const data = doc.data();
            
            // Check various possible fields that might store province info
            if (
              (data.province && data.province.toLowerCase() === province.toLowerCase()) ||
              (data.provinceName && data.provinceName.toLowerCase() === province.toLowerCase()) ||
              (data.provinceId && data.provinceId.toLowerCase() === province.toLowerCase()) ||
              (data.location && data.location.toLowerCase().includes(province.toLowerCase()))
            ) {
              possibleMatches.push({
                id: doc.id,
                name: data.name || `District Office ${doc.id}`,
                matchedOn: data.province ? 'province' : 
                           data.provinceName ? 'provinceName' : 
                           data.provinceId ? 'provinceId' : 'location'
              });
            }
          }
          
          console.log(`Found ${possibleMatches.length} possible matching districts`);
          console.log('Matches:', possibleMatches);
          
          // Try the original query now
          const institutionsRef = collection(db, "institutions");
          const q = query(
            institutionsRef,
            where("type", "==", "District Office"),
            where("province", "==", province)
          );
          
          const querySnapshot = await getDocs(q);
          console.log(`Original query returned ${querySnapshot.size} results`);
          
          const districtList = [];
          
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.name) {
              districtList.push(data.name);
            }
          });
          
          // If we didn't find any with the standard query, use our possible matches
          if (districtList.length === 0 && possibleMatches.length > 0) {
            console.log("Using alternative matches");
            possibleMatches.forEach(match => {
              districtList.push(match.name);
            });
          }
          
          setDistricts(districtList);
        } catch (error) {
          console.error('Error fetching districts:', error);
          setDistricts([]);
        } finally {
          setIsLoadingDistricts(false);
        }
      };
      
      fetchDistricts();
    }, [province]);

  // Fetch circuits when district changes
    // Fetch circuits when district changes
useEffect(() => {
  const fetchCircuits = async () => {
    if (!district) {
      setCircuits([]);
      return;
    }
    
    setIsLoadingCircuits(true);
    try {
      console.log('Fetching circuits for district:', district);
      
      // For debugging purposes, first check what circuit offices exist
      const allCircuitsRef = collection(db, "institutions");
      const debugQuery = query(
        allCircuitsRef,
        where("type", "==", "Circuit Office")
      );
      
      const debugSnapshot = await getDocs(debugQuery);
      console.log(`Found ${debugSnapshot.size} total circuit offices in database`);
      
      if (debugSnapshot.size > 0) {
        console.log("Sample circuit data:");
        debugSnapshot.forEach(doc => {
          const data = doc.data();
          console.log(`- ID: ${doc.id}, Name: ${data.name || 'unnamed'}, District: ${data.district || 'no district'}, District field exists: ${data.hasOwnProperty('district')}`);
        });
      }
      
      // Build a list of possible matches using various potential field combinations
      const possibleMatches = [];
      for (const doc of debugSnapshot.docs) {
        const data = doc.data();
        
        // Check various possible fields that might store district info
        if (
          (data.district && data.district.toLowerCase() === district.toLowerCase()) ||
          (data.districtName && data.districtName.toLowerCase() === district.toLowerCase()) ||
          (data.districtId && data.districtId.toLowerCase() === district.toLowerCase()) ||
          (data.location && data.location.toLowerCase().includes(district.toLowerCase()))
        ) {
          possibleMatches.push({
            id: doc.id,
            name: data.name || `Circuit Office ${doc.id}`,
            matchedOn: data.district ? 'district' : 
                       data.districtName ? 'districtName' : 
                       data.districtId ? 'districtId' : 'location'
          });
        }
      }
      
      console.log(`Found ${possibleMatches.length} possible matching circuits`);
      console.log('Circuit matches:', possibleMatches);
      
      // Try the standard query
      const institutionsRef = collection(db, "institutions");
      const q = query(
        institutionsRef,
        where("type", "==", "Circuit Office"),
        where("district", "==", district),
        orderBy("name")
      );
      
      const querySnapshot = await getDocs(q);
      console.log(`Standard circuit query returned ${querySnapshot.size} results`);
      
      const circuitList = [];
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.name) {
          circuitList.push(data.name);
        }
      });
      
      // If we didn't find any with the standard query, use our possible matches
      if (circuitList.length === 0 && possibleMatches.length > 0) {
        console.log("Using alternative matches for circuits");
        possibleMatches.forEach(match => {
          circuitList.push(match.name);
        });
      }
      
      setCircuits(circuitList);
    } catch (error) {
      console.error('Error fetching circuits:', error);
      setCircuits([]);
    } finally {
      setIsLoadingCircuits(false);
    }
  };
  
  fetchCircuits();
}, [district]);
  
  // Handle province change
  const handleProvinceChange = (e) => {
    const selectedProvince = e.target.value;
    setProvince(selectedProvince);
    setDistrict('');
    setCircuit('');
  };
  
  // Handle district change
  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setDistrict(selectedDistrict);
    setCircuit('');
  };
  
  // Handle institution type change
  useEffect(() => {
    if (institutionType) {
      // Reset fields that depend on institution type
      setPhase('');
      setPrincipal('');
      setDeputyPrincipal('');
      setCircuitManager('');
      setDistrictDirector('');
      setHeadOfDepartment('');
      
      // Generate new institution ID
      generateInstitutionId();
    }
  }, [institutionType]);
  
  // Search for users
  const searchUsers = async (term, field) => {
    if (term.length < 3) {
      setSearchResults([]);
      return;
    }
    
    try {
      setIsLoading(true);
      
      // In a real implementation, we would query Firestore:
      const usersRef = collection(db, "users");
      const q = query(
        usersRef,
        where("firstName", ">=", term),
        where("firstName", "<=", term + '\uf8ff'),
        limit(10)
      );
      
      const querySnapshot = await getDocs(q);
      const userResults = [];
      
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        userResults.push({
          id: doc.id,
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          title: userData.title || '',
          userRole: userData.userRole || ''
        });
      });
      
      // If no results, try searching by last name
      if (userResults.length === 0) {
        const q2 = query(
          usersRef,
          where("lastName", ">=", term),
          where("lastName", "<=", term + '\uf8ff'),
          limit(10)
        );
        
        const snapshot2 = await getDocs(q2);
        snapshot2.forEach((doc) => {
          const userData = doc.data();
          userResults.push({
            id: doc.id,
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            title: userData.title || '',
            userRole: userData.userRole || ''
          });
        });
      }
      
      setSearchResults(userResults);
    } catch (error) {
      console.error('Error searching users:', error);
      // Fallback to mock data for demo
      const mockResults = [
        { id: '1', firstName: 'Michael', lastName: 'Johnson', title: 'Mr', userRole: 'Principal' },
        { id: '2', firstName: 'Sarah', lastName: 'King', title: 'Ms', userRole: 'Deputy Principal' },
        { id: '3', firstName: 'Robert', lastName: 'Lewis', title: 'Dr', userRole: 'Circuit Manager' },
        { id: '4', firstName: 'Jessica', lastName: 'Davis', title: 'Ms', userRole: 'District Director' },
        { id: '5', firstName: 'Terry', lastName: 'Mitchell', title: 'Prof', userRole: 'Head of Department' },
      ];
      
      setSearchResults(mockResults.filter(user => 
        user.firstName.toLowerCase().includes(term.toLowerCase()) || 
        user.lastName.toLowerCase().includes(term.toLowerCase())
      ));
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleUserSelect = (user) => {
    const fullName = `${user.title || ''} ${user.firstName || ''} ${user.lastName || ''}`.trim();
    
    switch (selectedField) {
      case 'principal':
        setPrincipal(fullName);
        break;
      case 'deputyPrincipal':
        setDeputyPrincipal(fullName);
        break;
      case 'circuitManager':
        setCircuitManager(fullName);
        break;
      case 'districtDirector':
        setDistrictDirector(fullName);
        break;
      case 'headOfDepartment':
        setHeadOfDepartment(fullName);
        break;
      default:
        break;
    }
    
    setShowUserSearch(false);
    setSearchTerm('');
    setSearchResults([]);
  };
  
  const openUserSearch = (field) => {
    setSelectedField(field);
    setShowUserSearch(true);
    setSearchTerm('');
    setSearchResults([]);
  };
  
  // Generate institution ID
  const generateInstitutionId = () => {
    const prefix = institutionType === 'School' ? 'SCH' : 
                  institutionType === 'Circuit Office' ? 'CIR' : 
                  institutionType === 'District Office' ? 'DIS' : 'PRV';
                  
    const randomNum = Math.floor(10000 + Math.random() * 90000);
    setInstitutionId(`${prefix}-${randomNum}`);
  };
  
  // Validate form based on institution type
  const validateForm = () => {
    if (!institutionId || !institutionName || !institutionType) {
      setError('Institution ID, name, and type are required');
      return false;
    }
    
    if (institutionType === 'School' && !phase) {
      setError('Phase is required for schools');
      return false;
    }
    
    // Province is required for all institution types
    if (!province) {
      setError('Province is required');
      return false;
    }
    
    // Institution type specific validations
    switch (institutionType) {
      case 'Provincial Department':
        if (!headOfDepartment) {
          setError('Head of Department information is required for provincial departments');
          return false;
        }
        break;
        
      case 'District Office':
        if (!districtDirector) {
          setError('District Director information is required for district offices');
          return false;
        }
        break;
        
      case 'Circuit Office':
        // For circuit offices, require province and district but no error for circuit
        if (!province) {
          setError('Province is required for circuit offices');
          return false;
        }
        if (!district) {
          setError('District is required for circuit offices');
          return false;
        }
        if (!circuitManager) {
          setError('Circuit Manager information is required for circuit offices');
          return false;
        }
        break;
        
      case 'School':
        // For schools, require province, district, and circuit
        if (!province) {
          setError('Province is required for schools');
          return false;
        }
        if (!district) {
          setError('District is required for schools');
          return false;
        }
        if (!circuit) {
          setError('Circuit is required for schools');
          return false;
        }
        if (!principal) {
          setError('Principal information is required for schools');
          return false;
        }
        break;
        
      default:
        break;
    }
    
    return true;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      // Form data to be submitted to the database
      const institutionData = {
        // Basic information
        institutionId, // This is just for reference, not the actual document ID
        name: institutionName.trim(),  // For consistent queries
        type: institutionType,
        phase: institutionType === 'School' ? phase : null,
        
        // Contact information
        address,
        phone: telNumber,
        mobileNumber,
        email,
        website,
        socialMedia: {
          youtube: youtube || null,
          tiktok: tiktok || null,
          facebook: facebook || null,
          instagram: instagram || null
        },
        
        // CRITICAL: Location information for the hierarchical permissions
        // These should be string values, not IDs
        province,
        district,
        circuit: (institutionType === 'School' || institutionType === 'Circuit Office') ? circuit : null,
        
        // Other legacy/reference fields that might be used elsewhere
        provinceId: province || null,
        districtId: district || null,
        circuitId: circuit || null,
        
        // Leadership information
        principal: institutionType === 'School' ? principal : null,
        deputyPrincipal: institutionType === 'School' ? deputyPrincipal : null,
        manager: institutionType === 'Circuit Office' ? circuitManager : null,
        director: institutionType === 'District Office' ? districtDirector : null,
        head: institutionType === 'Provincial Department' ? headOfDepartment : null,
        
        // Consolidated location for display and filtering
        location: [province, district, circuit].filter(Boolean).join(', '),
        
        // Statistics fields that the list might look for
        studentCount: institutionType === 'School' ? 0 : null,
        staffCount: institutionType === 'School' ? 0 : null,
        classroomCount: institutionType === 'School' ? 0 : null,
        districtsCount: institutionType === 'Provincial Department' ? 0 : null,
        circuitsCount: institutionType === 'District Office' ? 0 : null,
        schoolsCount: ['Provincial Department', 'District Office', 'Circuit Office'].includes(institutionType) ? 0 : null,
        
        // Metadata
        createdBy: currentUser?.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
        status: 'Active'
      };
      
      console.log('Saving institution data:', institutionData);
      
      // Create a new document in the institutions collection
      const institutionsRef = collection(db, "institutions");
      const newDocRef = await addDoc(institutionsRef, institutionData);
      
      console.log('Institution registered with ID:', newDocRef.id);
      
      setSuccess('Institution registered successfully!');
      
      // Wait a moment before navigating back to the list
      setTimeout(() => {
        navigate('/institutions');
      }, 1500);
      
    } catch (err) {
      console.error('Failed to register institution:', err);
      setError('Failed to register institution: ' + (err.message || 'Unknown error'));
    } finally {
      setIsLoading(false);
    }
  };
  
  const getInstitutionIcon = () => {
    switch (institutionType) {
      case 'School':
        return <School className="h-10 w-10 text-blue-600" />;
      case 'Circuit Office':
        return <Building2 className="h-10 w-10 text-green-600" />;
      case 'District Office':
        return <Building className="h-10 w-10 text-purple-600" />;
      case 'Provincial Department':
        return <Landmark className="h-10 w-10 text-red-600" />;
      default:
        return null;
    }
  };
  
  // Determine if district selection should be shown and if it's required
  const showDistrictSelection = () => {
    // Show district selection for all except Provincial Department
    return institutionType !== '';
  };
  
  const isDistrictRequired = () => {
    // District is required for Circuit Office and School
    return institutionType === 'Circuit Office' || institutionType === 'School';
  };
  
  // Determine if circuit selection should be shown and if it's required
  const showCircuitSelection = () => {
    // Only show circuit selection for Schools
    return institutionType === 'School';
  };
  
  const isCircuitRequired = () => {
    // Circuit is required for Schools
    return institutionType === 'School';
  };
  
  return (
    <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6 mb-8">
      <div className="flex items-start mb-6">
        <div className="mr-4">
          {getInstitutionIcon()}
        </div>
        <div>
          <h1 className="text-2xl font-bold text-gray-800">Register New Institution</h1>
          <p className="text-gray-600">Complete the form below to register a new educational institution in the system</p>
        </div>
      </div>
      
      {error && (
        <div className="mb-6 bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded-md">
          {error}
        </div>
      )}
      
      {success && (
        <div className="mb-6 bg-green-50 border border-green-200 text-green-800 px-4 py-3 rounded-md">
          {success}
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Basic Information Section */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">Basic Information</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="institutionType" className="block text-sm font-medium text-gray-700 mb-1">
                Institution Type <span className="text-red-500">*</span>
              </label>
              <select
                id="institutionType"
                name="institutionType"
                className="mt-1 block w-full pl-2 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                value={institutionType}
                onChange={(e) => setInstitutionType(e.target.value)}
                disabled={isLoading}
                required
              >
                <option value="">Select an institution type</option>
                {institutionTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            
            {institutionType === 'School' && (
              <div>
                <label htmlFor="phase" className="block text-sm font-medium text-gray-700 mb-1">
                  Phase <span className="text-red-500">*</span>
                </label>
                <select
                  id="phase"
                  name="phase"
                  className="mt-1 block w-full pl-2 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={phase}
                  onChange={(e) => setPhase(e.target.value)}
                  disabled={isLoading}
                  required={institutionType === 'School'}
                >
                  <option value="">Select a phase</option>
                  {phases.map((p) => (
                    <option key={p} value={p}>{p}</option>
                  ))}
                </select>
              </div>
            )}
            
            <div>
              <label htmlFor="institutionId" className="block text-sm font-medium text-gray-700 mb-1">
                Institution ID <span className="text-red-500">*</span>
              </label>
              <input
                id="institutionId"
                name="institutionId"
                type="text"
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={institutionId}
                onChange={(e) => setInstitutionId(e.target.value)}
                disabled={true}
                required
              />
              <p className="mt-1 text-xs text-gray-500">Auto-generated based on institution type</p>
            </div>
            
            <div>
              <label htmlFor="institutionName" className="block text-sm font-medium text-gray-700 mb-1">
                Institution Name <span className="text-red-500">*</span>
              </label>
              <input
                id="institutionName"
                name="institutionName"
                type="text"
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={institutionName}
                onChange={(e) => setInstitutionName(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>
          </div>
        </div>
        
        {/* Contact Information Section */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">Contact Information</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="md:col-span-2">
              <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
                Physical Address
              </label>
              <textarea
                id="address"
                name="address"
                rows={3}
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                disabled={isLoading}
              />
            </div>
            
            <div>
              <label htmlFor="telNumber" className="block text-sm font-medium text-gray-700 mb-1">
                Telephone Number
              </label>
              <input
                id="telNumber"
                name="telNumber"
                type="tel"
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={telNumber}
                onChange={(e) => setTelNumber(e.target.value)}
                disabled={isLoading}
              />
            </div>
            
            <div>
              <label htmlFor="mobileNumber" className="block text-sm font-medium text-gray-700 mb-1">
                Mobile Number
              </label>
              <input
                id="mobileNumber"
                name="mobileNumber"
                type="tel"
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                disabled={isLoading}
              />
            </div>
            
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
              />
            </div>
            
            <div>
              <label htmlFor="website" className="block text-sm font-medium text-gray-700 mb-1">
                Website
              </label>
              <input
                id="website"
                name="website"
                type="url"
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
        
        {/* Social Media Section */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">Social Media</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="youtube" className="block text-sm font-medium text-gray-700 mb-1">
                YouTube
              </label>
              <input
                id="youtube"
                name="youtube"
                type="url"
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
                disabled={isLoading}
              />
            </div>
            
            <div>
              <label htmlFor="tiktok" className="block text-sm font-medium text-gray-700 mb-1">
                TikTok
              </label>
              <input
                id="tiktok"
                name="tiktok"
                type="url"
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={tiktok}
                onChange={(e) => setTiktok(e.target.value)}
                disabled={isLoading}
              />
            </div>
            
            <div>
              <label htmlFor="facebook" className="block text-sm font-medium text-gray-700 mb-1">
                Facebook
              </label>
              <input
                id="facebook"
                name="facebook"
                type="url"
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
                disabled={isLoading}
              />
            </div>
            
            <div>
              <label htmlFor="instagram" className="block text-sm font-medium text-gray-700 mb-1">
                Instagram
              </label>
              <input
                id="instagram"
                name="instagram"
                type="url"
                className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
        
        {/* Location Information Section */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">Location Information</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Province selection - required for all institution types */}
            <div>
              <label htmlFor="province" className="block text-sm font-medium text-gray-700 mb-1">
                Province <span className="text-red-500">*</span>
              </label>
              <select
                id="province"
                name="province"
                className="mt-1 block w-full pl-2 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                value={province}
                onChange={handleProvinceChange}
                disabled={isLoading || isLoadingProvinces}
                required
              >
                <option value="">Select a province</option>
                {isLoadingProvinces ? (
                  <option value="" disabled>Loading provinces...</option>
                ) : (
                  provinces.map((prov) => (
                    <option key={prov} value={prov}>{prov}</option>
                  ))
                )}
              </select>
            </div>
            
            {/* District selection - conditional display and requirement */}
            {showDistrictSelection() && (
              <div>
                <label htmlFor="district" className="block text-sm font-medium text-gray-700 mb-1">
                  District {isDistrictRequired() && <span className="text-red-500">*</span>}
                </label>
                <select
                  id="district"
                  name="district"
                  className="mt-1 block w-full pl-2 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={district}
                  onChange={handleDistrictChange}
                  disabled={isLoading || !province || isLoadingDistricts}
                  required={isDistrictRequired()}
                >
                  <option value="">Select a district</option>
                  {isLoadingDistricts ? (
                    <option value="" disabled>Loading districts...</option>
                  ) : districts.length === 0 ? (
                    <option value="" disabled>No districts found for selected province</option>
                  ) : (
                    districts.map((dist) => (
                      <option key={dist} value={dist}>{dist}</option>
                    ))
                  )}
                </select>
              </div>
            )}
            
            {/* Circuit selection - only for Schools */}
            {showCircuitSelection() && (
              <div>
                <label htmlFor="circuit" className="block text-sm font-medium text-gray-700 mb-1">
                  Circuit {isCircuitRequired() && <span className="text-red-500">*</span>}
                </label>
                <select
                  id="circuit"
                  name="circuit"
                  className="mt-1 block w-full pl-2 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={circuit}
                  onChange={(e) => setCircuit(e.target.value)}
                  disabled={isLoading || !district || isLoadingCircuits}
                  required={isCircuitRequired()}
                >
                  <option value="">Select a circuit</option>
                  {isLoadingCircuits ? (
                    <option value="" disabled>Loading circuits...</option>
                  ) : circuits.length === 0 ? (
                    <option value="" disabled>No circuits found for selected district</option>
                  ) : (
                    circuits.map((circ) => (
                      <option key={circ} value={circ}>{circ}</option>
                    ))
                  )}
                </select>
              </div>
            )}
          </div>
        </div>
        
        {/* Leadership Section */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">Leadership</h2>
          
          <div className="space-y-4">
            {institutionType === 'School' && (
              <>
                <div>
                  <label htmlFor="principal" className="block text-sm font-medium text-gray-700 mb-1">
                    Principal <span className="text-red-500">*</span>
                  </label>
                  <div className="flex">
                    <input
                      id="principal"
                      name="principal"
                      type="text"
                      className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      value={principal}
                      onChange={(e) => setPrincipal(e.target.value)}
                      disabled={isLoading}
                      placeholder="Enter principal's name"
                      required={institutionType === 'School'}
                    />
                    <button
                      type="button"
                      className="ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => openUserSearch('principal')}
                    >
                      Search
                    </button>
                  </div>
                </div>
                
                <div>
                  <label htmlFor="deputyPrincipal" className="block text-sm font-medium text-gray-700 mb-1">
                    Deputy Principal
                  </label>
                  <div className="flex">
                    <input
                      id="deputyPrincipal"
                      name="deputyPrincipal"
                      type="text"
                      className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      value={deputyPrincipal}
                      onChange={(e) => setDeputyPrincipal(e.target.value)}
                      disabled={isLoading}
                      placeholder="Enter deputy principal's name"
                    />
                    <button
                      type="button"
                      className="ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => openUserSearch('deputyPrincipal')}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </>
            )}
            
            {institutionType === 'Circuit Office' && (
              <div>
                <label htmlFor="circuitManager" className="block text-sm font-medium text-gray-700 mb-1">
                  Circuit Manager <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <input
                    id="circuitManager"
                    name="circuitManager"
                    type="text"
                    className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    value={circuitManager}
                    onChange={(e) => setCircuitManager(e.target.value)}
                    disabled={isLoading}
                    placeholder="Enter circuit manager's name"
                    required={institutionType === 'Circuit Office'}
                  />
                  <button
                    type="button"
                    className="ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => openUserSearch('circuitManager')}
                  >
                    Search
                  </button>
                </div>
              </div>
            )}
            
            {institutionType === 'District Office' && (
              <div>
                <label htmlFor="districtDirector" className="block text-sm font-medium text-gray-700 mb-1">
                  District Director <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <input
                    id="districtDirector"
                    name="districtDirector"
                    type="text"
                    className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    value={districtDirector}
                    onChange={(e) => setDistrictDirector(e.target.value)}
                    disabled={isLoading}
                    placeholder="Enter district director's name"
                    required={institutionType === 'District Office'}
                  />
                  <button
                    type="button"
                    className="ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => openUserSearch('districtDirector')}
                  >
                    Search
                  </button>
                </div>
              </div>
            )}
            
            {institutionType === 'Provincial Department' && (
              <div>
                <label htmlFor="headOfDepartment" className="block text-sm font-medium text-gray-700 mb-1">
                  Head of Department <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <input
                    id="headOfDepartment"
                    name="headOfDepartment"
                    type="text"
                    className="mt-1 block w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    value={headOfDepartment}
                    onChange={(e) => setHeadOfDepartment(e.target.value)}
                    disabled={isLoading}
                    placeholder="Enter head of department's name"
                    required={institutionType === 'Provincial Department'}
                  />
                  <button
                    type="button"
                    className="ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => openUserSearch('headOfDepartment')}
                  >
                    Search
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
  
        {/* User Search Modal */}
        {showUserSearch && (
          <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => setShowUserSearch(false)}></div>
              
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        Search Users
                      </h3>
                      <div className="mt-4">
                        <input
                          type="text"
                          placeholder="Search by name..."
                          className="w-full pl-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          value={searchTerm}
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                            if (e.target.value.length >= 3) {
                              searchUsers(e.target.value, selectedField);
                            }
                          }}
                          onKeyDown={(e) => e.key === 'Enter' && searchTerm.length >= 3 && searchUsers(searchTerm, selectedField)}
                        />
                      </div>
                      
                      <div className="mt-4 max-h-60 overflow-y-auto">
                        {isLoading ? (
                          <p className="text-center text-gray-500">Loading...</p>
                        ) : searchResults.length === 0 ? (
                          <p className="text-center text-gray-500">
                            {searchTerm.length < 3 ? 'Type at least 3 characters to search' : 'No users found'}
                          </p>
                        ) : (
                          <ul className="divide-y divide-gray-200">
                            {searchResults.map((user) => (
                              <li key={user.id} className="py-2">
                                <button
                                  type="button"
                                  className="w-full text-left px-2 py-2 rounded hover:bg-gray-100"
                                  onClick={() => handleUserSelect(user)}
                                >
                                  <div className="flex items-center">
                                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium">
                                      {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                    </div>
                                    <div className="ml-3">
                                      <p className="text-sm font-medium text-gray-900">
                                        {user.title} {user.firstName} {user.lastName}
                                      </p>
                                      <p className="text-xs text-gray-500">{user.userRole}</p>
                                    </div>
                                  </div>
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setShowUserSearch(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
  
        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="button"
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-3"
            onClick={() => navigate('/institutions')}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Registering...
              </>
            ) : (
              'Register Institution'
            )}
          </button>
        </div>
      </form>
    </div>
  );
  };
  
  export default InstitutionRegistration;
  