// Updated ChannelList.js
import React, { useState, useEffect, useRef } from 'react';
import { Plus, Lock, AlertCircle, MoreHorizontal, Edit, Trash2, Settings } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { collection, addDoc, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';

const ChannelList = ({ channels, activeChannel, onChannelSelect, isDirectMessage }) => {
  const { currentUser, userProfile } = useAuth();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [channelToEdit, setChannelToEdit] = useState(null);
  const [newChannelName, setNewChannelName] = useState('');
  const [newChannelType, setNewChannelType] = useState('public');
  const [newChannelDescription, setNewChannelDescription] = useState('');
  const [error, setError] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    }
    
    // Add event listener when dropdown is open
    if (activeDropdown !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeDropdown]);

  // Log authentication state for debugging
  useEffect(() => {
    console.log('Auth state in ChannelList:', { 
      currentUser: currentUser ? { uid: currentUser.uid, email: currentUser.email } : null,
      userProfile: userProfile ? { ...userProfile } : null 
    });
  }, [currentUser, userProfile]);
  
  const handleCreateChannel = async (e) => {
    e.preventDefault();
    console.log('Create channel form submitted');
    
    // Clear previous errors
    setError('');
    
    // Check for user authentication
    if (!currentUser) {
      console.error('Authentication error: No current user');
      setError('You must be logged in to create a channel');
      return;
    }
    
    if (!newChannelName.trim()) {
      setError('Channel name is required');
      return;
    }
    
    try {
      console.log('Preparing to create channel with data:', {
        creator: currentUser.uid,
        userProfile: userProfile ? 'available' : 'not available'
      });
      
      // Create a new channel
      const channelData = {
        name: newChannelName.trim().toLowerCase().replace(/\s+/g, '-'),
        displayName: newChannelName.trim(),
        description: newChannelDescription.trim(),
        type: newChannelType,
        createdBy: currentUser.uid,
        creatorEmail: currentUser.email,
        admin: currentUser.uid, // Explicitly set creator as admin
        institutionName: userProfile?.institutionName || 'Unknown Institution',
        createdAt: new Date(),
        membersCount: 1,
        members: [currentUser.uid]
      };
      
      console.log('Channel data to submit:', channelData);
      
      const docRef = await addDoc(collection(db, "channels"), channelData);
      console.log('Channel created with ID:', docRef.id);
      
      // Reset form
      setNewChannelName('');
      setNewChannelDescription('');
      setNewChannelType('public');
      setShowCreateForm(false);
      
      // Automatically select the new channel
      onChannelSelect(docRef.id);
      
    } catch (error) {
      console.error("Error creating channel:", error);
      setError(`Failed to create channel: ${error.message}`);
    }
  };

  const handleEditChannel = async (e) => {
    e.preventDefault();
    
    if (!newChannelName.trim()) {
      setError('Channel name is required');
      return;
    }
    
    try {
      // Update channel data
      const channelRef = doc(db, "channels", channelToEdit.id);
      
      await updateDoc(channelRef, {
        displayName: newChannelName.trim(),
        description: newChannelDescription.trim(),
        type: newChannelType,
        updatedAt: new Date()
      });
      
      // Reset form
      setNewChannelName('');
      setNewChannelDescription('');
      setNewChannelType('public');
      setShowEditForm(false);
      setChannelToEdit(null);
      
    } catch (error) {
      console.error("Error updating channel:", error);
      setError(`Failed to update channel: ${error.message}`);
    }
  };

  const handleDeleteChannel = async (channelId) => {
    if (window.confirm("Are you sure you want to delete this channel?")) return;
    
    try {
      await deleteDoc(doc(db, "channels", channelId));
      // If it was the active channel, we may want to select another channel
      if (activeChannel === channelId) {
        onChannelSelect(channels.length > 1 ? channels[0].id : null);
      }
    } catch (error) {
      console.error("Error deleting channel:", error);
      setError(`Failed to delete channel: ${error.message}`);
    }
  };
  
  const handleShowCreateForm = () => {
    // Check authentication before showing the form
    if (!currentUser) {
      setError('You must be logged in to create a channel');
      return;
    }
    
    setShowCreateForm(!showCreateForm);
    setError(''); // Clear any previous errors
  };

  const editChannel = (channel) => {
    setChannelToEdit(channel);
    setNewChannelName(channel.displayName || channel.name);
    setNewChannelDescription(channel.description || '');
    setNewChannelType(channel.type || 'public');
    setShowEditForm(true);
    setActiveDropdown(null);
  };

  const isChannelAdmin = (channel) => {
    return channel.admin === currentUser?.uid || channel.createdBy === currentUser?.uid;
  };

  const toggleDropdown = (channelId, e) => {
    e.stopPropagation();
    setActiveDropdown(activeDropdown === channelId ? null : channelId);
  };
  
  return (
    <div className="px-4 py-3 flex-none overflow-y-auto">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-xs font-semibold text-[#f7f7f7] uppercase tracking-wider">Channels</h3>
        <button 
          className="text-[#f7f7f7] hover:text-white"
          onClick={() => setShowCreateForm(!showCreateForm)}
        >
          <Plus size={16} />
        </button>
      </div>
      
      {/* Error message */}
      {error && (
        <div className="mb-3 bg-red-50 border border-red-200 rounded-md p-2 flex items-start">
          <AlertCircle size={14} className="text-red-500 mt-0.5 mr-1 flex-shrink-0" />
          <p className="text-xs text-red-600">{error}</p>
        </div>
      )}
      
      {/* Create Channel Form */}
      {showCreateForm && (
        <div className="mb-3 bg-[#ffffff22] p-2 rounded-md">
          <form onSubmit={handleCreateChannel}>
            {error && (
              <p className="text-xs text-red-300 mb-1">{error}</p>
            )}
            <input
              type="text"
              placeholder="Channel name"
              className="w-full px-2 py-1 text-sm border rounded mb-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
              value={newChannelName}
              onChange={(e) => setNewChannelName(e.target.value)}
            />
            <textarea
              placeholder="Description (optional)"
              className="w-full px-2 py-1 text-sm border rounded mb-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
              value={newChannelDescription}
              onChange={(e) => setNewChannelDescription(e.target.value)}
              rows={2}
            />
            <div className="flex items-center space-x-2 mb-2">
              <label className="flex items-center text-xs text-[#f7f7f7]">
                <input
                  type="radio"
                  name="channelType"
                  value="public"
                  checked={newChannelType === 'public'}
                  onChange={() => setNewChannelType('public')}
                  className="mr-1"
                />
                Public
              </label>
              <label className="flex items-center text-xs text-[#f7f7f7]">
                <input
                  type="radio"
                  name="channelType"
                  value="private"
                  checked={newChannelType === 'private'}
                  onChange={() => setNewChannelType('private')}
                  className="mr-1"
                />
                Private
              </label>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="px-2 py-1 text-xs text-[#f7f7f7] hover:text-white mr-2"
                onClick={() => setShowCreateForm(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-2 py-1 text-xs bg-white text-[#1d67cd] rounded hover:bg-gray-100"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Edit Channel Form */}
      {showEditForm && (
        <div className="mb-3 bg-[#ffffff22] p-2 rounded-md">
          <form onSubmit={handleEditChannel}>
            {error && (
              <p className="text-xs text-red-300 mb-1">{error}</p>
            )}
            <input
              type="text"
              placeholder="Channel name"
              className="w-full px-2 py-1 text-sm border rounded mb-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
              value={newChannelName}
              onChange={(e) => setNewChannelName(e.target.value)}
            />
            <textarea
              placeholder="Description (optional)"
              className="w-full px-2 py-1 text-sm border rounded mb-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
              value={newChannelDescription}
              onChange={(e) => setNewChannelDescription(e.target.value)}
              rows={2}
            />
            <div className="flex items-center space-x-2 mb-2">
              <label className="flex items-center text-xs text-[#f7f7f7]">
                <input
                  type="radio"
                  name="editChannelType"
                  value="public"
                  checked={newChannelType === 'public'}
                  onChange={() => setNewChannelType('public')}
                  className="mr-1"
                />
                Public
              </label>
              <label className="flex items-center text-xs text-[#f7f7f7]">
                <input
                  type="radio"
                  name="editChannelType"
                  value="private"
                  checked={newChannelType === 'private'}
                  onChange={() => setNewChannelType('private')}
                  className="mr-1"
                />
                Private
              </label>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="px-2 py-1 text-xs text-[#f7f7f7] hover:text-white mr-2"
                onClick={() => {
                  setShowEditForm(false); 
                  setChannelToEdit(null);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-2 py-1 text-xs bg-white text-[#1d67cd] rounded hover:bg-gray-100"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      )}
      
      {/* Channels List */}
      <div className="space-y-1">
        {channels.map((channel) => (
          <div
            key={channel.id}
            className={`flex items-center justify-between w-full rounded-md text-sm ${
              activeChannel === channel.id && !isDirectMessage
                ? 'bg-blue-50 text-blue-600'
                : 'hover:bg-gray-100 text-gray-300'
            }`}
          >
            <button
              className="flex items-center py-1.5 px-2 w-full text-left"
              onClick={() => onChannelSelect(channel.id)}
            >
              <span className="mr-2">#</span>
              <span>{channel.displayName || channel.name}</span>
              {channel.type === 'private' && (
                <Lock size={12} className="ml-1 text-gray-400" />
              )}
            </button>
            
            <div className="relative">
              <button
                className="px-1.5 py-1.5 text-gray-400 hover:text-gray-600"
                onClick={(e) => toggleDropdown(channel.id, e)}
              >
                <MoreHorizontal size={14} />
              </button>
              
              {activeDropdown === channel.id && (
                <div 
                  ref={dropdownRef}
                  className="absolute right-0 mt-1 w-36 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                  {isChannelAdmin(channel) ? (
                    <div className="py-1">
                      <button
                        className="w-full text-left px-3 py-1 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          editChannel(channel);
                        }}
                      >
                        <Edit size={12} className="inline mr-1" /> Edit
                      </button>
                      <button
                        className="w-full text-left px-3 py-1 text-sm text-red-600 hover:bg-gray-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteChannel(channel.id);
                        }}
                      >
                        <Trash2 size={12} className="inline mr-1" /> Delete
                      </button>
                      <button
                        className="w-full text-left px-3 py-1 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.channelMembersModal.openModal(channel.id);
                          setActiveDropdown(null);
                        }}
                      >
                        <Settings size={12} className="inline mr-1" /> Manage Members
                      </button>
                    </div>
                  ) : (
                    <div className="py-1">
                      <button
                        className="w-full text-left px-3 py-1 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.channelMembersModal.openModal(channel.id);
                          setActiveDropdown(null);
                        }}
                      >
                        <Settings size={12} className="inline mr-1" /> View Members
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            
            {channel.unread > 0 && (
              <span className="bg-white text-[#1d67cd] text-xs font-medium px-2 py-0.5 rounded-full">
                {channel.unread}
              </span>
            )}
          </div>
        ))}
        
        {channels.length === 0 && (
          <div className="text-center py-2 text-sm text-gray-500">
            No channels available
          </div>
        )}
      </div>
    </div>
  );
};

export default ChannelList;