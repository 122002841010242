import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Plus, Search, Filter, Edit, Trash2, Eye, FileText,
  Calendar, Clock, User, AlertCircle, ChevronDown, 
  CheckCircle, XCircle, MoreHorizontal, Camera, Video, File,
  PlusCircle, Download
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, query, where, orderBy, limit, 
  getDocs, deleteDoc, doc
} from 'firebase/firestore';
import { format } from 'date-fns';

const RecordList = () => {
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  
  // Ref for handling clicks outside dropdown
  const dropdownRef = useRef(null);
  
  // Handle clicks outside dropdown to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  // Fetch records
  useEffect(() => {
    if (!currentUser) return;
    
    const fetchRecords = async () => {
      setIsLoading(true);
      try {
        const recordsRef = collection(db, "records");
        let q = query(
          recordsRef,
          where("institutionId", "==", userProfile?.institutionId || ''),
          orderBy("date", "desc"),
          limit(100)
        );
        
        const querySnapshot = await getDocs(q);
        const recordsList = [];
        
        querySnapshot.forEach((doc) => {
          recordsList.push({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate() || new Date(),
            updatedAt: doc.data().updatedAt?.toDate() || new Date()
          });
        });
        
        setRecords(recordsList);
      } catch (err) {
        console.error("Error fetching records:", err);
        setError("Failed to load records");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchRecords();
  }, [currentUser, userProfile]);
  
  // Filter records based on search term and filters
  const filteredRecords = records.filter(item => {
    const matchesSearch = item.recordMessage?.toLowerCase().includes(searchTerm.toLowerCase()) || 
                         item.type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         item.targetRef?.toLowerCase().includes(searchTerm.toLowerCase());
                         
    const matchesCategory = categoryFilter ? item.category === categoryFilter : true;
    const matchesStatus = statusFilter ? item.status === statusFilter : true;
    
    return matchesSearch && matchesCategory && matchesStatus;
  });
  
  // Get unique categories for filter
  const categories = [...new Set(records.map(item => item.category))].filter(Boolean);
  
// Simplified renderFileIcon function for RecordList
const renderFileIcon = (item) => {
  // Check if the record has media
  if (!item || !item.mediaUrl) return null;
  
  // Determine the icon based on mediaType
  switch (item.mediaType) {
    case 'Image':
      return <Camera size={16} className="ml-2 text-blue-500" />;
    case 'Video':
      return <Video size={16} className="ml-2 text-purple-500" />;
    case 'Document':
      // If we have specific logic for different document types, we could expand this
      return <FileText size={16} className="ml-2 text-red-500" />;
    default:
      // Default file icon
      return <File size={16} className="ml-2 text-gray-500" />;
  }
};
  
  // Handle record deletion
  const handleDeleteRecord = async (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      try {
        await deleteDoc(doc(db, "records", id));
        
        // Update the UI by removing the deleted record
        setRecords(records.filter(item => item.id !== id));
        
        // Close dropdown
        setActiveDropdown(null);
      } catch (err) {
        console.error("Error deleting record:", err);
        setError("Failed to delete record");
      }
    }
  };
  
  // Format date
  const formatDate = (date) => {
    if (!date) return 'N/A';
    return format(new Date(date), 'MMM d, yyyy');
  };
  
  // Handle clicking on a record to view details
  const handleViewRecord = (id) => {
    navigate(`/records/view/${id}`);
  };

  const groupedRecords = filteredRecords.reduce((groups, record) => {
    const category = record.category || 'Uncategorized';
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(record);
    return groups;
  }, {});
  
  // Sort categories alphabetically
  const sortedCategories = Object.keys(groupedRecords).sort();
  
  // Toggle dropdown menu
  const toggleDropdown = (recordId) => {
    setActiveDropdown(activeDropdown === recordId ? null : recordId);
  };
  
  return (
    <div className="container mx-auto px-4 py-6">
      <div className="mb-6 flex flex-col md:flex-row md:items-center md:justify-between">
        <h1 className="text-2xl font-bold text-gray-900 mb-4 md:mb-0">School Records</h1>
        
        <div className="flex items-center">
          <Link
            to="/records/create"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add New Record
          </Link>
        </div>
      </div>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
          <div className="flex">
            <AlertCircle className="h-5 w-5 mr-2" />
            <span>{error}</span>
          </div>
        </div>
      )}
      
      {/* Search and Filters */}
      <div className="mb-6 bg-white p-4 rounded-lg shadow-sm">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="relative flex-grow">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
            <input
              type="text"
              placeholder="Search records..."
              className="pl-10 pr-4 py-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="flex gap-2">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Filter className="h-4 w-4 mr-2" />
              Filters
              <ChevronDown className={`ml-1 h-4 w-4 transition-transform ${showFilters ? 'rotate-180' : ''}`} />
            </button>
          </div>
        </div>
        
        {/* Filter options */}
        {showFilters && (
          <div className="mt-4 pt-4 border-t grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Filter by Category
              </label>
              <select
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
              >
                <option value="">All Categories</option>
                {categories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Filter by Status
              </label>
              <select
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="">All Statuses</option>
                <option value="Green">Green</option>
                <option value="Orange">Orange</option>
                <option value="Red">Red</option>
              </select>
            </div>
            
            <div className="flex items-end">
              <button
                onClick={() => {
                  setSearchTerm('');
                  setCategoryFilter('');
                  setStatusFilter('');
                }}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Clear All Filters
              </button>
            </div>
          </div>
        )}
      </div>
      
      {/* Records List */}
      {isLoading ? (
        <div className="text-center py-10">
          <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          <p className="mt-2 text-gray-500">Loading records...</p>
        </div>
      ) : filteredRecords.length === 0 ? (
        <div className="bg-white rounded-lg shadow-sm p-8 text-center">
          <FileText className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No records found</h3>
          <p className="mt-1 text-sm text-gray-500">
            {searchTerm || categoryFilter || statusFilter
              ? 'Try changing your search or filter criteria'
              : 'Get started by adding your first record'}
          </p>
          {!(searchTerm || categoryFilter || statusFilter) && (
            <div className="mt-6">
              <Link
                to="/records/create"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <Plus className="h-4 w-4 mr-2" />
                Add New Record
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          {/* Single table for all records */}
          <div className="overflow-x-auto" style={{ overflowX: 'auto' }}>
            <table className="min-w-full divide-y divide-gray-200 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  {/* Table Headers - same as before */}
                  <th scope="col" className="sticky left-0 z-10 bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-80">
                    Record Message
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                    Type
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-40">
                    Target
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                    Created At
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-20">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {/* Render categories and their records */}
                {sortedCategories.map((category, categoryIndex) => (
                  <React.Fragment key={category}>
                    {/* Category header row */}
                    <tr className="bg-gray-50">
                      <td colSpan="7" className="px-6 py-3">
                        <div className="flex items-center">
                          <h3 className="text-md font-medium text-gray-900">{category}</h3>
                          <span className="ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            {groupedRecords[category].length} records
                          </span>
                        </div>
                      </td>
                    </tr>
                    
                    {/* Records for this category */}
                    {groupedRecords[category].map((item) => (
                      <tr key={item.id} className="hover:bg-gray-50">
                        {/* Table row contents - same as before */}
                        <td 
                          className="sticky left-0 z-10 bg-white border-r px-6 py-4 whitespace-nowrap cursor-pointer hover:bg-gray-50"
                          onClick={() => handleViewRecord(item.id)}
                        >
                          <div className="flex items-center">
                            <div className="flex-1">
                              <div className="text-sm font-medium text-gray-900 truncate max-w-xs flex items-center">
                                {item.recordMessage || "No message"}
                                {renderFileIcon(item)}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{formatDate(item.date)}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{item.type || 'N/A'}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.target ? (
                            <div className="flex items-center">
                              <span className="text-sm text-gray-900">{item.target}</span>
                              {item.targetRef && (
                                <span className="ml-1 text-xs text-gray-500">({item.targetRef})</span>
                              )}
                            </div>
                          ) : (
                            <span className="text-sm text-gray-500">None</span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            item.status === 'Green' 
                              ? 'bg-green-100 text-green-800'
                              : item.status === 'Orange'
                                ? 'bg-yellow-100 text-yellow-800'
                                : 'bg-red-100 text-red-800'
                          }`}>
                            {item.status}
                          </span>
                          {item.requireAction === 'Yes' && (
                            <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                              Action
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(item.createdAt)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="relative" ref={activeDropdown === item.id ? dropdownRef : null}>
                            <button
                              onClick={() => toggleDropdown(item.id)}
                              className="text-gray-500 hover:text-gray-700 focus:outline-none"
                              aria-label="Actions"
                            >
                              <MoreHorizontal className="h-5 w-5" />
                            </button>
                            
                            {activeDropdown === item.id && (
                              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-1000">
                                <div className="py-1 rounded-md bg-white shadow-xs">
                                  <button
                                    onClick={() => handleViewRecord(item.id)}
                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                  >
                                    <Eye className="inline-block h-4 w-4 mr-2" />
                                    View Details
                                  </button>
                                  <Link
                                    to={`/records/edit/${item.id}`}
                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                  >
                                    <Edit className="inline-block h-4 w-4 mr-2" />
                                    Edit Record
                                  </Link>
                                  
                                  {/* Add Create Task Button */}
                                  
                                    <Link
                                      to={`/tasks/create?referenceType=Record&referenceId=${item.id}&referenceTitle=${encodeURIComponent(item.recordMessage || '')}`}
                                      className="block w-full text-left px-4 py-2 text-sm text-blue-600 hover:bg-gray-100 hover:text-blue-900"
                                    >
                                      <PlusCircle className="inline-block h-4 w-4 mr-2" />
                                      Create Task
                                    </Link>
                                  
                                  
                                  <button
                                    onClick={() => handleDeleteRecord(item.id)}
                                    className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 hover:text-red-900"
                                  >
                                    <Trash2 className="inline-block h-4 w-4 mr-2" />
                                    Delete
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordList;