import React, { useState, useEffect } from 'react';
import { Bell, Calendar, Users, FileText, Briefcase, BarChart2, MessageSquare, Search, Menu, X, ChevronDown, Settings, LogOut, User } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

const Dashboard = () => {
  const { userProfile } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    stats: {
      upcomingMeetings: 12,
      meetingsChange: '+2 from yesterday',
      resourceUtilization: '84%',
      utilizationChange: '+5% from last week',
      documentWorkflows: 7,
      workflowsStatus: '3 pending approval',
      activeStaff: 42,
      staffBreakdown: '32 educators, 10 managers'
    },
    recentActivity: [
      {
        id: 1,
        user: {
          initials: 'TM',
          name: 'Terry Mitchell',
          color: 'blue'
        },
        action: 'created a new document workflow',
        time: 'Today, 10:30 AM'
      },
      {
        id: 2,
        user: {
          initials: 'SK',
          name: 'Sarah King',
          color: 'green'
        },
        action: 'scheduled a staff meeting',
        time: 'Today, 9:15 AM'
      },
      {
        id: 3,
        user: {
          initials: 'RL',
          name: 'Robert Lewis',
          color: 'purple'
        },
        action: 'approved resource request',
        time: 'Yesterday, 4:30 PM'
      },
      {
        id: 4,
        user: {
          initials: 'JD',
          name: 'Jessica Davis',
          color: 'yellow'
        },
        action: 'commented on report',
        time: 'Yesterday, 2:15 PM'
      },
      {
        id: 5,
        user: {
          initials: 'BM',
          name: 'Brian Moore',
          color: 'red'
        },
        action: 'requested equipment',
        time: 'Yesterday, 11:30 AM'
      }
    ],
    aiInsights: [
      {
        id: 1,
        title: 'Resource Optimization',
        description: 'Science lab utilization is 32% below average. Consider rescheduling sessions to optimize resource usage.'
      },
      {
        id: 2,
        title: 'Staff Workload Alert',
        description: 'English department showing signs of meeting overload. Consider redistributing administrative tasks.'
      },
      {
        id: 3,
        title: 'Approval Bottleneck',
        description: 'Document approvals for budget requests are taking 3x longer than other workflows. Review process recommended.'
      }
    ],
    upcomingMeetings: [
      {
        id: 1,
        title: 'Department Heads Sync',
        time: 'Today, 2:00 PM - 3:00 PM',
        borderColor: 'blue',
        participants: [
          { initials: 'MJ', color: 'blue' },
          { initials: 'SK', color: 'green' },
          { initials: 'RL', color: 'purple' },
          { more: 4 }
        ]
      },
      {
        id: 2,
        title: 'Budget Planning Session',
        time: 'Tomorrow, 10:00 AM - 12:00 PM',
        borderColor: 'indigo',
        participants: [
          { initials: 'MJ', color: 'blue' },
          { initials: 'JD', color: 'yellow' },
          { initials: 'BM', color: 'red' }
        ]
      },
      {
        id: 3,
        title: 'Resource Allocation Review',
        time: 'Tomorrow, 3:30 PM - 4:30 PM',
        borderColor: 'green',
        participants: [
          { initials: 'MJ', color: 'blue' },
          { initials: 'SK', color: 'green' },
          { more: 2 }
        ]
      }
    ]
  });

  // Simulate loading data
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin h-12 w-12 border-4 border-blue-500 rounded-full border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between bg-white bg-opacity-20 backdrop-blur-sm p-4 rounded-lg shadow">
        <h1 className="text-2xl font-bold text-gray-800">School Management Dashboard</h1>
        <div className="mt-4 md:mt-0 flex space-x-2">
          <select className="border rounded-md px-3 py-2 bg-white bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option>This Week</option>
            <option>This Month</option>
            <option>This Quarter</option>
            <option>This Year</option>
          </select>
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            Export Report
          </button>
        </div>
      </div>
      
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white bg-opacity-80 backdrop-blur-sm rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Upcoming Meetings</p>
              <p className="text-3xl font-bold mt-1">{dashboardData.stats.upcomingMeetings}</p>
            </div>
            <div className="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center text-blue-600">
              <Calendar size={24} />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <span className="text-green-500 text-sm font-medium flex items-center">
              {dashboardData.stats.meetingsChange}
            </span>
          </div>
        </div>
        
        <div className="bg-white bg-opacity-80 backdrop-blur-sm rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Resource Utilization</p>
              <p className="text-3xl font-bold mt-1">{dashboardData.stats.resourceUtilization}</p>
            </div>
            <div className="w-12 h-12 bg-indigo-50 rounded-full flex items-center justify-center text-indigo-600">
              <Briefcase size={24} />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <span className="text-green-500 text-sm font-medium flex items-center">
              {dashboardData.stats.utilizationChange}
            </span>
          </div>
        </div>
        
        <div className="bg-white bg-opacity-80 backdrop-blur-sm rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Document Workflows</p>
              <p className="text-3xl font-bold mt-1">{dashboardData.stats.documentWorkflows}</p>
            </div>
            <div className="w-12 h-12 bg-yellow-50 rounded-full flex items-center justify-center text-yellow-600">
              <FileText size={24} />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <span className="text-red-500 text-sm font-medium flex items-center">
              {dashboardData.stats.workflowsStatus}
            </span>
          </div>
        </div>
        
        <div className="bg-white bg-opacity-80 backdrop-blur-sm rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Active Staff</p>
              <p className="text-3xl font-bold mt-1">{dashboardData.stats.activeStaff}</p>
            </div>
            <div className="w-12 h-12 bg-purple-50 rounded-full flex items-center justify-center text-purple-600">
              <Users size={24} />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <span className="text-gray-500 text-sm font-medium flex items-center">
              {dashboardData.stats.staffBreakdown}
            </span>
          </div>
        </div>
      </div>
      
      {/* Charts and Activity Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Resource Allocation Chart */}
        <div className="lg:col-span-2 bg-white bg-opacity-80 backdrop-blur-sm rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-semibold">Resource Allocation</h3>
            <select className="border rounded-md px-2 py-1 text-sm bg-white bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option>All Departments</option>
              <option>English Department</option>
              <option>Science Department</option>
              <option>Math Department</option>
            </select>
          </div>
          <div className="h-64 flex items-center justify-center bg-gray-50 bg-opacity-50 rounded-md">
            <p className="text-gray-400">Resource allocation chart visualization would appear here</p>
          </div>
        </div>
        
        {/* Recent Activity */}
        <div className="bg-white bg-opacity-80 backdrop-blur-sm rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-6">Recent Activity</h3>
          <div className="space-y-5">
            {dashboardData.recentActivity.map((activity) => (
              <div key={activity.id} className="flex">
                <div className={`flex-shrink-0 w-8 h-8 bg-${activity.user.color}-100 rounded-full flex items-center justify-center text-${activity.user.color}-600 font-medium mr-3`}>
                  {activity.user.initials}
                </div>
                <div>
                  <p className="text-sm">
                    <span className="font-medium">{activity.user.name}</span> {activity.action}
                  </p>
                  <p className="text-xs text-gray-500 mt-1">{activity.time}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* AI Insights and Upcoming Meetings */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-gradient-to-br from-blue-500 to-blue-600 bg-opacity-90 rounded-lg shadow p-6 text-white">
          <div className="flex items-start justify-between mb-4">
            <h3 className="text-lg font-semibold">AI Insights</h3>
            <button className="text-blue-200 hover:text-white text-sm">View All</button>
          </div>
          <div className="space-y-4">
            {dashboardData.aiInsights.map((insight) => (
              <div key={insight.id} className="bg-white bg-opacity-10 backdrop-blur-sm rounded-lg p-4">
                <p className="text-sm font-medium">{insight.title}</p>
                <p className="text-sm mt-1 text-blue-100">{insight.description}</p>
              </div>
            ))}
          </div>
        </div>
        
        <div className="bg-white bg-opacity-80 backdrop-blur-sm rounded-lg shadow p-6">
          <div className="flex items-start justify-between mb-4">
            <h3 className="text-lg font-semibold">Upcoming Meetings</h3>
            <button className="text-blue-600 hover:text-blue-800 text-sm">View Calendar</button>
          </div>
          <div className="space-y-4">
            {dashboardData.upcomingMeetings.map((meeting) => (
              <div key={meeting.id} className={`border-l-4 border-${meeting.borderColor}-500 pl-4`}>
                <p className="font-medium">{meeting.title}</p>
                <p className="text-sm text-gray-500 mt-1">{meeting.time}</p>
                <div className="flex items-center space-x-1 mt-2">
                  {meeting.participants.map((participant, index) => 
                    participant.more ? (
                      <div key={index} className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-gray-600 text-xs">
                        +{participant.more}
                      </div>
                    ) : (
                      <div key={index} className={`w-6 h-6 rounded-full bg-${participant.color}-100 flex items-center justify-center text-${participant.color}-600 text-xs font-medium`}>
                        {participant.initials}
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;