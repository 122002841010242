// src/components/structures/SchoolStructureForm.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Save, Plus, X, FileText, User } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { collection, doc, getDoc, setDoc, query, where, getDocs } from 'firebase/firestore';

const SchoolStructureForm = ({ isEditing = false }) => {
  const navigate = useNavigate();
  const { structureId } = useParams();
  const { userProfile } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Form fields
  const [structureName, setStructureName] = useState('');
  const [category, setCategory] = useState('');
  const [type, setType] = useState('');
  const [structureLead, setStructureLead] = useState('');
  const [leadMobile, setLeadMobile] = useState('');
  const [coordinator, setCoordinator] = useState('');
  const [coordinatorMobile, setCoordinatorMobile] = useState('');
  const [memberIds, setMemberIds] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showMemberSearch, setShowMemberSearch] = useState(false);
  const [members, setMembers] = useState([]);

  // Dropdown options
  const categories = ['Learners', 'Staff', 'Parents'];
  const types = ['Department', 'Committee', 'Community', 'Sports Team', 'Cultural Group', 'Academic Team'];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // In a real app, this would be paginated and filtered by the current user's institution
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("institutionName", "==", userProfile?.institutionName || ""));
        const querySnapshot = await getDocs(q);
        
        const usersList = [];
        querySnapshot.forEach((doc) => {
          usersList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setAllUsers(usersList);
      } catch (error) {
        console.error("Error fetching users:", error);
        setError("Failed to load users. Please try again.");
      }
    };

    if (userProfile?.institutionName) {
      fetchUsers();
    }
  }, [userProfile]);

  useEffect(() => {
    const fetchStructure = async () => {
      if (!isEditing || !structureId) return;
      
      setIsLoading(true);
      try {
        const structureDoc = await getDoc(doc(db, "structures", structureId));
        
        if (structureDoc.exists()) {
          const data = structureDoc.data();
          setStructureName(data.name || '');
          setCategory(data.category || '');
          setType(data.type || '');
          setStructureLead(data.structureLead || '');
          setLeadMobile(data.leadMobile || '');
          setCoordinator(data.coordinator || '');
          setCoordinatorMobile(data.coordinatorMobile || '');
          setMemberIds(data.memberIds || []);
          
          // Fetch member details
          const fetchedMembers = [];
          for (const memberId of data.memberIds || []) {
            const memberDoc = await getDoc(doc(db, "users", memberId));
            if (memberDoc.exists()) {
              fetchedMembers.push({
                id: memberId,
                ...memberDoc.data()
              });
            }
          }
          setMembers(fetchedMembers);
        } else {
          setError("Structure not found");
          navigate('/structures');
        }
      } catch (error) {
        console.error("Error fetching structure:", error);
        setError("Failed to load structure. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStructure();
  }, [isEditing, structureId, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!structureName.trim()) {
      setError('Structure name is required');
      return;
    }
    
    if (!category) {
      setError('Category is required');
      return;
    }
    
    if (!type) {
      setError('Type is required');
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      const structureData = {
        name: structureName.trim(),
        category,
        type,
        structureLead,
        leadMobile,
        coordinator,
        coordinatorMobile,
        memberIds,
        institutionName: userProfile?.institutionName,
        institutionName: userProfile?.institutionName,
        updatedBy: userProfile?.id,
        updatedAt: new Date(),
        ...(isEditing ? {} : { createdBy: userProfile?.id, createdAt: new Date() })
      };
      
      const structureRef = isEditing 
        ? doc(db, "structures", structureId) 
        : doc(collection(db, "structures"));
        
      await setDoc(structureRef, structureData, { merge: isEditing });
      
      setSuccessMessage(`Structure successfully ${isEditing ? 'updated' : 'created'}`);
      setTimeout(() => {
        navigate('/structures');
      }, 1500);
    } catch (err) {
      console.error(err);
      setError(`Failed to ${isEditing ? 'update' : 'create'} structure: ${err.message || 'Unknown error'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddMember = (user) => {
    if (!memberIds.includes(user.id)) {
      setMemberIds([...memberIds, user.id]);
      setMembers([...members, user]);
    }
    setShowMemberSearch(false);
    setSearchTerm('');
  };

  const handleRemoveMember = (userId) => {
    setMemberIds(memberIds.filter(id => id !== userId));
    setMembers(members.filter(member => member.id !== userId));
  };

  const filteredUsers = allUsers.filter(user => 
    (user.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) || 
     user.lastName?.toLowerCase().includes(searchTerm.toLowerCase())) &&
    !memberIds.includes(user.id)
  );

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">
            {isEditing ? 'Edit School Structure' : 'Create School Structure'}
          </h2>
          <button 
            onClick={() => navigate('/structures')}
            className="text-gray-500 hover:text-gray-700 flex items-center"
          >
            <ArrowLeft size={18} className="mr-1" />
            Back to Structures
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 text-red-800 p-4 border-l-4 border-red-500">
          {error}
        </div>
      )}

      {successMessage && (
        <div className="bg-green-50 text-green-800 p-4 border-l-4 border-green-500">
          {successMessage}
        </div>
      )}

      <form onSubmit={handleSubmit} className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="structureName" className="block text-sm font-medium text-gray-700 mb-1">
              Structure Name*
            </label>
            <input
              id="structureName"
              name="structureName"
              type="text"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={structureName}
              onChange={(e) => setStructureName(e.target.value)}
              disabled={isLoading}
            />
          </div>

          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
              Category*
            </label>
            <select
              id="category"
              name="category"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              disabled={isLoading}
            >
              <option value="">Select a category</option>
              {categories.map((cat) => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
              Type*
            </label>
            <select
              id="type"
              name="type"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={type}
              onChange={(e) => setType(e.target.value)}
              disabled={isLoading}
            >
              <option value="">Select a type</option>
              {types.map((t) => (
                <option key={t} value={t}>{t}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="structureLead" className="block text-sm font-medium text-gray-700 mb-1">
              Structure Lead
            </label>
            <input
              id="structureLead"
              name="structureLead"
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={structureLead}
              onChange={(e) => setStructureLead(e.target.value)}
              disabled={isLoading}
            />
          </div>

          <div>
            <label htmlFor="leadMobile" className="block text-sm font-medium text-gray-700 mb-1">
              Lead Mobile
            </label>
            <input
              id="leadMobile"
              name="leadMobile"
              type="tel"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={leadMobile}
              onChange={(e) => setLeadMobile(e.target.value)}
              disabled={isLoading}
            />
          </div>

          <div>
            <label htmlFor="coordinator" className="block text-sm font-medium text-gray-700 mb-1">
              Coordinator
            </label>
            <input
              id="coordinator"
              name="coordinator"
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={coordinator}
              onChange={(e) => setCoordinator(e.target.value)}
              disabled={isLoading}
            />
          </div>

          <div>
            <label htmlFor="coordinatorMobile" className="block text-sm font-medium text-gray-700 mb-1">
              Coordinator Mobile
            </label>
            <input
              id="coordinatorMobile"
              name="coordinatorMobile"
              type="tel"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={coordinatorMobile}
              onChange={(e) => setCoordinatorMobile(e.target.value)}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Structure Members
          </label>
          
          <div className="relative">
            {showMemberSearch && (
              <div className="mb-4">
                <div className="flex items-center mb-2">
                  <input
                    type="text"
                    placeholder="Search users..."
                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button 
                    type="button"
                    onClick={() => setShowMemberSearch(false)}
                    className="ml-2 text-gray-500 hover:text-gray-700"
                  >
                    <X size={18} />
                  </button>
                </div>
                
                <div className="bg-white border border-gray-300 rounded-md shadow-sm max-h-60 overflow-y-auto mt-1">
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map(user => (
                      <div 
                        key={user.id}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                        onClick={() => handleAddMember(user)}
                      >
                        <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium mr-2">
                          {user.firstName?.charAt(0) || ''}{user.lastName?.charAt(0) || ''}
                        </div>
                        <div>
                          <p className="text-sm font-medium">{user.title} {user.firstName} {user.lastName}</p>
                          <p className="text-xs text-gray-500">{user.userRole}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="px-4 py-3 text-sm text-gray-500">
                      {searchTerm ? 'No matching users found' : 'Start typing to search for users'}
                    </div>
                  )}
                </div>
              </div>
            )}
            
            {!showMemberSearch && (
              <button 
                type="button"
                onClick={() => setShowMemberSearch(true)}
                className="mb-4 flex items-center text-blue-600 hover:text-blue-800 text-sm font-medium"
              >
                <Plus size={16} className="mr-1" />
                Add Member
              </button>
            )}
            
            {members.length > 0 ? (
              <div className="space-y-2">
                {members.map(member => (
                  <div 
                    key={member.id}
                    className="flex items-center justify-between bg-gray-50 p-3 rounded-md border border-gray-200"
                  >
                    <div className="flex items-center">
                      <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium mr-3">
                        {member.firstName?.charAt(0) || ''}{member.lastName?.charAt(0) || ''}
                      </div>
                      <div>
                        <p className="text-sm font-medium">{member.title} {member.firstName} {member.lastName}</p>
                        <p className="text-xs text-gray-500">{member.userRole}</p>
                      </div>
                    </div>
                    <button 
                      type="button"
                      onClick={() => handleRemoveMember(member.id)}
                      className="text-gray-500 hover:text-red-600"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-sm text-gray-500 bg-gray-50 p-4 rounded-md border border-gray-200 text-center">
                No members added yet. Click "Add Member" to add structure members.
              </div>
            )}
          </div>
        </div>

        <div className="mt-8 flex items-center justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate('/structures')}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center"
            disabled={isLoading}
          >
            <Save size={16} className="mr-2" />
            {isLoading ? 'Saving...' : 'Save Structure'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SchoolStructureForm;