// src/components/resources/ResourceForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Save } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { collection, doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

const ResourceForm = ({ isEditing = false }) => {
  const navigate = useNavigate();
  const { resourceId } = useParams();
  const { userProfile } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Form fields
  const [resourceName, setResourceName] = useState('');
  const [category, setCategory] = useState('');
  const [status, setStatus] = useState('Available');
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [purchaseDate, setPurchaseDate] = useState('');
  const [purchaseValue, setPurchaseValue] = useState('');
  const [condition, setCondition] = useState('Excellent');
  const [availableDate, setAvailableDate] = useState('');
  const [serialNumber, setSerialNumber] = useState('');

  // Dropdown options
  const categories = ['Classroom', 'Technology', 'Sports', 'Office', 'Laboratory', 'Library'];
  const statuses = ['Available', 'In Use', 'Maintenance', 'Reserved'];
  const conditions = ['Excellent', 'Good', 'Fair', 'Poor'];

  useEffect(() => {
    const fetchResource = async () => {
      if (!isEditing || !resourceId) return;
      
      setIsLoading(true);
      try {
        const resourceDoc = await getDoc(doc(db, "resources", resourceId));
        
        if (resourceDoc.exists()) {
          const data = resourceDoc.data();
          setResourceName(data.name || '');
          setCategory(data.category || '');
          setStatus(data.status || 'Available');
          setLocation(data.location || '');
          setDescription(data.description || '');
          
          // Handle date formatting
          if (data.purchaseDate) {
            const date = new Date(data.purchaseDate.seconds * 1000);
            setPurchaseDate(formatDateForInput(date));
          }
          
          setPurchaseValue(data.purchaseValue || '');
          setCondition(data.condition || 'Excellent');
          
          // Handle available date formatting
          if (data.availableDate) {
            const date = new Date(data.availableDate.seconds * 1000);
            setAvailableDate(formatDateForInput(date));
          }
          
          setSerialNumber(data.serialNumber || '');
        } else {
          setError("Resource not found");
          navigate('/resources');
        }
      } catch (error) {
        console.error("Error fetching resource:", error);
        setError("Failed to load resource. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchResource();
  }, [isEditing, resourceId, navigate]);

  // Format date for input field (YYYY-MM-DD)
  const formatDateForInput = (date) => {
    if (!date) return '';
    return date.toISOString().split('T')[0];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!resourceName.trim()) {
      setError('Resource name is required');
      return;
    }
    
    if (!category) {
      setError('Category is required');
      return;
    }
    
    if (!status) {
      setError('Status is required');
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      // Convert string dates to Firestore timestamps if present
      let purchaseDateObj = null;
      if (purchaseDate) {
        purchaseDateObj = new Date(purchaseDate);
      }
      
      let availableDateObj = null;
      if (availableDate) {
        availableDateObj = new Date(availableDate);
      }
      
      const resourceData = {
        name: resourceName.trim(),
        category,
        status,
        location: location.trim(),
        description: description.trim(),
        purchaseDate: purchaseDateObj,
        purchaseValue: purchaseValue ? parseFloat(purchaseValue) : null,
        condition,
        availableDate: availableDateObj,
        serialNumber: serialNumber.trim(),
        institutionName: userProfile?.institutionName,
        institutionName: userProfile?.institutionName,
        updatedBy: userProfile?.id || userProfile?.uid,
        updatedAt: serverTimestamp(),
        ...(isEditing ? {} : { 
          createdBy: userProfile?.id || userProfile?.uid, 
          createdAt: serverTimestamp() 
        })
      };
      
      const resourceRef = isEditing 
        ? doc(db, "resources", resourceId) 
        : doc(collection(db, "resources"));
        
      await setDoc(resourceRef, resourceData, { merge: isEditing });
      
      setSuccessMessage(`Resource successfully ${isEditing ? 'updated' : 'created'}`);
      setTimeout(() => {
        navigate('/resources');
      }, 1500);
    } catch (err) {
      console.error(err);
      setError(`Failed to ${isEditing ? 'update' : 'create'} resource: ${err.message || 'Unknown error'}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">
            {isEditing ? 'Edit Resource' : 'Add New Resource'}
          </h2>
          <button 
            onClick={() => navigate('/resources')}
            className="text-gray-500 hover:text-gray-700 flex items-center"
          >
            <ArrowLeft size={18} className="mr-1" />
            Back to Resources
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 text-red-800 p-4 border-l-4 border-red-500">
          {error}
        </div>
      )}

      {successMessage && (
        <div className="bg-green-50 text-green-800 p-4 border-l-4 border-green-500">
          {successMessage}
        </div>
      )}

      <form onSubmit={handleSubmit} className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="resourceName" className="block text-sm font-medium text-gray-700 mb-1">
              Resource Name*
            </label>
            <input
              id="resourceName"
              name="resourceName"
              type="text"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={resourceName}
              onChange={(e) => setResourceName(e.target.value)}
              disabled={isLoading}
            />
          </div>

          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
              Category*
            </label>
            <select
              id="category"
              name="category"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              disabled={isLoading}
            >
              <option value="">Select a category</option>
              {categories.map((cat) => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-1">
              Status*
            </label>
            <select
              id="status"
              name="status"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              disabled={isLoading}
            >
              {statuses.map((stat) => (
                <option key={stat} value={stat}>{stat}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
              Location
            </label>
            <input
              id="location"
              name="location"
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              disabled={isLoading}
            />
          </div>

          <div className="md:col-span-2">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              rows="3"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={isLoading}
            ></textarea>
          </div>

          <div>
            <label htmlFor="purchaseDate" className="block text-sm font-medium text-gray-700 mb-1">
              Purchase Date
            </label>
            <input
              id="purchaseDate"
              name="purchaseDate"
              type="date"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={purchaseDate}
              onChange={(e) => setPurchaseDate(e.target.value)}
              disabled={isLoading}
            />
          </div>

          <div>
            <label htmlFor="purchaseValue" className="block text-sm font-medium text-gray-700 mb-1">
              Purchase Value
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">R</span>
              </div>
              <input
                id="purchaseValue"
                name="purchaseValue"
                type="number"
                step="0.01"
                min="0"
                className="w-full pl-8 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={purchaseValue}
                onChange={(e) => setPurchaseValue(e.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>

          <div>
            <label htmlFor="condition" className="block text-sm font-medium text-gray-700 mb-1">
              Condition
            </label>
            <select
              id="condition"
              name="condition"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
              disabled={isLoading}
            >
              {conditions.map((cond) => (
                <option key={cond} value={cond}>{cond}</option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="serialNumber" className="block text-sm font-medium text-gray-700 mb-1">
              Serial Number
            </label>
            <input
              id="serialNumber"
              name="serialNumber"
              type="text"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
              disabled={isLoading}
            />
          </div>

          {(status === 'In Use' || status === 'Maintenance' || status === 'Reserved') && (
            <div>
              <label htmlFor="availableDate" className="block text-sm font-medium text-gray-700 mb-1">
                Next Available Date
              </label>
              <input
                id="availableDate"
                name="availableDate"
                type="date"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={availableDate}
                onChange={(e) => setAvailableDate(e.target.value)}
                disabled={isLoading}
              />
            </div>
          )}
        </div>

        <div className="mt-8 flex items-center justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate('/resources')}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center"
            disabled={isLoading}
          >
            <Save size={16} className="mr-2" />
            {isLoading ? 'Saving...' : 'Save Resource'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResourceForm;