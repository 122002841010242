// src/components/planner/PlanDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  Calendar, Clock, Target, ChevronDown, ChevronUp, 
  Edit, Trash2, User, Briefcase, FileText, ArrowLeft, 
  CheckCircle, AlertCircle, List, Plus, ExternalLink 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  doc, 
  getDoc, 
  deleteDoc, 
  collection, 
  query, 
  where, 
  getDocs 
} from 'firebase/firestore';

const PlanDetails = () => {
  const { planId } = useParams();
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  
  const [plan, setPlan] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [showGoals, setShowGoals] = useState(true);
  const [showOutcomes, setShowOutcomes] = useState(true);
  
  // Fetch plan data
  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const planDoc = await getDoc(doc(db, 'plans', planId));
        
        if (planDoc.exists()) {
          const planData = planDoc.data();
          // Convert Firestore timestamps to JS Date objects where available
          setPlan({
            id: planDoc.id,
            ...planData,
            date: planData.date ? planData.date.toDate() : null,
            timeframe: planData.timeframe ? {
              start: planData.timeframe.start ? planData.timeframe.start.toDate() : null,
              end: planData.timeframe.end ? planData.timeframe.end.toDate() : null
            } : {},
            createdAt: planData.createdAt ? planData.createdAt.toDate() : null,
            updatedAt: planData.updatedAt ? planData.updatedAt.toDate() : null
          });
        } else {
          setError('Plan not found');
        }
      } catch (err) {
        console.error('Error fetching plan:', err);
        setError('Failed to load plan data');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchPlan();
  }, [planId]);
  
  // Fetch related tasks
  useEffect(() => {
    if (!planId) return;
    
    const fetchTasks = async () => {
      try {
        const tasksRef = collection(db, 'tasks');
        const q = query(
          tasksRef,
          where('taskRef.type', '==', 'Plan'),
          where('taskRef.id', '==', planId)
        );
        
        const querySnapshot = await getDocs(q);
        const tasksList = [];
        
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          tasksList.push({
            id: doc.id,
            ...data,
            // Convert Firestore timestamps to JS Date objects if present
            date: data.date ? data.date.toDate() : null,
            startDate: data.startDate ? data.startDate.toDate() : null,
            endDate: data.endDate ? data.endDate.toDate() : null
          });
        });
        
        setTasks(tasksList);
      } catch (err) {
        console.error('Error fetching tasks:', err);
      }
    };
    
    fetchTasks();
  }, [planId]);
  
  // Handle plan deletion
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await deleteDoc(doc(db, 'plans', planId));
      navigate('/planner');
    } catch (err) {
      console.error('Error deleting plan:', err);
      setError('Failed to delete plan');
      setDeleteConfirm(false);
      setIsLoading(false);
    }
  };
  
  // Calculate plan status based on timeframe
  const getPlanStatus = () => {
    if (!plan || !plan.timeframe || !plan.timeframe.start || !plan.timeframe.end) {
      return { label: 'Draft', color: 'bg-gray-100 text-gray-800' };
    }
    
    const now = new Date();
    const startDate = plan.timeframe.start;
    const endDate = plan.timeframe.end;
    
    if (now < startDate) {
      return { label: 'Upcoming', color: 'bg-blue-100 text-blue-800' };
    } else if (now > endDate) {
      return { label: 'Completed', color: 'bg-green-100 text-green-800' };
    } else {
      return { label: 'Active', color: 'bg-yellow-100 text-yellow-800' };
    }
  };
  
  // Format date for display using South African locale
  const formatDate = (date) => {
    if (!date) return 'Not specified';
    return date.toLocaleDateString('en-ZA', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };
  
  // Calculate task status
  const getTaskStatus = (task) => {
    if (!task.startDate || !task.endDate) {
      return { label: 'Not Started', color: 'bg-gray-100 text-gray-800' };
    }
    
    const now = new Date();
    const startDate = task.startDate;
    const endDate = task.endDate;
    
    if (task.status === 'Completed') {
      return { label: 'Completed', color: 'bg-green-100 text-green-800' };
    } else if (now < startDate) {
      return { label: 'Upcoming', color: 'bg-blue-100 text-blue-800' };
    } else if (now > endDate) {
      return { label: 'Overdue', color: 'bg-red-100 text-red-800' };
    } else {
      return { label: 'In Progress', color: 'bg-yellow-100 text-yellow-800' };
    }
  };
  
  // Get category badge color
  const getCategoryBadgeColor = (category) => {
    switch (category) {
      case 'Administration':
        return 'bg-purple-100 text-purple-800';
      case 'Curriculum':
        return 'bg-green-100 text-green-800';
      case 'Operations':
        return 'bg-blue-100 text-blue-800';
      case 'Extra-Curricula':
        return 'bg-amber-100 text-amber-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <button
          onClick={() => navigate('/planner')}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft size={16} className="mr-2" />
          Back to Plans
        </button>
      </div>
    );
  }
  
  if (!plan) {
    return (
      <div className="bg-white shadow rounded-lg p-6 text-center">
        <p>Plan not found</p>
        <button
          onClick={() => navigate('/planner')}
          className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft size={16} className="mr-2" />
          Back to Plans
        </button>
      </div>
    );
  }
  
  const status = getPlanStatus();

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/planner')}
            className="mr-4 text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft size={18} />
          </button>
          <h2 className="text-xl font-semibold text-gray-800">Plan Details</h2>
        </div>
        
        <div className="flex space-x-3">
          <Link
            to={`/planner/edit/${planId}`}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Edit size={14} className="mr-2" />
            Edit
          </Link>
          
          <button
            onClick={() => setDeleteConfirm(true)}
            className="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <Trash2 size={14} className="mr-2" />
            Delete
          </button>
        </div>
      </div>
      
      {/* Title and Status */}
      <div className="px-6 py-5 border-b border-gray-200">
        <div className="flex flex-col md:flex-row md:justify-between md:items-start">
          <div>
            <div className="flex items-center mb-2">
              <h1 className="text-2xl font-bold text-gray-900 mr-3">{plan.title}</h1>
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getCategoryBadgeColor(plan.category)}`}>
                {plan.category}
              </span>
              <span className={`ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${status.color}`}>
                {status.label}
              </span>
            </div>
            
            <p className="text-gray-600">
              {plan.description || 'No description provided'}
            </p>
          </div>
          
          <div className="mt-4 md:mt-0 md:ml-6 text-sm text-gray-500 flex flex-col items-start md:items-end">
            <div className="flex items-center mb-1">
              <Calendar size={16} className="mr-2" />
              Created: {formatDate(plan.createdAt || plan.date)}
            </div>
            {plan.updatedAt && (
              <div className="flex items-center">
                <Clock size={16} className="mr-2" />
                Updated: {formatDate(plan.updatedAt)}
              </div>
            )}
          </div>
        </div>
      </div>
      
      {/* Plan Details */}
      <div className="px-6 py-5 grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column */}
        <div className="space-y-6">
          {/* Timeframe */}
          <div className="border-b pb-4">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Timeframe</h3>
            <div className="flex items-center mb-1">
              <Calendar size={16} className="text-gray-500 mr-2" />
              <p className="text-sm text-gray-600">
                Start: {plan.timeframe?.start ? formatDate(plan.timeframe.start) : 'Not specified'}
              </p>
            </div>
            <div className="flex items-center">
              <Calendar size={16} className="text-gray-500 mr-2" />
              <p className="text-sm text-gray-600">
                End: {plan.timeframe?.end ? formatDate(plan.timeframe.end) : 'Not specified'}
              </p>
            </div>
          </div>
          
          {/* Goals */}
          <div className="border-b pb-4">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-medium text-gray-900">Goals</h3>
              <button 
                onClick={() => setShowGoals(!showGoals)}
                className="text-gray-500 hover:text-gray-700"
              >
                {showGoals ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
              </button>
            </div>
            
            {showGoals && (
              <ul className="space-y-2">
                {plan.goals && plan.goals.length > 0 ? (
                  plan.goals.map((goal, index) => (
                    <li key={index} className="flex items-start">
                      <div className="flex-shrink-0 mr-2">
                        <div className="w-6 h-6 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 text-xs font-medium">
                          {index + 1}
                        </div>
                      </div>
                      <p className="text-sm text-gray-600 mt-1">{goal.text}</p>
                    </li>
                  ))
                ) : (
                  <p className="text-sm text-gray-500 italic">No goals defined</p>
                )}
              </ul>
            )}
          </div>
          
          {/* Policy Reference */}
          {plan.policyRef && (
            <div className="border-b pb-4">
              <h3 className="text-lg font-medium text-gray-900 mb-2">Policy Reference</h3>
              <div className="flex items-center">
                <FileText size={16} className="text-gray-500 mr-2" />
                <p className="text-sm text-gray-600">{plan.policyRef}</p>
              </div>
            </div>
          )}
        </div>
        
        {/* Right Column */}
        <div className="space-y-6">
          {/* Responsibility */}
          {plan.responsibility && (
            <div className="border-b pb-4">
              <h3 className="text-lg font-medium text-gray-900 mb-2">Responsibility</h3>
              <div className="flex items-center">
                <User size={16} className="text-gray-500 mr-2" />
                <p className="text-sm text-gray-600">{plan.responsibility}</p>
              </div>
            </div>
          )}
          
          {/* Structure */}
          {plan.structure && (
            <div className="border-b pb-4">
              <h3 className="text-lg font-medium text-gray-900 mb-2">Structure</h3>
              <div className="flex items-center">
                <Briefcase size={16} className="text-gray-500 mr-2" />
                <p className="text-sm text-gray-600">{plan.structure}</p>
              </div>
            </div>
          )}
          
          {/* Measurable Outcomes */}
          <div className="border-b pb-4">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-medium text-gray-900">Measurable Outcomes</h3>
              <button 
                onClick={() => setShowOutcomes(!showOutcomes)}
                className="text-gray-500 hover:text-gray-700"
              >
                {showOutcomes ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
              </button>
            </div>
            
            {showOutcomes && (
              <ul className="space-y-2">
                {plan.measurableOutcomes && plan.measurableOutcomes.length > 0 ? (
                  plan.measurableOutcomes.map((outcome, index) => (
                    <li key={index} className="flex items-start">
                      <div className="flex-shrink-0 mr-2">
                        <div className="w-6 h-6 bg-green-100 rounded-full flex items-center justify-center text-green-600 text-xs font-medium">
                          {index + 1}
                        </div>
                      </div>
                      <p className="text-sm text-gray-600 mt-1">{outcome.text}</p>
                    </li>
                  ))
                ) : (
                  <p className="text-sm text-gray-500 italic">No measurable outcomes defined</p>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
      
      {/* Related Tasks Section */}
      <div className="px-6 py-5 border-t border-gray-200">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">Related Tasks</h3>
          <Link
            to={`/tasks/create?planId=${planId}&planTitle=${encodeURIComponent(plan.title)}`}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Plus size={14} className="mr-2" />
            Add Task
          </Link>
        </div>
        
        {tasks.length === 0 ? (
          <div className="text-center py-8 bg-gray-50 rounded-lg">
            <List className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No tasks yet</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by creating a task for this plan.</p>
            <div className="mt-6">
              <Link
                to={`/tasks/create?planId=${planId}&planTitle=${encodeURIComponent(plan.title)}`}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <Plus size={16} className="mr-2" />
                Create a task
              </Link>
            </div>
          </div>
        ) : (
          <div className="overflow-hidden rounded-md border border-gray-200">
            <ul className="divide-y divide-gray-200">
              {tasks.map((task) => {
                const taskStatus = getTaskStatus(task);
                
                return (
                  <li key={task.id} className="hover:bg-gray-50">
                    <Link to={`/tasks/details/${task.id}`} className="block p-4">
                      <div className="flex items-center justify-between">
                        <div className="flex-1 min-w-0">
                          <h4 className="text-base font-medium text-gray-900 truncate">{task.description}</h4>
                          <div className="mt-1 flex flex-wrap items-center text-sm text-gray-500 gap-x-4 gap-y-1">
                            {task.assignee && (
                              <div className="flex items-center">
                                <User className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                                {task.assignee}
                              </div>
                            )}
                            
                            {task.startDate && task.endDate && (
                              <div className="flex items-center">
                                <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                                {formatDate(task.startDate)} - {formatDate(task.endDate)}
                              </div>
                            )}
                          </div>
                        </div>
                        
                        <div className="ml-4">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${taskStatus.color}`}>
                            {taskStatus.label}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        
        {tasks.length > 0 && (
          <div className="mt-4 text-right">
            <Link
              to={`/tasks?planId=${planId}`}
              className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
            >
              View all tasks <ExternalLink size={14} className="ml-1" />
            </Link>
          </div>
        )}
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteConfirm && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div 
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
              aria-hidden="true"
              onClick={() => setDeleteConfirm(false)}
            ></div>
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <AlertCircle className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Delete Plan
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this plan? All data associated with 
                        &quot;{plan.title}&quot; will be permanently removed.
                        This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleDelete}
                  disabled={isLoading}
                >
                  {isLoading ? 'Deleting...' : 'Delete'}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setDeleteConfirm(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanDetails;
