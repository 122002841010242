// src/components/ai/ChatMessage.jsx
import React from 'react';
import { format } from 'date-fns';
import { User, Clock, FileText, AlertTriangle } from 'lucide-react';

const ChatMessage = ({ message }) => {
    const isUser = message.role === 'user';
    const isError = message.isError;
    const isLoading = message.isLoading;
    
    // Format timestamp safely
    const formatTimestamp = (timestamp) => {
      if (!timestamp) return '';
      
      try {
        // Check if timestamp is a Date object
        const date = timestamp instanceof Date ? 
          timestamp : 
          // If it's a Firebase timestamp, it might have a toDate method
          (timestamp.toDate ? timestamp.toDate() : new Date(timestamp));
        
        return format(date, 'MMM d, h:mm a');
      } catch (error) {
        console.error('Error formatting timestamp:', error);
        return '';
      }
    };

    if (isLoading) {
        return (
          <div className="flex justify-start">
            <div className="max-w-2xl rounded-lg p-4 bg-white shadow-sm rounded-bl-none">
              <div className="flex items-start">
                <div className="flex-shrink-0 mr-2">
                  <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 12H15M12 9V15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#1D67CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                </div>
                
                <div className="flex-1">
                  <div className="text-sm text-gray-800 flex items-center">
                    <span>{message.content}</span>
                    <span className="ml-2 inline-flex">
                      <span className="animate-bounce mx-0.5">.</span>
                      <span className="animate-bounce animation-delay-200 mx-0.5">.</span>
                      <span className="animate-bounce animation-delay-400 mx-0.5">.</span>
                    </span>
                  </div>
                  
                  <div className="mt-1 text-xs text-gray-500">
                    {formatTimestamp(message.timestamp)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
  
  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'}`}>
      <div className={`max-w-2xl rounded-lg p-4 ${
        isUser 
          ? 'bg-blue-200 text-gray-800 rounded-br-none' 
          : isError
            ? 'bg-red-50 border border-red-200 rounded-bl-none'
            : 'bg-white shadow-sm rounded-bl-none'
      }`}>
        <div className="flex items-start">
          {!isUser && (
            <div className="flex-shrink-0 mr-2">
              <div className={`h-8 w-8 rounded-full ${isError ? 'bg-red-100' : 'bg-blue-100'} flex items-center justify-center`}>
                {isError ? (
                  <AlertTriangle className="h-4 w-4 text-red-600" />
                ) : (
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 12H15M12 9V15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#1D67CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                )}
              </div>
            </div>
          )}
          
          <div className="flex-1">
            <div className={`text-sm ${
              isUser 
                ? 'text-gray-800' 
                : isError 
                  ? 'text-red-700' 
                  : 'text-gray-800'
            }`}>
              {message.content}
            </div>
            
            {isUser && (message.timePeriod || message.dataSources?.length > 0) && (
              <div className="mt-2 text-xs text-blue-800 flex flex-wrap items-center gap-2">
                {message.timePeriod && (
                  <div className="flex items-center bg-white px-2 py-0.5 rounded">
                    <Clock className="h-3 w-3 mr-1" />
                    {message.timePeriod.replace('_', ' ')}
                  </div>
                )}
                
                {message.dataSources?.map(source => (
                  <div key={source} className="flex items-center bg-white px-2 py-0.5 rounded">
                    <FileText className="h-3 w-3 mr-1" />
                    {source}
                  </div>
                ))}
              </div>
            )}
            
            <div className={`mt-1 text-xs ${
              isUser 
                ? 'text-blue-200' 
                : isError 
                  ? 'text-red-500' 
                  : 'text-gray-500'
            }`}>
              {formatTimestamp(message.timestamp)}
            </div>
          </div>
          
          {isUser && (
            <div className="flex-shrink-0 ml-2">
              <div className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center">
                <User className="h-5 w-5 text-white" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;