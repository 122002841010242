// src/components/institutions/InstitutionNavigation.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ChevronRight, Building, Building2, School } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useInstitutionPermissions } from '../../hooks/useInstitutionPermissions';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, doc, getDoc, orderBy } from 'firebase/firestore';

const InstitutionNavigation = () => {
  const navigate = useNavigate();
  const { level: urlLevel, id: urlId } = useParams();
  const { userProfile } = useAuth();
  const { 
    getUserInstitutionLevel,
    getDefaultInstitutionLevel,
    canViewInstitutionLevel,
    getInstitutionScope
  } = useInstitutionPermissions();
  
  // Navigation state
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCircuit, setSelectedCircuit] = useState(null);
  
  // Data for dropdowns
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [circuits, setCircuits] = useState([]);
  const [schools, setSchools] = useState([]);
  
  const [isLoadingProvinces, setIsLoadingProvinces] = useState(false);
  const [isLoadingDistricts, setIsLoadingDistricts] = useState(false);
  const [isLoadingCircuits, setIsLoadingCircuits] = useState(false);
  const [isLoadingSchools, setIsLoadingSchools] = useState(false);
  
  // Get user level to determine starting point
  const userLevel = getUserInstitutionLevel();
  const defaultLevel = getDefaultInstitutionLevel();
  
  // Initialize navigation based on URL and permissions
  useEffect(() => {
    // If URL contains level and ID, use that
    if (urlLevel && urlId && canViewInstitutionLevel(urlLevel)) {
      // Fetch the information about the selected institution
      const fetchSelectedInstitution = async () => {
        try {
          const institutionDoc = await getDoc(doc(db, "institutions", urlId));
          if (institutionDoc.exists()) {
            const data = institutionDoc.data();
            
            // Set appropriate level based on URL
            if (urlLevel === 'province' && data.type === 'Provincial Department') {
              setSelectedProvince({
                id: urlId,
                name: data.name || 'Unknown Province'
              });
            } else if (urlLevel === 'district' && data.type === 'District Office') {
              // If we're at district level, also set the province
              setSelectedDistrict({
                id: urlId,
                name: data.name || 'Unknown District'
              });
              
              if (data.province) {
                // Find province by name and set it
                const provincesRef = collection(db, "institutions");
                const q = query(
                  provincesRef,
                  where("type", "==", "Provincial Department"),
                  where("name", "==", data.province)
                );
                
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                  const provinceDoc = querySnapshot.docs[0];
                  setSelectedProvince({
                    id: provinceDoc.id,
                    name: provinceDoc.data().name
                  });
                }
              }
            } else if (urlLevel === 'circuit' && data.type === 'Circuit Office') {
              // If we're at circuit level, also set the district and province
              setSelectedCircuit({
                id: urlId,
                name: data.name || 'Unknown Circuit'
              });
              
              if (data.district) {
                // Find district by name and set it
                const districtsRef = collection(db, "institutions");
                const q = query(
                  districtsRef,
                  where("type", "==", "District Office"),
                  where("name", "==", data.district)
                );
                
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                  const districtDoc = querySnapshot.docs[0];
                  setSelectedDistrict({
                    id: districtDoc.id,
                    name: districtDoc.data().name
                  });
                  
                  // Also set province if available
                  if (districtDoc.data().province) {
                    const provincesRef = collection(db, "institutions");
                    const q2 = query(
                      provincesRef,
                      where("type", "==", "Provincial Department"),
                      where("name", "==", districtDoc.data().province)
                    );
                    
                    const provinceSnapshot = await getDocs(q2);
                    if (!provinceSnapshot.empty) {
                      const provinceDoc = provinceSnapshot.docs[0];
                      setSelectedProvince({
                        id: provinceDoc.id,
                        name: provinceDoc.data().name
                      });
                    }
                  }
                }
              }
            }
          }
        } catch (error) {
          console.error('Error fetching selected institution:', error);
        }
      };
      
      fetchSelectedInstitution();
    } else {
      // If no valid URL parameters, set based on user's access level
      if (userLevel === 'province') {
        // Province users start at province level
        fetchUserProvince();
      } else if (userLevel === 'district') {
        // District users start at district level
        fetchUserDistrict();
      } else if (userLevel === 'circuit') {
        // Circuit users start at circuit level
        fetchUserCircuit();
      }
    }
  }, [urlLevel, urlId, userLevel, canViewInstitutionLevel]);
  
  // Fetch user's province if they're associated with one
  const fetchUserProvince = async () => {
    if (!userProfile || !userProfile.provinceId) return;
    
    try {
      const provinceDoc = await getDoc(doc(db, "institutions", userProfile.provinceId));
      if (provinceDoc.exists()) {
        setSelectedProvince({
          id: userProfile.provinceId,
          name: provinceDoc.data().name || 'Unknown Province'
        });
      }
    } catch (error) {
      console.error('Error fetching user province:', error);
    }
  };
  
  // Fetch user's district if they're associated with one
  const fetchUserDistrict = async () => {
    if (!userProfile || !userProfile.districtId) return;
    
    try {
      const districtDoc = await getDoc(doc(db, "institutions", userProfile.districtId));
      if (districtDoc.exists()) {
        setSelectedDistrict({
          id: userProfile.districtId,
          name: districtDoc.data().name || 'Unknown District'
        });
        
        // Also set the province if available
        if (districtDoc.data().province && userProfile.provinceId) {
          fetchUserProvince();
        }
      }
    } catch (error) {
      console.error('Error fetching user district:', error);
    }
  };
  
  // Fetch user's circuit if they're associated with one
  const fetchUserCircuit = async () => {
    if (!userProfile || !userProfile.circuitId) return;
    
    try {
      const circuitDoc = await getDoc(doc(db, "institutions", userProfile.circuitId));
      if (circuitDoc.exists()) {
        setSelectedCircuit({
          id: userProfile.circuitId,
          name: circuitDoc.data().name || 'Unknown Circuit'
        });
        
        // Also set the district if available
        if (circuitDoc.data().district && userProfile.districtId) {
          fetchUserDistrict();
        }
      }
    } catch (error) {
      console.error('Error fetching user circuit:', error);
    }
  };
  
  // Fetch all provinces the user has access to
  useEffect(() => {
    const fetchProvinces = async () => {
      if (!canViewInstitutionLevel('province')) {
        setProvinces([]);
        return;
      }
      
      setIsLoadingProvinces(true);
      
      try {
        const scope = getInstitutionScope('province');
        const institutionsRef = collection(db, "institutions");
        
        let q;
        if (scope.hasFullAccess) {
          q = query(
            institutionsRef,
            where("type", "==", "Provincial Department"),
            orderBy("name")
          );
        } else if (scope.ids && scope.ids.length > 0) {
          // Only fetch specific provinces the user has access to
          q = query(
            institutionsRef,
            where("type", "==", "Provincial Department"),
            where("__name__", "in", scope.ids)
          );
        } else {
          setProvinces([]);
          setIsLoadingProvinces(false);
          return;
        }
        
        const querySnapshot = await getDocs(q);
        const provinceList = [];
        
        querySnapshot.forEach((doc) => {
          provinceList.push({
            id: doc.id,
            name: doc.data().name || 'Unnamed Province'
          });
        });
        
        setProvinces(provinceList);
        
        // If we have provinces but none selected, select the first one
        if (provinceList.length > 0 && !selectedProvince) {
          setSelectedProvince(provinceList[0]);
        }
      } catch (error) {
        console.error('Error fetching provinces:', error);
      } finally {
        setIsLoadingProvinces(false);
      }
    };
    
    fetchProvinces();
  }, [canViewInstitutionLevel, getInstitutionScope, selectedProvince]);
  
  // Fetch districts based on selected province
  useEffect(() => {
    const fetchDistricts = async () => {
      if (!selectedProvince || !canViewInstitutionLevel('district')) {
        setDistricts([]);
        return;
      }
      
      setIsLoadingDistricts(true);
      
      try {
        const institutionsRef = collection(db, "institutions");
        const q = query(
          institutionsRef,
          where("type", "==", "District Office"),
          where("province", "==", selectedProvince.name),
          orderBy("name")
        );
        
        const querySnapshot = await getDocs(q);
        const districtList = [];
        
        querySnapshot.forEach((doc) => {
          districtList.push({
            id: doc.id,
            name: doc.data().name || 'Unnamed District',
            province: doc.data().province
          });
        });
        
        setDistricts(districtList);
        
        // If no districts found, try an alternative approach
        if (districtList.length === 0) {
          console.log('No districts found with standard query, trying alternative...');
          
          // Try to find districts that match the province
          const altQuery = query(
            institutionsRef,
            where("type", "==", "District Office")
          );
          
          const altSnapshot = await getDocs(altQuery);
          const altDistricts = [];
          
          altSnapshot.forEach(doc => {
            const data = doc.data();
            if (
              (data.province && data.province === selectedProvince.name) ||
              (data.provinceName && data.provinceName === selectedProvince.name) ||
              (data.provinceId && data.provinceId === selectedProvince.id) ||
              (data.location && data.location.includes(selectedProvince.name))
            ) {
              altDistricts.push({
                id: doc.id,
                name: data.name || 'Unnamed District',
                province: data.province || selectedProvince.name
              });
            }
          });
          
          if (altDistricts.length > 0) {
            setDistricts(altDistricts);
          }
        }
      } catch (error) {
        console.error('Error fetching districts:', error);
      } finally {
        setIsLoadingDistricts(false);
      }
    };
    
    fetchDistricts();
  }, [selectedProvince, canViewInstitutionLevel]);
  
  // Fetch circuits based on selected district
  useEffect(() => {
    const fetchCircuits = async () => {
      if (!selectedDistrict || !canViewInstitutionLevel('circuit')) {
        setCircuits([]);
        return;
      }
      
      setIsLoadingCircuits(true);
      
      try {
        // Debug fetch to understand the data structure
        console.log('Fetching circuits for district:', selectedDistrict.name);
        
        const institutionsRef = collection(db, "institutions");
        
        // First try with a direct query
        const q = query(
          institutionsRef,
          where("type", "==", "Circuit Office"),
          where("district", "==", selectedDistrict.name),
          orderBy("name")
        );
        
        const querySnapshot = await getDocs(q);
        const circuitList = [];
        
        querySnapshot.forEach((doc) => {
          circuitList.push({
            id: doc.id,
            name: doc.data().name || 'Unnamed Circuit',
            district: doc.data().district
          });
        });
        
        console.log(`Found ${circuitList.length} circuits with standard query`);
        
        // If no circuits found, try an alternative approach
        if (circuitList.length === 0) {
          console.log('No circuits found with standard query, trying alternative...');
          
          // Try querying all circuit offices and filter manually
          const altQuery = query(
            institutionsRef,
            where("type", "==", "Circuit Office")
          );
          
          const altSnapshot = await getDocs(altQuery);
          const altCircuits = [];
          
          altSnapshot.forEach(doc => {
            const data = doc.data();
            if (
              (data.district && data.district === selectedDistrict.name) ||
              (data.districtName && data.districtName === selectedDistrict.name) ||
              (data.districtId && data.districtId === selectedDistrict.id) ||
              (data.location && data.location.includes(selectedDistrict.name))
            ) {
              altCircuits.push({
                id: doc.id,
                name: data.name || 'Unnamed Circuit',
                district: data.district || selectedDistrict.name
              });
            }
          });
          
          console.log(`Found ${altCircuits.length} circuits with alternative query`);
          
          if (altCircuits.length > 0) {
            setCircuits(altCircuits);
          } else {
            setCircuits([]);
          }
        } else {
          setCircuits(circuitList);
        }
      } catch (error) {
        console.error('Error fetching circuits:', error);
        setCircuits([]);
      } finally {
        setIsLoadingCircuits(false);
      }
    };
    
    fetchCircuits();
  }, [selectedDistrict, canViewInstitutionLevel]);
  
  // Fetch schools based on selected circuit
  // Fetch schools based on selected circuit
  useEffect(() => {
    const fetchSchools = async () => {
      if (!selectedCircuit) {
        setSchools([]);
        return;
      }
      
      setIsLoadingSchools(true);
      
      try {
        const institutionsRef = collection(db, "institutions");
        const q = query(
          institutionsRef,
          where("type", "==", "School"),
          where("circuit", "==", selectedCircuit.name),
          orderBy("name")
        );
        
        const querySnapshot = await getDocs(q);
        const schoolList = [];
        
        querySnapshot.forEach((doc) => {
          schoolList.push({
            id: doc.id,
            name: doc.data().name || 'Unnamed School',
            phase: doc.data().phase || '',
            circuit: doc.data().circuit
          });
        });
        
        // If no schools found, try an alternative approach
        if (schoolList.length === 0) {
          // Try finding schools that match this circuit
          const altQuery = query(
            institutionsRef,
            where("type", "==", "School")
          );
          
          const altSnapshot = await getDocs(altQuery);
          const altSchools = [];
          
          altSnapshot.forEach(doc => {
            const data = doc.data();
            if (
              (data.circuit && data.circuit === selectedCircuit.name) ||
              (data.circuitName && data.circuitName === selectedCircuit.name) ||
              (data.circuitId && data.circuitId === selectedCircuit.id) ||
              (data.location && data.location.includes(selectedCircuit.name))
            ) {
              altSchools.push({
                id: doc.id,
                name: data.name || 'Unnamed School',
                phase: data.phase || '',
                circuit: data.circuit || selectedCircuit.name
              });
            }
          });
          
          if (altSchools.length > 0) {
            setSchools(altSchools);
          } else {
            setSchools([]);
          }
        } else {
          setSchools(schoolList);
        }
      } catch (error) {
        console.error('Error fetching schools:', error);
        setSchools([]);
      } finally {
        setIsLoadingSchools(false);
      }
    };
    
    fetchSchools();
  }, [selectedCircuit]);
  
  // Navigation handlers
  const handleProvinceChange = (e) => {
    const provinceId = e.target.value;
    const province = provinces.find(p => p.id === provinceId);
    
    if (province) {
      setSelectedProvince(province);
      setSelectedDistrict(null);
      setSelectedCircuit(null);
      navigate(`/institutions/province/${provinceId}`);
    }
  };
  
  const handleDistrictChange = (e) => {
    const districtId = e.target.value;
    const district = districts.find(d => d.id === districtId);
    
    if (district) {
      setSelectedDistrict(district);
      setSelectedCircuit(null);
      navigate(`/institutions/district/${districtId}`);
    }
  };
  
  const handleCircuitChange = (e) => {
    const circuitId = e.target.value;
    const circuit = circuits.find(c => c.id === circuitId);
    
    if (circuit) {
      setSelectedCircuit(circuit);
      navigate(`/institutions/circuit/${circuitId}`);
    }
  };
  
  const handleSchoolSelect = (schoolId) => {
    navigate(`/institutions/details/${schoolId}`);
  };
  
  // Show loading state
  if (isLoadingProvinces && !provinces.length) {
    return (
      <div className="bg-white shadow rounded-lg p-4 mb-6">
        <div className="animate-pulse space-y-2">
          <div className="h-4 bg-gray-200 rounded"></div>
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="bg-white shadow rounded-lg p-4 mb-6">
      <h2 className="text-lg font-medium text-gray-800 mb-4">Institution Navigation</h2>
      
      <div className="flex flex-wrap items-center gap-2 mb-4">
        {/* Province Selection */}
        {canViewInstitutionLevel('province') && (
          <div className="flex items-center">
            <Building className="text-gray-500 mr-2 h-5 w-5" />
            <select
              className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={selectedProvince?.id || ''}
              onChange={handleProvinceChange}
              disabled={isLoadingProvinces}
            >
              <option value="">Select Province</option>
              {provinces.map(province => (
                <option key={province.id} value={province.id}>
                  {province.name}
                </option>
              ))}
            </select>
            
            {isLoadingProvinces && (
              <div className="animate-spin ml-2 h-4 w-4 border-2 border-blue-500 rounded-full border-t-transparent"></div>
            )}
          </div>
        )}
        
        {/* District Selection */}
        {canViewInstitutionLevel('district') && selectedProvince && (
          <div className="flex items-center">
            <ChevronRight className="text-gray-400 mx-1 h-5 w-5" />
            <Building className="text-gray-500 mr-2 h-5 w-5" />
            <select
              className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={selectedDistrict?.id || ''}
              onChange={handleDistrictChange}
              disabled={isLoadingDistricts || districts.length === 0}
            >
              <option value="">Select District</option>
              {districts.map(district => (
                <option key={district.id} value={district.id}>
                  {district.name}
                </option>
              ))}
            </select>
            
            {isLoadingDistricts && (
              <div className="animate-spin ml-2 h-4 w-4 border-2 border-blue-500 rounded-full border-t-transparent"></div>
            )}
          </div>
        )}
        
        {/* Circuit Selection */}
        {canViewInstitutionLevel('circuit') && selectedDistrict && (
          <div className="flex items-center">
            <ChevronRight className="text-gray-400 mx-1 h-5 w-5" />
            <Building2 className="text-gray-500 mr-2 h-5 w-5" />
            <select
              className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={selectedCircuit?.id || ''}
              onChange={handleCircuitChange}
              disabled={isLoadingCircuits || circuits.length === 0}
            >
              <option value="">Select Circuit</option>
              {circuits.map(circuit => (
                <option key={circuit.id} value={circuit.id}>
                  {circuit.name}
                </option>
              ))}
            </select>
            
            {isLoadingCircuits && (
              <div className="animate-spin ml-2 h-4 w-4 border-2 border-blue-500 rounded-full border-t-transparent"></div>
            )}
          </div>
        )}
      </div>
      
      {/* Schools Display */}
      {selectedCircuit && (
        <div>
          <h3 className="text-md font-medium text-gray-700 mb-2 flex items-center">
            <School className="h-5 w-5 text-blue-500 mr-2" />
            Schools in {selectedCircuit.name}
          </h3>
          
          {isLoadingSchools ? (
            <div className="animate-pulse space-y-2">
              <div className="h-10 bg-gray-200 rounded"></div>
              <div className="h-10 bg-gray-200 rounded"></div>
              <div className="h-10 bg-gray-200 rounded"></div>
            </div>
          ) : schools.length === 0 ? (
            <div className="text-center py-6 bg-gray-50 rounded-md">
              <p className="text-gray-500">No schools found in this circuit</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
              {schools.map(school => (
                <div
                  key={school.id}
                  className="p-3 border rounded-md hover:bg-blue-50 cursor-pointer transition-colors"
                  onClick={() => handleSchoolSelect(school.id)}
                >
                  <div className="flex items-center">
                    <School className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                    <div>
                      <p className="font-medium text-sm text-gray-800">{school.name}</p>
                      {school.phase && (
                        <span className="inline-block px-2 py-0.5 text-xs bg-gray-100 text-gray-600 rounded">
                          {school.phase}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InstitutionNavigation;