// src/hooks/useInstitutionPermissions.js
import { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase/config';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  getDoc 
} from 'firebase/firestore';

export const useInstitutionPermissions = () => {
  const { currentUser, userProfile } = useAuth();
  const [permissions, setPermissions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Check if the user is a development admin
  const isDevAdmin = useCallback(() => {
    return userProfile?.email === 'mfundo@appcentral.co.za';
  }, [userProfile]);

  // Get the user's institution level based on their profile
  const getUserInstitutionLevel = useCallback(() => {
    if (!userProfile) return 'none';
    
    // Special development access for specific email
    if (isDevAdmin()) {
      console.log('Development user detected - granting full province-level access');
      return 'province'; // Province level gives access to everything
    }
    
    // Normal role-based permissions
    if (userProfile.institutionCategory === 'Provincial Department') {
      return 'province';
    } else if (userProfile.institutionCategory === 'District Office') {
      return 'district';
    } else if (userProfile.institutionCategory === 'Circuit Office') {
      return 'circuit';
    } else if (userProfile.institutionCategory === 'School') {
      return 'school';
    } else {
      return 'none';
    }
  }, [userProfile, isDevAdmin]);

  // Get the default institution level the user can view
  const getDefaultInstitutionLevel = useCallback(() => {
    // For development admin, default to province view
    if (isDevAdmin()) {
      return 'province';
    }
    
    const level = getUserInstitutionLevel();
    
    // Users can view their own level and levels below them
    switch (level) {
      case 'province':
        return 'province';
      case 'district':
        return 'district';
      case 'circuit':
        return 'circuit';
      case 'school':
        return 'school';
      default:
        // Default to showing schools if no specific level found
        return 'school';
    }
  }, [getUserInstitutionLevel, isDevAdmin]);

  // Check if user can view a specific institution level
  const canViewInstitutionLevel = useCallback((level) => {
    // Development admin can view all levels
    if (isDevAdmin()) {
      return true;
    }
    
    const userLevel = getUserInstitutionLevel();
    
    // Define level hierarchy (higher index means higher level)
    const levelHierarchy = ['school', 'circuit', 'district', 'province'];
    
    const userLevelIndex = levelHierarchy.indexOf(userLevel);
    const targetLevelIndex = levelHierarchy.indexOf(level);
    
    // User can view their level and levels below them
    return userLevelIndex >= targetLevelIndex;
  }, [getUserInstitutionLevel, isDevAdmin]);

  // Check if user can view a specific institution
  const canViewInstitution = useCallback((institution) => {
    if (!userProfile || !institution) return false;
    
    // Development admin can view all institutions
    if (isDevAdmin()) {
      return true;
    }
    
    const userLevel = getUserInstitutionLevel();
    
    switch (userLevel) {
      case 'province':
        // Provincial users can see everything
        return true;
        
      case 'district':
        // District users can see their district and everything below it
        if (institution.type === 'Provincial Department') {
          return institution.id === userProfile.provinceId;
        } else {
          return institution.districtId === userProfile.institutionId || 
                 institution.id === userProfile.institutionId;
        }
        
      case 'circuit':
        // Circuit users can see their circuit and schools in their circuit
        if (institution.type === 'School') {
          return institution.circuitId === userProfile.institutionId;
        } else if (institution.type === 'Circuit Office') {
          return institution.id === userProfile.institutionId;
        } else if (institution.type === 'District Office') {
          return institution.id === userProfile.districtId;
        } else if (institution.type === 'Provincial Department') {
          return institution.id === userProfile.provinceId;
        }
        return false;
        
      case 'school':
        // School users can only see their own school and parent institutions
        if (institution.type === 'School') {
          return institution.id === userProfile.institutionId;
        } else if (institution.type === 'Circuit Office') {
          return institution.id === userProfile.circuitId;
        } else if (institution.type === 'District Office') {
          return institution.id === userProfile.districtId;
        } else if (institution.type === 'Provincial Department') {
          return institution.id === userProfile.provinceId;
        }
        return false;
        
      default:
        return false;
    }
  }, [userProfile, getUserInstitutionLevel, isDevAdmin]);

  // Check if user can edit a specific institution
  const canEditInstitution = useCallback((institution) => {
    if (!userProfile || !institution) return false;
    
    // Development admin can edit all institutions
    if (isDevAdmin()) {
      return true;
    }
    
    const userLevel = getUserInstitutionLevel();
    
    switch (userLevel) {
      case 'province':
        // Provincial users can edit everything
        return true;
        
      case 'district':
        // District users can edit their district and everything below it
        return institution.districtId === userProfile.institutionId || 
               institution.id === userProfile.institutionId;
        
      case 'circuit':
        // Circuit users can edit their circuit and schools in their circuit
        if (institution.type === 'School') {
          return institution.circuitId === userProfile.institutionId;
        } else if (institution.type === 'Circuit Office') {
          return institution.id === userProfile.institutionId;
        }
        return false;
        
      case 'school':
        // School users can only edit their own school
        return institution.type === 'School' && institution.id === userProfile.institutionId;
        
      default:
        return false;
    }
  }, [userProfile, getUserInstitutionLevel, isDevAdmin]);

  // Check if user can create a new institution at a specific level
  const canCreateInstitution = useCallback((level) => {
    // Development admin can create at any level
    if (isDevAdmin()) {
      return true;
    }
    
    const userLevel = getUserInstitutionLevel();
    
    // Define level hierarchy (higher index means higher level)
    const levelHierarchy = ['school', 'circuit', 'district', 'province'];
    
    const userLevelIndex = levelHierarchy.indexOf(userLevel);
    const targetLevelIndex = levelHierarchy.indexOf(level);
    
    // User can create institutions at their level or below
    return userLevelIndex >= targetLevelIndex;
  }, [getUserInstitutionLevel, isDevAdmin]);

  // Get the scope of institutions the user has access to at a specific level
  const getInstitutionScope = useCallback((level) => {
    // Development admin has full access to all levels
    if (isDevAdmin()) {
      return {
        hasFullAccess: true,
        ids: [], // Empty array means no specific filtering
        provinceId: null,
        districtId: null,
        circuitId: null
      };
    }
    
    const userLevel = getUserInstitutionLevel();
    
    // Define default scope with no access
    let scope = {
      hasFullAccess: false,
      ids: [], // IDs of institutions user can access
      provinceId: null,
      districtId: null,
      circuitId: null
    };
    
    // Set scope based on user's level and target level
    if (userLevel === 'province') {
      // Provincial users have full access to all levels
      scope.hasFullAccess = true;
      
      if (userProfile?.institutionId) {
        scope.provinceId = userProfile.institutionId;
      }
    } else if (userLevel === 'district') {
      // District users have scoped access
      if (level === 'province') {
        // Can only see their own province
        if (userProfile?.provinceId) {
          scope.ids = [userProfile.provinceId];
        }
      } else if (level === 'district') {
        // Can only see their own district
        if (userProfile?.institutionId) {
          scope.ids = [userProfile.institutionId];
          scope.districtId = userProfile.institutionId;
        }
      } else {
        // For circuits and schools, they have full access within their district
        scope.hasFullAccess = false;
        scope.districtId = userProfile?.institutionId || null;
      }
    } else if (userLevel === 'circuit') {
      // Circuit users have scoped access
      if (level === 'province') {
        // Can only see their own province
        if (userProfile?.provinceId) {
          scope.ids = [userProfile.provinceId];
        }
      } else if (level === 'district') {
        // Can only see their own district
        if (userProfile?.districtId) {
          scope.ids = [userProfile.districtId];
        }
      } else if (level === 'circuit') {
        // Can only see their own circuit
        if (userProfile?.institutionId) {
          scope.ids = [userProfile.institutionId];
          scope.circuitId = userProfile.institutionId;
        }
      } else {
        // For schools, they have full access within their circuit
        scope.hasFullAccess = false;
        scope.circuitId = userProfile?.institutionId || null;
      }
    } else if (userLevel === 'school') {
      // School users have very limited access
      if (level === 'province') {
        // Can only see their own province
        if (userProfile?.provinceId) {
          scope.ids = [userProfile.provinceId];
        }
      } else if (level === 'district') {
        // Can only see their own district
        if (userProfile?.districtId) {
          scope.ids = [userProfile.districtId];
        }
      } else if (level === 'circuit') {
        // Can only see their own circuit
        if (userProfile?.circuitId) {
          scope.ids = [userProfile.circuitId];
        }
      } else {
        // Can only see their own school
        if (userProfile?.institutionId) {
          scope.ids = [userProfile.institutionId];
        }
      }
    }
    
    // Ensure ids is always an array (never undefined)
    if (!scope.ids) {
      scope.ids = [];
    }
    
    return scope;
  }, [getUserInstitutionLevel, userProfile, isDevAdmin]);

  return {
    getUserInstitutionLevel,
    getDefaultInstitutionLevel,
    canViewInstitutionLevel,
    canViewInstitution,
    canEditInstitution,
    canCreateInstitution,
    getInstitutionScope,
    isDevAdmin,
    isLoading
  };
};