import React, { useState, useEffect } from 'react';
import { 
  MessageSquare, 
  Bell, 
  X, 
  Pin, 
  PinOff, 
  Mail, 
  CheckCircle, 
  AlertCircle, 
  Info
} from 'lucide-react';
import { db } from '../../firebase/config';
import { useAuth } from '../../context/AuthContext';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs, 
  updateDoc,
  doc,
  deleteDoc,
  onSnapshot
} from 'firebase/firestore';

const UserMessages = () => {
  const { currentUser } = useAuth();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  // Fetch user messages
  useEffect(() => {
    if (!currentUser || !currentUser.uid) return;

    setLoading(true);
    
    const messagesRef = collection(db, 'userMessages');
    const q = query(
      messagesRef,
      where('userId', '==', currentUser.uid),
      orderBy('createdAt', 'desc')
    );

    // Set up real-time listener for messages
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messageList = [];
      let unread = 0;
      
      snapshot.forEach((doc) => {
        const message = {
          id: doc.id,
          ...doc.data()
        };
        
        messageList.push(message);
        
        if (!message.isRead) {
          unread++;
        }
      });
      
      setMessages(messageList);
      setUnreadCount(unread);
      setLoading(false);
    }, (err) => {
      console.error('Error fetching messages:', err);
      setError('Failed to load messages');
      setLoading(false);
    });
    
    return () => unsubscribe();
  }, [currentUser]);

  // Toggle the dropdown
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    if (showDropdown) {
      setSelectedMessage(null);
    }
  };

  // View a message
  const viewMessage = async (message) => {
    setSelectedMessage(message);
    
    // Mark as read if not already
    if (!message.isRead && currentUser && currentUser.uid) {
      try {
        const messageRef = doc(db, 'userMessages', message.id);
        await updateDoc(messageRef, {
          isRead: true
        });
      } catch (err) {
        console.error('Error marking message as read:', err);
      }
    }
  };

  // Toggle pin status
  const togglePin = async (messageId, currentPinStatus) => {
    if (!currentUser || !currentUser.uid) return;
    
    try {
      const messageRef = doc(db, 'userMessages', messageId);
      await updateDoc(messageRef, {
        isPinned: !currentPinStatus
      });
    } catch (err) {
      console.error('Error toggling pin status:', err);
    }
  };

  // Delete a message
  const deleteMessage = async (messageId) => {
    if (!currentUser || !currentUser.uid) return;
    
    if (window.confirm('Are you sure you want to delete this message?')) {
      try {
        const messageRef = doc(db, 'userMessages', messageId);
        await deleteDoc(messageRef);
        
        // Clear selected message if it's the one we're deleting
        if (selectedMessage && selectedMessage.id === messageId) {
          setSelectedMessage(null);
        }
      } catch (err) {
        console.error('Error deleting message:', err);
      }
    }
  };

  // Get icon for message type
  const getMessageIcon = (message) => {
    if (message.subject && message.subject.includes('Welcome')) {
      return <CheckCircle className="h-5 w-5 text-green-500" />;
    } else if (message.isSystem && message.subject && message.subject.includes('Alert')) {
      return <AlertCircle className="h-5 w-5 text-red-500" />;
    } else if (message.isSystem) {
      return <Info className="h-5 w-5 text-blue-500" />;
    } else {
      return <Mail className="h-5 w-5 text-gray-500" />;
    }
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    const now = new Date();
    const diffDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
    
    if (diffDays < 1) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (diffDays < 7) {
      const options = { weekday: 'short' };
      return date.toLocaleDateString(undefined, options);
    } else {
      const options = { month: 'short', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    }
  };

  return (
    <div className="relative">
      {/* Notification Bell */}
      <button 
        className="relative text-gray-500 hover:text-gray-700 focus:outline-none"
        onClick={toggleDropdown}
      >
        <Bell size={20} />
        {unreadCount > 0 && (
          <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs font-bold rounded-full h-4 w-4 flex items-center justify-center">
            {unreadCount}
          </span>
        )}
      </button>
      
      {/* Messages Dropdown */}
      {showDropdown && (
        <div className="absolute right-0 mt-2 w-80 md:w-96 bg-white rounded-md shadow-lg z-50">
          <div className="flex items-center justify-between p-3 border-b">
            <h3 className="text-sm font-semibold">Notifications</h3>
            <button 
              onClick={toggleDropdown}
              className="text-gray-500 hover:text-gray-700"
            >
              <X size={16} />
            </button>
          </div>
          
          {loading ? (
            <div className="p-4 text-center">
              <div className="animate-spin mx-auto h-6 w-6 border-2 border-b-blue-600 rounded-full"></div>
              <p className="mt-2 text-sm text-gray-500">Loading messages...</p>
            </div>
          ) : error ? (
            <div className="p-4 text-center text-red-500">{error}</div>
          ) : messages.length === 0 ? (
            <div className="p-4 text-center text-gray-500">
              <MessageSquare className="mx-auto h-6 w-6 mb-2" />
              <p>No messages yet</p>
            </div>
          ) : selectedMessage ? (
            // Message detail view
            <div className="p-4">
              <div className="flex items-start justify-between mb-3">
                <button 
                  onClick={() => setSelectedMessage(null)}
                  className="text-blue-600 hover:text-blue-800 text-sm"
                >
                  Back to messages
                </button>
                
                <div className="flex space-x-2">
                  {!selectedMessage.isSystem && (
                    <button 
                      onClick={() => deleteMessage(selectedMessage.id)}
                      className="text-gray-500 hover:text-red-600"
                    >
                      <X size={16} />
                    </button>
                  )}
                  <button 
                    onClick={() => togglePin(selectedMessage.id, selectedMessage.isPinned)}
                    className={`${selectedMessage.isPinned ? 'text-blue-600' : 'text-gray-500'} hover:text-blue-800`}
                  >
                    {selectedMessage.isPinned ? <PinOff size={16} /> : <Pin size={16} />}
                  </button>
                </div>
              </div>
              
              <h4 className="font-medium text-lg mb-1">{selectedMessage.subject}</h4>
              <p className="text-xs text-gray-500 mb-3">
                {formatDate(selectedMessage.createdAt)}
              </p>
              
              <div 
                className="prose prose-sm max-w-none"
                dangerouslySetInnerHTML={{ __html: selectedMessage.content }}
              />
            </div>
          ) : (
            // Message list view
            <div className="max-h-80 overflow-y-auto">
              {messages.map((message) => (
                <div 
                  key={message.id}
                  onClick={() => viewMessage(message)}
                  className={`p-3 border-b hover:bg-gray-50 cursor-pointer flex items-start
                    ${!message.isRead ? 'bg-blue-50' : ''}`}
                >
                  <div className="flex-shrink-0 mr-3">
                    {getMessageIcon(message)}
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex justify-between">
                      <p className={`text-sm font-medium truncate ${!message.isRead ? 'text-blue-600' : 'text-gray-900'}`}>
                        {message.subject}
                      </p>
                      <span className="text-xs text-gray-500 whitespace-nowrap ml-2">
                        {formatDate(message.createdAt)}
                      </span>
                    </div>
                    <div className="flex items-center mt-1">
                      {message.isPinned && (
                        <Pin size={12} className="text-blue-600 mr-1" />
                      )}
                      <p className="text-xs text-gray-500 truncate">
                        {message.content ? message.content.replace(/<[^>]*>?/gm, '').substring(0, 60) + '...' : ''}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserMessages;