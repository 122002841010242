// src/components/subjects/SubjectForm.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Save, ArrowLeft, AlertTriangle, 
  Book, FileText, Upload, Download, X 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase/config';
import { 
  collection, doc, getDoc, setDoc, addDoc, serverTimestamp 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const SubjectForm = ({ isEditing = false }) => {
  const navigate = useNavigate();
  const { subjectId } = useParams();
  const { currentUser, userProfile } = useAuth();
  
  // Form state
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    description: '',
    department: '', // New field for department
    phase: '',
    grade: '',
    nominalHours: '',
    status: 'Active',
    atpDocument: null,
    poaDocument: null,
    policyDocument: null,
    sipDocument: null,
    capsDocument: null,
    createdBy: currentUser?.uid,
    institutionId: userProfile?.institutionId || '',
    institutionName: userProfile?.institutionName || '',
  });
  
  // UI state
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // File upload states
  const [files, setFiles] = useState({
    atp: null,
    poa: null,
    policy: null,
    sip: null,
    caps: null // Add CAPS document file state
  });
  
  const [fileUploading, setFileUploading] = useState({
    sip: false,
    atp: false,
    poa: false,
    policy: false,
    caps: false 
  });

  // Fetch subject data if editing
  useEffect(() => {
    if (isEditing && subjectId) {
      fetchSubject();
    }
  }, [isEditing, subjectId]);

  const fetchSubject = async () => {
    setIsLoading(true);
    try {
      const subjectDoc = await getDoc(doc(db, "subjects", subjectId));
      
      if (subjectDoc.exists()) {
        const subjectData = subjectDoc.data();
        setFormData({
          ...subjectData,
          // Set default values for any new fields not in the existing data
          grade: subjectData.grade || '',
          department: subjectData.department || '', 
          atpDocument: subjectData.atpDocument || null,
          poaDocument: subjectData.poaDocument || null,
          policyDocument: subjectData.policyDocument || null,
          sipDocument: subjectData.sipDocument || null,
          capsDocument: subjectData.capsDocument || null 
        });
      } else {
        setError("Subject not found");
        setTimeout(() => navigate('/subjects'), 2000);
      }
    } catch (err) {
      console.error("Error fetching subject:", err);
      setError("Failed to load subject data");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle file selection
  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 10MB)
    if (file.size > 10 * 1024 * 1024) {
      setError(`File is too large. Maximum size is 10MB`);
      return;
    }
    
    setFiles(prev => ({
      ...prev,
      [fileType]: file
    }));
  };

  // Upload file to Firebase Storage
  const uploadFile = async (file, fileType) => {
    if (!file) return null;
    
    try {
      setFileUploading(prev => ({ ...prev, [fileType]: true }));
      
      const userId = currentUser?.uid;
      if (!userId) {
        throw new Error("User authentication required");
      }
      
      // Create a unique filename
      const timestamp = Date.now();
      const filename = `${timestamp}_${file.name.replace(/\s+/g, '_')}`;
      const storagePath = `subjects/${userId}/${fileType}/${filename}`;
      
      // Create storage reference
      const storageRef = ref(storage, storagePath);
      
      // Upload the file
      const snapshot = await uploadBytes(storageRef, file);
      
      // Get the download URL
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      // Return file info
      return {
        url: downloadURL,
        name: file.name,
        type: file.type,
        path: storagePath,
        uploadedAt: new Date()
      };
    } catch (error) {
      console.error(`Error uploading ${fileType} file:`, error);
      setError(`Failed to upload ${fileType} file: ${error.message}`);
      return null;
    } finally {
      setFileUploading(prev => ({ ...prev, [fileType]: false }));
    }
  };

  // Remove a file
  const removeFile = (fileType) => {
    setFiles(prev => ({
      ...prev,
      [fileType]: null
    }));
  };

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!currentUser) {
      setError("You must be logged in to submit a subject");
      return;
    }
    
    // Validate form
    if (!formData.name || !formData.phase) {
      setError("Please fill in all required fields");
      return;
    }
    
    try {
      setIsLoading(true);
      setError('');
      
      // Upload files if selected
      let updatedFiles = {};
      
      // Upload ATP document if changed
      if (files.atp) {
        const atpFile = await uploadFile(files.atp, 'atp');
        if (atpFile) {
          updatedFiles.atpDocument = atpFile;
        }
      }
      
      // Upload POA document if changed  
      if (files.poa) {
        const poaFile = await uploadFile(files.poa, 'poa');
        if (poaFile) {
          updatedFiles.poaDocument = poaFile;
        }
      }
      
      // Upload Policy document if changed
      if (files.policy) {
        const policyFile = await uploadFile(files.policy, 'policy');
        if (policyFile) {
          updatedFiles.policyDocument = policyFile;
        }
      }
      
      // Upload SIP document if changed
      if (files.sip) {
        const sipFile = await uploadFile(files.sip, 'sip');
        if (sipFile) {
          updatedFiles.sipDocument = sipFile;
        }
      }

      if (files.caps) {
        const capsFile = await uploadFile(files.caps, 'caps');
        if (capsFile) {
          updatedFiles.capsDocument = capsFile;
        }
      }
      
      // Prepare subject data
      const subjectData = {
        ...formData,
        ...updatedFiles,
        updatedAt: serverTimestamp()
      };
      
      // Add or update subject in Firestore
      if (isEditing && subjectId) {
        await setDoc(doc(db, "subjects", subjectId), subjectData, { merge: true });
        setSuccess("Subject updated successfully");
      } else {
        subjectData.createdAt = serverTimestamp();
        await addDoc(collection(db, "subjects"), subjectData);
        setSuccess("Subject created successfully");
      }
      
      // Navigate back after showing success message
      setTimeout(() => {
        navigate('/subjects');
      }, 1500);
      
    } catch (err) {
      console.error("Error saving subject:", err);
      setError(`Failed to save subject: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
      <div className="flex items-start justify-between mb-6">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/subjects')}
            className="mr-4 text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <h1 className="text-xl font-bold text-gray-900">
            {isEditing ? 'Edit Subject' : 'Create New Subject'}
          </h1>
        </div>
      </div>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 mr-2" />
            <span>{error}</span>
          </div>
        </div>
      )}
      
      {success && (
        <div className="mb-4 p-3 bg-green-50 border-l-4 border-green-500 text-green-700">
          <div className="flex">
            <Save className="h-5 w-5 mr-2" />
            <span>{success}</span>
          </div>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Basic Information */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-md font-medium text-gray-800 mb-4">Basic Information</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Subject Name*
              </label>
              <input
                id="name"
                name="name"
                type="text"
                className="block w-full rounded-md border-gray-300 pl-2 py-1 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            
            <div>
              <label htmlFor="code" className="block text-sm font-medium text-gray-700 mb-1">
                Subject Code
              </label>
              <input
                id="code"
                name="code"
                type="text"
                className="block w-full rounded-md border-gray-300 pl-2 py-1 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.code}
                onChange={handleChange}
              />
            </div>
          </div>
          
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                rows={3}
                className="block w-full rounded-md border-gray-300 pl-2 py-1 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.description}
                onChange={handleChange}
              />
            </div>
            
            <div>
              <label htmlFor="department" className="block text-sm font-medium text-gray-700 mb-1">
                Department
              </label>
              <select
                id="department"
                name="department"
                className="block w-full rounded-md border-gray-300 pl-2 py-1 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.department}
                onChange={handleChange}
              >
                <option value="">Select a department</option>
                <option value="Mathematics">Mathematics</option>
                <option value="Languages">Languages</option>
                <option value="Sciences">Sciences</option>
                <option value="Commerce">Commerce</option>
                <option value="Humanities">Humanities</option>
                <option value="Technical">Technical</option>
                <option value="Skills">Skills</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
        </div>
        
        {/* Classification */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-md font-medium text-gray-800 mb-4">Subject Classification</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label htmlFor="phase" className="block text-sm font-medium text-gray-700 mb-1">
                Phase*
              </label>
              <select
                id="phase"
                name="phase"
                className="block w-full rounded-md border-gray-300 pl-2 py-1 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.phase}
                onChange={handleChange}
                required
              >
                <option value="">Select a phase</option>
                <option value="Foundation">Foundation Phase</option>
                <option value="Intermediate">Intermediate Phase</option>
                <option value="Senior">Senior Phase</option>
                <option value="FET">Further Education and Training</option>
              </select>
            </div>
            
            <div>
              <label htmlFor="grade" className="block text-sm font-medium text-gray-700 mb-1">
                Grade
              </label>
              <select
                id="grade"
                name="grade"
                className="block w-full rounded-md border-gray-300 pl-2 py-1 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.grade}
                onChange={handleChange}
              >
                <option value="">Select a grade</option>
                <option value="R">Grade R</option>
                <option value="1">Grade 1</option>
                <option value="2">Grade 2</option>
                <option value="3">Grade 3</option>
                <option value="4">Grade 4</option>
                <option value="5">Grade 5</option>
                <option value="6">Grade 6</option>
                <option value="7">Grade 7</option>
                <option value="8">Grade 8</option>
                <option value="9">Grade 9</option>
                <option value="10">Grade 10</option>
                <option value="11">Grade 11</option>
                <option value="12">Grade 12</option>
              </select>
            </div>
            
            <div>
              <label htmlFor="nominalHours" className="block text-sm font-medium text-gray-700 mb-1">
                Nominal Hours
              </label>
              <input
                id="nominalHours"
                name="nominalHours"
                type="number"
                min="0"
                className="block w-full rounded-md border-gray-300 pl-2 py-1 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.nominalHours}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        
        {/* Documents */}
        <div className="bg-gray-50 p-4 rounded-md">
          <h2 className="text-md font-medium text-gray-800 mb-4">Subject Documents</h2>
          
          {/* ATP Document */}
          <div className="mb-4 border-b pb-4">
            <div className="flex items-center justify-between mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Annual Teaching Plan (ATP)
              </label>
            </div>
            
            {formData.atpDocument ? (
              <div className="flex items-center justify-between bg-blue-50 p-3 rounded-md">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-blue-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">{formData.atpDocument.name}</p>
                    <p className="text-xs text-gray-500">{new Date(formData.atpDocument.uploadedAt).toLocaleDateString()}</p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <a
                    href={formData.atpDocument.url}
                    target="_blank"
                    rel="noreferrer"
                    className="p-1.5 text-blue-600 hover:text-blue-800"
                    title="Download"
                  >
                    <Download size={16} />
                  </a>
                  <button
                    type="button"
                    onClick={() => setFormData(prev => ({ ...prev, atpDocument: null }))}
                    className="p-1.5 text-red-600 hover:text-red-800"
                    title="Remove"
                  >
                    <X size={16} />
                  </button>
                </div>
              </div>
            ) : files.atp ? (
              <div className="flex items-center justify-between bg-green-50 p-3 rounded-md">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-green-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">{files.atp.name}</p>
                    <p className="text-xs text-gray-500">{Math.round(files.atp.size / 1024)} KB</p>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => removeFile('atp')}
                  className="p-1.5 text-red-600 hover:text-red-800"
                  title="Remove"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <div className="border-2 border-dashed border-gray-300 rounded-md p-4">
                <div className="flex justify-center">
                  <label className="cursor-pointer text-center">
                    <div className="flex flex-col items-center">
                      <Upload className="h-6 w-6 text-gray-400 mb-1" />
                      <span className="text-sm text-gray-500">Upload ATP Document</span>
                      <span className="text-xs text-gray-400 mt-1">PDF, DOC up to 10MB</span>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      accept=".pdf,.doc,.docx"
                      onChange={(e) => handleFileChange(e, 'atp')}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
          
          {/* POA Document */}
          <div className="mb-4 border-b pb-4">
            <div className="flex items-center justify-between mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Programme of Assessment (PoA)
              </label>
            </div>
            
            {formData.poaDocument ? (
              <div className="flex items-center justify-between bg-blue-50 p-3 rounded-md">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-blue-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">{formData.poaDocument.name}</p>
                    <p className="text-xs text-gray-500">{new Date(formData.poaDocument.uploadedAt).toLocaleDateString()}</p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <a
                    href={formData.poaDocument.url}
                    target="_blank"
                    rel="noreferrer"
                    className="p-1.5 text-blue-600 hover:text-blue-800"
                    title="Download"
                  >
                    <Download size={16} />
                  </a>
                  <button
                    type="button"
                    onClick={() => setFormData(prev => ({ ...prev, poaDocument: null }))}
                    className="p-1.5 text-red-600 hover:text-red-800"
                    title="Remove"
                  >
                    <X size={16} />
                  </button>
                </div>
              </div>
            ) : files.poa ? (
              <div className="flex items-center justify-between bg-green-50 p-3 rounded-md">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-green-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">{files.poa.name}</p>
                    <p className="text-xs text-gray-500">{Math.round(files.poa.size / 1024)} KB</p>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => removeFile('poa')}
                  className="p-1.5 text-red-600 hover:text-red-800"
                  title="Remove"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <div className="border-2 border-dashed border-gray-300 rounded-md p-4">
                <div className="flex justify-center">
                  <label className="cursor-pointer text-center">
                    <div className="flex flex-col items-center">
                      <Upload className="h-6 w-6 text-gray-400 mb-1" />
                      <span className="text-sm text-gray-500">Upload PoA Document</span>
                      <span className="text-xs text-gray-400 mt-1">PDF, DOC up to 10MB</span>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      accept=".pdf,.doc,.docx"
                      onChange={(e) => handleFileChange(e, 'poa')}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
          
          {/* Subject Policy Document */}
          <div className="mb-4 border-b pb-4">
            <div className="flex items-center justify-between mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Subject Policy
              </label>
            </div>
            
            {formData.policyDocument ? (
              <div className="flex items-center justify-between bg-blue-50 p-3 rounded-md">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-blue-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">{formData.policyDocument.name}</p>
                    <p className="text-xs text-gray-500">{new Date(formData.policyDocument.uploadedAt).toLocaleDateString()}</p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <a
                    href={formData.policyDocument.url}
                    target="_blank"
                    rel="noreferrer"
                    className="p-1.5 text-blue-600 hover:text-blue-800"
                    title="Download"
                  >
                    <Download size={16} />
                  </a>
                  <button
                    type="button"
                    onClick={() => setFormData(prev => ({ ...prev, policyDocument: null }))}
                    className="p-1.5 text-red-600 hover:text-red-800"
                    title="Remove"
                  >
                    <X size={16} />
                  </button>
                </div>
              </div>
            ) : files.policy ? (
              <div className="flex items-center justify-between bg-green-50 p-3 rounded-md">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-green-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">{files.policy.name}</p>
                    <p className="text-xs text-gray-500">{Math.round(files.policy.size / 1024)} KB</p>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => removeFile('policy')}
                  className="p-1.5 text-red-600 hover:text-red-800"
                  title="Remove"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <div className="border-2 border-dashed border-gray-300 rounded-md p-4">
                <div className="flex justify-center">
                  <label className="cursor-pointer text-center">
                    <div className="flex flex-col items-center">
                      <Upload className="h-6 w-6 text-gray-400 mb-1" />
                      <span className="text-sm text-gray-500">Upload Subject Policy</span>
                      <span className="text-xs text-gray-400 mt-1">PDF, DOC up to 10MB</span>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      accept=".pdf,.doc,.docx"
                      onChange={(e) => handleFileChange(e, 'policy')}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
          
          {/* SIP Document */}
          <div className="mb-2">
            <div className="flex items-center justify-between mb-2">
              <label className="block text-sm font-medium text-gray-700">
                School Improvement Plan (SIP)
              </label>
            </div>
            
            {formData.sipDocument ? (
              <div className="flex items-center justify-between bg-blue-50 p-3 rounded-md">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-blue-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">{formData.sipDocument.name}</p>
                    <p className="text-xs text-gray-500">{new Date(formData.sipDocument.uploadedAt).toLocaleDateString()}</p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <a
                    href={formData.sipDocument.url}
                    target="_blank"
                    rel="noreferrer"
                    className="p-1.5 text-blue-600 hover:text-blue-800"
                    title="Download"
                  >
                    <Download size={16} />
                  </a>
                  <button
                    type="button"
                    onClick={() => setFormData(prev => ({ ...prev, sipDocument: null }))}
                    className="p-1.5 text-red-600 hover:text-red-800"
                    title="Remove"
                  >
                    <X size={16} />
                  </button>
                </div>
              </div>
            ) : files.sip ? (
              <div className="flex items-center justify-between bg-green-50 p-3 rounded-md">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-green-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">{files.sip.name}</p>
                    <p className="text-xs text-gray-500">{Math.round(files.sip.size / 1024)} KB</p>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => removeFile('sip')}
                  className="p-1.5 text-red-600 hover:text-red-800"
                  title="Remove"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <div className="border-2 border-dashed border-gray-300 rounded-md p-4">
                <div className="flex justify-center">
                  <label className="cursor-pointer text-center">
                    <div className="flex flex-col items-center">
                      <Upload className="h-6 w-6 text-gray-400 mb-1" />
                      <span className="text-sm text-gray-500">Upload SIP Document</span>
                      <span className="text-xs text-gray-400 mt-1">PDF, DOC up to 10MB</span>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      accept=".pdf,.doc,.docx"
                      onChange={(e) => handleFileChange(e, 'sip')}
                    />
                  </label>
                </div>
              </div>
            )}
            
          </div>

          {/* In the Documents section, add CAPS Document after ATP Document */}

          {/* CAPS Document */}
          <div className="mb-4 border-b pb-4">
            <div className="flex items-center justify-between mb-2">
              <label className="block text-sm font-medium text-gray-700">
                CAPS Document
              </label>
            </div>
            
            {formData.capsDocument ? (
              <div className="flex items-center justify-between bg-blue-50 p-3 rounded-md">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-blue-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">{formData.capsDocument.name}</p>
                    <p className="text-xs text-gray-500">{new Date(formData.capsDocument.uploadedAt).toLocaleDateString()}</p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <a
                    href={formData.capsDocument.url}
                    target="_blank"
                    rel="noreferrer"
                    className="p-1.5 text-blue-600 hover:text-blue-800"
                    title="Download"
                  >
                    <Download size={16} />
                  </a>
                  <button
                    type="button"
                    onClick={() => setFormData(prev => ({ ...prev, capsDocument: null }))}
                    className="p-1.5 text-red-600 hover:text-red-800"
                    title="Remove"
                  >
                    <X size={16} />
                  </button>
                </div>
              </div>
            ) : files.caps ? (
              <div className="flex items-center justify-between bg-green-50 p-3 rounded-md">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-green-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium">{files.caps.name}</p>
                    <p className="text-xs text-gray-500">{Math.round(files.caps.size / 1024)} KB</p>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => removeFile('caps')}
                  className="p-1.5 text-red-600 hover:text-red-800"
                  title="Remove"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <div className="border-2 border-dashed border-gray-300 rounded-md p-4">
                <div className="flex justify-center">
                  <label className="cursor-pointer text-center">
                    <div className="flex flex-col items-center">
                      <Upload className="h-6 w-6 text-gray-400 mb-1" />
                      <span className="text-sm text-gray-500">Upload CAPS Document</span>
                      <span className="text-xs text-gray-400 mt-1">PDF, DOC up to 10MB</span>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      accept=".pdf,.doc,.docx"
                      onChange={(e) => handleFileChange(e, 'caps')}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate('/subjects')}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className={`inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-[#1d67cd] border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
          >
            <Save className="h-4 w-4 mr-2" />
            {isLoading ? 'Saving...' : isEditing ? 'Update Subject' : 'Save Subject'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubjectForm;
