// src/components/subjects/SubjectList.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Plus, Search, Filter, FileText, Book,
  ChevronDown, ChevronUp, Edit, Trash2, Eye,
  Download, AlertTriangle, MoreHorizontal, Copy
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, query, where, orderBy, 
  getDocs, deleteDoc, doc, addDoc, serverTimestamp
} from 'firebase/firestore';

const SubjectList = () => {
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  
  const [subjects, setSubjects] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [phaseFilter, setPhaseFilter] = useState('');
  const [departmentFilter, setDepartmentFilter] = useState('');
  const [gradeFilter, setGradeFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  
  // Reference for handling clicks outside the dropdown
  const dropdownRef = useRef(null);
  
  // Handle clicks outside dropdown to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  // Fetch subjects
  useEffect(() => {
    const fetchSubjects = async () => {
      if (!userProfile?.institutionId) {
        setIsLoading(false);
        return;
      }
      
      setIsLoading(true);
      try {
        const subjectsRef = collection(db, "subjects");
        const q = query(
          subjectsRef,
          where("institutionId", "==", userProfile.institutionId),
          orderBy("name")
        );
        
        const querySnapshot = await getDocs(q);
        const subjectsList = [];
        
        querySnapshot.forEach((doc) => {
          subjectsList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setSubjects(subjectsList);
        setFilteredSubjects(subjectsList);
      } catch (err) {
        console.error("Error fetching subjects:", err);
        setError("Failed to load subjects");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchSubjects();
  }, [userProfile]);
  
  // Filter subjects when search term or filters change
  useEffect(() => {
    if (subjects.length === 0) {
      setFilteredSubjects([]);
      return;
    }
    
    const filtered = subjects.filter(subject => {
      const matchesSearch = 
        !searchTerm || 
        subject.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        subject.code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        subject.description?.toLowerCase().includes(searchTerm.toLowerCase());
        
      const matchesPhase = !phaseFilter || subject.phase === phaseFilter;
      const matchesDepartment = !departmentFilter || subject.department === departmentFilter;
      const matchesGrade = !gradeFilter || subject.grade === gradeFilter;
      
      return matchesSearch && matchesPhase && matchesDepartment && matchesGrade;
    });
    
    setFilteredSubjects(filtered);
  }, [subjects, searchTerm, phaseFilter, departmentFilter, gradeFilter]);
  
  // Handle subject deletion
  const handleDeleteSubject = async (id) => {
    if (window.confirm("Are you sure you want to delete this subject? This action cannot be undone.")) {
      try {
        await deleteDoc(doc(db, "subjects", id));
        setSubjects(subjects.filter(subject => subject.id !== id));
        setActiveDropdown(null);
      } catch (err) {
        console.error("Error deleting subject:", err);
        setError("Failed to delete subject");
      }
    }
  };
  
  // Duplicate subject
  const handleDuplicateSubject = async (subjectToDuplicate) => {
    try {
      // Remove ID and add timestamp
      const { id, createdAt, ...subjectData } = subjectToDuplicate;
      
      // Create new subject with modified name to indicate it's a copy
      const newSubject = {
        ...subjectData,
        name: `${subjectData.name} - Copy`,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };
      
      // Add to Firestore
      const docRef = await addDoc(collection(db, "subjects"), newSubject);
      
      // Close dropdown
      setActiveDropdown(null);
      
      // Navigate to edit form for the new subject
      navigate(`/subjects/edit/${docRef.id}`);
      
    } catch (err) {
      console.error("Error duplicating subject:", err);
      setError("Failed to duplicate subject");
    }
  };
  
  // Toggle dropdown for actions
  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };
  
  // Navigate to subject details
  const viewSubjectDetails = (id) => {
    navigate(`/subjects/details/${id}`);
  };
  
  // Get unique departments for filter
  const departments = [...new Set(subjects.filter(s => s.department).map(s => s.department))];
  
  // Get document indicator with label and icon
  const DocumentIndicator = ({ document, label, colorClass = "blue" }) => {
    if (!document) return (
      <div title={`No ${label} Document`} className="flex items-center text-gray-300 text-xs">
        <FileText size={16} className="mr-1" />
        {label}
      </div>
    );
    
    return (
      <a 
        href={document.url} 
        target="_blank" 
        rel="noreferrer"
        title={`Download ${label}: ${document.name}`}
        className={`flex items-center text-${colorClass}-600 text-xs hover:underline`}
      >
        <FileText size={16} className="mr-1" />
        {label}
        <Download size={12} className="ml-1" />
      </a>
    );
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="mb-6 flex flex-col md:flex-row md:items-center md:justify-between">
        <h1 className="text-2xl font-bold text-gray-900 mb-4 md:mb-0">Subject Management</h1>
        
        <div className="flex items-center">
          <Link
            to="/subjects/create"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#1d67cd] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add New Subject
          </Link>
        </div>
      </div>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 mr-2" />
            <span>{error}</span>
          </div>
        </div>
      )}
      
      {/* Search and Filters */}
      <div className="mb-6 bg-white p-4 rounded-lg shadow-sm">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="relative flex-grow">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
            <input
              type="text"
              placeholder="Search subjects..."
              className="pl-10 pr-4 py-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="flex gap-2">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Filter className="h-4 w-4 mr-2" />
              Filters
              <ChevronDown className={`ml-1 h-4 w-4 transition-transform ${showFilters ? 'rotate-180' : ''}`} />
            </button>
          </div>
        </div>
        
        {/* Filter options */}
        {showFilters && (
          <div className="mt-4 pt-4 border-t grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Phase
              </label>
              <select
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={phaseFilter}
                onChange={(e) => setPhaseFilter(e.target.value)}
              >
                <option value="">All Phases</option>
                <option value="Foundation">Foundation Phase</option>
                <option value="Intermediate">Intermediate Phase</option>
                <option value="Senior">Senior Phase</option>
                <option value="FET">Further Education and Training</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Department
              </label>
              <select
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={departmentFilter}
                onChange={(e) => setDepartmentFilter(e.target.value)}
              >
                <option value="">All Departments</option>
                {departments.map(dept => (
                  <option key={dept} value={dept}>{dept}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Grade
              </label>
              <select
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={gradeFilter}
                onChange={(e) => setGradeFilter(e.target.value)}
              >
                <option value="">All Grades</option>
                <option value="R">Grade R</option>
                <option value="1">Grade 1</option>
                <option value="2">Grade 2</option>
                <option value="3">Grade 3</option>
                <option value="4">Grade 4</option>
                <option value="5">Grade 5</option>
                <option value="6">Grade 6</option>
                <option value="7">Grade 7</option>
                <option value="8">Grade 8</option>
                <option value="9">Grade 9</option>
                <option value="10">Grade 10</option>
                <option value="11">Grade 11</option>
                <option value="12">Grade 12</option>
              </select>
            </div>
            
            <div className="flex items-end">
              <button
                onClick={() => {
                  setSearchTerm('');
                  setPhaseFilter('');
                  setDepartmentFilter('');
                  setGradeFilter('');
                }}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Clear All Filters
              </button>
            </div>
          </div>
        )}
      </div>
      
      {/* Subjects List */}
      {isLoading ? (
        <div className="text-center py-10">
          <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          <p className="mt-2 text-gray-500">Loading subjects...</p>
        </div>
      ) : filteredSubjects.length === 0 ? (
        <div className="bg-white rounded-lg shadow-sm p-8 text-center">
          <Book className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No subjects found</h3>
          <p className="mt-1 text-sm text-gray-500">
            {searchTerm || phaseFilter || departmentFilter || gradeFilter
              ? 'Try changing your search or filter criteria'
              : 'Get started by adding your first subject'}
          </p>
          {!(searchTerm || phaseFilter || departmentFilter || gradeFilter) && (
            <div className="mt-6">
              <Link
                to="/subjects/create"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#1d67cd] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <Plus className="h-4 w-4 mr-2" />
                Add New Subject
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Subject
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Department
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Phase
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Grade
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Documents
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredSubjects.map((subject) => (
                  <tr key={subject.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div 
                        className="flex items-center cursor-pointer" 
                        onClick={() => viewSubjectDetails(subject.id)}
                      >
                        <div className="flex-shrink-0 h-10 w-10 bg-[#1d67cd] bg-opacity-10 rounded-full flex items-center justify-center text-[#1d67cd]">
                          <Book className="h-5 w-5" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900 hover:text-[#1d67cd]">
                            {subject.name}
                          </div>
                          <div className="text-sm text-gray-500">
                            {subject.grade && <span>Grade: {subject.grade}</span>}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-sm text-gray-900">
                        {subject.department || 'Not specified'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-sm text-gray-900">{subject.phase || 'N/A'}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-sm text-gray-900">
                        {subject.grade ? `Grade ${subject.grade}` : 'N/A'}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-col space-y-2">
                        <DocumentIndicator document={subject.atpDocument} label="ATP" colorClass="green" />
                        <DocumentIndicator document={subject.poaDocument} label="PoA" colorClass="blue" />
                        <DocumentIndicator document={subject.policyDocument} label="Policy" colorClass="purple" />
                        <DocumentIndicator document={subject.sipDocument} label="SIP" colorClass="orange" />
                        <DocumentIndicator document={subject.capsDocument} label="CAPS" colorClass="red" />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="relative" ref={activeDropdown === subject.id ? dropdownRef : null}>
                        <button
                          onClick={() => toggleDropdown(subject.id)}
                          className="text-gray-500 hover:text-[#1d67cd] focus:outline-none"
                          aria-label="Actions"
                        >
                          <MoreHorizontal className="h-5 w-5" />
                        </button>
                        
                        {activeDropdown === subject.id && (
                          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                            <div className="py-1 rounded-md bg-white shadow-xs">
                              <button
                                onClick={() => viewSubjectDetails(subject.id)}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              >
                                <Eye className="inline-block h-4 w-4 mr-2" />
                                View Details
                              </button>
                              <Link
                                to={`/subjects/edit/${subject.id}`}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              >
                                <Edit className="inline-block h-4 w-4 mr-2" />
                                Edit Subject
                              </Link>
                              <button
                                onClick={() => handleDuplicateSubject(subject)}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              >
                                <Copy className="inline-block h-4 w-4 mr-2" />
                                Duplicate
                              </button>
                              <button
                                onClick={() => handleDeleteSubject(subject.id)}
                                className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 hover:text-red-900"
                              >
                                <Trash2 className="inline-block h-4 w-4 mr-2" />
                                Delete
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubjectList;