// src/pages/StaffPage.js
import React from 'react';
import { Outlet } from 'react-router-dom';

const StaffPage = () => {
  return (
    <div className="space-y-6">
      <Outlet /> {/* Renders nested routes here */}
    </div>
  );
};

export default StaffPage;
