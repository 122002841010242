// src/components/resources/ResourceDetails.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { 
  ArrowLeft, Edit, Trash, Calendar, Clipboard, Tag, MapPin, 
  DollarSign, Wrench, Clock, AlertTriangle, CheckCircle
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { doc, getDoc, deleteDoc, collection, query, where, getDocs, orderBy } from 'firebase/firestore';

const ResourceDetails = () => {
  const navigate = useNavigate();
  const { resourceId } = useParams();
  const { userProfile } = useAuth();
  const [resource, setResource] = useState(null);
  const [usageHistory, setUsageHistory] = useState([]);
  const [maintenanceHistory, setMaintenanceHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchResource = async () => {
      if (!resourceId) return;
      
      setIsLoading(true);
      try {
        const resourceDoc = await getDoc(doc(db, "resources", resourceId));
        
        if (resourceDoc.exists()) {
          setResource({
            id: resourceId,
            ...resourceDoc.data()
          });

          // In a real application, you would fetch usage history from a separate collection
          // For now, we'll use placeholder data
          setUsageHistory([
            {
              id: 1,
              user: 'Sarah King',
              startDate: new Date('2025-02-15'),
              endDate: new Date('2025-02-20'),
              purpose: 'Grade 10 Science Project'
            },
            {
              id: 2,
              user: 'Robert Lewis',
              startDate: new Date('2025-01-10'),
              endDate: new Date('2025-01-15'),
              purpose: 'Staff Training Session'
            }
          ]);

          // Similar for maintenance history
          setMaintenanceHistory([
            {
              id: 1,
              date: new Date('2025-01-05'),
              type: 'Routine Check',
              technician: 'Terry Mitchell',
              notes: 'Regular maintenance check. All systems functioning properly.'
            },
            {
              id: 2,
              date: new Date('2024-11-12'),
              type: 'Repair',
              technician: 'External Vendor',
              notes: 'Replaced damaged power cable and tested functionality.'
            }
          ]);
        } else {
          setError("Resource not found");
        }
      } catch (error) {
        console.error("Error fetching resource:", error);
        setError("Failed to load resource. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchResource();
  }, [resourceId]);

  const handleDeleteResource = async () => {
    try {
      await deleteDoc(doc(db, "resources", resourceId));
      navigate('/resources');
    } catch (error) {
      console.error("Error deleting resource:", error);
      setError("Failed to delete resource. Please try again.");
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow p-6 flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-center mb-6">
          <button 
            onClick={() => navigate('/resources')}
            className="text-gray-500 hover:text-gray-700 flex items-center"
          >
            <ArrowLeft size={18} className="mr-1" />
            Back to Resources
          </button>
        </div>
        <div className="bg-red-50 text-red-800 p-4 rounded-md">
          {error}
        </div>
      </div>
    );
  }

  if (!resource) {
    return null;
  }

  const formatDate = (date) => {
    if (!date) return '';
    if (typeof date === 'string') return date;
    
    try {
      if (date.toDate) {
        // Handle Firestore Timestamp
        date = date.toDate();
      }
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(date);
    } catch (err) {
      console.error('Date formatting error:', err);
      return 'Invalid date';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Available':
        return 'bg-green-100 text-green-800';
      case 'In Use':
        return 'bg-blue-100 text-blue-800';
      case 'Maintenance':
        return 'bg-red-100 text-red-800';
      case 'Reserved':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Available':
        return <CheckCircle size={16} className="mr-1" />;
      case 'In Use':
        return <Clock size={16} className="mr-1" />;
      case 'Maintenance':
        return <Wrench size={16} className="mr-1" />;
      case 'Reserved':
        return <Calendar size={16} className="mr-1" />;
      default:
        return <AlertTriangle size={16} className="mr-1" />;
    }
  };

  const getConditionColor = (condition) => {
    switch (condition) {
      case 'Excellent':
        return 'text-green-600';
      case 'Good':
        return 'text-blue-600';
      case 'Fair':
        return 'text-yellow-600';
      case 'Poor':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <button 
              onClick={() => navigate('/resources')}
              className="text-gray-500 hover:text-gray-700 flex items-center mr-4"
            >
              <ArrowLeft size={18} className="mr-1" />
              Back
            </button>
            <h2 className="text-xl font-semibold text-gray-800">{resource.name}</h2>
            <span className={`ml-3 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(resource.status)}`}>
              {getStatusIcon(resource.status)}
              {resource.status}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <Link
              to={`/resources/edit/${resourceId}`}
              className="bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setConfirmDelete(true)}
              className="bg-white border border-gray-300 text-red-600 px-4 py-2 rounded-md hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 flex items-center"
            >
              <Trash size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200">
        <nav className="flex -mb-px px-6">
          <button
            onClick={() => setActiveTab('overview')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'overview'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Overview
          </button>
          <button
            onClick={() => setActiveTab('usage')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8 ${
              activeTab === 'usage'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Usage History
          </button>
          <button
            onClick={() => setActiveTab('maintenance')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8 ${
              activeTab === 'maintenance'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Maintenance
          </button>
        </nav>
      </div>

      {/* Content */}
      <div className="p-6">
        {activeTab === 'overview' && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Main Info */}
            <div className="md:col-span-2 space-y-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Resource Information</h3>
                <div className="bg-gray-50 rounded-lg p-4">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Resource Name</dt>
                      <dd className="mt-1 text-sm text-gray-900">{resource.name}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Category</dt>
                      <dd className="mt-1 text-sm text-gray-900 flex items-center">
                        <Tag size={14} className="mr-1 text-gray-400" />
                        {resource.category || 'Uncategorized'}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Location</dt>
                      <dd className="mt-1 text-sm text-gray-900 flex items-center">
                        <MapPin size={14} className="mr-1 text-gray-400" />
                        {resource.location || '-'}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Current Status</dt>
                      <dd className="mt-1 text-sm">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(resource.status)}`}>
                          {resource.status || 'Unknown'}
                        </span>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Condition</dt>
                      <dd className={`mt-1 text-sm font-medium ${getConditionColor(resource.condition)}`}>
                        {resource.condition || 'Unknown'}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Next Available</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {resource.status === 'Available' ? (
                          <span className="text-green-600">Now</span>
                        ) : resource.availableDate ? (
                          <span className="flex items-center">
                            <Calendar size={14} className="mr-1 text-gray-400" />
                            {formatDate(resource.availableDate)}
                          </span>
                        ) : (
                          'Unknown'
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">Description</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {resource.description || 'No description available.'}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Purchase Information</h3>
                <div className="bg-gray-50 rounded-lg p-4">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Purchase Date</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {resource.purchaseDate ? formatDate(resource.purchaseDate) : '-'}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Purchase Value</dt>
                      <dd className="mt-1 text-sm text-gray-900 flex items-center">
                        <DollarSign size={14} className="mr-1 text-gray-400" />
                        {resource.purchaseValue ? `R ${resource.purchaseValue.toFixed(2)}` : '-'}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Serial Number</dt>
                      <dd className="mt-1 text-sm text-gray-900 flex items-center">
                        <Clipboard size={14} className="mr-1 text-gray-400" />
                        {resource.serialNumber || '-'}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>

            {/* Sidebar */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Resource Details</h3>
              <div className="space-y-4">
                <div>
                  <p className="text-sm text-gray-500">Recent Usage</p>
                  <p className="text-sm font-medium text-gray-900">{usageHistory.length} records</p>
                </div>
                
                <div>
                  <p className="text-sm text-gray-500">Maintenance History</p>
                  <p className="text-sm font-medium text-gray-900">{maintenanceHistory.length} records</p>
                </div>

                <div className="pt-4 mt-4 border-t border-gray-200">
                  <p className="text-sm text-gray-500">Added on</p>
                  <p className="text-sm text-gray-900">
                    {resource.createdAt ? formatDate(resource.createdAt) : 'Unknown'}
                  </p>
                </div>

                <div className="pt-2">
                  <p className="text-sm text-gray-500">Last updated</p>
                  <p className="text-sm text-gray-900">
                    {resource.updatedAt ? formatDate(resource.updatedAt) : 'Unknown'}
                  </p>
                </div>
              </div>

              {/* Quick Actions */}
              <div className="mt-6 pt-4 border-t border-gray-200">
                <h4 className="text-sm font-medium text-gray-900 mb-3">Quick Actions</h4>
                <div className="flex flex-col space-y-2">
                  <button
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <Calendar size={16} className="mr-2" />
                    Reserve Resource
                  </button>
                  <button
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <Wrench size={16} className="mr-2" />
                    Report Issue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'usage' && (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium text-gray-900">Usage History</h3>
              <button
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
              >
                <Clock size={18} className="mr-2" />
                Record Usage
              </button>
            </div>

            {usageHistory.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        User
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Start Date
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        End Date
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Purpose
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {usageHistory.map((record) => (
                      <tr key={record.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {record.user}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(record.startDate)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(record.endDate)}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {record.purpose}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-12 border border-dashed border-gray-300 rounded-lg">
                <Clock size={48} className="mx-auto text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">No Usage Records</h3>
                <p className="text-gray-500">
                  There are no usage records for this resource yet.
                </p>
                <button
                  className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Record First Usage
                </button>
              </div>
            )}
          </div>
        )}

        {activeTab === 'maintenance' && (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium text-gray-900">Maintenance History</h3>
              <button
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
              >
                <Wrench size={18} className="mr-2" />
                Record Maintenance
              </button>
            </div>

            {maintenanceHistory.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Technician
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Notes
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {maintenanceHistory.map((record) => (
                      <tr key={record.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(record.date)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            record.type === 'Routine Check' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                          }`}>
                            {record.type}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {record.technician}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {record.notes}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-12 border border-dashed border-gray-300 rounded-lg">
                <Wrench size={48} className="mx-auto text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">No Maintenance Records</h3>
                <p className="text-gray-500">
                  There are no maintenance records for this resource yet.
                </p>
                <button
                  className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Record First Maintenance
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {confirmDelete && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md mx-auto">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Delete</h3>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to delete {resource.name}? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setConfirmDelete(false)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteResource}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourceDetails;