// src/pages/Profile.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  User, Mail, Phone, MapPin, Briefcase, BookOpen, Clock, School,
  Building, ArrowLeft, Edit, FileText, Download, Users
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase/config';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';

const Profile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  
  // Fetch user profile data
  useEffect(() => {
    const fetchProfile = async () => {
      if (!currentUser) {
        navigate('/login');
        return;
      }
      
      setIsLoading(true);
      setError('');
      
      try {
        // If no ID provided or ID is current user, use current user's profile from context
        if (!id || id === currentUser.uid) {
          if (userProfile) {
            setProfileData({
              id: currentUser.uid,
              ...userProfile
            });
            setIsLoading(false);
            return;
          }
        }
        
        // Fetch user profile from Firestore 
        const profileId = id || currentUser.uid;
        
        // First, check the users collection
        const userDocRef = doc(db, "users", profileId);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          setProfileData({
            id: userDoc.id,
            ...userDoc.data()
          });
          setIsLoading(false);
          return;
        }
        
        // If user not found directly by ID, try querying by email
        // This is useful if users are stored with different IDs than auth UIDs
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("email", "==", currentUser.email));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setProfileData({
            id: querySnapshot.docs[0].id,
            ...userData
          });
          setIsLoading(false);
          return;
        }
        
        // If we get here, the profile was not found
        setError('Profile not found');
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Failed to load profile data: ' + err.message);
        setIsLoading(false);
      }
    };
    
    fetchProfile();
  }, [currentUser, userProfile, id, navigate]);
  
  // If we don't have user profile data yet, use the current user's profile if available
  useEffect(() => {
    if (!profileData && !isLoading && !error && userProfile) {
      setProfileData({
        id: currentUser.uid,
        ...userProfile
      });
    }
  }, [currentUser, userProfile, profileData, isLoading, error]);

  if (isLoading) {
    return (
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
        <div className="text-center py-12">
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent"></div>
          <p className="mt-4 text-gray-600">Loading profile information...</p>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
        <div className="bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded-md">
          {error}
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Go Back
          </button>
        </div>
      </div>
    );
  }
  
  if (!profileData) {
    return (
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
        <div className="bg-yellow-50 border border-yellow-200 text-yellow-800 px-4 py-3 rounded-md">
          Profile not found
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Go Back
          </button>
        </div>
      </div>
    );
  }
  
  // Format date function
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    try {
      const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
      return date.toLocaleDateString('en-ZA', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      return 'Invalid date';
    }
  };
  
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      {/* Back button and actions */}
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={() => navigate(-1)}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </button>
        
        {currentUser.uid === profileData.id && (
          <button
            onClick={() => navigate('/edit-profile')}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Edit className="mr-2 h-4 w-4" />
            Edit Profile
          </button>
        )}
      </div>
      
      {/* Profile Header */}
      <div className="flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-6 mb-6">
        <div className="w-24 h-24 bg-blue-600 rounded-full flex items-center justify-center text-white text-3xl font-bold">
          {profileData.firstName?.charAt(0) || ''}{profileData.lastName?.charAt(0) || ''}
        </div>
        
        <div className="text-center md:text-left">
          <h1 className="text-2xl font-bold text-gray-900">
            {profileData.title} {profileData.firstName} {profileData.lastName}
          </h1>
          <p className="text-gray-600 mt-1">{profileData.userRole || 'Staff Member'}</p>
          <div className="flex flex-wrap justify-center md:justify-start gap-2 mt-3">
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              {profileData.userLevel ? `Level ${profileData.userLevel}` : 'Staff'}
            </span>
            {profileData.department && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                {profileData.department}
              </span>
            )}
            {profileData.institutionCategory && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                {profileData.institutionCategory}
              </span>
            )}
          </div>
        </div>
      </div>
      
      {/* Contact Information */}
      <div className="bg-gray-50 rounded-lg p-4 mb-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex items-center">
            <Mail className="h-5 w-5 text-gray-400 mr-2" />
            <div>
              <p className="text-sm font-medium text-gray-500">Email</p>
              <p className="text-sm">{profileData.email}</p>
            </div>
          </div>
          
          {profileData.phone && (
            <div className="flex items-center">
              <Phone className="h-5 w-5 text-gray-400 mr-2" />
              <div>
                <p className="text-sm font-medium text-gray-500">Phone</p>
                <p className="text-sm">{profileData.phone}</p>
              </div>
            </div>
          )}
          
          {profileData.alternateEmail && (
            <div className="flex items-center">
              <Mail className="h-5 w-5 text-gray-400 mr-2" />
              <div>
                <p className="text-sm font-medium text-gray-500">Alternate Email</p>
                <p className="text-sm">{profileData.alternateEmail}</p>
              </div>
            </div>
          )}
          
          <div className="flex items-center">
            <MapPin className="h-5 w-5 text-gray-400 mr-2" />
            <div>
              <p className="text-sm font-medium text-gray-500">Location</p>
              <p className="text-sm">{profileData.province || 'Not specified'}</p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Work Information */}
      <div className="bg-gray-50 rounded-lg p-4 mb-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Work Information</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex items-center">
            <Briefcase className="h-5 w-5 text-gray-400 mr-2" />
            <div>
              <p className="text-sm font-medium text-gray-500">Role</p>
              <p className="text-sm">{profileData.userRole || 'Not specified'}</p>
            </div>
          </div>
          
          <div className="flex items-center">
            <School className="h-5 w-5 text-gray-400 mr-2" />
            <div>
              <p className="text-sm font-medium text-gray-500">Institution</p>
              <p className="text-sm">{profileData.institutionName || 'Not specified'}</p>
            </div>
          </div>
          
          {profileData.department && (
            <div className="flex items-center">
              <Building className="h-5 w-5 text-gray-400 mr-2" />
              <div>
                <p className="text-sm font-medium text-gray-500">Department</p>
                <p className="text-sm">{profileData.department}</p>
              </div>
            </div>
          )}
          
          {profileData.circuit && (
            <div className="flex items-center">
              <MapPin className="h-5 w-5 text-gray-400 mr-2" />
              <div>
                <p className="text-sm font-medium text-gray-500">Circuit</p>
                <p className="text-sm">{profileData.circuit}</p>
              </div>
            </div>
          )}
        </div>
        
        {/* Additional information for educators */}
        {['Educator', 'Departmental Head', 'Deputy Principal', 'Principal'].includes(profileData.userRole) && (
          <>
            {/* Grades */}
            {profileData.grades && profileData.grades.length > 0 && (
              <div className="mt-4">
                <h3 className="text-sm font-medium text-gray-500 mb-2">Grades</h3>
                <div className="flex flex-wrap gap-2">
                  {profileData.grades.sort().map((grade) => (
                    <span key={grade} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                      Grade {grade === '00' ? 'R' : grade}
                    </span>
                  ))}
                </div>
              </div>
            )}
            
            {/* Subjects */}
            {profileData.subjects && profileData.subjects.length > 0 && (
              <div className="mt-4">
                <h3 className="text-sm font-medium text-gray-500 mb-2">Subjects</h3>
                <div className="flex flex-wrap gap-2">
                  {profileData.subjects.map((subject) => (
                    <span key={subject} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      {subject}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      
      {/* Account Information */}
      <div className="bg-gray-50 rounded-lg p-4">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Account Information</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex items-center">
            <Clock className="h-5 w-5 text-gray-400 mr-2" />
            <div>
              <p className="text-sm font-medium text-gray-500">Account Created</p>
              <p className="text-sm">{formatDate(profileData.createdAt) || 'Unknown'}</p>
            </div>
          </div>
          
          <div className="flex items-center">
            <User className="h-5 w-5 text-gray-400 mr-2" />
            <div>
              <p className="text-sm font-medium text-gray-500">Last Login</p>
              <p className="text-sm">{formatDate(profileData.lastLogin) || 'Unknown'}</p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Documents and Reports Section (if needed) */}
      {currentUser.uid === profileData.id && (
        <div className="mt-8">
          <h2 className="text-lg font-medium text-gray-900 mb-4">My Documents & Reports</h2>
          <div className="bg-gray-50 border border-gray-200 rounded-lg p-8 text-center">
            <FileText className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No documents yet</h3>
            <p className="mt-1 text-sm text-gray-500">Documents and reports will appear here once they're available.</p>
            <div className="mt-6">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <Download className="mr-2 h-5 w-5" aria-hidden="true" />
                Download Reports
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;