// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCACbRDeA4R635J2s5gajxBRZFJZg-jnx0",
  authDomain: "sawbona-app.firebaseapp.com",
  projectId: "sawbona-app",
  storageBucket: "sawbona-app.firebasestorage.app",
  messagingSenderId: "473710798109",
  appId: "1:473710798109:web:f1e44950581bf8ab182df4",
  measurementId: "G-WZ0B4F0J0N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

// Firestore collection references
export const collections = {
  users: 'users',
  userProfiles: 'userProfiles',
  schools: 'schools',
  districts: 'districts',
  circuits: 'circuits',
  provinces: 'provinces',
  departments: 'departments',
  resources: 'resources',
  documents: 'documents',
  meetings: 'meetings',
  staff: 'staff',
  activityLogs: 'activityLogs'
};
