// src/components/documents/DocumentWorkflowList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Plus, Search, ArrowRight, Clock, Check, X, MoreHorizontal, 
  ChevronDown, Calendar, Users, FileText, MessageSquare, Filter 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, orderBy, doc, updateDoc } from 'firebase/firestore';

const DocumentWorkflowList = () => {
  const { userProfile } = useAuth();
  const [activeTab, setActiveTab] = useState('active');
  const [workflows, setWorkflows] = useState([]);
  const [completedWorkflows, setCompletedWorkflows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [priorityFilter, setPriorityFilter] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(null);

  // Fetch workflows from Firestore
  useEffect(() => {
    const fetchWorkflows = async () => {
      if (!userProfile?.institutionName) return;
      
      setIsLoading(true);
      try {
        const workflowsRef = collection(db, "documentWorkflows");
        
        // Active workflows query
        const activeQuery = query(
          workflowsRef, 
          where("institutionName", "==", userProfile.institutionName),
          where("status", "!=", "Completed"),
          orderBy("status"),
          orderBy("dueDate")
        );
        
        // Completed workflows query
        const completedQuery = query(
          workflowsRef, 
          where("institutionName", "==", userProfile.institutionName),
          where("status", "==", "Completed"),
          orderBy("completedDate", "desc")
        );
        
        const [activeSnapshot, completedSnapshot] = await Promise.all([
          getDocs(activeQuery),
          getDocs(completedQuery)
        ]);
        
        const activeList = [];
        activeSnapshot.forEach((doc) => {
          activeList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        const completedList = [];
        completedSnapshot.forEach((doc) => {
          completedList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setWorkflows(activeList);
        setCompletedWorkflows(completedList);
      } catch (error) {
        console.error("Error fetching workflows:", error);
        setError("Failed to load workflows. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkflows();
  }, [userProfile]);

  const handleApproveStep = async (workflowId, stepId) => {
    try {
      // Find the workflow
      const workflow = workflows.find(w => w.id === workflowId);
      if (!workflow) return;
      
      // Update the step status
      const updatedSteps = workflow.steps.map(step => {
        if (step.id === stepId) {
          return { ...step, status: 'Completed' };
        }
        
        // If current step is completed, set the next step to "In Progress"
        const stepIndex = workflow.steps.findIndex(s => s.id === stepId);
        if (step.id === workflow.steps[stepIndex + 1]?.id) {
          return { ...step, status: 'In Progress' };
        }
        
        return step;
      });
      
      // Calculate new progress
      const completedSteps = updatedSteps.filter(step => step.status === 'Completed').length;
      const progress = Math.round((completedSteps / updatedSteps.length) * 100);
      
      // Determine if workflow is now complete
      const isComplete = completedSteps === updatedSteps.length;
      
      // Update workflow
      const workflowRef = doc(db, "documentWorkflows", workflowId);
      await updateDoc(workflowRef, {
        steps: updatedSteps,
        progress,
        status: isComplete ? 'Completed' : 'In Progress',
        ...(isComplete ? { completedDate: new Date() } : {})
      });
      
      // Update local state
      if (isComplete) {
        setWorkflows(workflows.filter(w => w.id !== workflowId));
        setCompletedWorkflows([
          {
            ...workflow,
            steps: updatedSteps,
            progress,
            status: 'Completed',
            completedDate: new Date()
          },
          ...completedWorkflows
        ]);
      } else {
        setWorkflows(workflows.map(w => 
          w.id === workflowId 
            ? { ...w, steps: updatedSteps, progress, status: 'In Progress' } 
            : w
        ));
      }
    } catch (error) {
      console.error("Error approving step:", error);
      setError("Failed to approve step. Please try again.");
    }
  };

  const handleRejectStep = async (workflowId, stepId) => {
    try {
      // Find the workflow
      const workflow = workflows.find(w => w.id === workflowId);
      if (!workflow) return;
      
      // Update the step status
      const updatedSteps = workflow.steps.map(step => {
        if (step.id === stepId) {
          return { ...step, status: 'Rejected' };
        }
        return step;
      });
      
      // Update workflow
      const workflowRef = doc(db, "documentWorkflows", workflowId);
      await updateDoc(workflowRef, {
        steps: updatedSteps,
        status: 'Rejected'
      });
      
      // Update local state
      setWorkflows(workflows.map(w => 
        w.id === workflowId 
          ? { ...w, steps: updatedSteps, status: 'Rejected' } 
          : w
      ));
    } catch (error) {
      console.error("Error rejecting step:", error);
      setError("Failed to reject step. Please try again.");
    }
  };

  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  // Filter workflows based on search term and priority filter
  const filteredWorkflows = (activeTab === 'active' ? workflows : completedWorkflows).filter(workflow => {
    const matchesSearch = workflow.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
                          workflow.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesPriority = priorityFilter ? workflow.priority === priorityFilter : true;
    
    return matchesSearch && matchesPriority;
  });

  const resetFilters = () => {
    setSearchTerm('');
    setPriorityFilter('');
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-800">Document Workflows</h2>
          <Link 
            to="/documents/create"
            className="bg-blue-600 text-white px-4 py-2 rounded-md flex items-center hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <Plus size={18} className="mr-2" />
            New Workflow
          </Link>
        </div>
      </div>
      
      {error && (
        <div className="bg-red-50 text-red-800 p-4 border-l-4 border-red-500">
          {error}
        </div>
      )}
      
      {/* Filters and Search */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6 px-6 py-4 border-b border-gray-200">
        <div className="flex overflow-x-auto pb-3 md:pb-0 space-x-2">
          <button 
            className={`px-4 py-2 rounded-md ${activeTab === 'active' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
            onClick={() => setActiveTab('active')}
          >
            Active Workflows
          </button>
          <button 
            className={`px-4 py-2 rounded-md ${activeTab === 'completed' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
            onClick={() => setActiveTab('completed')}
          >
            Completed
          </button>
        </div>
        
        <div className="flex mt-4 md:mt-0 space-x-2">
          <div className="relative">
            <Search size={18} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input 
              type="text" 
              placeholder="Search workflows..." 
              className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-64" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="relative">
            <select 
              className="appearance-none pl-4 pr-10 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
              value={priorityFilter}
              onChange={(e) => setPriorityFilter(e.target.value)}
            >
              <option value="">All Priorities</option>
              <option value="High">High Priority</option>
              <option value="Medium">Medium Priority</option>
              <option value="Low">Low Priority</option>
            </select>
            <ChevronDown size={16} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
          </div>
          {(searchTerm || priorityFilter) && (
            <button 
              onClick={resetFilters}
              className="text-blue-600 hover:text-blue-800 text-sm font-medium flex items-center"
            >
              <X size={16} className="mr-1" />
              Clear
            </button>
          )}
        </div>
      </div>
      
      {/* Workflows Grid */}
      {isLoading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : filteredWorkflows.length > 0 ? (
        <div className="grid grid-cols-1 gap-6 p-6">
          {filteredWorkflows.map((workflow) => (
            <div key={workflow.id} className="bg-white rounded-lg shadow overflow-hidden">
              <div className="px-6 py-4">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800">{workflow.title}</h3>
                    <p className="text-sm text-gray-500 mt-1">{workflow.description}</p>
                  </div>
                  <div className="flex items-center">
                    <span className={`text-xs px-2 py-1 rounded-full font-medium ${
                      workflow.priority === 'High' ? 'bg-red-100 text-red-800' :
                      workflow.priority === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {workflow.priority} Priority
                    </span>
                    <div className="relative ml-2">
                      <button 
                        onClick={() => toggleDropdown(workflow.id)}
                        className="text-gray-400 hover:text-gray-600"
                      >
                        <MoreHorizontal size={18} />
                      </button>
                      {activeDropdown === workflow.id && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 ring-1 ring-black ring-opacity-5">
                          <div className="py-1">
                            <Link 
                              to={`/documents/details/${workflow.id}`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              View Details
                            </Link>
                            <Link 
                              to={`/documents/edit/${workflow.id}`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Edit Workflow
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                
                <div className="mt-4">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-medium text-gray-700">Progress</span>
                    <span className="text-sm text-gray-500">{workflow.progress}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div 
                      className={`h-2 rounded-full ${
                        workflow.status === 'Completed' ? 'bg-green-500' :
                        workflow.status === 'Awaiting Input' ? 'bg-yellow-500' :
                        workflow.status === 'Rejected' ? 'bg-red-500' :
                        'bg-blue-500'
                      }`} 
                      style={{ width: `${workflow.progress}%` }}
                    ></div>
                  </div>
                </div>
                
                {activeTab === 'active' && workflow.steps && (
                  <div className="mt-6">
                    <div className="flex items-center mb-4">
                      <span className="text-sm font-medium text-gray-700 mr-2">Workflow Steps</span>
                      <span className="text-xs text-gray-500">
                        {workflow.currentStep}/{workflow.steps.length} Steps
                      </span>
                    </div>
                    
                    <div className="space-y-3">
                      {workflow.steps.map((step, index) => (
                        <div key={step.id} className="flex items-center">
                          <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
                            step.status === 'Completed' ? 'bg-green-100 text-green-600' :
                            step.status === 'In Progress' ? 'bg-blue-100 text-blue-600' :
                            step.status === 'Awaiting Input' ? 'bg-yellow-100 text-yellow-600' :
                            step.status === 'Rejected' ? 'bg-red-100 text-red-600' :
                            'bg-gray-100 text-gray-400'
                          }`}>
                            {step.status === 'Completed' ? <Check size={16} /> :
                             step.status === 'In Progress' ? <Clock size={16} /> :
                             step.status === 'Awaiting Input' ? <Clock size={16} /> :
                             step.status === 'Rejected' ? <X size={16} /> :
                             index + 1}
                          </div>
                          
                          {index < workflow.steps.length - 1 && (
                            <div className="flex-shrink-0 w-8 h-px bg-gray-200 mx-2"></div>
                          )}
                          
                          <div className={`flex-grow p-3 rounded-md ${
                            step.status === 'Completed' ? 'bg-green-50' :
                            step.status === 'In Progress' ? 'bg-blue-50' :
                            step.status === 'Awaiting Input' ? 'bg-yellow-50' :
                            step.status === 'Rejected' ? 'bg-red-50' :
                            'bg-gray-50'
                          }`}>
                            <div className="flex justify-between items-center">
                              <div>
                                <p className="text-sm font-medium">{step.name}</p>
                                <div className="flex items-center mt-1">
                                  <div className={`w-5 h-5 rounded-full bg-${
                                    step.assignee === 'MJ' ? 'blue' :
                                    step.assignee === 'SK' ? 'green' :
                                    step.assignee === 'RL' ? 'purple' :
                                    step.assignee === 'JD' ? 'yellow' :
                                    step.assignee === 'TM' ? 'blue' :
                                    'red'
                                  }-100 flex items-center justify-center text-${
                                    step.assignee === 'MJ' ? 'blue' :
                                    step.assignee === 'SK' ? 'green' :
                                    step.assignee === 'RL' ? 'purple' :
                                    step.assignee === 'JD' ? 'yellow' :
                                    step.assignee === 'TM' ? 'blue' :
                                    'red'
                                  }-600 text-xs font-medium mr-1`}>
                                    {step.assignee}
                                  </div>
                                  <span className="text-xs text-gray-500">Due {step.dueDate}</span>
                                </div>
                              </div>
                              
                              <div>
                                {step.status === 'In Progress' && (
                                  <Link
                                    to={`/documents/details/${workflow.id}`}
                                    className="text-blue-600 hover:text-blue-800 text-xs font-medium"
                                  >
                                    View Task
                                  </Link>
                                )}
                                {step.status === 'Awaiting Input' && (
                                  <div className="flex space-x-2">
                                    <button 
                                      onClick={() => handleRejectStep(workflow.id, step.id)}
                                      className="bg-white text-red-600 border border-red-600 px-3 py-1 rounded text-xs font-medium hover:bg-red-50"
                                    >
                                      Reject
                                    </button>
                                    <button 
                                      onClick={() => handleApproveStep(workflow.id, step.id)}
                                      className="bg-green-600 text-white px-3 py-1 rounded text-xs font-medium hover:bg-green-700"
                                    >
                                      Approve
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                
                <div className={`mt-6 flex ${activeTab === 'active' ? 'justify-between' : 'justify-end'} items-center`}>
                  {activeTab === 'active' && workflow.participants && (
                    <div className="flex items-center">
                      <div className="flex -space-x-2 mr-3">
                        {workflow.participants.slice(0, 3).map((participant, index) => (
                          <div key={index} className={`w-8 h-8 rounded-full bg-${participant.color}-100 border-2 border-white flex items-center justify-center text-${participant.color}-600 font-medium`}>
                            {participant.avatar}
                          </div>
                        ))}
                        {workflow.participants.length > 3 && (
                          <div className="w-8 h-8 rounded-full bg-gray-100 border-2 border-white flex items-center justify-center text-gray-600 text-xs font-medium">
                            +{workflow.participants.length - 3}
                          </div>
                        )}
                      </div>
                      <div className="text-sm text-gray-500 flex items-center">
                        <Calendar size={14} className="mr-1" />
                        Due {workflow.dueDate}
                      </div>
                    </div>
                  )}
                  
                  <div className="flex items-center">
                    {activeTab === 'completed' && (
                      <div className="text-sm text-gray-500 flex items-center mr-4">
                        <Check size={14} className="mr-1 text-green-500" />
                        Completed on {workflow.completedDate instanceof Date 
                          ? workflow.completedDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
                          : typeof workflow.completedDate === 'object' && workflow.completedDate.seconds
                          ? new Date(workflow.completedDate.seconds * 1000).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
                          : workflow.completedDate}
                      </div>
                    )}
                    <div className="text-sm text-gray-500">
                      Updated {workflow.lastUpdated instanceof Date 
                        ? workflow.lastUpdated.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
                        : typeof workflow.lastUpdated === 'object' && workflow.lastUpdated.seconds
                        ? new Date(workflow.lastUpdated.seconds * 1000).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
                        : workflow.lastUpdated}
                    </div>
                  </div>
                </div>
              </div>
              
              {activeTab === 'active' && (
                <div className="bg-gray-50 px-6 py-3 flex justify-between items-center border-t">
                  <div className="flex space-x-4">
                    <button className="text-gray-500 hover:text-gray-700 flex items-center text-sm">
                      <FileText size={16} className="mr-1" />
                      Documents ({workflow.documents ? workflow.documents.length : 0})
                    </button>
                    <button className="text-gray-500 hover:text-gray-700 flex items-center text-sm">
                      <MessageSquare size={16} className="mr-1" />
                      Comments ({workflow.comments ? workflow.comments.length : 0})
                    </button>
                  </div>
                  
                  <div className="flex space-x-2">
                    {workflow.status === 'Awaiting Input' && (
                      <>
                        <button className="flex items-center px-3 py-1 bg-white border rounded text-gray-700 hover:bg-gray-50 text-sm">
                          <X size={14} className="mr-1" />
                          Reject
                        </button>
                        <button className="flex items-center px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700 text-sm">
                          <Check size={14} className="mr-1" />
                          Approve
                        </button>
                      </>
                    )}
                    
                    {(workflow.status === 'In Progress' || workflow.status === 'Rejected') && (
                      <Link
                        to={`/documents/details/${workflow.id}`} 
                        className="flex items-center px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-700 text-sm"
                      >
                        View Details
                        <ArrowRight size={14} className="ml-1" />
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12">
          <FileText size={48} className="mx-auto text-gray-400 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No Workflows Found</h3>
          <p className="text-gray-500 max-w-md mx-auto mb-6">
            {searchTerm || priorityFilter ? 
              'No workflows match your search criteria. Try adjusting your filters.' : 
              activeTab === 'active' ?
                'You don\'t have any active document workflows. Create a new workflow to get started.' :
                'There are no completed workflows yet. Active workflows will appear here once they are completed.'
            }
          </p>
          {activeTab === 'active' && !searchTerm && !priorityFilter && (
            <Link
              to="/documents/create"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
            >
              <Plus size={16} className="mr-2" />
              Create Workflow
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default DocumentWorkflowList;