// src/components/staff/StaffList.js
import React, { useState, useEffect } from 'react';
import { 
  Phone, Mail, Search, Filter, ChevronDown, 
  MessageSquare, User, Book, Briefcase, AlertTriangle 
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, 
  query, 
  where, 
  getDocs,
  getDoc,
  doc,
  orderBy 
} from 'firebase/firestore';

const StaffList = () => {
  const { currentUser, userProfile } = useAuth();
  const navigate = useNavigate();
  const [staffMembers, setStaffMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [subjectFilter, setSubjectFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  // Debug info
  console.log('StaffList - currentUser:', currentUser);
  console.log('StaffList - userProfile:', userProfile);

  // Fetch staff members
  useEffect(() => {
    const fetchStaffMembers = async () => {
      if (!currentUser) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        // Let's check where user data is actually stored
        console.log('Checking userProfiles collection...');
        const userProfilesRef = collection(db, "userProfiles");
        const userProfilesSnapshot = await getDocs(userProfilesRef);
        console.log(`Found ${userProfilesSnapshot.size} documents in userProfiles collection`);
        
        if (userProfilesSnapshot.size > 0) {
          console.log('Sample userProfile document:');
          userProfilesSnapshot.forEach(doc => {
            console.log(`- ${doc.id}: `, doc.data());
          });
        }

        // Based on the logs, it looks like the data is in userProfiles collection
        // So let's query that instead of the users collection
        const q = query(
          userProfilesRef,
          // No filtering by institution yet - let's see all users first
          orderBy("firstName")
        );

        const querySnapshot = await getDocs(q);
        const staffList = [];

        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          staffList.push({
            id: doc.id,
            ...userData,
            isCurrentUser: doc.id === currentUser.uid
          });
        });

        console.log(`Found ${staffList.length} staff members`);
        
        // If we have staff but none match the current user's institution,
        // filter them by institution
        if (staffList.length > 0 && userProfile?.institutionName) {
          const institutionFilteredStaff = staffList.filter(staff => 
            staff.institutionName === userProfile.institutionName
          );
          
          console.log(`After institution filtering: ${institutionFilteredStaff.length} staff members`);
          setStaffMembers(institutionFilteredStaff);
        } else {
          // Just show all staff for now to see what's available
          setStaffMembers(staffList);
        }

        // If no staff found but we have userProfile data, add the current user
        if (staffList.length === 0 && userProfile) {
          console.log('No staff found, adding current user to list');
          setStaffMembers([{
            id: currentUser.uid,
            email: currentUser.email,
            ...userProfile,
            isCurrentUser: true
          }]);
        }
      } catch (err) {
        console.error("Error fetching staff members:", err);
        setError("Failed to load staff members. Please try again.");
        
        // Fallback - at least show current user
        if (userProfile) {
          setStaffMembers([{
            id: currentUser.uid,
            email: currentUser.email,
            ...userProfile,
            isCurrentUser: true
          }]);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchStaffMembers();
  }, [currentUser, userProfile]);

  // Filter staff members based on search and filters
  const filteredStaffMembers = staffMembers.filter(staff => {
    const fullName = `${staff.title || ''} ${staff.firstName || ''} ${staff.lastName || ''}`.toLowerCase();
    const matchesSearch = 
      searchTerm === '' || 
      fullName.includes(searchTerm.toLowerCase()) || 
      (staff.email && staff.email.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesRole = roleFilter === '' || staff.userRole === roleFilter;
    const matchesSubject = subjectFilter === '' || 
      (staff.subjects && staff.subjects.some(subject => subject.toLowerCase().includes(subjectFilter.toLowerCase())));
    
    return matchesSearch && matchesRole && matchesSubject;
  });

  // Get unique roles for filter dropdown
  const roles = [...new Set(staffMembers.map(staff => staff.userRole).filter(Boolean))];
  
  // Get unique subjects for filter dropdown
  const subjects = [...new Set(
    staffMembers
      .flatMap(staff => staff.subjects || [])
      .filter(Boolean)
  )];

  // Handle phone call
  const handleCall = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  // Handle email
  const handleEmail = (email) => {
    window.location.href = `mailto:${email}`;
  };

  // Handle direct message
  const handleDirectMessage = (staffMember) => {
    navigate('/communication', { 
      state: { 
        directMessageUser: {
          id: staffMember.id,
          name: `${staffMember.title || ''} ${staffMember.firstName} ${staffMember.lastName}`.trim(),
          avatar: `${staffMember.firstName?.charAt(0) || ''}${staffMember.lastName?.charAt(0) || ''}`
        } 
      } 
    });
  };

  // Format phone number for display
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    
    // Remove any non-numeric characters
    const cleaned = phoneNumber.replace(/\D/g, '');
    
    // Format based on length
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    } else if (cleaned.length === 11 && cleaned.startsWith('1')) {
      return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
    }
    
    // Return original if we can't format it
    return phoneNumber;
  };

  // Get color for avatar based on initial
  const getColorForInitial = (initial) => {
    const colors = ['blue', 'green', 'purple', 'yellow', 'red', 'indigo', 'pink'];
    const charCode = initial ? initial.charCodeAt(0) : 0;
    return colors[charCode % colors.length];
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Staff Directory</h1>
          <p className="mt-1 text-sm text-gray-500">
            View and contact staff members at your institution
          </p>
        </div>
      </div>

      {/* Search and Filters */}
      <div className="bg-white shadow rounded-lg mb-6 p-4">
        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
          <div className="relative flex-grow">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
            <input
              type="text"
              placeholder="Search staff by name or email..."
              className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="mt-3 md:mt-0 flex">
            <button
              className="inline-flex items-center px-4 py-2 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => setShowFilters(!showFilters)}
            >
              <Filter size={16} className="mr-2" />
              Filters
              <ChevronDown size={16} className={`ml-1 transition-transform ${showFilters ? 'transform rotate-180' : ''}`} />
            </button>
          </div>
        </div>
        
        {/* Filter options */}
        {showFilters && (
          <div className="mt-4 pt-4 border-t grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label htmlFor="roleFilter" className="block text-sm font-medium text-gray-700 mb-1">
                Role
              </label>
              <select
                id="roleFilter"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                value={roleFilter}
                onChange={(e) => setRoleFilter(e.target.value)}
              >
                <option value="">All Roles</option>
                {roles.map(role => (
                  <option key={role} value={role}>{role}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label htmlFor="subjectFilter" className="block text-sm font-medium text-gray-700 mb-1">
                Subject
              </label>
              <select
                id="subjectFilter"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                value={subjectFilter}
                onChange={(e) => setSubjectFilter(e.target.value)}
              >
                <option value="">All Subjects</option>
                {subjects.map(subject => (
                  <option key={subject} value={subject}>{subject}</option>
                ))}
              </select>
            </div>
            
            <div className="md:col-span-3 flex justify-end">
              <button
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => {
                  setRoleFilter('');
                  setSubjectFilter('');
                  setSearchTerm('');
                }}
              >
                Reset Filters
              </button>
            </div>
          </div>
        )}
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded-md mb-6">
          {error}
        </div>
      )}

      {/* Loading indicator */}
      {isLoading && (
        <div className="text-center py-12">
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent"></div>
          <p className="mt-2 text-gray-600">Loading staff directory...</p>
        </div>
      )}

      {/* No results */}
      {!isLoading && filteredStaffMembers.length === 0 && (
        <div className="bg-white shadow rounded-lg p-6 text-center">
          <User className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No staff members found</h3>
          <p className="mt-1 text-sm text-gray-500">
            {searchTerm || roleFilter || subjectFilter 
              ? 'Try adjusting your search or filters' 
              : 'There are no staff members registered for your institution yet'}
          </p>
        </div>
      )}

      {/* Staff List */}
      {!isLoading && filteredStaffMembers.length > 0 && (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <ul className="divide-y divide-gray-200">
            {filteredStaffMembers.map((staffMember) => (
              <li key={staffMember.id} className={`p-4 hover:bg-gray-50 ${staffMember.isCurrentUser ? 'bg-blue-50 border-l-4 border-blue-500' : ''}`}>
                <div className="flex flex-col md:flex-row md:items-center">
                  <div className="flex items-center flex-1">
                    <div className={`flex-shrink-0 h-12 w-12 rounded-full bg-${getColorForInitial(staffMember.firstName?.charAt(0) || 'A')}-100 flex items-center justify-center text-${getColorForInitial(staffMember.firstName?.charAt(0) || 'A')}-600 font-medium text-lg`}>
                      {staffMember.firstName?.charAt(0) || ''}{staffMember.lastName?.charAt(0) || ''}
                    </div>
                    
                    <div className="ml-4 flex-1 min-w-0">
                      <p className="text-lg font-medium text-gray-900 truncate">
                        {staffMember.title} {staffMember.firstName} {staffMember.lastName}
                        {staffMember.isCurrentUser && (
                          <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            You
                          </span>
                        )}
                      </p>
                      <div className="mt-1 flex flex-wrap gap-2">
                        {staffMember.userRole && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            {staffMember.userRole}
                          </span>
                        )}
                        {staffMember.grade && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Grade {staffMember.grade}
                          </span>
                        )}
                      </div>
                      <div className="mt-2 flex flex-col sm:flex-row sm:flex-wrap sm:gap-x-6">
                        {staffMember.subjects && staffMember.subjects.length > 0 && (
                          <div className="mt-1 flex items-center text-sm text-gray-500">
                            <Book className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                            {staffMember.subjects.join(', ')}
                          </div>
                        )}
                        {staffMember.department && (
                          <div className="mt-1 flex items-center text-sm text-gray-500">
                            <Briefcase className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                            {staffMember.department}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  
                  <div className="mt-4 md:mt-0 md:ml-4 flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4">
                    {staffMember.phoneNumber && (
                      <div className="flex items-center">
                        <Phone size={16} className="text-gray-400 mr-2" />
                        <span className="text-sm text-gray-600 mr-2">{formatPhoneNumber(staffMember.phoneNumber)}</span>
                        <button
                          onClick={() => handleCall(staffMember.phoneNumber)}
                          className="inline-flex items-center py-1 px-2 border border-gray-300 rounded-md text-xs bg-white text-gray-700 hover:bg-gray-50"
                        >
                          Call
                        </button>
                      </div>
                    )}
                    
                    {staffMember.email && (
                      <div className="flex items-center">
                        <Mail size={16} className="text-gray-400 mr-2" />
                        <span className="text-sm text-gray-600 mr-2 truncate max-w-[150px]">{staffMember.email}</span>
                        <button
                          onClick={() => handleEmail(staffMember.email)}
                          className="inline-flex items-center py-1 px-2 border border-gray-300 rounded-md text-xs bg-white text-gray-700 hover:bg-gray-50"
                        >
                          Email
                        </button>
                      </div>
                    )}
                    
                    {!staffMember.isCurrentUser && (
                      <button
                        onClick={() => handleDirectMessage(staffMember)}
                        className="inline-flex items-center py-1 px-3 border border-blue-300 rounded-md text-sm bg-blue-50 text-blue-700 hover:bg-blue-100"
                      >
                        <MessageSquare size={14} className="mr-1" />
                        Message
                      </button>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default StaffList;