// src/components/subjects/SubjectDetails.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  ArrowLeft, Edit, Trash2, Book, Download, 
  FileText, AlertTriangle, Calendar, Clock,
  BookOpen, Users, Copy
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  doc, getDoc, deleteDoc, addDoc, collection, serverTimestamp 
} from 'firebase/firestore';
import CurriculumList from './CurriculumList';

const SubjectDetails = () => {
  const { subjectId } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  
  const [subject, setSubject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  
  // Fetch subject data
  useEffect(() => {
    const fetchSubject = async () => {
      if (!subjectId) return;
      
      setIsLoading(true);
      try {
        const subjectDoc = await getDoc(doc(db, "subjects", subjectId));
        if (subjectDoc.exists()) {
          setSubject({
            id: subjectDoc.id,
            ...subjectDoc.data()
          });
        } else {
          setError("Subject not found");
        }
      } catch (err) {
        console.error("Error fetching subject:", err);
        setError("Failed to load subject data");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchSubject();
  }, [subjectId]);
  
  // Handle delete
  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "subjects", subjectId));
      navigate('/subjects');
    } catch (err) {
      console.error("Error deleting subject:", err);
      setError("Failed to delete subject");
      setDeleteConfirm(false);
    }
  };
  
  // Handle duplicate
  const handleDuplicate = async () => {
    try {
      const { id, createdAt, ...subjectData } = subject;
      
      // Create new subject with modified name
      const newSubject = {
        ...subjectData,
        name: `${subjectData.name} - Copy`,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };
      
      // Add to Firestore
      const docRef = await addDoc(collection(db, "subjects"), newSubject);
      
      // Navigate to edit form for the new subject
      navigate(`/subjects/edit/${docRef.id}`);
      
    } catch (err) {
      console.error("Error duplicating subject:", err);
      setError("Failed to duplicate subject");
    }
  };
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-[#1d67cd]"></div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <div className="flex">
            <AlertTriangle className="h-6 w-6 text-red-500" />
            <div className="ml-3">
              <p className="text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate('/subjects')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#1d67cd] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Subjects
          </button>
        </div>
      </div>
    );
  }
  
  if (!subject) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4">
          <div className="flex">
            <AlertTriangle className="h-6 w-6 text-yellow-500" />
            <div className="ml-3">
              <p className="text-yellow-700">Subject not found</p>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate('/subjects')}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#1d67cd] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Subjects
          </button>
        </div>
      </div>
    );
  }

  // Document display component
  const DocumentDisplay = ({ document, title, icon = <FileText />, color = "blue" }) => {
    if (!document) return (
      <div className="mt-2 bg-gray-50 p-4 rounded-md flex items-center">
        <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-gray-400 mr-3">
          {icon}
        </div>
        <div>
          <p className="text-sm font-medium text-gray-500">No {title} Document</p>
          <p className="text-xs text-gray-400">Document not uploaded</p>
        </div>
      </div>
    );
    
    return (
      <div className={`mt-2 bg-${color}-50 p-4 rounded-md flex items-center justify-between`}>
        <div className="flex items-center">
          <div className={`w-8 h-8 rounded-full bg-${color}-100 flex items-center justify-center text-${color}-600 mr-3`}>
            {icon}
          </div>
          <div>
            <p className="text-sm font-medium">{document.name}</p>
            {document.uploadedAt && (
              <p className="text-xs text-gray-500">
                Uploaded on {new Date(document.uploadedAt).toLocaleDateString()}
              </p>
            )}
          </div>
        </div>
        <a
          href={document.url}
          target="_blank"
          rel="noreferrer"
          className={`inline-flex items-center px-3 py-1.5 border border-${color}-300 rounded-md shadow-sm text-sm font-medium text-${color}-700 bg-white hover:bg-${color}-50`}
        >
          <Download className="h-4 w-4 mr-1" />
          Download
        </a>
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      {/* Header */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/subjects')}
            className="mr-4 text-gray-500 hover:text-gray-700"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <h1 className="text-xl font-bold text-gray-900">Subject Details</h1>
        </div>
        
        <div className="mt-4 md:mt-0 flex space-x-2">
          <button
            onClick={handleDuplicate}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Copy className="h-4 w-4 mr-2" />
            Duplicate
          </button>
          <Link
            to={`/subjects/edit/${subjectId}`}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Edit className="h-4 w-4 mr-2" />
            Edit
          </Link>
          <button
            onClick={() => setDeleteConfirm(true)}
            className="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <Trash2 className="h-4 w-4 mr-2" />
            Delete
          </button>
        </div>
      </div>
      
      {/* Basic Details */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:px-6 bg-[#1d67cd] bg-opacity-5">
          <div className="flex items-center">
            <div className="w-12 h-12 bg-[#1d67cd] bg-opacity-10 rounded-full flex items-center justify-center text-[#1d67cd] mr-4">
              <Book className="h-6 w-6" />
            </div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {subject.name}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {subject.code && <span className="mr-2">Code: {subject.code}</span>}
                {subject.phase && <span className="mr-2">Phase: {subject.phase}</span>}
                {subject.grade && <span>Grade: {subject.grade}</span>}
              </p>
            </div>
          </div>
        </div>
        
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Department
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {subject.department || 'Not specified'}
              </dd>
            </div>
            
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Description
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {subject.description || 'No description provided'}
              </dd>
            </div>
            
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Phase
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {subject.phase || 'Not specified'}
              </dd>
            </div>
            
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Grade
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {subject.grade ? `Grade ${subject.grade}` : 'Not specified'}
              </dd>
            </div>
            
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Nominal Hours
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {subject.nominalHours || 'Not specified'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      
      {/* ATP Curriculum Section */}
      <div className="mb-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex items-center justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Curriculum (ATP)
          </h3>
          <Link
            to={`/subjects/curriculum/${subjectId}`}
            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#1d67cd] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <BookOpen className="h-4 w-4 mr-2" />
            Manage Curriculum
          </Link>
        </div>
        <div className="border-t border-gray-200">
          <CurriculumList 
            curriculumItems={subject.curriculumItems || []} 
            subjectId={subjectId} 
          />
        </div>
      </div>
      
      {/* Documents */}
      <div className="mb-6 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Subject Documents
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Review and download the subject's documents
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5">
          {/* ATP Document */}
          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Annual Teaching Plan (ATP)
            </h4>
            <DocumentDisplay 
              document={subject.atpDocument} 
              title="ATP" 
              color="green" 
              icon={<Calendar className="h-4 w-4" />} 
            />
          </div>
          
          {/* CAPS Document */}
          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              CAPS Document
            </h4>
            <DocumentDisplay 
              document={subject.capsDocument} 
              title="CAPS" 
              color="red" 
              icon={<BookOpen className="h-4 w-4" />} 
            />
          </div>
          
          {/* PoA Document */}
          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Programme of Assessment (PoA)
            </h4>
            <DocumentDisplay 
              document={subject.poaDocument} 
              title="PoA" 
              color="blue" 
              icon={<FileText className="h-4 w-4" />} 
            />
          </div>
          
          {/* Subject Policy Document */}
          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Subject Policy
            </h4>
            <DocumentDisplay 
              document={subject.policyDocument} 
              title="Policy" 
              color="purple" 
              icon={<FileText className="h-4 w-4" />} 
            />
          </div>
          
          {/* SIP Document */}
          <div>
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              School Improvement Plan (SIP)
            </h4>
            <DocumentDisplay 
              document={subject.sipDocument} 
              title="SIP" 
              color="orange" 
              icon={<FileText className="h-4 w-4" />} 
            />
          </div>
        </div>
      </div>
      
      {/* Delete Confirmation Dialog */}
      {deleteConfirm && (
        <div className="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div 
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={() => setDeleteConfirm(false)}
            ></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <AlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Delete Subject
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this subject? This action cannot be undone and all data related to {subject.name} will be permanently removed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button 
                  type="button" 
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button 
                  type="button" 
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setDeleteConfirm(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubjectDetails;