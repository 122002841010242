// src/components/stakeholders/StakeholderDetails.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { 
  ArrowLeft, Edit, Trash, Mail, Phone, Globe, Calendar, 
  File, MessageSquare, Plus, ChevronDown, Download
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { doc, getDoc, deleteDoc, collection, query, where, getDocs, orderBy } from 'firebase/firestore';

const StakeholderDetails = () => {
  const navigate = useNavigate();
  const { stakeholderId } = useParams();
  const { userProfile } = useAuth();
  const [stakeholder, setStakeholder] = useState(null);
  const [interactions, setInteractions] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const fetchStakeholder = async () => {
      if (!stakeholderId) return;
      
      setIsLoading(true);
      try {
        const stakeholderDoc = await getDoc(doc(db, "stakeholders", stakeholderId));
        
        if (stakeholderDoc.exists()) {
          setStakeholder({
            id: stakeholderId,
            ...stakeholderDoc.data()
          });

          // In a real app, you would fetch related interactions
          // This is a placeholder for demonstration
          setInteractions([
            {
              id: 1,
              type: 'Meeting',
              date: new Date('2025-02-15'),
              notes: 'Discussed potential partnership for science fair sponsorship',
              createdBy: 'Michael Johnson'
            },
            {
              id: 2,
              type: 'Email',
              date: new Date('2025-03-01'),
              notes: 'Sent follow-up regarding sponsorship details',
              createdBy: 'Sarah King'
            }
          ]);

          // In a real app, you would fetch related documents
          // This is a placeholder for demonstration
          setDocuments([
            {
              id: 1,
              name: 'Partnership Agreement.pdf',
              date: new Date('2025-03-05'),
              size: '420 KB',
              uploadedBy: 'Michael Johnson'
            },
            {
              id: 2,
              name: 'Sponsorship Details.docx',
              date: new Date('2025-03-02'),
              size: '156 KB',
              uploadedBy: 'Sarah King'
            }
          ]);
        } else {
          setError("Stakeholder not found");
        }
      } catch (error) {
        console.error("Error fetching stakeholder:", error);
        setError("Failed to load stakeholder. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStakeholder();
  }, [stakeholderId]);

  const handleDeleteStakeholder = async () => {
    try {
      await deleteDoc(doc(db, "stakeholders", stakeholderId));
      navigate('/stakeholders');
    } catch (error) {
      console.error("Error deleting stakeholder:", error);
      setError("Failed to delete stakeholder. Please try again.");
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow p-6 flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-center mb-6">
          <button 
            onClick={() => navigate('/stakeholders')}
            className="text-gray-500 hover:text-gray-700 flex items-center"
          >
            <ArrowLeft size={18} className="mr-1" />
            Back to Stakeholders
          </button>
        </div>
        <div className="bg-red-50 text-red-800 p-4 rounded-md">
          {error}
        </div>
      </div>
    );
  }

  if (!stakeholder) {
    return null;
  }

  const formatDate = (date) => {
    if (!date) return '';
    if (typeof date === 'string') return date;
    
    try {
      if (date.toDate) {
        // Handle Firestore Timestamp
        date = date.toDate();
      }
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(date);
    } catch (err) {
      console.error('Date formatting error:', err);
      return 'Invalid date';
    }
  };

  const getCategoryColor = (category) => {
    switch (category) {
      case 'NGO':
        return 'bg-green-100 text-green-800';
      case 'Government':
        return 'bg-blue-100 text-blue-800';
      case 'Supplier':
        return 'bg-yellow-100 text-yellow-800';
      case 'Supporter':
        return 'bg-purple-100 text-purple-800';
      case 'Community Organization':
        return 'bg-pink-100 text-pink-800';
      case 'Corporate':
        return 'bg-indigo-100 text-indigo-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <button 
              onClick={() => navigate('/stakeholders')}
              className="text-gray-500 hover:text-gray-700 flex items-center mr-4"
            >
              <ArrowLeft size={18} className="mr-1" />
              Back
            </button>
            <h2 className="text-xl font-semibold text-gray-800">{stakeholder.name}</h2>
            <span className={`ml-3 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getCategoryColor(stakeholder.category)}`}>
              {stakeholder.category}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <Link
              to={`/stakeholders/edit/${stakeholderId}`}
              className="bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setConfirmDelete(true)}
              className="bg-white border border-gray-300 text-red-600 px-4 py-2 rounded-md hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 flex items-center"
            >
              <Trash size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200">
        <nav className="flex -mb-px px-6">
          <button
            onClick={() => setActiveTab('overview')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'overview'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Overview
          </button>
          <button
            onClick={() => setActiveTab('interactions')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8 ${
              activeTab === 'interactions'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Interactions
          </button>
          <button
            onClick={() => setActiveTab('documents')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8 ${
              activeTab === 'documents'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Documents
          </button>
        </nav>
      </div>

      {/* Content */}
      <div className="p-6">
        {activeTab === 'overview' && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Main Info */}
            <div className="md:col-span-2 space-y-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">About</h3>
                <p className="text-gray-600">
                  {stakeholder.description || "No description provided."}
                </p>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">Recent Activity</h3>
                {interactions.length > 0 ? (
                  <div className="space-y-4">
                    {interactions.slice(0, 3).map((interaction) => (
                      <div key={interaction.id} className="flex items-start">
                        <div className="flex-shrink-0 bg-blue-100 rounded-md p-2 text-blue-600">
                          {interaction.type === 'Meeting' ? 
                            <Calendar size={20} /> : 
                            <MessageSquare size={20} />
                          }
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-gray-900">{interaction.type}</p>
                          <p className="text-sm text-gray-500">{formatDate(interaction.date)}</p>
                          <p className="text-sm text-gray-600 mt-1">{interaction.notes}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 text-sm">No recent activity recorded.</p>
                )}
              </div>
            </div>

            {/* Contact Info */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h3>
              <div className="space-y-4">
                {stakeholder.contactPerson && (
                  <div>
                    <p className="text-sm text-gray-500">Contact Person</p>
                    <p className="text-sm font-medium text-gray-900">{stakeholder.contactPerson}</p>
                  </div>
                )}
                
                {stakeholder.contactEmail && (
                  <div>
                    <p className="text-sm text-gray-500">Email</p>
                    <div className="flex items-center">
                      <Mail size={16} className="text-gray-400 mr-2" />
                      <a 
                        href={`mailto:${stakeholder.contactEmail}`} 
                        className="text-sm text-blue-600 hover:text-blue-800"
                      >
                        {stakeholder.contactEmail}
                      </a>
                    </div>
                  </div>
                )}
                
                {stakeholder.contactNumber && (
                  <div>
                    <p className="text-sm text-gray-500">Phone</p>
                    <div className="flex items-center">
                      <Phone size={16} className="text-gray-400 mr-2" />
                      <p className="text-sm text-gray-900">{stakeholder.contactNumber}</p>
                    </div>
                  </div>
                )}

                <div className="pt-4 mt-4 border-t border-gray-200">
                  <p className="text-sm text-gray-500">Added on</p>
                  <p className="text-sm text-gray-900">
                    {stakeholder.createdAt ? formatDate(stakeholder.createdAt) : 'Unknown'}
                  </p>
                </div>

                <div className="pt-2">
                  <p className="text-sm text-gray-500">Last updated</p>
                  <p className="text-sm text-gray-900">
                    {stakeholder.updatedAt ? formatDate(stakeholder.updatedAt) : 'Unknown'}
                  </p>
                </div>
              </div>

              {/* Quick Actions */}
              <div className="mt-6 pt-4 border-t border-gray-200">
                <h4 className="text-sm font-medium text-gray-900 mb-3">Quick Actions</h4>
                <div className="flex flex-col space-y-2">
                  {stakeholder.contactEmail && (
                    <a
                      href={`mailto:${stakeholder.contactEmail}`}
                      className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <Mail size={16} className="mr-2" />
                      Send Email
                    </a>
                  )}
                  <button
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <Plus size={16} className="mr-2" />
                    Add Interaction
                  </button>
                  <button
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <File size={16} className="mr-2" />
                    Upload Document
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'interactions' && (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium text-gray-900">Stakeholder Interactions</h3>
              <button
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
              >
                <Plus size={18} className="mr-2" />
                Add Interaction
              </button>
            </div>

            {interactions.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Notes
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Created By
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {interactions.map((interaction) => (
                      <tr key={interaction.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <span className={`p-1 rounded-md ${
                              interaction.type === 'Meeting' ? 'bg-blue-100 text-blue-600' : 'bg-green-100 text-green-600'
                            }`}>
                              {interaction.type === 'Meeting' ? <Calendar size={16} /> : <MessageSquare size={16} />}
                            </span>
                            <span className="ml-2 text-sm font-medium text-gray-900">{interaction.type}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(interaction.date)}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          <div className="max-w-xs truncate">
                            {interaction.notes}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {interaction.createdBy}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-blue-600 hover:text-blue-800">
                            Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-8">
                <p className="text-gray-500 mb-4">No interactions recorded yet.</p>
                <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <Plus size={16} className="mr-2" />
                  Add First Interaction
                </button>
              </div>
            )}
          </div>
        )}

        {activeTab === 'documents' && (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium text-gray-900">Stakeholder Documents</h3>
              <button
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
              >
                <Plus size={18} className="mr-2" />
                Upload Document
              </button>
            </div>

            {documents.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date Uploaded
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Size
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Uploaded By
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {documents.map((document) => (
                      <tr key={document.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <File size={16} className="text-gray-400 mr-2" />
                            <span className="text-sm font-medium text-gray-900">{document.name}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(document.date)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {document.size}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {document.uploadedBy}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-blue-600 hover:text-blue-800 mr-4">
                            <Download size={16} />
                          </button>
                          <button className="text-red-600 hover:text-red-800">
                            <Trash size={16} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-8">
                <p className="text-gray-500 mb-4">No documents uploaded yet.</p>
                <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <Plus size={16} className="mr-2" />
                  Upload First Document
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {confirmDelete && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md mx-auto">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Delete</h3>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to delete {stakeholder.name}? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setConfirmDelete(false)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteStakeholder}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StakeholderDetails;