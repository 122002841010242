import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { 
  ArrowLeft, Edit, Trash, FileText, Users, 
  Calendar, Download, MoreHorizontal, Clipboard, User, Phone
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { doc, getDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';

const SchoolStructureDetails = () => {
  const navigate = useNavigate();
  const { structureId } = useParams();
  const { userProfile } = useAuth();
  const [structure, setStructure] = useState(null);
  const [members, setMembers] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  const [activeDropdown, setActiveDropdown] = useState(null);

  useEffect(() => {
    const fetchStructure = async () => {
      if (!structureId) return;
      
      setIsLoading(true);
      try {
        const structureDoc = await getDoc(doc(db, "structures", structureId));
        
        if (structureDoc.exists()) {
          const structureData = {
            id: structureId,
            ...structureDoc.data()
          };
          setStructure(structureData);

          // Fetch members if there are any
          if (structureData.memberIds && structureData.memberIds.length > 0) {
            const memberPromises = structureData.memberIds.map(memberId => 
              getDoc(doc(db, "users", memberId))
            );
            const memberDocs = await Promise.all(memberPromises);
            
            const membersData = memberDocs
              .filter(memberDoc => memberDoc.exists())
              .map(memberDoc => ({
                id: memberDoc.id,
                ...memberDoc.data()
              }));
            
            setMembers(membersData);
          }

          // In a real app, you would fetch documents related to this structure
          // This is placeholder data for demonstration
          setDocuments([
            {
              id: '1',
              name: 'Committee Constitution',
              type: 'PDF',
              size: '256 KB',
              uploadedBy: 'Michael Johnson',
              uploadedAt: new Date('2025-02-15'),
              url: '#'
            },
            {
              id: '2',
              name: 'Meeting Minutes - February 2025',
              type: 'DOCX',
              size: '128 KB',
              uploadedBy: 'Sarah King',
              uploadedAt: new Date('2025-02-20'),
              url: '#'
            }
          ]);
        } else {
          setError("Structure not found");
        }
      } catch (error) {
        console.error("Error fetching structure:", error);
        setError("Failed to load structure. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStructure();
  }, [structureId]);

  const handleDeleteStructure = async () => {
    try {
      await deleteDoc(doc(db, "structures", structureId));
      navigate('/structures');
    } catch (error) {
      console.error("Error deleting structure:", error);
      setError("Failed to delete structure. Please try again.");
    }
  };

  const toggleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow p-6 flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-center mb-6">
          <button 
            onClick={() => navigate('/structures')}
            className="text-gray-500 hover:text-gray-700 flex items-center"
          >
            <ArrowLeft size={18} className="mr-1" />
            Back to Structures
          </button>
        </div>
        <div className="bg-red-50 text-red-800 p-4 rounded-md">
          {error}
        </div>
      </div>
    );
  }

  if (!structure) {
    return null;
  }

  const formatDate = (date) => {
    if (!date) return '';
    if (typeof date === 'string') return date;
    
    try {
      if (date.toDate) {
        // Handle Firestore Timestamp
        date = date.toDate();
      }
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(date);
    } catch (err) {
      console.error('Date formatting error:', err);
      return 'Invalid date';
    }
  };

  const getCategoryColor = (category) => {
    switch (category) {
      case 'Learners':
        return 'bg-green-100 text-green-800';
      case 'Staff':
        return 'bg-blue-100 text-blue-800';
      case 'Parents':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <button 
              onClick={() => navigate('/structures')}
              className="text-gray-500 hover:text-gray-700 flex items-center mr-4"
            >
              <ArrowLeft size={18} className="mr-1" />
              Back
            </button>
            <h2 className="text-xl font-semibold text-gray-800">{structure.name}</h2>
            <span className={`ml-3 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getCategoryColor(structure.category)}`}>
              {structure.category}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <Link
              to={`/structures/edit/${structureId}`}
              className="bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setConfirmDelete(true)}
              className="bg-white border border-gray-300 text-red-600 px-4 py-2 rounded-md hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 flex items-center"
            >
              <Trash size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200">
        <nav className="flex -mb-px px-6">
          <button
            onClick={() => setActiveTab('overview')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'overview'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Overview
          </button>
          <button
            onClick={() => setActiveTab('members')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8 ${
              activeTab === 'members'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Members
          </button>
          <button
            onClick={() => setActiveTab('documents')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8 ${
              activeTab === 'documents'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Documents
          </button>
        </nav>
      </div>

      {/* Content */}
      <div className="p-6">
        {activeTab === 'overview' && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Main Info */}
            <div className="md:col-span-2 space-y-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Structure Information</h3>
                <div className="bg-gray-50 rounded-lg p-4">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Structure Name</dt>
                      <dd className="mt-1 text-sm text-gray-900">{structure.name}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Category</dt>
                      <dd className="mt-1 text-sm">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getCategoryColor(structure.category)}`}>
                          {structure.category}
                        </span>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Type</dt>
                      <dd className="mt-1 text-sm text-gray-900">{structure.type}</dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Members</dt>
                      <dd className="mt-1 text-sm text-gray-900">{structure.memberIds?.length || 0} members</dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Leadership</h3>
                <div className="bg-gray-50 rounded-lg p-4">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Structure Lead</dt>
                      <dd className="mt-1 text-sm text-gray-900 flex flex-col">
                        <span className="font-medium">{structure.structureLead || '-'}</span>
                        {structure.leadMobile && (
                          <span className="text-sm text-gray-500 flex items-center mt-1">
                            <Phone size={14} className="mr-1" />
                            {structure.leadMobile}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Coordinator</dt>
                      <dd className="mt-1 text-sm text-gray-900 flex flex-col">
                        <span className="font-medium">{structure.coordinator || '-'}</span>
                        {structure.coordinatorMobile && (
                          <span className="text-sm text-gray-500 flex items-center mt-1">
                            <Phone size={14} className="mr-1" />
                            {structure.coordinatorMobile}
                          </span>
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Recent Documents</h3>
                <div className="bg-gray-50 rounded-lg p-4">
                  {documents.length > 0 ? (
                    <ul className="divide-y divide-gray-200">
                      {documents.slice(0, 3).map((document) => (
                        <li key={document.id} className="py-3 flex justify-between">
                          <div className="flex items-center">
                            <FileText size={16} className="text-gray-400 mr-2" />
                            <div>
                              <p className="text-sm font-medium text-gray-900">{document.name}</p>
                              <p className="text-xs text-gray-500">
                                {document.type} • {document.size} • Uploaded {formatDate(document.uploadedAt)}
                              </p>
                            </div>
                          </div>
                          <a 
                            href={document.url} 
                            target="_blank" 
                            rel="noreferrer" 
                            className="text-blue-600 hover:text-blue-800 text-sm flex items-center"
                          >
                            <Download size={14} className="mr-1" />
                            Download
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-sm text-gray-500 text-center py-4">
                      No documents available
                    </div>
                  )}
                  {documents.length > 3 && (
                    <div className="mt-4 text-center">
                      <button 
                        onClick={() => setActiveTab('documents')}
                        className="text-blue-600 hover:text-blue-800 text-sm font-medium"
                      >
                        View all documents
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Sidebar */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Structure Details</h3>
              <div className="space-y-4">
                <div>
                  <p className="text-sm text-gray-500">Total Members</p>
                  <p className="text-sm font-medium text-gray-900">{structure.memberIds?.length || 0}</p>
                </div>
                
                <div>
                  <p className="text-sm text-gray-500">Documents</p>
                  <p className="text-sm font-medium text-gray-900">{documents.length} documents</p>
                </div>

                <div className="pt-4 mt-4 border-t border-gray-200">
                  <p className="text-sm text-gray-500">Created on</p>
                  <p className="text-sm text-gray-900">
                    {structure.createdAt ? formatDate(structure.createdAt) : 'Unknown'}
                  </p>
                </div>

                <div className="pt-2">
                  <p className="text-sm text-gray-500">Last updated</p>
                  <p className="text-sm text-gray-900">
                    {structure.updatedAt ? formatDate(structure.updatedAt) : 'Unknown'}
                  </p>
                </div>
              </div>

              {/* Quick Actions */}
              <div className="mt-6 pt-4 border-t border-gray-200">
                <h4 className="text-sm font-medium text-gray-900 mb-3">Quick Actions</h4>
                <div className="flex flex-col space-y-2">
                  <Link
                    to={`/structures/edit/${structureId}`}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <Edit size={16} className="mr-2" />
                    Edit Structure
                  </Link>
                  <Link
                    to={`/structures/${structureId}/members`}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <Users size={16} className="mr-2" />
                    Manage Members
                  </Link>
                  <Link
                    to={`/structures/${structureId}/documents/upload`}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <FileText size={16} className="mr-2" />
                    Upload Document
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'members' && (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium text-gray-900">Structure Members</h3>
              <Link
                to={`/structures/${structureId}/members`}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
              >
                <Users size={18} className="mr-2" />
                Manage Members
              </Link>
            </div>

            {members.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Member
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Role
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {members.map((member) => (
                      <tr key={member.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium">
                              {member.firstName?.charAt(0) || ''}{member.lastName?.charAt(0) || ''}
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {member.title} {member.firstName} {member.lastName}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{member.userRole || '-'}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">{member.email}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="relative inline-block text-left">
                            <button
                              onClick={() => toggleDropdown(member.id)}
                              className="text-gray-500 hover:text-gray-700"
                              aria-expanded={activeDropdown === member.id}
                              aria-haspopup="true"
                            >
                              <MoreHorizontal size={20} />
                            </button>
                            
                            {activeDropdown === member.id && (
                              <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" 
                                onClick={() => setActiveDropdown(null)}>
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <Link
                                    to={`/users/${member.id}`}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    role="menuitem"
                                  >
                                    <User size={16} className="inline-block mr-2" />
                                    View Profile
                                  </Link>
                                  <button
                                    className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-900"
                                    role="menuitem"
                                  >
                                    <Trash size={16} className="inline-block mr-2" />
                                    Remove from Structure
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-12 border border-dashed border-gray-300 rounded-lg">
                <Users size={48} className="mx-auto text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">No Members Yet</h3>
                <p className="text-gray-500">
                  This structure doesn't have any members yet.
                </p>
                <Link
                  to={`/structures/${structureId}/members`}
                  className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add Members
                </Link>
              </div>
            )}
          </div>
        )}

        {activeTab === 'documents' && (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium text-gray-900">Structure Documents</h3>
              <Link
                to={`/structures/${structureId}/documents/upload`}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
              >
                <FileText size={18} className="mr-2" />
                Upload Document
              </Link>
            </div>

            {documents.length > 0 ? (
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Document
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Size
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Uploaded By
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {documents.map((document) => (
                      <tr key={document.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <FileText size={16} className="text-gray-400 mr-2" />
                            <div className="text-sm font-medium text-gray-900">{document.name}</div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">{document.type}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">{document.size}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{document.uploadedBy}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">{formatDate(document.uploadedAt)}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="relative inline-block text-left">
                            <button
                              onClick={() => toggleDropdown(`doc-${document.id}`)}
                              className="text-gray-500 hover:text-gray-700"
                              aria-expanded={activeDropdown === `doc-${document.id}`}
                              aria-haspopup="true"
                            >
                              <MoreHorizontal size={20} />
                            </button>
                            
                            {activeDropdown === `doc-${document.id}` && (
                              <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" 
                                onClick={() => setActiveDropdown(null)}>
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                  <a
                                    href={document.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    role="menuitem"
                                  >
                                    <Download size={16} className="inline-block mr-2" />
                                    Download
                                  </a>
                                  <button
                                    className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-900"
                                    role="menuitem"
                                  >
                                    <Trash size={16} className="inline-block mr-2" />
                                    Delete Document
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-12 border border-dashed border-gray-300 rounded-lg">
                <FileText size={48} className="mx-auto text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">No Documents Yet</h3>
                <p className="text-gray-500">
                  No documents have been uploaded for this structure.
                </p>
                <Link
                  to={`/structures/${structureId}/documents/upload`}
                  className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Upload Document
                </Link>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {confirmDelete && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md mx-auto">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Delete</h3>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to delete {structure.name}? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setConfirmDelete(false)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteStructure}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SchoolStructureDetails;