import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { 
  School, Building2, Building, Landmark, Plus, Search, 
  Filter, ArrowUpRight, ChevronDown, MapPin, Phone,
  Mail, Globe, FileText, Users, ExternalLink, AlertTriangle
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useInstitutionPermissions } from '../../hooks/useInstitutionPermissions';
import InstitutionNavigation from './InstitutionNavigation';
import { db } from '../../firebase/config';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  limit,
  doc,
  getDoc
} from 'firebase/firestore';

const InstitutionList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { level: urlLevel, id: urlId } = useParams();
  const { currentUser, userProfile } = useAuth();
  const { 
    canViewInstitutionLevel, 
    canCreateInstitution, 
    getDefaultInstitutionLevel,
    getInstitutionScope
  } = useInstitutionPermissions();
  
  const [institutions, setInstitutions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterPhase, setFilterPhase] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  
  // Set default view level based on user's permissions
  const defaultLevel = getDefaultInstitutionLevel();
  const [selectedProvinceName, setSelectedProvinceName] = useState('');
  const [selectedDistrictName, setSelectedDistrictName] = useState('');
  const [selectedCircuitName, setSelectedCircuitName] = useState('');
  const [currentLevel, setCurrentLevel] = useState('province');
  
  const [currentView, setCurrentView] = useState({
    level: urlLevel || defaultLevel,
    id: urlId || null,
    name: ''
  });
  
  const [breadcrumbs, setBreadcrumbs] = useState([
    { level: defaultLevel, name: `All ${defaultLevel.charAt(0).toUpperCase() + defaultLevel.slice(1)}s`, id: null }
  ]);

  // Get the user's institution level based on their profile
  const getUserInstitutionLevel = () => {
    if (!userProfile) return 'none';
    
    // Special development access for specific email
    if (userProfile.email === 'mfundo@appcentral.co.za') {
      console.log('Development user detected - granting full province-level access');
      return 'province'; // Province level gives access to everything
    }
    
    // Normal role-based permissions
    if (userProfile.institutionCategory === 'Provincial Department') {
      return 'province';
    } else if (userProfile.institutionCategory === 'District Office') {
      return 'district';
    } else if (userProfile.institutionCategory === 'Circuit Office') {
      return 'circuit';
    } else if (userProfile.institutionCategory === 'School') {
      return 'school';
    } else {
      return 'none';
    }
  };

  // Verify user has permission to view the requested level
  useEffect(() => {
    if (params.level) {
      setCurrentLevel(params.level);
    } else {
      setCurrentLevel('province');
    }
  }, [params.level]);
  
  // Fetch institutions based on current params
  useEffect(() => {
    const fetchInstitutions = async () => {
      if (!currentUser) return;
      
      setIsLoading(true);
      setError('');
      
      try {
        const institutionsRef = collection(db, "institutions");
        let queryRef;
        
        if (params.level === 'province' && params.id) {
          // Show districts in this province
          
          // Get the province name for display
          try {
            const provinceDoc = await getDoc(doc(db, "institutions", params.id));
            if (provinceDoc.exists()) {
              setSelectedProvinceName(provinceDoc.data().name);
              
              // Now create query with the province name
              queryRef = query(
                institutionsRef,
                where("type", "==", "District Office"),
                where("province", "==", provinceDoc.data().name),
                orderBy("name")
              );
            }
          } catch (error) {
            console.error("Error fetching province:", error);
          }
          
          // If we couldn't create a query with the province, try a fallback
          if (!queryRef) {
            queryRef = query(
              institutionsRef,
              where("type", "==", "District Office"),
              orderBy("name")
            );
          }
        } 
        else if (params.level === 'district' && params.id) {
          // Show circuits in this district
          
          // Get the district name for display
          try {
            const districtDoc = await getDoc(doc(db, "institutions", params.id));
            if (districtDoc.exists()) {
              setSelectedDistrictName(districtDoc.data().name);
              
              // Now create query with the district name
              queryRef = query(
                institutionsRef,
                where("type", "==", "Circuit Office"),
                where("district", "==", districtDoc.data().name),
                orderBy("name")
              );
            }
          } catch (error) {
            console.error("Error fetching district:", error);
          }
          
          // If we couldn't create a query with the district, try a fallback
          if (!queryRef) {
            queryRef = query(
              institutionsRef,
              where("type", "==", "Circuit Office"),
              orderBy("name")
            );
          }
        }
        else if (params.level === 'circuit' && params.id) {
          // Show schools in this circuit
          
          // Get the circuit name for display
          try {
            const circuitDoc = await getDoc(doc(db, "institutions", params.id));
            if (circuitDoc.exists()) {
              setSelectedCircuitName(circuitDoc.data().name);
              
              // Now create query with the circuit name
              queryRef = query(
                institutionsRef,
                where("type", "==", "School"),
                where("circuit", "==", circuitDoc.data().name),
                orderBy("name")
              );
            }
          } catch (error) {
            console.error("Error fetching circuit:", error);
          }
          
          // If we couldn't create a query with the circuit, try a fallback
          if (!queryRef) {
            queryRef = query(
              institutionsRef,
              where("type", "==", "School"),
              orderBy("name"),
              limit(50)
            );
          }
        }
        else {
          // Default view - show provinces
          queryRef = query(
            institutionsRef,
            where("type", "==", "Provincial Department"),
            orderBy("name")
          );
        }
        
        // If a query was created, execute it
        if (queryRef) {
          const querySnapshot = await getDocs(queryRef);
          const institutionList = [];
          
          querySnapshot.forEach((doc) => {
            institutionList.push({
              id: doc.id,
              ...doc.data()
            });
          });
          
          // Apply client-side filtering based on search term
          let filteredInstitutions = institutionList;
          
          if (searchTerm) {
            filteredInstitutions = filteredInstitutions.filter(inst => 
              inst.name?.toLowerCase().includes(searchTerm.toLowerCase())
            );
          }
          
          // Apply phase filter if looking at schools
          if (filterPhase && params.level === 'circuit') {
            filteredInstitutions = filteredInstitutions.filter(inst =>
              inst.phase === filterPhase
            );
          }
          
          setInstitutions(filteredInstitutions);
        } else {
          setInstitutions([]);
        }
      } catch (error) {
        console.error("Error fetching institutions:", error);
        setError("Failed to load institutions. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchInstitutions();
  }, [currentUser, params.level, params.id, searchTerm, filterPhase]);
  
  // Update breadcrumbs when view changes
  useEffect(() => {
    // Logic to build breadcrumbs based on current view...
    // This would be similar to the original code but adapted for the new permission system
    
    // Example simplified logic:
    if (currentView.level === defaultLevel && !currentView.id) {
      setBreadcrumbs([
        { level: defaultLevel, name: `All ${defaultLevel.charAt(0).toUpperCase() + defaultLevel.slice(1)}s`, id: null }
      ]);
    } else {
      // Add breadcrumb for current view
      // In a real implementation, we'd need to fetch the full hierarchy
      setBreadcrumbs(prevBreadcrumbs => {
        const newBreadcrumb = { 
          level: currentView.level, 
          name: currentView.name || `${currentView.level.charAt(0).toUpperCase() + currentView.level.slice(1)}`, 
          id: currentView.id 
        };
        
        // Check if this breadcrumb already exists
        const existingIndex = prevBreadcrumbs.findIndex(b => b.level === currentView.level);
        
        if (existingIndex >= 0) {
          // Replace existing breadcrumb and remove any after it
          return [...prevBreadcrumbs.slice(0, existingIndex), newBreadcrumb];
        } else {
          // Add new breadcrumb
          return [...prevBreadcrumbs, newBreadcrumb];
        }
      });
    }
  }, [currentView, defaultLevel]);
  
  // Navigate to a different level
  const navigateToLevel = (level, id) => {
    if (canViewInstitutionLevel(level)) {
      navigate(`/institutions/${level}${id ? `/${id}` : ''}`);
      setCurrentView({ level, id, name: '' });
    } else {
      setError(`You don't have permission to view ${level}s.`);
    }
  };
  
  // Handle breadcrumb navigation
  const handleBreadcrumbClick = (breadcrumb) => {
    navigateToLevel(breadcrumb.level, breadcrumb.id);
  };
  
  // Get icon based on institution type
  const getInstitutionIcon = (type) => {
    switch (type) {
      case 'School':
        return <School className="h-10 w-10 text-blue-600" />;
      case 'Circuit Office':
        return <Building2 className="h-10 w-10 text-green-600" />;
      case 'District Office':
        return <Building className="h-10 w-10 text-purple-600" />;
      case 'Provincial Department':
        return <Landmark className="h-10 w-10 text-red-600" />;
      default:
        return null;
    }
  };
  
  // Get the next level based on current level
  const getNextLevel = (currentLevel) => {
    switch(currentLevel) {
      case 'province':
        return 'district';
      case 'district':
        return 'circuit';
      case 'circuit':
        return 'school';
      default:
        return null;
    }
  };
  
  // Render card content based on institution type
  const renderCardContent = (institution) => {
    switch(institution.type) {
      case 'Provincial Department':
        return (
          <div className="mt-2 space-y-2">
            <p className="text-sm text-gray-600 flex items-center">
              <MapPin size={14} className="mr-1" /> {institution.location || 'N/A'}
            </p>
            {institution.head && (
              <p className="text-sm text-gray-600 flex items-center">
                <Users size={14} className="mr-1" /> Head: {institution.head}
              </p>
            )}
            <div className="mt-2 grid grid-cols-2 gap-2">
              {institution.districtsCount && (
                <div className="bg-purple-50 rounded-md p-2 flex items-center">
                  <Building className="text-purple-600 mr-2 h-4 w-4" />
                  <div>
                    <p className="text-xs text-gray-500">Districts</p>
                    <p className="text-sm font-semibold text-purple-700">{institution.districtsCount}</p>
                  </div>
                </div>
              )}
              {institution.schoolsCount && (
                <div className="bg-blue-50 rounded-md p-2 flex items-center">
                  <School className="text-blue-600 mr-2 h-4 w-4" />
                  <div>
                    <p className="text-xs text-gray-500">Schools</p>
                    <p className="text-sm font-semibold text-blue-700">{institution.schoolsCount}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      
      case 'District Office':
        return (
          <div className="mt-2 space-y-2">
            <p className="text-sm text-gray-600 flex items-center">
              <MapPin size={14} className="mr-1" /> {institution.location || 'N/A'}
            </p>
            {institution.director && (
              <p className="text-sm text-gray-600 flex items-center">
                <Users size={14} className="mr-1" /> Director: {institution.director}
              </p>
            )}
            <div className="mt-2 grid grid-cols-2 gap-2">
              {institution.circuitsCount && (
                <div className="bg-green-50 rounded-md p-2 flex items-center">
                  <Building2 className="text-green-600 mr-2 h-4 w-4" />
                  <div>
                    <p className="text-xs text-gray-500">Circuits</p>
                    <p className="text-sm font-semibold text-green-700">{institution.circuitsCount}</p>
                  </div>
                </div>
              )}
              {institution.schoolsCount && (
                <div className="bg-blue-50 rounded-md p-2 flex items-center">
                  <School className="text-blue-600 mr-2 h-4 w-4" />
                  <div>
                    <p className="text-xs text-gray-500">Schools</p>
                    <p className="text-sm font-semibold text-blue-700">{institution.schoolsCount}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
        
      case 'Circuit Office':
        return (
          <div className="mt-2 space-y-2">
            <p className="text-sm text-gray-600 flex items-center">
              <MapPin size={14} className="mr-1" /> {institution.location || 'N/A'}
            </p>
            {institution.manager && (
              <p className="text-sm text-gray-600 flex items-center">
                <Users size={14} className="mr-1" /> Manager: {institution.manager}
              </p>
            )}
            {institution.schoolsCount && (
              <div className="mt-2">
                <div className="bg-blue-50 rounded-md p-2 flex items-center">
                  <School className="text-blue-600 mr-2 h-4 w-4" />
                  <div>
                    <p className="text-xs text-gray-500">Schools</p>
                    <p className="text-sm font-semibold text-blue-700">{institution.schoolsCount}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
        
      case 'School':
        return (
          <div className="mt-2 space-y-2">
            <p className="text-sm text-gray-600 flex items-center">
              <MapPin size={14} className="mr-1" /> {institution.location || 'N/A'}
            </p>
            {institution.principal && (
              <p className="text-sm text-gray-600 flex items-center">
                <Users size={14} className="mr-1" /> Principal: {institution.principal}
              </p>
            )}
            {institution.phase && (
              <div className="bg-amber-50 rounded-md px-2 py-1 inline-block">
                <p className="text-xs font-medium text-amber-700">{institution.phase}</p>
              </div>
            )}
            {(institution.studentCount || institution.staffCount) && (
              <div className="mt-2 grid grid-cols-2 gap-2">
                {institution.studentCount && (
                  <div className="bg-indigo-50 rounded-md p-2 flex items-center">
                    <Users className="text-indigo-600 mr-2 h-4 w-4" />
                    <div>
                      <p className="text-xs text-gray-500">Students</p>
                      <p className="text-sm font-semibold text-indigo-700">{institution.studentCount}</p>
                    </div>
                  </div>
                )}
                {institution.staffCount && (
                  <div className="bg-teal-50 rounded-md p-2 flex items-center">
                    <Users className="text-teal-600 mr-2 h-4 w-4" />
                    <div>
                      <p className="text-xs text-gray-500">Staff</p>
                      <p className="text-sm font-semibold text-teal-700">{institution.staffCount}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
            {institution.contact && (
              <div className="mt-2 space-y-1 text-xs text-gray-600">
                {institution.contact.phone && (
                  <p className="flex items-center">
                    <Phone size={12} className="mr-1" /> {institution.contact.phone}
                  </p>
                )}
                {institution.contact.email && (
                  <p className="flex items-center">
                    <Mail size={12} className="mr-1" /> {institution.contact.email}
                  </p>
                )}
                {institution.contact.website && (
                  <p className="flex items-center">
                    <Globe size={12} className="mr-1" /> {institution.contact.website}
                  </p>
                )}
              </div>
            )}
          </div>
        );
        
      default:
        return null;
    }
  };
  
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">Institutions</h1>
          
          {/* Breadcrumbs */}
          <nav className="flex mt-2" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-2">
              {breadcrumbs.map((breadcrumb, index) => (
                <li key={index}>
                  {index > 0 && (
                    <span className="text-gray-400 mx-1">/</span>
                  )}
                  <button 
                    onClick={() => handleBreadcrumbClick(breadcrumb)}
                    className={`text-sm ${
                      index === breadcrumbs.length - 1
                        ? 'font-medium text-blue-600'
                        : 'text-gray-600 hover:text-gray-900'
                    }`}
                  >
                    {breadcrumb.name}
                  </button>
                </li>
              ))}
            </ol>
          </nav>
        </div>
        
        <div className="mt-4 md:mt-0 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
          {canCreateInstitution(currentView.level) && (
            <Link
              to="/institutions/register"
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Plus size={16} className="mr-2" />
              Register Institution
            </Link>
          )}
        </div>
      </div>

      {/* Add the navigation component here */}
      <InstitutionNavigation />
      
      {/* Access information for limited permission users */}
      {userProfile && getUserInstitutionLevel() !== 'province' && (
        <div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4 text-sm text-blue-800 flex items-center">
          <AlertTriangle size={18} className="mr-2 text-blue-500" />
          You are viewing institutions based on your {getUserInstitutionLevel()} level access.
        </div>
      )}
      
      {/* Search and Filters */}
      <div className="bg-white shadow rounded-lg mb-6 p-4">
        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
          <div className="relative flex-grow">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
            <input
              type="text"
              placeholder="Search institutions..."
              className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="mt-3 md:mt-0 flex">
            <button
              className="inline-flex items-center px-4 py-2 border rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => setShowFilters(!showFilters)}
            >
              <Filter size={16} className="mr-2" />
              Filters
              <ChevronDown size={16} className={`ml-1 transition-transform ${showFilters ? 'transform rotate-180' : ''}`} />
            </button>
          </div>
        </div>
        
        {/* Filter options */}
        {showFilters && (
          <div className="mt-4 pt-4 border-t grid grid-cols-1 md:grid-cols-3 gap-4">
            {currentView.level === 'school' && (
              <div>
                <label htmlFor="filterPhase" className="block text-sm font-medium text-gray-700 mb-1">
                  School Phase
                </label>
                <select
                  id="filterPhase"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  value={filterPhase}
                  onChange={(e) => setFilterPhase(e.target.value)}
                >
                  <option value="">All Phases</option>
                  <option value="Primary">Primary</option>
                  <option value="Secondary">Secondary</option>
                  <option value="Combined">Combined</option>
                </select>
              </div>
            )}
            
            <div className="md:col-span-3 flex justify-end">
              <button
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => {
                  setFilterPhase('');
                  setSearchTerm('');
                }}
              >
                Reset Filters
              </button>
            </div>
          </div>
        )}
      </div>
      
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded-md mb-6">
          {error}
        </div>
      )}
      
      {/* Loading indicator */}
      {isLoading && (
        <div className="text-center py-12">
          <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-600 border-r-transparent"></div>
          <p className="mt-2 text-gray-600">Loading institutions...</p>
        </div>
      )}
      
      {/* No results */}
      {!isLoading && institutions.length === 0 && (
        <div className="bg-white shadow rounded-lg p-6 text-center">
          <p className="text-gray-600">No institutions found</p>
          {(searchTerm || filterPhase) && (
            <p className="mt-2 text-sm text-gray-500">Try adjusting your search or filters</p>
          )}
        </div>
      )}
      
      {/* Institution Cards */}
      {!isLoading && institutions.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {institutions.map((institution) => (
            <div key={institution.id} className="bg-white shadow rounded-lg overflow-hidden">
              <div className="p-6">
                <div className="flex items-start">
                  <div className="flex-shrink-0 mr-4">
                    {getInstitutionIcon(institution.type)}
                  </div>
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold text-gray-900 line-clamp-2">
                      {institution.name}
                    </h3>
                    <p className="text-sm text-gray-500">{institution.type}</p>
                    
                    {/* Dynamic card content based on institution type */}
                    {renderCardContent(institution)}
                  </div>
                </div>
              </div>
              
              <div className="bg-gray-50 px-6 py-3 flex justify-between items-center border-t">
                <div className="text-xs text-gray-500">
                  ID: {institution.id}
                </div>
                
                {getNextLevel(currentView.level) && canViewInstitutionLevel(getNextLevel(currentView.level)) ? (
                  <button
                    onClick={() => navigateToLevel(getNextLevel(currentView.level), institution.id)}
                    className="inline-flex items-center px-3 py-1 text-sm text-blue-600 hover:text-blue-800"
                  >
                    View {currentView.level === 'province' ? 'Districts' : 
                          currentView.level === 'district' ? 'Circuits' : 
                          currentView.level === 'circuit' ? 'Schools' : ''}
                    <ArrowUpRight size={16} className="ml-1" />
                  </button>
                ) : (
                  <Link
                    to={`/institutions/details/${institution.id}`}
                    className="inline-flex items-center px-3 py-1 text-sm text-blue-600 hover:text-blue-800"
                  >
                    View Details
                    <ExternalLink size={16} className="ml-1" />
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InstitutionList;