// src/components/tasks/TaskDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  Calendar, Clock, Target, ChevronDown, ChevronUp, 
  Edit, Trash2, User, CheckCircle, AlertCircle, 
  ArrowLeft, ExternalLink, Clipboard, MessageSquare
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  doc, 
  getDoc, 
  deleteDoc,
  updateDoc
} from 'firebase/firestore';

const TaskDetails = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const { currentUser, userProfile } = useAuth();
  
  const [task, setTask] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [showObjectives, setShowObjectives] = useState(true);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  
  // Fetch task data
  useEffect(() => {
    const fetchTask = async () => {
      try {
        const taskDoc = await getDoc(doc(db, 'tasks', taskId));
        
        if (taskDoc.exists()) {
          const taskData = taskDoc.data();
          // Convert Firestore timestamps to JS Date objects
          setTask({
            id: taskDoc.id,
            ...taskData,
            date: taskData.date?.toDate(),
            startDate: taskData.startDate?.toDate(),
            endDate: taskData.endDate?.toDate(),
            createdAt: taskData.createdAt?.toDate(),
            updatedAt: taskData.updatedAt?.toDate()
          });
        } else {
          setError('Task not found');
        }
      } catch (err) {
        console.error('Error fetching task:', err);
        setError('Failed to load task data');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTask();
  }, [taskId]);
  
  // Handle task deletion
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await deleteDoc(doc(db, 'tasks', taskId));
      navigate('/tasks');
    } catch (err) {
      console.error('Error deleting task:', err);
      setError('Failed to delete task');
      setDeleteConfirm(false);
      setIsLoading(false);
    }
  };
  
  // Handle status change
  const handleStatusChange = async (newStatus) => {
    try {
      setStatusChangeLoading(true);
      
      await updateDoc(doc(db, 'tasks', taskId), {
        status: newStatus,
        updatedAt: new Date()
      });
      
      // Update local state
      setTask({
        ...task,
        status: newStatus,
        updatedAt: new Date()
      });
    } catch (err) {
      console.error('Error updating status:', err);
      setError('Failed to update status');
    } finally {
      setStatusChangeLoading(false);
    }
  };
  
  // Calculate task status
  // Calculate task status
  const getTaskStatus = () => {
    if (!task) return { label: 'Unknown', color: 'bg-gray-100 text-gray-800' };
    
    if (task.status === 'Completed') {
      return { label: 'Completed', color: 'bg-green-100 text-green-800' };
    }
    
    if (!task.startDate || !task.endDate) {
      return { label: 'Not Started', color: 'bg-gray-100 text-gray-800' };
    }
    
    const now = new Date();
    const startDate = new Date(task.startDate);
    const endDate = new Date(task.endDate);
    
    if (now < startDate) {
      return { label: 'Not Started', color: 'bg-gray-100 text-gray-800' };
    } else if (now > endDate) {
      return { label: 'Overdue', color: 'bg-red-100 text-red-800' };
    } else {
      return { label: 'In Progress', color: 'bg-yellow-100 text-yellow-800' };
    }
  };
  
  // Format date for display
  const formatDate = (date) => {
    if (!date) return 'Not specified';
    
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };
  
  // Get category badge color
  const getCategoryBadgeColor = (category) => {
    switch (category) {
      case 'Planned':
        return 'bg-blue-100 text-blue-800';
      case 'Meeting':
        return 'bg-purple-100 text-purple-800';
      case 'Instruction':
        return 'bg-amber-100 text-amber-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  // Get priority badge color
  const getPriorityBadgeColor = (priority) => {
    switch (priority) {
      case 'Low':
        return 'bg-gray-100 text-gray-800';
      case 'Medium':
        return 'bg-blue-100 text-blue-800';
      case 'High':
        return 'bg-orange-100 text-orange-800';
      case 'Urgent':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  // Get task type icon
  const getTaskTypeIcon = () => {
    if (!task) return <Clipboard className="h-8 w-8 text-gray-500" />;
    
    switch (task.category) {
      case 'Planned':
        return <Clipboard className="h-8 w-8 text-blue-500" />;
      case 'Meeting':
        return <MessageSquare className="h-8 w-8 text-purple-500" />;
      case 'Instruction':
        return <Target className="h-8 w-8 text-amber-500" />;
      default:
        return <Clipboard className="h-8 w-8 text-gray-500" />;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <button
          onClick={() => navigate('/tasks')}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft size={16} className="mr-2" />
          Back to Tasks
        </button>
      </div>
    );
  }
  
  if (!task) {
    return (
      <div className="bg-white shadow rounded-lg p-6 text-center">
        <p>Task not found</p>
        <button
          onClick={() => navigate('/tasks')}
          className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowLeft size={16} className="mr-2" />
          Back to Tasks
        </button>
      </div>
    );
  }
  
  const status = getTaskStatus();

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <div className="flex items-center">
          <button
            onClick={() => task.taskRef?.type === 'Plan' ? navigate(`/planner/details/${task.taskRef.id}`) : navigate('/tasks')}
            className="mr-4 text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft size={18} />
          </button>
          <h2 className="text-xl font-semibold text-gray-800">Task Details</h2>
        </div>
        
        <div className="flex space-x-3">
          <Link
            to={`/tasks/edit/${taskId}`}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Edit size={14} className="mr-2" />
            Edit
          </Link>
          
          <button
            onClick={() => setDeleteConfirm(true)}
            className="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <Trash2 size={14} className="mr-2" />
            Delete
          </button>
        </div>
      </div>

      {/* Task Title and Status */}
      <div className="px-6 py-5 border-b border-gray-200">
        <div className="flex flex-col md:flex-row md:justify-between md:items-start">
          <div className="flex items-start">
            <div className="flex-shrink-0 mr-4">
              {getTaskTypeIcon()}
            </div>
            
            <div>
              <div className="flex flex-wrap items-center gap-2 mb-2">
                <h1 className="text-2xl font-bold text-gray-900 mr-3">{task.description}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getCategoryBadgeColor(task.category)}`}>
                  {task.category}
                </span>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getPriorityBadgeColor(task.priority)}`}>
                  {task.priority} Priority
                </span>
              </div>
              
              {task.assignee && (
                <div className="flex items-center text-sm text-gray-600">
                  <User size={16} className="mr-2" />
                  Assigned to: {task.assignee}
                </div>
              )}
            </div>
          </div>
          
          <div className="mt-4 md:mt-0 flex flex-col items-start md:items-end">
            <div className={`px-4 py-2 rounded-md ${status.color} mb-2 flex items-center`}>
              <span className="font-medium">{status.label}</span>
            </div>
            
            {/* Status change buttons */}
            <div className="flex space-x-2">
              {task.status !== 'Not Started' && (
                <button
                  onClick={() => handleStatusChange('Not Started')}
                  disabled={statusChangeLoading}
                  className="text-xs px-2 py-1 border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Set to Not Started
                </button>
              )}
              
              {task.status !== 'In Progress' && (
                <button
                  onClick={() => handleStatusChange('In Progress')}
                  disabled={statusChangeLoading}
                  className="text-xs px-2 py-1 border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Set to In Progress
                </button>
              )}
              
              {task.status !== 'Completed' && (
                <button
                  onClick={() => handleStatusChange('Completed')}
                  disabled={statusChangeLoading}
                  className="text-xs px-2 py-1 border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Mark Complete
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Task Details */}
      <div className="px-6 py-5 grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column */}
        <div className="space-y-6">
          {/* Timeframe */}
          <div className="border-b pb-4">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Timeframe</h3>
            <div className="flex items-center mb-1">
              <Calendar size={16} className="text-gray-500 mr-2" />
              <p className="text-sm text-gray-600">
                Start: {task.startDate ? formatDate(task.startDate) : 'Not specified'}
              </p>
            </div>
            
            <div className="flex items-center mb-1">
              <Calendar size={16} className="text-gray-500 mr-2" />
              <p className="text-sm text-gray-600">
                End: {task.endDate ? formatDate(task.endDate) : 'Not specified'}
              </p>
            </div>
            
            {task.duration && (
              <div className="flex items-center">
                <Clock size={16} className="text-gray-500 mr-2" />
                <p className="text-sm text-gray-600">
                  Duration: {task.duration} days
                </p>
              </div>
            )}
          </div>
          
          {/* Objectives */}
          <div className="border-b pb-4">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-medium text-gray-900">Objectives</h3>
              <button 
                onClick={() => setShowObjectives(!showObjectives)}
                className="text-gray-500 hover:text-gray-700"
              >
                {showObjectives ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
              </button>
            </div>
            
            {showObjectives && (
              <ul className="space-y-2">
                {task.objectives && task.objectives.length > 0 ? (
                  task.objectives.map((objective, index) => (
                    <li key={index} className="flex items-start">
                      <div className="flex-shrink-0 mr-2">
                        <div className="w-6 h-6 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 text-xs font-medium">
                          {index + 1}
                        </div>
                      </div>
                      <p className="text-sm text-gray-600 mt-1">{objective.text}</p>
                    </li>
                  ))
                ) : (
                  <p className="text-sm text-gray-500 italic">No objectives defined</p>
                )}
              </ul>
            )}
          </div>
        </div>
        
        {/* Right Column */}
        <div className="space-y-6">
          {/* Reference */}
          {task.taskRef && task.taskRef.type && (
            <div className="border-b pb-4">
              <h3 className="text-lg font-medium text-gray-900 mb-2">Reference</h3>
              <div className="bg-gray-50 p-4 rounded-md">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-900">{task.taskRef.type}</p>
                    <p className="text-sm text-gray-600">{task.taskRef.title}</p>
                  </div>
                  
                  <Link
                    to={`/${task.taskRef.type.toLowerCase() === 'plan' ? 'planner' : 
                           task.taskRef.type.toLowerCase() === 'meeting' ? 'meetings' : 
                           'documents'}/details/${task.taskRef.id}`}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <ExternalLink size={16} />
                  </Link>
                </div>
              </div>
            </div>
          )}
          
          {/* Metadata */}
          <div className="border-b pb-4">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Metadata</h3>
            <div className="space-y-1 text-sm text-gray-600">
              <p>Created: {formatDate(task.createdAt || task.date)}</p>
              {task.updatedAt && <p>Updated: {formatDate(task.updatedAt)}</p>}
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteConfirm && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div 
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
              aria-hidden="true"
              onClick={() => setDeleteConfirm(false)}
            ></div>
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <AlertCircle className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Delete Task
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this task? This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleDelete}
                  disabled={isLoading}
                >
                  {isLoading ? 'Deleting...' : 'Delete'}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setDeleteConfirm(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskDetails;