// src/components/ai/ChatSidebar.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Plus, MessageSquare, Edit, Trash2, Check, X, MoreVertical } from 'lucide-react';
import { format } from 'date-fns';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';

const ChatSidebar = ({ 
  conversations, 
  activeConversation, 
  setActiveConversation, 
  createNewConversation,
  isLoading, 
  toggleSidebar 
}) => {
  const [editingId, setEditingId] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [showOptions, setShowOptions] = useState(null);
  const optionsRef = useRef(null);

  // Handle clicks outside of the options menu
  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(null);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Start editing a conversation title
  const startEditing = (conversation) => {
    setEditingId(conversation.id);
    setEditTitle(conversation.title);
    setShowOptions(null);
  };

  // Cancel editing
  const cancelEditing = () => {
    setEditingId(null);
    setEditTitle('');
  };

  // Save the edited title
  const saveTitle = async (conversationId) => {
    if (!editTitle.trim()) return;
    
    try {
      await updateDoc(doc(db, "dataInsightChats", conversationId), {
        title: editTitle.trim()
      });
      
      // Update local state if this is the active conversation
      if (activeConversation && activeConversation.id === conversationId) {
        setActiveConversation(prev => ({
          ...prev,
          title: editTitle.trim()
        }));
      }
      
      setEditingId(null);
    } catch (error) {
      console.error("Error updating conversation title:", error);
    }
  };

  // Delete a conversation
  const deleteConversation = async (conversationId) => {
    if (window.confirm("Are you sure you want to delete this conversation?")) {
      try {
        await deleteDoc(doc(db, "dataInsightChats", conversationId));
        
        // If we deleted the active conversation, reset it
        if (activeConversation && activeConversation.id === conversationId) {
          setActiveConversation(null);
        }
        
        setShowOptions(null);
      } catch (error) {
        console.error("Error deleting conversation:", error);
      }
    }
  };

  // Toggle options menu for a conversation
  const toggleOptions = (conversationId) => {
    setShowOptions(showOptions === conversationId ? null : conversationId);
  };

  // Format date safely
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'New chat';
    
    try {
      const date = timestamp instanceof Date ? 
        timestamp : 
        (timestamp.toDate ? timestamp.toDate() : new Date(timestamp));
      
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      return 'New chat';
    }
  };

  return (
    <div className="w-64 bg-[#1d67cd] text-white flex flex-col h-full">
      {/* Header with close button */}
      <div className="p-4 border-b border-[#3d7dd6] flex items-center justify-between">
        <h2 className="text-lg font-semibold">Data Insights</h2>
        <button
          onClick={toggleSidebar}
          className="md:hidden text-white hover:text-blue-200"
        >
          <X size={20} />
        </button>
      </div>
      {/* Header */}
      <div className="p-4 border-b border-[#3d7dd6]">
        <button
          onClick={createNewConversation}
          className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-[#1d67cd] bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
        >
          <Plus className="h-4 w-4 mr-1" />
          New Chat
        </button>
      </div>
      
      {/* Conversation List */}
      <div className="flex-1 overflow-y-auto py-2 overflow-x-hidden">
        {isLoading ? (
          <div className="flex justify-center items-center h-20">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
          </div>
        ) : conversations.length === 0 ? (
          <div className="px-4 py-8 text-center text-blue-100">
            <MessageSquare className="h-12 w-12 mx-auto mb-2 opacity-50" />
            <p className="text-sm">No conversations yet</p>
            <p className="text-xs mt-1">Start a new chat to begin</p>
          </div>
        ) : (
          <ul className="space-y-1 px-2">
            {conversations.map((conversation) => (
              <li key={conversation.id} className="relative">
                {editingId === conversation.id ? (
                  // Editing mode
                  <div className="px-3 py-2 rounded-md bg-[#3d7dd6]">
                    <input
                      type="text"
                      value={editTitle}
                      onChange={(e) => setEditTitle(e.target.value)}
                      className="w-full px-2 py-1 text-sm bg-white text-gray-800 rounded border-0 focus:outline-none focus:ring-2 focus:ring-white mb-2"
                      autoFocus
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') saveTitle(conversation.id);
                        if (e.key === 'Escape') cancelEditing();
                      }}
                    />
                    <div className="flex justify-end space-x-2">
                      <button
                        onClick={cancelEditing}
                        className="text-white hover:text-red-200 p-1"
                        title="Cancel"
                      >
                        <X className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => saveTitle(conversation.id)}
                        className="text-white hover:text-green-200 p-1"
                        title="Save"
                      >
                        <Check className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                ) : (
                  // Normal display mode
                  <div className="flex items-center">
                    <button
                      onClick={() => {
                        setActiveConversation(conversation);
                        setShowOptions(null);
                      }}
                      className={`flex-1 text-left px-3 py-2 rounded-md flex items-center overflow-hidden ${
                        activeConversation?.id === conversation.id
                          ? 'bg-[#3d7dd6] text-white'
                          : 'text-blue-100 hover:bg-[#2d6dc6] hover:text-white'
                      }`}
                    >
                      <MessageSquare className="h-5 w-5 mr-2 flex-shrink-0" />
                      <div className="overflow-hidden w-full">
                        <p className="truncate text-sm font-medium w-full">
                          {conversation.title}
                        </p>
                        <p className="text-xs text-blue-200 truncate w-full">
                          {formatTimestamp(conversation.updatedAt)}
                        </p>
                      </div>
                    </button>
                    
                    {/* Options button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleOptions(conversation.id);
                      }}
                      className={`p-1.5 rounded-full ml-1 flex-shrink-0 ${
                        showOptions === conversation.id 
                          ? 'bg-[#3d7dd6] text-white' 
                          : 'text-blue-200 hover:bg-[#2d6dc6] hover:text-white'
                      }`}
                    >
                      <MoreVertical className="h-4 w-4" />
                    </button>
                    
                    {/* Options menu */}
                    {showOptions === conversation.id && (
                      <div 
                        ref={optionsRef}
                        className="absolute right-2 z-10 mt-2 w-36 rounded-md shadow-lg bg-white" 
                        style={{ top: '100%' }}
                      >
                        <div className="py-1">
                          <button
                            onClick={() => startEditing(conversation)}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                          >
                            <Edit className="h-4 w-4 mr-2 text-gray-500" />
                            Rename
                          </button>
                          <button
                            onClick={() => deleteConversation(conversation.id)}
                            className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 flex items-center"
                          >
                            <Trash2 className="h-4 w-4 mr-2 text-red-500" />
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ChatSidebar;