// src/components/ai/ChatInterface.jsx
// src/components/ai/ChatInterface.jsx - Import statement fix
import React, { useState, useEffect, useRef } from 'react';
import { 
  Send,
  Menu, 
  Calendar, 
  Clock, 
  BarChart,
  FileText,
  ChevronDown,
  ChevronRight,
  X
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { doc, updateDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import { fetchContextData, formatContextData } from '../../utils/dataFetcher';
import { generateResponse } from '../../utils/geminiApi'; // Make sure this import is present
import TimeSelector from './TimeSelector';
import DataSourceSelector from './DataSourceSelector';
import ChatMessage from './ChatMessage';

const ChatInterface = ({ 
  conversation, 
  setConversation, 
  createNewConversation,
  isLoading, 
  error, 
  toggleSidebar,
  sidebarOpen  
}) => {
  const { currentUser, userProfile } = useAuth();
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [timePeriod, setTimePeriod] = useState('today');
  const [dataSources, setDataSources] = useState([]);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [showDataSourcePicker, setShowDataSourcePicker] = useState(false);
  const [apiError, setApiError] = useState('');
  const messageEndRef = useRef(null);
  const dataSourceButtonRef = useRef(null);
  
  // Scroll to bottom when messages change
  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [conversation?.messages]);

  // Rename conversation if it's the first message
  const updateConversationTitle = async (question) => {
    if (!conversation) return;
    
    try {
      // Only update title if this is the first message or it's still the default title
      if (conversation.messages.length === 0 || conversation.title === "New Conversation") {
        // Create a title from the first ~30 chars of the question
        const newTitle = question.substring(0, 30) + (question.length > 30 ? '...' : '');
        
        await updateDoc(doc(db, "dataInsightChats", conversation.id), {
          title: newTitle,
          updatedAt: serverTimestamp()
        });
        
        // Update local state
        setConversation(prev => ({
          ...prev,
          title: newTitle,
          updatedAt: new Date()
        }));
      }
    } catch (err) {
      console.error("Error updating conversation title:", err);
    }
  };

  // Generate prompt with context
  const generatePrompt = (userMessage, timeContext, dataContext) => {
    return `
You are a helpful AI assistant for a school management system called Schoolmate. You help users gain insights from their data.

Context:
- Time period: ${timeContext}
- Data sources: ${dataContext}

User's question: ${userMessage}

Based on the time period and data sources mentioned, provide a helpful answer to the user's question.
If you don't have enough information, explain what specific data would help answer the question better.
`;
  };

  // Send message to AI
  const sendMessage = async () => {
    if (!message.trim() || !conversation || sending) return;
    
    try {
      setSending(true);
      setApiError('');
      
      // Format time display
      const timeContext = timePeriod.replace('_', ' ');
      const dataContext = dataSources.length > 0 
        ? dataSources.join(', ') 
        : 'all available sources';
      
      // Add user message to conversation
      const userMessage = {
        role: 'user',
        content: message,
        timestamp: new Date(),
        timePeriod,
        dataSources: [...dataSources]
      };
      
      // Update title based on first message
      await updateConversationTitle(message);
      
      // Add user message to Firestore
      await updateDoc(doc(db, "dataInsightChats", conversation.id), {
        messages: arrayUnion(userMessage),
        updatedAt: serverTimestamp()
      });
      
      // Update local state for immediate UI update
      setConversation(prev => ({
        ...prev,
        messages: [...(prev.messages || []), userMessage],
        updatedAt: new Date()
      }));
      
      // Clear message input
      setMessage('');
      
      // Show loading indicator
      const loadingMessage = {
        role: 'assistant',
        content: "Fetching data and generating insights...",
        isLoading: true,
        timestamp: new Date()
      };
      
      // Update local state with loading message (optional)
      setConversation(prev => ({
        ...prev,
        messages: [...(prev.messages || []), loadingMessage]
      }));
      
      // Fetch context data from Firebase based on selected time period and data sources
      let contextData = {};
        try {
        if (dataSources.length > 0) {
            contextData = await fetchContextData(timePeriod, dataSources, userProfile);
            console.log("Context data retrieved:", contextData);
        } else {
            console.log("No data sources selected, skipping context data fetch");
            contextData = { message: "No data sources were selected" };
        }
        } catch (fetchError) {
        console.error("Error fetching context data:", fetchError);
        contextData = { error: `Failed to fetch context data: ${fetchError.message}` };
        }

        // Format the context data for the prompt - with error handling
        let formattedContext;
        try {
        formattedContext = formatContextData(contextData);
        } catch (formatError) {
        console.error("Error formatting context data:", formatError);
        formattedContext = "Error: Could not format the retrieved data.";
        }
      
      // Generate the prompt with context data
      const prompt = `
  You are an AI assistant for Schoolmate, a school management system. You're analyzing data for ${userProfile?.institutionName || 'an educational institution'}.
  
  TIME PERIOD: ${timeContext}
  DATA SOURCES: ${dataContext}
  
  AVAILABLE DATA:
  ${formattedContext}
  
  USER QUESTION: ${message}
  
  Based on the provided data, please analyze and respond to the user's question.
  If the data is insufficient, explain what specific information would help provide a better answer.
  Keep your response concise and focused on the question. Highlight key insights and trends when relevant.
  `;
  
      try {
        // Call Gemini API with the enhanced prompt
        const aiResponseText = await generateResponse(prompt);
        
        // Create AI response object
        const aiResponse = {
          role: 'assistant',
          content: aiResponseText,
          timestamp: new Date()
        };
        
        // Remove loading message if it was added
        setConversation(prev => ({
          ...prev,
          messages: prev.messages.filter(msg => !msg.isLoading)
        }));
        
        // Add AI response to Firestore
        await updateDoc(doc(db, "dataInsightChats", conversation.id), {
          messages: arrayUnion(aiResponse),
          updatedAt: serverTimestamp()
        });
        
        // Update local state
        setConversation(prev => ({
          ...prev,
          messages: [...prev.messages.filter(msg => !msg.isLoading), aiResponse],
          updatedAt: new Date()
        }));
      } catch (apiErr) {
        console.error("Error from Gemini API:", apiErr);
        setApiError(`Failed to get AI response: ${apiErr.message}`);
        
        // Remove loading message if it was added
        setConversation(prev => ({
          ...prev,
          messages: prev.messages.filter(msg => !msg.isLoading)
        }));
        
        // Add error message to the chat
        const errorResponse = {
          role: 'assistant',
          content: "I'm sorry, I encountered an error processing your request. Please try again later.",
          isError: true,
          timestamp: new Date()
        };
        
        // Add error response to Firestore
        await updateDoc(doc(db, "dataInsightChats", conversation.id), {
          messages: arrayUnion(errorResponse),
          updatedAt: serverTimestamp()
        });
        
        // Update local state
        setConversation(prev => ({
          ...prev,
          messages: [...prev.messages.filter(msg => !msg.isLoading), errorResponse],
          updatedAt: new Date()
        }));
      }
      
    } catch (err) {
      console.error("Error sending message:", err);
      setApiError(`Error: ${err.message}`);
    } finally {
      setSending(false);
    }
  };

  // Handle key press (Enter to send)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  if (!conversation) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center bg-gray-100 p-6">
        <BarChart className="h-16 w-16 text-gray-400 mb-4" />
        <h2 className="text-2xl font-bold text-gray-700 mb-2">AI Data Insights</h2>
        <p className="text-gray-500 mb-6 text-center max-w-md">
          Ask questions about your data to gain insights on activity, progress, and challenges
        </p>
        <button
          onClick={createNewConversation}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Send className="h-4 w-4 mr-2" />
          Start a New Chat
        </button>
      </div>
    );
  }

  return (
    <div className="flex-1 flex flex-col bg-gray-100 overflow-hidden h-full">
      {/* Chat Header with mobile sidebar toggle */}
      <div className="bg-white border-b flex items-center px-4 py-2 shadow-sm">
        <button 
          onClick={toggleSidebar}
          className="md:hidden mr-4 text-gray-500 hover:text-gray-700"
        >
          <ChevronRight size={24} />
        </button>
        <h2 className="text-lg font-medium text-gray-800">
          {conversation ? conversation.title : 'AI Data Insights'}
        </h2>
      </div>

      {/* Chat Messages Area - keep your existing code */}
      <div className="flex-1 overflow-y-auto p-4">
        {/* Your existing chat messages code remains unchanged */}
        {apiError && (
          <div className="mb-4 bg-red-50 border-l-4 border-red-500 p-3 text-red-700 text-sm">
            {apiError}
          </div>
        )}
        
        {conversation?.messages && conversation.messages.length > 0 ? (
          <div className="space-y-4">
            {conversation.messages.map((msg, index) => (
              <ChatMessage 
                key={index} 
                message={msg} 
              />
            ))}
          </div>
        ) : (
          <div className="flex-1 flex flex-col items-center justify-center py-12">
            <BarChart className="h-12 w-12 text-gray-400 mb-4" />
            <h3 className="text-lg font-medium text-gray-700 mb-2">Ask about your data</h3>
            <p className="text-gray-500 mb-6 text-center max-w-md">
              Select a time period and data sources, then ask questions to gain insights
            </p>
            
            <div className="w-full max-w-md px-4">
              <div className="bg-white shadow-sm rounded-md p-4 mb-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">Example questions:</h4>
                <ul className="space-y-2 text-gray-600 text-sm">
                  <li className="cursor-pointer hover:text-blue-600" onClick={() => setMessage("What were the most reported incidents last week?")}>
                    • What were the most reported incidents last week?
                  </li>
                  <li className="cursor-pointer hover:text-blue-600" onClick={() => setMessage("Show me student attendance trends for the previous month")}>
                    • Show me student attendance trends for the previous month
                  </li>
                  <li className="cursor-pointer hover:text-blue-600" onClick={() => setMessage("What challenges were reported yesterday?")}>
                    • What challenges were reported yesterday?
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        <div ref={messageEndRef} />
      </div>
      
      {/* Input Area - keep your existing code */}
      <div className="border-t border-gray-200 bg-white p-4">
        {/* Rest of your existing input code remains unchanged */}
        <div className="mb-2 flex justify-between">
          <div className="flex space-x-2">
            {/* Time selector */}
            <div className="relative">
              <button 
                className={`inline-flex items-center px-3 py-1 border ${timePeriod ? 'border-blue-500 text-blue-700 bg-blue-50' : 'border-gray-300 text-gray-700'} rounded-md text-sm`}
                onClick={() => setShowTimePicker(!showTimePicker)}
              >
                <Calendar className="h-4 w-4 mr-1" />
                {timePeriod.replace('_', ' ') || 'Select time'}
                <ChevronDown className="h-4 w-4 ml-1" />
              </button>
              
              {showTimePicker && (
                <TimeSelector 
                  onSelect={(period) => {
                    setTimePeriod(period);
                    setShowTimePicker(false);
                  }}
                  onClose={() => setShowTimePicker(false)}
                />
              )}
            </div>
            
            {/* Data source selector */}
            <div className="relative">
              <button 
                className={`inline-flex items-center px-3 py-1 border ${dataSources.length > 0 ? 'border-blue-500 text-blue-700 bg-blue-50' : 'border-gray-300 text-gray-700'} rounded-md text-sm`}
                onClick={() => setShowDataSourcePicker(!showDataSourcePicker)}
              >
                <FileText className="h-4 w-4 mr-1" />
                {dataSources.length > 0 ? `${dataSources.length} sources` : 'Data sources'}
                <ChevronDown className="h-4 w-4 ml-1" />
              </button>
              
              {showDataSourcePicker && (
                <DataSourceSelector 
                  selected={dataSources}
                  onSelect={setDataSources}
                  onClose={() => setShowDataSourcePicker(false)}
                />
              )}
            </div>
          </div>
        </div>
        
        <div className="flex rounded-md shadow-sm">
          <textarea
            className="flex-1 min-h-[60px] max-h-32 focus:ring-blue-500 focus:border-blue-500 block w-full rounded-md sm:text-sm border-gray-300 resize-none py-2 px-4"
            placeholder="Ask about your data..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button
            type="button"
            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md shadow-sm text-white ${
              !message.trim() || sending 
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            }`}
            onClick={sendMessage}
            disabled={!message.trim() || sending}
          >
            {sending ? (
              <div className="h-5 w-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
            ) : (
              <Send className="h-5 w-5" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;