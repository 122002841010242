import React, { useState } from 'react';
import { 
  MonitoringCard as Card, 
  MonitoringCardContent as CardContent, 
  MonitoringCardDescription as CardDescription, 
  MonitoringCardFooter as CardFooter, 
  MonitoringCardHeader as CardHeader, 
  MonitoringCardTitle as CardTitle 
} from '../common/MonitoringCard';
import { 
  BarChart2, 
  PieChart, 
  Download, 
  FileText, 
  Calendar, 
  Clock, 
  AlertTriangle, 
  TrendingUp, 
  TrendingDown,
  Filter,
  CheckCircle,
  School
} from 'lucide-react';

const MonitoringAnalytics = () => {
  const [selectedSchool, setSelectedSchool] = useState('all');
  const [timeframe, setTimeframe] = useState('q1-2025');
  const [category, setCategory] = useState('all');
  
  // Sample data
  const complianceByCategory = [
    { category: 'Administration', rate: 78, change: 5 },
    { category: 'Policies', rate: 65, change: -3 },
    { category: 'Curriculum', rate: 82, change: 7 },
    { category: 'Infrastructure', rate: 58, change: 2 },
    { category: 'Finances', rate: 70, change: 0 },
    { category: 'Nutrition', rate: 90, change: 12 },
    { category: 'Campaigns', rate: 45, change: -8 }
  ];
  
  const schoolsData = [
    { id: 'sch1', name: 'Pretoria High School', overallRate: 82, urgentIssues: 0 },
    { id: 'sch2', name: 'Johannesburg Secondary School', overallRate: 71, urgentIssues: 1 },
    { id: 'sch3', name: 'Cape Town Primary', overallRate: 78, urgentIssues: 0 },
    { id: 'sch4', name: 'Township Secondary School', overallRate: 45, urgentIssues: 3 },
    { id: 'sch5', name: 'Riverside Primary School', overallRate: 58, urgentIssues: 2 }
  ];
  
  const criticalIssues = [
    { id: 1, school: 'Township Secondary School', area: 'Administration', issue: 'Missing essential administrative records', severity: 'critical' },
    { id: 2, school: 'Township Secondary School', area: 'Policies', issue: 'No implemented safety policy', severity: 'critical' },
    { id: 3, school: 'Riverside Primary School', area: 'Infrastructure', issue: 'Damaged ablution facilities', severity: 'high' },
    { id: 4, school: 'Johannesburg Secondary School', area: 'Finances', issue: 'Financial records not audited', severity: 'medium' }
  ];
  
  // Helper function for color coding
  const getRateColor = (rate) => {
    if (rate >= 80) return 'text-green-600';
    if (rate >= 60) return 'text-amber-600';
    return 'text-red-600';
  };
  
  const getChangeIndicator = (change) => {
    if (change > 0) return <TrendingUp className="h-4 w-4 text-green-600" />;
    if (change < 0) return <TrendingDown className="h-4 w-4 text-red-600" />;
    return <span className="h-4 w-4 inline-block" />;
  };
  
  const getSeverityColor = (severity) => {
    if (severity === 'critical') return 'bg-red-100 text-red-800';
    if (severity === 'high') return 'bg-amber-100 text-amber-800';
    return 'bg-blue-100 text-blue-800';
  };
  
  return (
    <div className="space-y-6 p-6">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">School Monitoring Analytics</h1>
          <p className="text-gray-600">Comprehensive view of school compliance and monitoring data</p>
        </div>
        
        <div className="mt-4 md:mt-0 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
          <select 
            className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={timeframe}
            onChange={(e) => setTimeframe(e.target.value)}
          >
            <option value="q1-2025">Q1 2025</option>
            <option value="q4-2024">Q4 2024</option>
            <option value="q3-2024">Q3 2024</option>
            <option value="q2-2024">Q2 2024</option>
            <option value="yearly-2024">Full Year 2024</option>
          </select>
          
          <select 
            className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={selectedSchool}
            onChange={(e) => setSelectedSchool(e.target.value)}
          >
            <option value="all">All Schools</option>
            {schoolsData.map(school => (
              <option key={school.id} value={school.id}>{school.name}</option>
            ))}
          </select>
          
          <select 
            className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="all">All Categories</option>
            {complianceByCategory.map(cat => (
              <option key={cat.category} value={cat.category.toLowerCase()}>{cat.category}</option>
            ))}
          </select>
          
          <button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50">
            <Download className="h-4 w-4 mr-2" />
            Export
          </button>
        </div>
      </div>
      
      {/* Overview Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <Card>
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500">Schools Monitored</p>
                <p className="text-2xl font-bold mt-1">{schoolsData.length}</p>
              </div>
              <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center text-blue-600">
                <School className="h-5 w-5" />
              </div>
            </div>
          </CardContent>
        </Card>
        
        <Card>
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500">Average Compliance</p>
                <p className="text-2xl font-bold mt-1">
                  {Math.round(schoolsData.reduce((acc, school) => acc + school.overallRate, 0) / schoolsData.length)}%
                </p>
              </div>
              <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center text-green-600">
                <CheckCircle className="h-5 w-5" />
              </div>
            </div>
          </CardContent>
        </Card>
        
        <Card>
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500">Critical Issues</p>
                <p className="text-2xl font-bold mt-1">{criticalIssues.filter(issue => issue.severity === 'critical').length}</p>
              </div>
              <div className="w-10 h-10 bg-red-100 rounded-full flex items-center justify-center text-red-600">
                <AlertTriangle className="h-5 w-5" />
              </div>
            </div>
          </CardContent>
        </Card>
        
        <Card>
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500">Most Recent Visit</p>
                <p className="text-xl font-bold mt-1">March 20, 2025</p>
              </div>
              <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center text-purple-600">
                <Calendar className="h-5 w-5" />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      
      {/* Main Analysis Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Compliance by Category */}
        <Card className="md:col-span-2">
          <CardHeader>
            <CardTitle>Compliance by Category</CardTitle>
            <CardDescription>Overview of compliance rates across different monitoring areas</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {complianceByCategory.map(cat => (
                <div key={cat.category} className="flex items-center">
                  <div className="w-32 text-sm font-medium">{cat.category}</div>
                  <div className="flex-1 mx-2">
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div 
                        className={`h-2.5 rounded-full ${
                          cat.rate >= 80 ? 'bg-green-600' : 
                          cat.rate >= 60 ? 'bg-amber-500' : 
                          'bg-red-600'
                        }`} 
                        style={{ width: `${cat.rate}%` }}
                      ></div>
                    </div>
                  </div>
                  <div className="w-16 flex items-center">
                    <span className={`text-sm font-medium ${getRateColor(cat.rate)}`}>{cat.rate}%</span>
                    <span className="ml-1">
                      {getChangeIndicator(cat.change)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-6 pt-4 border-t border-gray-200">
              <div className="flex items-center justify-between text-sm">
                <div className="flex items-center">
                  <span className="w-3 h-3 bg-green-600 rounded-full mr-2"></span>
                  <span className="text-gray-600">Good (80%+)</span>
                </div>
                <div className="flex items-center">
                  <span className="w-3 h-3 bg-amber-500 rounded-full mr-2"></span>
                  <span className="text-gray-600">Needs Improvement (60-79%)</span>
                </div>
                <div className="flex items-center">
                  <span className="w-3 h-3 bg-red-600 rounded-full mr-2"></span>
                  <span className="text-gray-600">Critical Attention (below 60%)</span>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        
        {/* School Rankings */}
        <Card>
          <CardHeader>
            <CardTitle>School Compliance Ranking</CardTitle>
            <CardDescription>Overall compliance ratings by school</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {schoolsData
                .sort((a, b) => b.overallRate - a.overallRate)
                .map((school, index) => (
                <div key={school.id} className="flex items-center">
                  <div className="w-6 text-sm font-medium text-gray-500">{index + 1}</div>
                  <div className="flex-1 mx-2 text-sm">
                    <div className="font-medium">{school.name}</div>
                    <div className="mt-1 w-full bg-gray-200 rounded-full h-2">
                      <div 
                        className={`h-2 rounded-full ${
                          school.overallRate >= 80 ? 'bg-green-600' : 
                          school.overallRate >= 60 ? 'bg-amber-500' : 
                          'bg-red-600'
                        }`} 
                        style={{ width: `${school.overallRate}%` }}
                      ></div>
                    </div>
                  </div>
                  <div className="w-12 text-right">
                    <span className={`text-sm font-medium ${getRateColor(school.overallRate)}`}>
                      {school.overallRate}%
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
        
        {/* Critical Issues */}
        <Card className="md:col-span-2">
          <CardHeader>
            <CardTitle>Critical Issues Requiring Attention</CardTitle>
            <CardDescription>Issues identified during monitoring that need immediate action</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="divide-y divide-gray-200">
              {criticalIssues.length > 0 ? criticalIssues.map(issue => (
                <div key={issue.id} className="py-3 flex items-start">
                  <div className={`mt-0.5 px-2 py-1 rounded-md text-xs font-medium ${getSeverityColor(issue.severity)}`}>
                    {issue.severity.toUpperCase()}
                  </div>
                  <div className="ml-3 flex-1">
                    <div className="flex justify-between">
                      <p className="text-sm font-medium text-gray-900">{issue.school}</p>
                      <p className="text-sm text-gray-500">{issue.area}</p>
                    </div>
                    <p className="mt-1 text-sm text-gray-600">{issue.issue}</p>
                  </div>
                </div>
              )) : (
                <div className="py-6 text-center text-gray-500">
                  <CheckCircle className="h-8 w-8 mx-auto text-green-500 mb-2" />
                  <p>No critical issues to display</p>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
        
        {/* Time-based Analysis */}
        <Card>
          <CardHeader>
            <CardTitle>Monitoring Trends</CardTitle>
            <CardDescription>Compliance change over time</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="h-48 flex items-center justify-center bg-gray-100 rounded-md mb-4">
              <p className="text-gray-500">Line chart visualization would appear here</p>
            </div>
            <div className="text-sm text-gray-600">
              <h4 className="font-medium">Key Observations:</h4>
              <ul className="mt-2 space-y-1 list-disc list-inside">
                <li>Improvement in Administration compliance</li>
                <li>Decline in Policy implementation</li>
                <li>Infrastructure remains a challenge</li>
              </ul>
            </div>
          </CardContent>
        </Card>
      </div>
      
      {/* Recommendations Section */}
      <Card>
        <CardHeader>
          <CardTitle>AI-Generated Recommendations</CardTitle>
          <CardDescription>Intelligent insights based on monitoring data</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
              <h3 className="text-lg font-semibold text-blue-800 mb-2">Administration</h3>
              <p className="text-sm text-blue-700">Standard operating procedures for record-keeping should be implemented at Township Secondary School, where administrative documentation compliance is critically low at 32%.</p>
            </div>
            
            <div className="bg-amber-50 p-4 rounded-lg border border-amber-200">
              <h3 className="text-lg font-semibold text-amber-800 mb-2">Policies</h3>
              <p className="text-sm text-amber-700">Conduct policy review workshops for all schools in the circuit. 65% of schools need to update their safety and security policies to align with current regulations.</p>
            </div>
            
            <div className="bg-green-50 p-4 rounded-lg border border-green-200">
              <h3 className="text-lg font-semibold text-green-800 mb-2">Best Practices</h3>
              <p className="text-sm text-green-700">Pretoria High School's nutrition program management can serve as a model for other schools. Consider organizing peer learning visits to share successful approaches.</p>
            </div>
          </div>
        </CardContent>
        <CardFooter className="border-t pt-4 flex justify-between">
          <p className="text-sm text-gray-500">Recommendations are based on data from 5 schools and analysis of 24 monitoring reports</p>
          <button className="text-blue-600 hover:text-blue-800 text-sm font-medium inline-flex items-center">
            <FileText className="h-4 w-4 mr-1" />
            Generate Detailed Recommendations Report
          </button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default MonitoringAnalytics;