// src/pages/SchoolStructuresPage.js
import React from 'react';
import { Outlet } from 'react-router-dom';

const SchoolStructuresPage = () => {
  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-800">School Structures</h1>
        <p className="text-gray-600 mt-1">
          Manage departments, committees, and other organisational structures at your institution.
        </p>
      </div>
      <Outlet />
    </div>
  );
};

export default SchoolStructuresPage;
