// src/pages/AIChatPage.jsx - Update the collection name
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase/config';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs, 
  addDoc, 
  serverTimestamp 
} from 'firebase/firestore';
import ChatAISidebar from '../components/ai/ChatSidebar';
import ChatInterface from '../components/ai/ChatInterface';

const AIChatPage = () => {
  const { currentUser, userProfile } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [AISidebarOpen, setAISidebarOpen] = useState(false);

  // Toggle AISidebar function
  const toggleAISidebar = () => {
    setAISidebarOpen(!AISidebarOpen);
  };
  // Fetch user's conversations - updated collection name
  useEffect(() => {
    if (!currentUser) return;

    const fetchConversations = async () => {
      setIsLoading(true);
      try {
        const conversationsRef = collection(db, "dataInsightChats"); // Changed collection name
        const q = query(
          conversationsRef,
          where("userId", "==", currentUser.uid),
          orderBy("updatedAt", "desc")
        );

        const querySnapshot = await getDocs(q);
        const conversationsList = [];
        
        querySnapshot.forEach((doc) => {
          conversationsList.push({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate(),
            updatedAt: doc.data().updatedAt?.toDate()
          });
        });
        
        setConversations(conversationsList);
        
        // Set active conversation to the most recent one if any exist
        if (conversationsList.length > 0 && !activeConversation) {
          setActiveConversation(conversationsList[0]);
        }
      } catch (err) {
        console.error("Error fetching conversations:", err);
        setError("Failed to load conversations");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchConversations();
  }, [currentUser, activeConversation]);

  // Create a new conversation - updated collection name
  const createNewConversation = async () => {
    if (!currentUser) return;
    
    try {
      const newConversation = {
        userId: currentUser.uid,
        institutionId: userProfile?.institutionId || '',
        title: "New Conversation",
        messages: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };
      
      const docRef = await addDoc(collection(db, "dataInsightChats"), newConversation); // Changed collection name
      
      const conversationWithId = {
        id: docRef.id,
        ...newConversation,
        createdAt: new Date(),
        updatedAt: new Date()
      };
      
      setConversations(prev => [conversationWithId, ...prev]);
      setActiveConversation(conversationWithId);
      
    } catch (err) {
      console.error("Error creating new conversation:", err);
      setError("Failed to create new conversation");
    }
  };

  return (
    <div className="h-full flex overflow-hidden bg-gray-50">
      {/* Overlay for mobile when AISidebar is open */}
      {AISidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-10 md:hidden"
          onClick={toggleAISidebar}
        ></div>
      )}
    
      {/* AISidebar with conversation history - fix the positioning */}
      <div className={`
        fixed left-0 top-0 bottom-0 md:relative h-full z-20 md:z-auto 
        transition-all duration-300 ease-in-out
        md:transform-none md:translate-x-0
        ${AISidebarOpen ? 'translate-x-0' : '-translate-x-full'}
      `}></div>

      {/* AISidebar with conversation history */}
      <ChatAISidebar 
        conversations={conversations}
        activeConversation={activeConversation}
        setActiveConversation={setActiveConversation}
        createNewConversation={createNewConversation}
        isLoading={isLoading}
      />
      
      {/* Main content area with chat interface */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <ChatInterface 
          conversation={activeConversation}
          setConversation={setActiveConversation}
          createNewConversation={createNewConversation}
          isLoading={isLoading}
          error={error}
        />
      </div>
    </div>
  );
};

export default AIChatPage;